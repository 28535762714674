// en_US => en
// DO NOT USE IT ANYMORE!!
// DO NOT USE IT ANYMORE!!
//
// After the feature flag(https://app.launchdarkly.com/jira/staging/features/use.bcp47.locale.format/targeting) is cleaned-up, this will be removed.
// The reason why it is deprecated is that we are providing locale to ReactIntlProvider as a locale prop after applying
// this function. This is wrong because we are passing only language part ignoring the region variance.
// The consequence is react-intl's format functions such as formatDate will format date into MM-DD-YYYY, instead of DD-MM-YYYY
// in case of en-gb. The similar things could happen in any other language variances.
// On 2. Feb. 2021, it is confirmed that this function is only used to provide locale for IntlProvider component
// Under the feature flag, it is replaced with getBCPLocale function that replaces _ to - to be BCP47 web standard locale format
//
// DO NOT USE IT ANYMORE!!
// DO NOT USE IT ANYMORE!!
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (locale: string): string => (locale ? locale.substring(0, locale.indexOf('_')) : '');
