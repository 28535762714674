import { useEffect } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { wrmBundleResource } from '@atlassian/jira-router-resources-classic-projects/src/services/wrm-bundle/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import type { StoreApi, UseWrmBundleLoaderResponse } from './types.tsx';

const actions = {
	initGhContext:
		() =>
		({ setState, getState }: StoreApi) => {
			const { hasGHContextLoaded, isGhContextLoading } = getState();

			if (!hasGHContextLoaded && !isGhContextLoading) {
				setState({ isGhContextLoading: true });
				setState({ hasGHContextLoaded: true, isGhContextLoading: false });
			}
		},
} as const;

export type Actions = typeof actions;

export const useWrmBundleLoader = (): UseWrmBundleLoaderResponse => {
	const { data: wrmBundleDescriptor, error: errorGHContext } = useResource(wrmBundleResource);

	useEffect(() => {
		if (errorGHContext != null) {
			log.safeErrorWithoutCustomerData(
				'classic.wrm.bundle.error',
				'Failed to load WRM bundle for Rapidboards',
				errorGHContext,
			);
		}
	}, [errorGHContext]);

	// @ts-expect-error - TS2322 - Type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error) | null' is not assignable to type 'Error | null'.
	return { hasGHContextLoaded: !!wrmBundleDescriptor, errorGHContext };
};
