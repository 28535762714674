import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'classic-software.common.error-flag.title',
		defaultMessage: 'Oops! Something went wrong',
		description:
			'Title of popup indicating how that something went wrong loading this classic project page.',
	},
	description: {
		id: 'classic-software.common.error-flag.description',
		defaultMessage: 'We cannot access this page at the moment. Please try again later.',
		description: 'Description of popup indicating how we cannot load this page at the moment.',
	},
});
