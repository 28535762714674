import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	columnConfigDescriptionCmp: {
		id: 'spa-apps-servicedesk-legacy-board-settings.column-config-description-cmp',
		defaultMessage:
			'Add, rename, or remove columns on this board. Columns are represented by a global status, with each containing the statuses belonging to workflows created in this project. <a>View workflows to edit statuses</a>.',
		description: 'Description under the column management page title in company managed JSM.',
	},
	columnConfigDescriptionTmp: {
		id: 'spa-apps-servicedesk-legacy-board-settings.column-config-description-tmp',
		defaultMessage:
			'Add, rename, or remove columns on this board. Columns are represented by a global status, with each containing the statuses belonging to workflows created in this project. <a>View workflows for each request type to edit statuses</a>.',
		description: 'Description under the column management page title in team managed JSM.',
	},
	columnConstraintGroupInstructions: {
		id: 'spa-apps-servicedesk-legacy-board-settings.column-constraint-group-instructions',
		defaultMessage: 'Minimum and maximum constraints can be set for each mapped column.',
		description: 'Description under the column constraint drop down selection.',
	},
});
