import { ROUTE_NAMES_RAPIDBOARD_REPORT } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';

export type EventHandler = (event: Event | CustomEvent) => void;

/**
 * Add an event listener and return a disposer function that will clear the
 * listener when called.
 */
export const listenTo = (name: string, callback: EventHandler): (() => void) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.addEventListener(name, callback);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return () => window.removeEventListener(name, callback);
};

export const useIsReportsPage = (): boolean => {
	const route = useCurrentRoute();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (ROUTE_NAMES_RAPIDBOARD_REPORT as string) === route.name;
};
