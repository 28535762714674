import React, { useMemo } from 'react';
import {
	getExperienceAttributes,
	getExperienceId,
} from '@atlassian/jira-classic-software/src/ui/sla-tracker/utils.tsx';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import { HistoryAdapter } from '@atlassian/jira-spa-router-adapters/src/controllers/history/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import RapidBoard from './main.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	// @ts-expect-error - TS2739 - Type '{ children: (history: any) => Element; }' is missing the following properties from type 'RouteContext': location, query, route, match, action
	<HistoryAdapter>{(history) => <RapidBoardWithSLA history={history} />}</HistoryAdapter>
);

type Props = {
	history: ReactRouterCompatibleHistory;
};

export const RapidBoardWithSLA = ({ history }: Props) => {
	const { appEditions } = useTenantContext();
	const { name } = useCurrentRoute();
	const [rapidView = ''] = useQueryParam('rapidView');
	const [projectKey = ''] = useQueryParam('projectKey');
	const [chart = ''] = useQueryParam('chart');
	const experienceId = getExperienceId(rapidView, projectKey, name, chart);
	const additionalAttributes = useMemo(
		() => ({
			synthetic: ff('jsw.pollinator.tenants'),
			isClassic: true,
			isSPA: true,
		}),
		[],
	);

	const experienceAttributes = useMemo(
		() => getExperienceAttributes(name, appEditions.software, chart),
		[appEditions.software, chart, name],
	);

	return (
		<UFOSegment name="rapid-board-with-sla">
			<ViewExperienceTrackingProvider
				experienceId={experienceId} // Use to reset value
				{...experienceAttributes}
				additionalAttributes={additionalAttributes}
			>
				<ErrorBoundary id="rapid-board" packageName="jiraClassicBoardAndBacklog">
					<RapidBoard history={history} />
				</ErrorBoundary>
			</ViewExperienceTrackingProvider>
		</UFOSegment>
	);
};
