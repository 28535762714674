import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { boardConfigApiResource } from '@atlassian/jira-router-resources-classic-projects/src/services/config-api/index.tsx';
import { getRapidBoardDataResource } from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { rapidboardResource } from '@atlassian/jira-router-resources-classic-projects/src/services/rapidboard-resource/index.tsx';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import { CONFIG_DATA, REPORT, WORK } from '../../constants.tsx';
import { isAllDataErrorValid } from '../../utils.tsx';
import { useRapidBoardContentLoadListener } from '../rapidboard-content-listener/index.tsx';
import { useRapidBoardResource } from '../resources/index.tsx';
import { useUrlState } from '../url-state/index.tsx';
import { useWrmBundleLoader } from '../wrm-bundle-state/index.tsx';
import { useCalendarSideEffects } from './utils/calendar.tsx';
import { cleanupDOM, cleanupGH, cleanupResources, hideGhControllers } from './utils/clean-up.tsx';
import {
	afterRenderSetup,
	initDOM,
	renderRapidboardDom,
	resetWorkControllerFilters,
	setupConfigResources,
	setUpHistory,
	setUpWrmData,
} from './utils/set-up.tsx';

type RapidboardApps = {
	startApps: () => void;
};

let resolveInstallAppsPromise: (
	result: Promise<{ startApps: () => void }> | { startApps: () => void },
) => void;
const installAppsPromise: Promise<RapidboardApps> = new Promise((resolve) => {
	resolveInstallAppsPromise = resolve;
});

/**
 * We import from /entry because we cannot move the app install logic while rapidboard lives inside and outside the SPA
 */
(() => {
	import(
		/* webpackChunkName: "async-rapid-board-apps" */ '@atlassian/jira-entry-rapid-board-wrm-apps'
	).then((rapidBoardApps) => {
		resolveInstallAppsPromise(rapidBoardApps);
	});
})();

const startApps = () => {
	installAppsPromise.then((rapidBoardApps) => rapidBoardApps.startApps());
};
const useConfigResource = (boardId: string | number) => {
	const { promise, refresh, loading, update, data } = useRapidBoardResource({
		resource: boardConfigApiResource,
		shouldShowFlag: false,
	});
	const key = `rapidViewConfig-${boardId}`;
	return useMemo(
		() => ({
			key,
			data,
			promise,
			refresh,
			update,
			loading,
		}),
		[key, data, promise, refresh, update, loading],
	);
};

export const useRapidBoardSetupAndTearDown = () => {
	const [hasLayoutLoaded, setHasLayoutLoaded] = useState(false);
	const { data: rapidboardData, loading: rapidboardResourceLoading } = useRapidBoardResource({
		resource: rapidboardResource,
	});
	const [{ data: projectData }] = useContainerContext();

	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	const isBentoOn = !!rapidboardData?.isNewIssueViewEnabled;
	const hasProjectDataLoaded = !!projectData;
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'. | TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	const projectKey = projectData && projectData.project && projectData.project.key;
	// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'. | TS2339 - Property 'project' does not exist on type 'ContainerContext'.
	const projectId = projectData && projectData.project && projectData.project.id;

	useLayoutEffect(
		() => () => {
			cleanupGH(window, document)();
		},
		[],
	);

	useLayoutEffect(() => {
		let metaTags: Array<HTMLElement> = [];
		let initalClassList = ['adg3'];
		if (!rapidboardResourceLoading && hasProjectDataLoaded) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			initalClassList = Array.from(document.body ? document.body.classList : []);
			metaTags = initDOM(isBentoOn, projectKey, String(projectId));
			setHasLayoutLoaded(true);
		}
		return () => {
			setHasLayoutLoaded(false);
			ff('jsw.classic.board.api-calls.modern-frontend') && cleanupResources([CONFIG_DATA]);
			cleanupDOM(document)(initalClassList, metaTags, isBentoOn);
		};
	}, [rapidboardResourceLoading, hasProjectDataLoaded, isBentoOn, projectId, projectKey]);

	useEffect(
		() => () => {
			!rapidboardResourceLoading && hideGhControllers();
		},
		[rapidboardResourceLoading],
	);

	return [hasLayoutLoaded, isBentoOn] as const;
};

const handleBackToBackNavigation = (mode: string) => {
	const { GH } = window;
	if (
		ff('jsw.classic.board.api-calls.modern-frontend') &&
		GH.RapidBoard.State?.isFirstLoad() === false
	) {
		GH.RapidBoard.State.setMode(mode);
	}
};

export const useRapidBoard = (history: ReactRouterCompatibleHistory) => {
	const [pastRapidView, setPastRapidView] = useState<string | null | undefined>(null);
	const [pastChartView, setPastChartView] = useState<string | undefined>(undefined);
	const [areAppsInstalled, setAreAppsInstalled] = useState<boolean | null>(null);
	const [isRapidBoardsReady, setIsRapidBoardsReady] = useState(false);
	const [shouldDeferToMonolith, setShouldDeferToMonolith] = useState(false);

	const { accountId, mode, boardId, chartView, projectKey, routeName, isBoardLocationless } =
		useUrlState();
	const [hasLayoutLoaded, isBentoEnabled] = useRapidBoardSetupAndTearDown();
	const [isAppContentLoaded, setIsAppContentLoaded] = useRapidBoardContentLoadListener();
	useCalendarSideEffects();

	const { hasGHContextLoaded } = useWrmBundleLoader();

	const {
		promise: allDataPromise,
		data,
		loading: allDataLoading,
		error: allDataError,
		hasFinishedRetrying: allDataAttemptFinished,
	} = useRapidBoardResource({
		resource: getRapidBoardDataResource(),
		retryPredicate: isAllDataErrorValid,
	});

	// @ts-expect-error - TS2339 - Property 'data' does not exist on type 'unknown'. | TS2339 - Property 'route' does not exist on type 'unknown'.
	const { data: allData, route: initialRoute } = data ?? {};

	const shouldUserSeeBoard = !(
		(allDataError &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			(allDataError as any).statusCode != null &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			(allDataError as any).statusCode === 404) ||
		(allData && allData.errors && allData.errors.rapidViewId)
	);

	let haveResourcesLoaded = !!(
		hasGHContextLoaded &&
		hasLayoutLoaded &&
		(allDataPromise || !allDataLoading)
	);
	let configResource: ReturnType<typeof useConfigResource>;

	if (ff('jsw.classic.board.api-calls.modern-frontend')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		configResource = useConfigResource(boardId == null ? '' : boardId);

		haveResourcesLoaded = !!(
			hasGHContextLoaded &&
			hasLayoutLoaded &&
			(allDataPromise || !allDataLoading) &&
			(configResource.promise || !configResource.loading)
		);
	}

	useEffect(() => {
		const { GH } = window;
		const hasUserSwitchedRouteDuringLoad =
			hasGHContextLoaded &&
			initialRoute &&
			initialRoute !== routeName &&
			GH?.RapidBoard?.State?.isFirstLoad();

		if (hasUserSwitchedRouteDuringLoad && isRapidBoardsReady) {
			GH?.RapidBoard?.State?.changeFirstLoadState?.();
			GH?.RapidBoard?.loadNewBoard?.(boardId);
		}
	}, [routeName, isRapidBoardsReady, boardId, initialRoute, hasGHContextLoaded]);

	useEffect(() => {
		const { GH } = window;
		// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | Record<string, any> | (Error & Record<string, any>) | (Record<string, any> & Error)'.
		if (hasGHContextLoaded && allDataAttemptFinished && allDataError?.statusCode === 500) {
			// Fallback to let monolith handle error as it may be a board config issue
			GH.RapidBoard.State.changeFirstLoadState();
			setShouldDeferToMonolith(true);
		}
	}, [allDataAttemptFinished, allDataError, hasGHContextLoaded]);

	useEffect(() => {
		if (
			shouldDeferToMonolith ||
			(haveResourcesLoaded && (allData || mode === REPORT || pastRapidView))
		) {
			const { GH, AJS } = window;
			const dataPromise = new Promise((r: (result: Promise<never>) => void) => r(allData));

			if (mode !== REPORT && allData) {
				setUpWrmData(dataPromise);
			}
			if (ff('jsw.classic.board.api-calls.modern-frontend')) {
				const { key: configKey, promise: configPromise, update: configUpdate } = configResource;
				setupConfigResources(configPromise, configKey, configUpdate);
			}
			setUpHistory(history, {
				accountId,
				mode,
				boardId,
				rapidView: boardId,
				chartView,
				projectKey,
				isBoardLocationless,
			});

			if (
				pastRapidView === boardId &&
				pastChartView === chartView &&
				GH?.RapidBoard?.State?.getMode?.() === mode
			) {
				return;
			}

			if (
				pastRapidView == null &&
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.GH?.RapidBoard &&
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				String(window.GH.RapidBoard?.State?.data?.rapidViewId) === boardId
			) {
				// Render initial content for rapidboards to mount onto
				renderRapidboardDom(mode === WORK);
				GH.RapidBoard.ViewController.setMode(mode, true);
				afterRenderSetup();

				setIsRapidBoardsReady(true);
				setPastRapidView(boardId);
				return;
			}

			if (pastRapidView === boardId) {
				if (
					isAppContentLoaded &&
					isRapidBoardsReady &&
					GH.RapidBoard.State.getDataValue('mode') &&
					mode !== GH.RapidBoard.State.getMode()
				) {
					GH.RapidBoard.ViewController.setMode(mode);
				}
				if (pastChartView !== chartView) {
					setIsAppContentLoaded(false);
					GH.RapidBoard.init();
					setPastChartView(chartView);
				}
			} else {
				resetWorkControllerFilters();
				setIsRapidBoardsReady(false);
				setIsAppContentLoaded(false);
				handleBackToBackNavigation(mode);
				GH.RapidBoard.init().then(() => {
					setIsRapidBoardsReady(true);
					!areAppsInstalled && startApps();
					setAreAppsInstalled(true);
					setShouldDeferToMonolith(false);

					if (mode === REPORT && AJS) {
						AJS.$(window).trigger('resize.rapidboardSpa');
					}

					afterRenderSetup();
				});
				setPastRapidView(boardId);
				setPastChartView(chartView);
			}
		}
	}, [
		haveResourcesLoaded,
		allData,
		mode,
		history,
		areAppsInstalled,
		pastRapidView,
		boardId,
		pastChartView,
		chartView,
		isRapidBoardsReady,
		projectKey,
		isAppContentLoaded,
		setIsAppContentLoaded,
		shouldDeferToMonolith,
		accountId,
		// @ts-expect-error - Variable 'configResource' is used before being assigned
		configResource,
		isBoardLocationless,
	]);

	return {
		isRapidBoardsReady,
		mode,
		isBentoEnabled: !!isBentoEnabled,
		shouldUserSeeBoard,
	};
};
