function $parcel$defineInteropFlag(a) {
    Object.defineProperty(a, '__esModule', {
        value: true,
        configurable: true
    });
}
function $parcel$export(e, n, v, s) {
    Object.defineProperty(e, n, {
        get: v,
        set: s,
        enumerable: true,
        configurable: true
    });
}
function $parcel$interopDefault(a) {
    return a && a.__esModule ? a.default : a;
}
var $parcel$global = globalThis;
var parcelRequire = $parcel$global["parcelRequire94c2"];
var parcelRegister = parcelRequire.register;
parcelRegister("lWiJj", function(module, exports) {
    $parcel$defineInteropFlag(module.exports);
    $parcel$export(module.exports, "default", ()=>(parcelRequire("cGZQT")).ServicedeskLegacyBoardSettingsAppLayout);
    var $cGZQT;
});
parcelRegister("cGZQT", function(module, exports) {
    $parcel$export(module.exports, "ServicedeskLegacyBoardSettingsAppLayout", ()=>$79908e66652ff79f$export$d625bad74a9d2c3d);
    var $5uXOq = parcelRequire("5uXOq");
    var $7nsd3 = parcelRequire("7nsd3");
    parcelRequire("2Geeh");
    var $cgL5K;
    var $9B86Q;
    var $2vSU6;
    var $lzeFL;
    var $heNNl;
    var $ousew;
    var $koVbs;
    var $1nUas;
    var $1J3t1;
    var $lfTZh;
    var $02Psx;
    var $3mYHL;
    var $dxCvy;
    var $8H3A4;
    var $f7Y15;
    var $e9YVH;
    var $6GLlD;
    var $6awMq;
    var $9kARR;
    var $Gk4RP;
    var $4dRjY;
    var $l8ruj;
    var $bmKPg;
    var $2YP9W;
    var $7Q7Lr;
    const $79908e66652ff79f$var$ColumnsConfigMappingPageDescription = ({ projectKey: projectKey, isSimplified: isSimplified })=>{
        const { message: message, url: url } = (0, $5uXOq.useMemo)(()=>isSimplified ? {
                message: (0, (0, parcelRequire("7Q7Lr")).default).columnConfigDescriptionTmp,
                url: (0, (0, parcelRequire("02Psx")).getNextgenSettingsIssueTypesUrl)((0, (0, parcelRequire("2vSU6")).SERVICE_DESK_PROJECT), projectKey)
            } : {
                message: (0, (0, parcelRequire("7Q7Lr")).default).columnConfigDescriptionCmp,
                url: `/plugins/servlet/project-config/${projectKey}/workflows`
            }, [
            isSimplified,
            projectKey
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ousew")).default), {
            ...message,
            values: {
                a: (content)=>(0, ($parcel$interopDefault($5uXOq))).createElement("a", {
                        "data-testid": (0, $8e4c2c85f99b85c0$export$f14ca5709988143a),
                        href: url
                    }, content)
            }
        });
    };
    const $79908e66652ff79f$var$ColumnConstraintGroupInstructions = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cgL5K")).default), null, formatMessage((0, (0, parcelRequire("7Q7Lr")).default).columnConstraintGroupInstructions));
    };
    const $79908e66652ff79f$var$getBoardSettingsTabs = (isBoardEnabled, isCalendarEnabled)=>{
        const dateFieldsTab = isCalendarEnabled ? [
            'dateFields'
        ] : [];
        if (!isBoardEnabled) return [
            'filter',
            ...dateFieldsTab
        ];
        return [
            'columns',
            'filter',
            'quickFilters',
            'swimlanes',
            'cardColors',
            'cardLayout',
            ...dateFieldsTab
        ];
    };
    const $79908e66652ff79f$var$createApi = ({ locale: locale, projectKey: projectKey, isSimplified: isSimplified, analyticsContextAttributes: analyticsContextAttributes, isBoardEnabled: isBoardEnabled, isCalendarEnabled: isCalendarEnabled })=>{
        const renderComponent = ({ children: children, container: container })=>(0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1nUas")).IntlProvider), {
                locale: (0, (0, parcelRequire("lzeFL")).standardizeLocale)(locale)
            }, children), container);
        return {
            renderConfigEditScreen: ()=>{
                const configNav = document.getElementById('ghx-config-nav');
                if (configNav) {
                    const tabs = $79908e66652ff79f$var$getBoardSettingsTabs(isBoardEnabled, isCalendarEnabled);
                    for (const child of configNav.children)if (!tabs.includes(child.getAttribute('data-tabitem'))) child.style.display = 'none';
                }
            },
            renderColumnMapping: ()=>{
                const configColumnsDescription = document.querySelector('#ghx-config-columns-description');
                if (document.body && document.body.contains(configColumnsDescription)) renderComponent({
                    children: (0, ($parcel$interopDefault($5uXOq))).createElement($79908e66652ff79f$var$ColumnsConfigMappingPageDescription, {
                        projectKey: projectKey,
                        isSimplified: isSimplified
                    }),
                    container: configColumnsDescription
                });
                const columnsConfigForm = document.getElementById('ghx-config-columns-form');
                if (columnsConfigForm) {
                    for (const child of columnsConfigForm.children)if (child.id !== 'ghx-config-columns-column-constraint-group') child.style.display = 'none';
                }
                const columnConstraintDescriptionContainer = document.querySelector('#ghx-config-columns-column-constraint-group div.description');
                if (document.body && document.body.contains(columnConstraintDescriptionContainer)) renderComponent({
                    children: (0, ($parcel$interopDefault($5uXOq))).createElement($79908e66652ff79f$var$ColumnConstraintGroupInstructions, null),
                    container: columnConstraintDescriptionContainer
                });
                const backlogColumn = document.querySelector('#ghx-mapping-columns div.ghx-kanplan-backlog-column');
                if (backlogColumn) backlogColumn.style.display = 'none';
            },
            getAnalyticContextAttributes: ()=>analyticsContextAttributes
        };
    };
    const $79908e66652ff79f$var$useApi = ({ projectKey: projectKey, isSimplified: isSimplified, analyticsContextAttributes: analyticsContextAttributes })=>{
        const { locale: locale } = (0, (0, parcelRequire("koVbs")).default)();
        const { data: data } = (0, (0, parcelRequire("1J3t1")).useJiraServiceManagementProjectNavigation)();
        const isBoardEnabled = !!data?.boardInfo.enabled;
        const isCalendarEnabled = !!data?.calendarInfo.enabled;
        return (0, $5uXOq.useMemo)(()=>$79908e66652ff79f$var$createApi({
                locale: locale,
                projectKey: projectKey,
                isSimplified: isSimplified,
                analyticsContextAttributes: analyticsContextAttributes,
                isBoardEnabled: isBoardEnabled,
                isCalendarEnabled: isCalendarEnabled
            }), [
            locale,
            projectKey,
            isSimplified,
            analyticsContextAttributes,
            isBoardEnabled,
            isCalendarEnabled
        ]);
    };
    const $79908e66652ff79f$var$ServicedeskLegacyBoardSettingsApp = ({ projectKey: projectKey, isSimplified: isSimplified, analyticsContextAttributes: analyticsContextAttributes })=>{
        const api = $79908e66652ff79f$var$useApi({
            projectKey: projectKey,
            isSimplified: isSimplified,
            analyticsContextAttributes: analyticsContextAttributes
        });
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("9B86Q")).loadBridgeWeak)({
                name: 'jira-servicedesk/boards/configuration/bridge'
            }).then((bridge)=>{
                bridge(api);
            });
        }, [
            api
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("Gk4RP")).default), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3mYHL")).default), {
            projectKey: projectKey
        }));
    };
    const $79908e66652ff79f$export$d625bad74a9d2c3d = ()=>{
        const [paramProjectKey] = (0, (0, parcelRequire("bmKPg")).usePathParam)('projectKey');
        const cmpBoardData = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("f7Y15")).uifBoardResource));
        const { key: key } = cmpBoardData.data?.result?.boardLocation ?? {};
        const projectKey = key ?? paramProjectKey ?? null;
        const projectContext = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("dxCvy")).projectContextResource));
        const projectId = projectContext?.data?.projectId;
        const simplified = projectContext?.data?.simplified;
        const { data: serviceDeskAnalyticsAttributes } = (0, (0, parcelRequire("8H3A4")).useServiceDeskAnalyticsContext)();
        const analyticsWithAdditionalAttributes = (0, $5uXOq.useMemo)(()=>({
                ...serviceDeskAnalyticsAttributes,
                projectId: projectId
            }), [
            serviceDeskAnalyticsAttributes,
            projectId
        ]);
        const analyticsContextAttributes = analyticsWithAdditionalAttributes;
        if (!projectKey || simplified == null) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "servicedesk-legacy-board-settings"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6awMq")).NextGenBoardSkeleton), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9kARR")).SuspenseTrackerFallback), null)),
            name: "servicedesk-legacy-board-settings"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4dRjY")).MarkProductStart), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6GLlD")).default), {
            appName: (0, (0, parcelRequire("e9YVH")).APP_NAMES).BOARD_SETTINGS
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: "spa-apps-servicedesk.legacy-board-settings",
            packageName: "spa-apps-servicedesk-legacy-board-settings"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($79908e66652ff79f$var$ServicedeskLegacyBoardSettingsApp, {
            projectKey: projectKey,
            isSimplified: simplified,
            analyticsContextAttributes: analyticsContextAttributes
        })))));
    };
});
parcelRegister("3mYHL", function(module, exports) {
    $parcel$export(module.exports, "isProjectArchivedCondition", ()=>$90d8776d2c89f4bd$export$34040938aec58f90);
    $parcel$export(module.exports, "RedirectToArchivedPage", ()=>$90d8776d2c89f4bd$export$36db2ddf32573bd8);
    $parcel$export(module.exports, "default", ()=>$90d8776d2c89f4bd$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $dAfqg;
    var $7tQej;
    const $90d8776d2c89f4bd$export$34040938aec58f90 = (projectContextData)=>projectContextData && projectContextData.isProjectArchived || false;
    const $90d8776d2c89f4bd$var$RedirectIfProjectArchived = ({ projectKey: projectKey })=>{
        if (projectKey === null) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dAfqg")).ProjectContextProvider), null, (projectContext)=>$90d8776d2c89f4bd$export$34040938aec58f90(projectContext.data) ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7tQej")).Redirect), {
                to: (0, $a9bebd574fd66055$export$9a6ccb84170b615b)
            }) : null);
    };
    const $90d8776d2c89f4bd$export$36db2ddf32573bd8 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7tQej")).Redirect), {
            to: (0, $a9bebd574fd66055$export$9a6ccb84170b615b)
        });
    var $90d8776d2c89f4bd$export$2e2bcd8739ae039 = $90d8776d2c89f4bd$var$RedirectIfProjectArchived;
});
const $a9bebd574fd66055$export$9a6ccb84170b615b = '/jira/errors/project-archived';
parcelRegister("6GLlD", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b1e4a0c92f65aa33$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $iXVV6;
    var $9noxX;
    var $e9YVH;
    var $9dbF6;
    var $9NiTW;
    var $cYaqS;
    const $b1e4a0c92f65aa33$var$ServiceDeskAppBase = ({ appName: appName, children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9NiTW")).AuthenticationCheck), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("cYaqS")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9dbF6")).SubProductUpdater), {
            subProduct: "serviceDesk"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9noxX")).ServiceDeskAnalyticsContext), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceName: (0, (0, parcelRequire("e9YVH")).toPackageName)(appName),
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a)
        }, children))));
    var $b1e4a0c92f65aa33$export$2e2bcd8739ae039 = $b1e4a0c92f65aa33$var$ServiceDeskAppBase;
});
const $4d5e0871c06cee03$export$f636b5b56f1d624a = 'Screen';
const $4d5e0871c06cee03$export$9d2de6eebf7462b5 = 'Drawer';
const $4d5e0871c06cee03$export$1171103ea9083ffa = 'Modal';
const $4d5e0871c06cee03$export$cae2783c9279926 = 'InlineDialog';
const $4d5e0871c06cee03$export$d1123261ca8831d2 = 'Dropdown';
const $4d5e0871c06cee03$export$238d30785c4bca84 = 'Banner';
const $4d5e0871c06cee03$export$a1562d08625f5d7e = 'UI';
const $4d5e0871c06cee03$export$947dac6c72b26f81 = 'TRACK';
const $4d5e0871c06cee03$export$f1d537d508d2945a = 'SCREEN';
const $4d5e0871c06cee03$export$12346a674644c156 = 'OPERATIONAL';
parcelRegister("9dbF6", function(module, exports) {
    $parcel$export(module.exports, "SubProductUpdater", ()=>$5979c081afa32bfa$export$eb40d3df56aa5c53);
    var $5uXOq = parcelRequire("5uXOq");
    var $bNvU3;
    var $3iTiR;
    var $ero6m;
    var $54I24;
    var $8OHH4;
    const $5979c081afa32bfa$export$eb40d3df56aa5c53 = ({ allowMonolithDeferral: allowMonolithDeferral = false, ...props })=>{
        const subProduct = (0, (0, parcelRequire("8OHH4")).isSubProductProps)(props) ? props.subProduct : (0, (0, parcelRequire("ero6m")).getSubProductFromProjectType)(props.projectType);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bNvU3")).default), {
            op: (0, (0, parcelRequire("54I24")).default),
            args: [
                subProduct,
                allowMonolithDeferral
            ]
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3iTiR")).default), {
            subProduct: subProduct
        }));
    };
});
parcelRegister("3iTiR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1214b0731559b377$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $1u3Jv;
    var $hFChZ;
    var $1214b0731559b377$export$2e2bcd8739ae039 = ({ subProduct: subProduct })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1u3Jv")).default), {
            op: (0, (0, parcelRequire("hFChZ")).default),
            args: [
                subProduct
            ]
        });
});
parcelRegister("hFChZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$0e606430ead76cd8$export$2e2bcd8739ae039);
    var $dJl1T;
    var $eusud;
    var $0e606430ead76cd8$export$2e2bcd8739ae039 = (subProduct)=>{
        (0, (0, parcelRequire("eusud")).performGetRequest)(`/rest/internal/2/mauTag/${subProduct}`).catch((err)=>{
            (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('common.tag-mau.tag-mau-event', 'Error connecting to monolith mau tag endpoint', err);
        });
    };
});
parcelRegister("ero6m", function(module, exports) {
    $parcel$export(module.exports, "getSubProductFromProjectType", ()=>$0997694844c8a9e7$export$b1fe420ed15dff7b);
    var $16v4H;
    const $0997694844c8a9e7$export$b1fe420ed15dff7b = (projectType)=>{
        if (projectType === 'product_discovery') return 'polaris';
        return (0, (0, parcelRequire("16v4H")).subProductFromProjectType)(projectType);
    };
});
parcelRegister("54I24", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bcbbcd48ca398ed4$export$2e2bcd8739ae039);
    var $7T84i;
    var $9B86Q;
    var $cQZXC;
    var $dJl1T;
    var $fjcgS;
    const $bcbbcd48ca398ed4$var$maybeDelayAndRace = (maybeWaitPromise, getDefaultPromise, getRacePromise)=>maybeWaitPromise ? Promise.race([
            maybeWaitPromise.then(getDefaultPromise),
            getRacePromise()
        ]) : getDefaultPromise();
    var $bcbbcd48ca398ed4$export$2e2bcd8739ae039 = async (subProduct, allowMonolithDeferral)=>{
        (0, (0, parcelRequire("fjcgS")).getAnalyticsWebClientPromise)().then((client)=>{
            client.setSubProduct(subProduct);
            if (subProduct === 'serviceDesk') client.setUIViewedAttributes({
                solutions: [
                    (0, $86344d58e71e925a$export$c8b406ad8ee0dab9)
                ]
            });
            else client.removeUIViewedAttribute('solutions');
        });
        $bcbbcd48ca398ed4$var$maybeDelayAndRace(allowMonolithDeferral ? (0, (0, parcelRequire("cQZXC")).waitForHeritage)() : null, ()=>(0, (0, parcelRequire("7T84i")).loadBridgeStrict)({
                name: 'jira/analytics/sub-product-resolver',
                wrmKeys: [
                    'wrc!com.atlassian.jira.jira-client-analytics-plugin:analytics-web-client'
                ]
            }), ()=>(0, (0, parcelRequire("9B86Q")).loadBridgeWeak)({
                name: 'jira/analytics/sub-product-resolver'
            })).then((subProductResolver)=>{
            subProductResolver.setSubProduct(subProduct);
        }).catch((e)=>(0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('common.analytics-sub-product.sub-product-updater', 'Error connecting to monolith sub product resolver', e));
    };
});
const $86344d58e71e925a$export$c8b406ad8ee0dab9 = 'jiraServiceManagement';
parcelRegister("8OHH4", function(module, exports) {
    $parcel$export(module.exports, "isSubProductProps", ()=>$701d07b56c3e3574$export$c3063d6d7a8c075f);
    const $701d07b56c3e3574$export$c3063d6d7a8c075f = (props)=>'subProduct' in props && typeof props.subProduct === 'string';
    const $701d07b56c3e3574$export$81da4a7e1ba1dcee = (props)=>'projectType' in props && typeof props.projectType === 'string';
});
parcelRegister("cYaqS", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f72fff5a1e4c8937$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $6jY6G;
    var $4UFLs;
    var $g3A84;
    const $f72fff5a1e4c8937$var$AccessCheck = ({ children: children })=>{
        const isSettingsRoute = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)().path.includes(`${(0, $7c914880cb332728$export$967ba7bfb5ce30bd)}/:projectKey/settings/`);
        const Gate = isSettingsRoute ? (0, (0, parcelRequire("g3A84")).default) : (0, (0, parcelRequire("4UFLs")).default);
        return (0, ($parcel$interopDefault($5uXOq))).createElement(Gate, null, children);
    };
    var $f72fff5a1e4c8937$export$2e2bcd8739ae039 = $f72fff5a1e4c8937$var$AccessCheck;
});
const $7c914880cb332728$export$967ba7bfb5ce30bd = '/jira/servicedesk/projects';
parcelRegister("4UFLs", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$9befbeaff9f5385d$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $daoB1;
    var $7tQej;
    var $bmKPg;
    var $iDvG9;
    const $9befbeaff9f5385d$var$ProductAccessCheck = ({ children: children })=>{
        const { hasServiceDeskAccess: hasServiceDeskAccess } = (0, (0, parcelRequire("daoB1")).useApplicationPermissions)();
        const [projectKey = ''] = (0, (0, parcelRequire("bmKPg")).usePathParam)('projectKey');
        if (projectKey) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iDvG9")).default), null, children);
        return hasServiceDeskAccess ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children) : (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7tQej")).Redirect), {
            to: "/jira"
        });
    };
    var $9befbeaff9f5385d$export$2e2bcd8739ae039 = $9befbeaff9f5385d$var$ProductAccessCheck;
});
parcelRegister("iDvG9", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$34dd3dc578b1a167$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $7tQej;
    var $bmKPg;
    var $dFOAR;
    var $fhAnU;
    const $34dd3dc578b1a167$var$AgentCheck = ({ children: children })=>{
        const [projectKey = ''] = (0, (0, parcelRequire("bmKPg")).usePathParam)('projectKey');
        const [{ data: data, error: error }] = (0, (0, parcelRequire("dFOAR")).useServiceDeskContext)();
        if (data || error) {
            if (!(0, (0, parcelRequire("fhAnU")).isAccessError)(error)) {
                const { userRole: userRole = '' } = data || {};
                if (userRole === 'agent' || userRole === 'agent-project-admin') return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
                if (error) throw error;
            }
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7tQej")).Redirect), {
                to: `${(0, $7c914880cb332728$export$967ba7bfb5ce30bd)}/${projectKey}/issues`
            });
        }
        return null;
    };
    var $34dd3dc578b1a167$export$2e2bcd8739ae039 = $34dd3dc578b1a167$var$AgentCheck;
});
parcelRegister("dFOAR", function(module, exports) {
    $parcel$export(module.exports, "useServiceDeskContext", ()=>$6c09349e97fba749$export$712ead87353ac687);
    var $5uXOq = parcelRequire("5uXOq");
    var $324W5;
    var $cAofY;
    const $6c09349e97fba749$var$getServiceDeskContextResource = ()=>(0, (0, parcelRequire("cAofY")).serviceDeskContextResource);
    const $6c09349e97fba749$export$712ead87353ac687 = (projectKey, customContext)=>{
        const { data: data, loading: loading, error: error, refresh: refreshCache } = (0, (0, parcelRequire("324W5")).useResourceWithCustomRouterContext)($6c09349e97fba749$var$getServiceDeskContextResource(), customContext);
        (0, $5uXOq.useEffect)(()=>{
            if (!false) refreshCache();
        }, [
            projectKey,
            refreshCache
        ]);
        return [
            {
                data: data,
                loading: loading,
                error: error
            }
        ];
    };
});
parcelRegister("fhAnU", function(module, exports) {
    $parcel$export(module.exports, "isAccessError", ()=>$1a790f4bb1355504$export$ddaebe4928e793cb);
    const $1a790f4bb1355504$var$RESTRICTED_ACCESS_CODES = [
        (0, $3cd8a0ece36fbae5$export$a3269a3914b007e3),
        (0, $3cd8a0ece36fbae5$export$8bca792d963eb0ef),
        (0, $3cd8a0ece36fbae5$export$67d19e9a991baa07)
    ];
    const $1a790f4bb1355504$export$ddaebe4928e793cb = (error)=>error?.statusCode != null ? $1a790f4bb1355504$var$RESTRICTED_ACCESS_CODES.includes(error.statusCode) : false;
});
const $3cd8a0ece36fbae5$export$ff39ebd4bb12b718 = 201;
const $3cd8a0ece36fbae5$export$3288b14b6745c572 = 204;
const $3cd8a0ece36fbae5$export$9de493bcd9b1d977 = 400;
const $3cd8a0ece36fbae5$export$67d19e9a991baa07 = 401;
const $3cd8a0ece36fbae5$export$a3269a3914b007e3 = 403;
const $3cd8a0ece36fbae5$export$8bca792d963eb0ef = 404;
const $3cd8a0ece36fbae5$export$4a3cb39801694eee = 407;
const $3cd8a0ece36fbae5$export$fa405bbf47d510e2 = 408;
const $3cd8a0ece36fbae5$export$c09e7ea0fc79f34f = 409;
const $3cd8a0ece36fbae5$export$5244030134e99 = 412;
const $3cd8a0ece36fbae5$export$f90ac1df0b60853d = 413;
const $3cd8a0ece36fbae5$export$a447d0a965e16776 = 422;
const $3cd8a0ece36fbae5$export$3b48aba8957000cd = 426;
const $3cd8a0ece36fbae5$export$6763168a631449a2 = 429;
const $3cd8a0ece36fbae5$export$e813cd7045fcc98d = 500;
const $3cd8a0ece36fbae5$export$2ce38d41a306034e = 502;
const $3cd8a0ece36fbae5$export$974c37534b0191b1 = 503;
const $3cd8a0ece36fbae5$export$5400e8dddac3fcf3 = 504;
parcelRegister("g3A84", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$60e7d6646168c089$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $8pMQX;
    var $dFOAR;
    var $fhAnU;
    const $60e7d6646168c089$var$SettingsAccessCheck = ({ children: children })=>{
        const [{ data: serviceDeskContext, error: serviceDeskContextError }] = (0, (0, parcelRequire("dFOAR")).useServiceDeskContext)();
        const userRole = serviceDeskContext?.userRole;
        const hasAccessError = (0, (0, parcelRequire("fhAnU")).isAccessError)(serviceDeskContextError);
        if (userRole === 'agent-project-admin' && !hasAccessError) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children);
        if (userRole !== undefined || hasAccessError) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8pMQX")).ProjectNotFound), null);
        if (serviceDeskContextError) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8pMQX")).GenericError), null);
        return null;
    };
    var $60e7d6646168c089$export$2e2bcd8739ae039 = $60e7d6646168c089$var$SettingsAccessCheck;
});
parcelRegister("8pMQX", function(module, exports) {
    $parcel$export(module.exports, "ProjectNotFound", ()=>$951f6bd200bf8b89$export$849d319c342682a2);
    $parcel$export(module.exports, "GenericError", ()=>$951f6bd200bf8b89$export$7d465be8efa67280);
    var $5uXOq = parcelRequire("5uXOq");
    var $hTEpF;
    var $dC5iT;
    var $8CUq3;
    var $tcn7E;
    var $gLj7z;
    var $4R6GH;
    var $ew1Lk;
    var $bTyOW;
    var $fC09Q;
    var $iVgQf;
    var $koVbs;
    var $hDzhb;
    var $4AdE6;
    const $951f6bd200bf8b89$export$849d319c342682a2 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const projectsPath = '/jira/projects';
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ew1Lk")).DocumentTitle), {
            title: formatMessage((0, (0, parcelRequire("4AdE6")).default).notFoundDocumentTitleJsm)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hTEpF")).default), {
            renderImage: (0, (0, parcelRequire("iVgQf")).getAkEmptyStateRenderImageFn)((0, (0, parcelRequire("fC09Q")).default)),
            maxImageWidth: 120,
            header: formatMessage((0, (0, parcelRequire("4AdE6")).default).notFoundTitle),
            description: formatMessage((0, (0, parcelRequire("4AdE6")).default).notFoundDescription),
            primaryAction: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hDzhb")).default), {
                to: projectsPath
            }, formatMessage((0, (0, parcelRequire("4AdE6")).default).notFoundAction))
        }));
    };
    const $951f6bd200bf8b89$export$7d465be8efa67280 = ()=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const traceId = (0, $5uXOq.useMemo)(()=>{
            const traceContext = (0, (0, parcelRequire("tcn7E")).getActiveTrace)();
            return traceContext?.traceId;
        }, []);
        let description = formatMessage((0, (0, parcelRequire("4AdE6")).default).genericErrorDescription);
        if (traceId && (0, (0, parcelRequire("4R6GH")).fg)('jfp-trace-id-in-error-boundaries')) {
            const error = new Error('An error occurred while loading this page');
            description = (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("div", null, description), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
                xcss: $951f6bd200bf8b89$var$styles
            }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gLj7z")).ErrorHashAndTraceId), {
                error: error,
                traceId: traceId
            })));
        }
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ew1Lk")).DocumentTitle), {
            title: formatMessage((0, (0, parcelRequire("4AdE6")).default).genericDocumentTitleJsm)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("hTEpF")).default), {
            renderImage: (0, (0, parcelRequire("iVgQf")).getAkEmptyStateRenderImageFn)((0, (0, parcelRequire("bTyOW")).default)),
            maxImageWidth: 120,
            header: formatMessage((0, (0, parcelRequire("4AdE6")).default).genericErrorTitle),
            description: description
        }));
    };
    const $951f6bd200bf8b89$var$styles = (0, (0, parcelRequire("8CUq3")).xcss)({
        marginTop: 'space.100'
    });
});
parcelRegister("gLj7z", function(module, exports) {
    $parcel$export(module.exports, "ErrorHashAndTraceId", ()=>$fe273132a82b30fc$export$61354a7dde2c2d3c);
    var $5uXOq = parcelRequire("5uXOq");
    var $5oFIy;
    var $kobfu;
    var $ftF7i;
    const $fe273132a82b30fc$export$61354a7dde2c2d3c = ({ error: error, traceId: traceId })=>{
        const errorHash = (0, (0, parcelRequire("kobfu")).getErrorHash)(error);
        const source = `Hash ${errorHash}\nTrace ${traceId}`;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5oFIy")).FireOperationalAnalytics), {
            action: "rendered",
            actionSubject: "errorHashAndTraceId",
            attributes: {
                errorHash: errorHash,
                traceId: traceId
            }
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            role: "note"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ftF7i")).CodeBlockWithCopyButton), {
            source: source,
            copyCodeActionSubjectId: "copyErrorHashAndTraceId"
        })));
    };
});
parcelRegister("ftF7i", function(module, exports) {
    $parcel$export(module.exports, "CodeBlockWithCopyButton", ()=>$92e21bbf80c400af$export$ced1fc74d907d387);
    var $5uXOq = parcelRequire("5uXOq");
    var $b8Qxt;
    var $dC5iT;
    var $8CUq3;
    var $lOZGq;
    const $92e21bbf80c400af$export$ced1fc74d907d387 = ({ source: source, copyCodeActionSubjectId: copyCodeActionSubjectId })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $92e21bbf80c400af$var$wrapperStyles,
            role: "region"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lOZGq")).ActionContainer), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lOZGq")).CopyAction), {
            source: source,
            actionSubjectId: copyCodeActionSubjectId
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("b8Qxt")).default), {
            text: source,
            showLineNumbers: false,
            shouldWrapLongLines: false
        })));
    const $92e21bbf80c400af$var$wrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        width: '100%',
        position: 'relative'
    });
});
parcelRegister("b8Qxt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$4fd53c1f9dbca185$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $e4VnT;
    var $1HkQx;
    var $eCBnN;
    var $9Xp6L;
    const $4fd53c1f9dbca185$var$CodeBlock = (0, $5uXOq.memo)(function CodeBlock({ showLineNumbers: showLineNumbers = true, firstLineNumber: firstLineNumber = 1, language: providedLanguage = 'text', highlight: highlight = '', highlightedStartText: highlightedStartText = 'Highlight start', highlightedEndText: highlightedEndText = 'Highlight end', testId: testId, text: text, codeBidiWarnings: codeBidiWarnings = true, codeBidiWarningLabel: codeBidiWarningLabel, codeBidiWarningTooltipEnabled: codeBidiWarningTooltipEnabled = true, shouldWrapLongLines: shouldWrapLongLines = false }) {
        const numLines = (text || '').split('\n').length + (firstLineNumber > 0 ? firstLineNumber : 1) - 1;
        const theme = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("1HkQx")).getCodeBlockTheme)(numLines), [
            numLines
        ]);
        const getStyles = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("1HkQx")).getCodeBlockStyles)(theme), [
            theme
        ]);
        const styles = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("f8Sb3")).css)(getStyles(highlightedStartText, highlightedEndText, showLineNumbers, shouldWrapLongLines)), [
            highlightedStartText,
            highlightedEndText,
            showLineNumbers,
            shouldWrapLongLines,
            getStyles
        ]);
        const { getHighlightStyles: getHighlightStyles, highlightedLines: highlightedLines } = (0, (0, parcelRequire("e4VnT")).useHighlightLines)({
            highlight: highlight,
            testId: testId
        });
        const getLineProps = (0, $5uXOq.useCallback)((line)=>getHighlightStyles(line, highlightedLines), [
            getHighlightStyles,
            highlightedLines
        ]);
        const language = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("eCBnN")).normalizeLanguage)(providedLanguage), [
            providedLanguage
        ]);
        const languageToUse = text ? language : 'text';
        return (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("9Xp6L")).default), {
            "data-code-lang": language,
            "data-ds--code--code-block": "",
            testId: testId,
            language: languageToUse,
            css: styles,
            showLineNumbers: showLineNumbers,
            firstLineNumber: firstLineNumber,
            lineProps: getLineProps,
            shouldCreateParentElementForLines: highlight.length > 0 || !!testId,
            shouldWrapLongLines: shouldWrapLongLines,
            codeBidiWarnings: codeBidiWarnings,
            codeBidiWarningLabel: codeBidiWarningLabel,
            codeBidiWarningTooltipEnabled: codeBidiWarningTooltipEnabled,
            text: text
        });
    });
    $4fd53c1f9dbca185$var$CodeBlock.displayName = 'CodeBlock';
    var $4fd53c1f9dbca185$export$2e2bcd8739ae039 = $4fd53c1f9dbca185$var$CodeBlock;
});
parcelRegister("e4VnT", function(module, exports) {
    $parcel$export(module.exports, "useHighlightLines", ()=>$a32b74c088f4b53f$export$84415a200bdf33ed);
    var $5uXOq = parcelRequire("5uXOq");
    const $a32b74c088f4b53f$var$DEFAULT_LINE_EL_ATTR_OBJ = {
        'data-ds--code--row': ''
    };
    const $a32b74c088f4b53f$var$getLineStyleObject = (lineNumber, testId)=>{
        return testId ? {
            'data-testid': `${testId}-line-${lineNumber}`,
            ...$a32b74c088f4b53f$var$DEFAULT_LINE_EL_ATTR_OBJ
        } : $a32b74c088f4b53f$var$DEFAULT_LINE_EL_ATTR_OBJ;
    };
    const $a32b74c088f4b53f$export$84415a200bdf33ed = ({ highlight: highlight = '', testId: testId })=>{
        const highlightedLines = (0, $5uXOq.useMemo)(()=>{
            if (!highlight) return [];
            return highlight.split(',').map((num)=>{
                if (num.indexOf('-') > 0) {
                    const [from, to] = num.split('-').map(Number).sort((a, b)=>a - b);
                    return Array(to + 1).fill(undefined).map((_, index)=>index).slice(from, to + 1);
                }
                return Number(num);
            }).reduce((acc, val)=>acc.concat(val), []) || [];
        }, [
            highlight
        ]);
        const getHighlightStyles = (0, $5uXOq.useCallback)((lineNumber, highlightedLines)=>{
            if (!highlight || highlightedLines.length === 0) return $a32b74c088f4b53f$var$getLineStyleObject(lineNumber, testId);
            if (highlightedLines.includes(lineNumber)) {
                const highlightedDataAttrObj = {
                    'data-ds--code--row--highlight': ''
                };
                return {
                    ...highlightedDataAttrObj,
                    ...$a32b74c088f4b53f$var$getLineStyleObject(lineNumber, testId)
                };
            }
            return $a32b74c088f4b53f$var$getLineStyleObject(lineNumber, testId);
        }, [
            highlight,
            testId
        ]);
        return {
            getHighlightStyles: getHighlightStyles,
            highlightedLines: highlightedLines
        };
    };
});
parcelRegister("1HkQx", function(module, exports) {
    $parcel$export(module.exports, "getCodeBlockStyles", ()=>$a9c1aabaca189fa1$export$b571c24d82780698);
    $parcel$export(module.exports, "getCodeStyles", ()=>$a9c1aabaca189fa1$export$d5fcf1b7ff23707b);
    $parcel$export(module.exports, "getCodeBlockTheme", ()=>$a9c1aabaca189fa1$export$57a2d4b573afd64a);
    var $lBXbx;
    var $8CJbG;
    const $a9c1aabaca189fa1$export$cb7aec0206b595d2 = (numLines)=>{
        if (!numLines) return '1ch';
        return `${numLines.toFixed(0).length}ch`;
    };
    const $a9c1aabaca189fa1$var$lineNumberStyle = (theme)=>({
            minWidth: `calc(${theme.lineNumberWidth} + ${0, (0, parcelRequire("lBXbx")).LINE_NUMBER_GUTTER}px) !important`,
            fontStyle: 'normal !important',
            color: `${theme.lineNumberColor} !important`,
            flexShrink: 0,
            boxSizing: 'border-box',
            paddingRight: `${0, (0, parcelRequire("lBXbx")).SPACING}px !important`,
            paddingLeft: (0, (0, parcelRequire("lBXbx")).SPACING),
            marginRight: (0, (0, parcelRequire("lBXbx")).SPACING),
            textAlign: 'right',
            userSelect: 'none',
            display: 'block',
            '&::after': {
                content: `attr(data-ds--line-number)`
            }
        });
    const $a9c1aabaca189fa1$var$syntaxKeywordColors = (theme)=>({
            '.token': {
                '&:not([class=token],[data-ds--code--row--highlight],[data-ds--code--row])': {
                    all: 'unset'
                },
                '&.key,&.keyword': {
                    color: theme.keywordColor,
                    fontWeight: 'bolder'
                },
                '&.attr-name': {
                    color: theme.attributeColor
                },
                '&.selector': {
                    color: theme.selectorTagColor
                },
                '&.comment,&.block-comment': {
                    color: theme.commentColor,
                    fontFamily: theme.fontFamilyItalic,
                    fontStyle: 'italic'
                },
                '&.function-name': {
                    color: theme.sectionColor
                },
                '&.doctype': {
                    color: theme.docTagColor
                },
                '&.substr': {
                    color: theme.substringColor
                },
                '&.namespace': {
                    color: theme.nameColor
                },
                '&.builtin': {
                    color: theme.builtInColor
                },
                '&.entity': {
                    color: theme.literalColor
                },
                '&.bullet': {
                    color: theme.bulletColor
                },
                '&.code': {
                    color: theme.codeColor
                },
                '&.regex': {
                    color: theme.regexpColor
                },
                '&.symbol': {
                    color: theme.symbolColor
                },
                '&.variable': {
                    color: theme.variableColor
                },
                '&.url': {
                    color: theme.linkColor
                },
                '&.selector-attr': {
                    color: theme.selectorAttributeColor
                },
                '&.selector-pseudo': {
                    color: theme.selectorPseudoColor
                },
                '&.type': {
                    color: theme.typeColor
                },
                '&.quote': {
                    color: theme.quoteColor
                },
                '&.tag': {
                    color: theme.templateTagColor
                },
                '&.string': {
                    color: theme.stringColor
                },
                '&.class-name': {
                    color: theme.sectionColor
                },
                '&.title': {
                    color: theme.titleColor
                },
                '&.section': {
                    color: theme.sectionColor
                },
                '&.meta-keyword': {
                    color: theme.metaKeywordColor
                },
                '&.meta': {
                    color: theme.metaColor
                },
                '&.italic': {
                    fontStyle: 'italic'
                },
                '&.bold': {
                    fontWeight: 'bolder'
                },
                '&.function': {
                    color: theme.functionColor
                },
                '&.number': {
                    color: theme.numberColor
                },
                '&.attr-value': {
                    color: theme.attributeColor
                },
                '&.prolog': {
                    color: theme.prologColor
                },
                '&.cdata': {
                    color: theme.cdataColor
                },
                '&.punctuation': {
                    color: theme.punctuationColor
                },
                '&.property': {
                    color: theme.propertyColor
                },
                '&.constant': {
                    color: theme.constantColor
                },
                '&.deleted': {
                    color: theme.deletedColor
                },
                '&.boolean': {
                    color: theme.booleanColor
                },
                '&.char': {
                    color: theme.charColor
                },
                '&.inserted': {
                    color: theme.insertedColor
                },
                '&.operator': {
                    color: theme.operatorColor
                },
                '&.atrule': {
                    color: theme.atruleColor
                },
                '&.important': {
                    color: theme.importantColor,
                    fontWeight: 'bold'
                }
            }
        });
    const $a9c1aabaca189fa1$export$ee58a26b5d612ac1 = (theme)=>({
            fontSize: (0, (0, parcelRequire("lBXbx")).CODE_FONT_SIZE),
            fontFamily: theme.fontFamily,
            fontWeight: "var(--ds-font-weight-regular, 400)",
            backgroundColor: `var(${0, (0, parcelRequire("lBXbx")).VAR_CODE_BG_COLOR},${theme.backgroundColor})`,
            color: theme.textColor,
            borderStyle: 'none',
            borderRadius: "var(--ds-border-radius, 3px)"
        });
    const $a9c1aabaca189fa1$export$b571c24d82780698 = (theme)=>(highlightedStartText, highlightedEndText, showLineNumbers, shouldWrapLongLines)=>({
                'code[class*="language-"], pre[class*="language-"], code': {
                    all: 'unset',
                    padding: showLineNumbers ? `${0, (0, parcelRequire("lBXbx")).SPACING}px 0` : (0, (0, parcelRequire("lBXbx")).SPACING),
                    tabSize: 4
                },
                display: 'flex',
                lineHeight: (0, (0, parcelRequire("lBXbx")).CODE_LINE_HEIGHT),
                overflowX: 'auto',
                whiteSpace: 'pre',
                direction: 'ltr',
                ...$a9c1aabaca189fa1$export$ee58a26b5d612ac1(theme),
                ...$a9c1aabaca189fa1$var$syntaxKeywordColors(theme),
                '& .linenumber, .ds-sh-line-number': $a9c1aabaca189fa1$var$lineNumberStyle(theme),
                '& .linenumber': {
                    display: 'inline-block !important',
                    float: 'left'
                },
                '& [data-ds--code--row]': {
                    display: showLineNumbers ? 'flex' : 'block',
                    paddingRight: `${0, (0, parcelRequire("lBXbx")).SPACING}px !important`,
                    marginRight: `-${0, (0, parcelRequire("lBXbx")).SPACING}px`
                },
                '& [data-ds--code--row--highlight]': {
                    background: `${theme.highlightedLineBgColor}`,
                    '&::before, &::after': {
                        clipPath: 'inset(100%)',
                        clip: 'rect(1px, 1px, 1px, 1px)',
                        height: '1px',
                        overflow: 'hidden',
                        position: 'absolute',
                        whiteSpace: 'nowrap',
                        width: '1px'
                    },
                    '&::before': {
                        content: `" [${highlightedStartText}] "`
                    },
                    '&::after': {
                        content: `" [${highlightedEndText}] "`
                    }
                },
                '& [data-ds--code--row--highlight] .linenumber': {
                    borderLeft: `${0, (0, parcelRequire("lBXbx")).HIGHLIGHT_BORDER_WIDTH} solid ${theme.highlightedLineBorderColor}`,
                    paddingLeft: `${(0, (0, parcelRequire("lBXbx")).SPACING) / 2}px !important`,
                    position: 'relative'
                },
                '& [data-ds--code--row--highlight] .linenumber::before': {
                    content: '""',
                    position: 'absolute',
                    width: (0, (0, parcelRequire("lBXbx")).HIGHLIGHT_BORDER_WIDTH),
                    top: '-1px',
                    left: `-${0, (0, parcelRequire("lBXbx")).HIGHLIGHT_BORDER_WIDTH}`,
                    borderTop: `1px solid ${theme.highlightedLineBorderColor}`
                },
                '[data-ds--code--row--highlight] + [data-ds--code--row]:not([data-ds--code--row--highlight]), [data-ds--code--row]:not([data-ds--code--row--highlight]) + [data-ds--code--row--highlight]': {
                    borderTop: '1px dashed transparent'
                },
                '[data-ds--code--row--highlight]:last-child': {
                    borderBottom: '1px dashed transparent'
                },
                '& code:first-of-type': {
                    paddingRight: `0px !important`,
                    backgroundImage: showLineNumbers ? `linear-gradient(to right, var(${0, (0, parcelRequire("lBXbx")).VAR_CODE_LINE_NUMBER_BG_COLOR},${theme.lineNumberBgColor}), var(${0, (0, parcelRequire("lBXbx")).VAR_CODE_LINE_NUMBER_BG_COLOR},${theme.lineNumberBgColor})
    calc(${theme.lineNumberWidth} + ${0, (0, parcelRequire("lBXbx")).LINE_NUMBER_GUTTER}px), transparent calc(${theme.lineNumberWidth} + ${0, (0, parcelRequire("lBXbx")).LINE_NUMBER_GUTTER}px), transparent)` : undefined
                },
                '& code:last-of-type': {
                    paddingRight: `${0, (0, parcelRequire("lBXbx")).SPACING}px !important`,
                    flexBasis: 'auto',
                    flexGrow: 1,
                    flexShrink: shouldWrapLongLines ? 1 : 0,
                    wordBreak: 'break-word'
                },
                'code > span:only-child:empty:before, code > span:only-child > span:only-child:empty:before': {
                    content: '" "'
                }
            });
    const $a9c1aabaca189fa1$export$d5fcf1b7ff23707b = ()=>{
        const theme = (0, (0, parcelRequire("8CJbG")).getBaseTheme)();
        const baseStyles = $a9c1aabaca189fa1$export$ee58a26b5d612ac1(theme);
        return {
            ...baseStyles,
            display: 'inline',
            padding: '2px 0.5ch',
            boxDecorationBreak: 'clone',
            fontSize: '0.875em',
            overflow: 'auto',
            overflowWrap: 'break-word',
            whiteSpace: 'pre-wrap'
        };
    };
    const $a9c1aabaca189fa1$export$57a2d4b573afd64a = (maxLines)=>{
        return {
            ...(0, (0, parcelRequire("8CJbG")).getBaseTheme)(),
            ...(0, (0, parcelRequire("8CJbG")).getColorPalette)(),
            lineNumberWidth: maxLines ? $a9c1aabaca189fa1$export$cb7aec0206b595d2(maxLines) : undefined
        };
    };
});
parcelRegister("lBXbx", function(module, exports) {
    $parcel$export(module.exports, "CODE_FONT_SIZE", ()=>$6ec31795911692d1$export$431ac46d758d98c8);
    $parcel$export(module.exports, "CODE_LINE_HEIGHT", ()=>$6ec31795911692d1$export$8e3c04032121c606);
    $parcel$export(module.exports, "HIGHLIGHT_BORDER_WIDTH", ()=>$6ec31795911692d1$export$c8b5dc30e9f2b86e);
    $parcel$export(module.exports, "SPACING", ()=>$6ec31795911692d1$export$82ea42e772d49e5b);
    $parcel$export(module.exports, "LINE_NUMBER_GUTTER", ()=>$6ec31795911692d1$export$eeeea0ff98e06fff);
    $parcel$export(module.exports, "VAR_CODE_LINE_NUMBER_BG_COLOR", ()=>$6ec31795911692d1$export$254015fe817dcb9e);
    $parcel$export(module.exports, "VAR_CODE_BG_COLOR", ()=>$6ec31795911692d1$export$1758fda69688282e);
    var $k5uoT;
    const $6ec31795911692d1$export$431ac46d758d98c8 = 12;
    const $6ec31795911692d1$export$8e3c04032121c606 = '20px';
    const $6ec31795911692d1$export$c8b5dc30e9f2b86e = '4px';
    const $6ec31795911692d1$export$82ea42e772d49e5b = (0, (0, parcelRequire("k5uoT")).gridSize)();
    const $6ec31795911692d1$export$eeeea0ff98e06fff = $6ec31795911692d1$export$82ea42e772d49e5b * 2;
    const $6ec31795911692d1$export$254015fe817dcb9e = '--ds--code--line-number-bg-color';
    const $6ec31795911692d1$export$1758fda69688282e = '--ds--code--bg-color';
    const $6ec31795911692d1$export$2cd00c745c539a1 = `data-ds--code--code-block`;
});
parcelRegister("8CJbG", function(module, exports) {
    $parcel$export(module.exports, "getBaseTheme", ()=>$eac5fe855702cfc1$export$4464aa303b3e4fda);
    $parcel$export(module.exports, "getColorPalette", ()=>$eac5fe855702cfc1$export$fe2a6478976e8b17);
    var $8QpSo;
    var $kTHGn;
    const $eac5fe855702cfc1$var$T800 = '#067384';
    const $eac5fe855702cfc1$var$Y1100 = '#7A5D1A';
    const $eac5fe855702cfc1$export$4464aa303b3e4fda = ()=>({
            fontFamily: "var(--ds-font-family-code, ui-monospace, Menlo, \"Segoe UI Mono\", \"Ubuntu Mono\", monospace)",
            fontFamilyItalic: `SFMono-MediumItalic, ${(0, (0, parcelRequire("kTHGn")).default)('font.family.code')}`,
            backgroundColor: `var(--ds-background-neutral, ${$829f609a65d26a98$export$9fdfb749e33cdf97})`,
            textColor: `var(--ds-text, ${$829f609a65d26a98$export$7d61b15e0da132af})`,
            lineNumberColor: `var(--ds-text-subtlest, ${$829f609a65d26a98$export$eece5f492e429f08})`,
            lineNumberBgColor: `var(--ds-background-neutral, ${$829f609a65d26a98$export$3e51e8f1440314ba})`
        });
    const $eac5fe855702cfc1$export$fe2a6478976e8b17 = (0, (0, parcelRequire("8QpSo")).default)(()=>{
        return {
            highlightedLineBgColor: `var(--ds-background-neutral, ${$829f609a65d26a98$export$3e51e8f1440314ba})`,
            highlightedLineBorderColor: `var(--ds-border-focused, ${$829f609a65d26a98$export$215b7063051591f1})`,
            substringColor: `var(--ds-text-subtlest, ${$829f609a65d26a98$export$eece5f492e429f08})`,
            keywordColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            attributeColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            selectorTagColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            docTagColor: `var(--ds-text-accent-yellow, ${$eac5fe855702cfc1$var$Y1100})`,
            nameColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            builtInColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            literalColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            bulletColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            codeColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            regexpColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            symbolColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            variableColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            templateVariableColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            linkColor: `var(--ds-text-accent-purple, ${$829f609a65d26a98$export$b48405b95304b5a1})`,
            selectorAttributeColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            selectorPseudoColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            typeColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            stringColor: `var(--ds-text-accent-green, ${$829f609a65d26a98$export$6fa04827ac68a4fb})`,
            selectorIdColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            selectorClassColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            quoteColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            templateTagColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            titleColor: `var(--ds-text-accent-purple, ${$829f609a65d26a98$export$b48405b95304b5a1})`,
            sectionColor: `var(--ds-text-accent-purple, ${$829f609a65d26a98$export$b48405b95304b5a1})`,
            commentColor: `var(--ds-text-subtlest, ${$829f609a65d26a98$export$eece5f492e429f08})`,
            metaKeywordColor: `var(--ds-text-accent-green, ${$829f609a65d26a98$export$6fa04827ac68a4fb})`,
            metaColor: `var(--ds-text-subtlest, ${$829f609a65d26a98$export$eece5f492e429f08})`,
            functionColor: `var(--ds-text, ${$829f609a65d26a98$export$7d61b15e0da132af})`,
            numberColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            prologColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            cdataColor: `var(--ds-text-subtlest, ${$829f609a65d26a98$export$eece5f492e429f08})`,
            punctuationColor: `var(--ds-text, ${$829f609a65d26a98$export$7d61b15e0da132af})`,
            propertyColor: `var(--ds-text-accent-purple, ${$829f609a65d26a98$export$b48405b95304b5a1})`,
            constantColor: `var(--ds-text-accent-teal, ${$eac5fe855702cfc1$var$T800})`,
            booleanColor: `var(--ds-text-accent-blue, ${$829f609a65d26a98$export$b3de0ae08da2ce64})`,
            charColor: `var(--ds-text, ${$829f609a65d26a98$export$7d61b15e0da132af})`,
            insertedColor: `var(--ds-text-accent-green, ${$829f609a65d26a98$export$6fa04827ac68a4fb})`,
            deletedColor: `var(--ds-text-accent-red, ${$829f609a65d26a98$export$2837b5a1a71d5c02})`,
            operatorColor: `var(--ds-text, ${$829f609a65d26a98$export$7d61b15e0da132af})`,
            atruleColor: `var(--ds-text-accent-green, ${$829f609a65d26a98$export$6fa04827ac68a4fb})`,
            importantColor: `var(--ds-text-accent-yellow, ${$eac5fe855702cfc1$var$Y1100})`
        };
    });
    const $eac5fe855702cfc1$var$getTheme = ()=>({
            ...$eac5fe855702cfc1$export$4464aa303b3e4fda(),
            ...$eac5fe855702cfc1$export$fe2a6478976e8b17()
        });
    var $eac5fe855702cfc1$export$2e2bcd8739ae039 = $eac5fe855702cfc1$var$getTheme;
});
var $829f609a65d26a98$exports = {};
$parcel$export($829f609a65d26a98$exports, "R50", ()=>$829f609a65d26a98$export$4b84fd0e2c617bd);
$parcel$export($829f609a65d26a98$exports, "R75", ()=>$829f609a65d26a98$export$b34b069eff244586);
$parcel$export($829f609a65d26a98$exports, "R100", ()=>$829f609a65d26a98$export$be802dd0d8b57f35);
$parcel$export($829f609a65d26a98$exports, "R200", ()=>$829f609a65d26a98$export$60847d42f9da92a6);
$parcel$export($829f609a65d26a98$exports, "R300", ()=>$829f609a65d26a98$export$4a7404f376229999);
$parcel$export($829f609a65d26a98$exports, "R400", ()=>$829f609a65d26a98$export$2010d0fb4295b346);
$parcel$export($829f609a65d26a98$exports, "R500", ()=>$829f609a65d26a98$export$2837b5a1a71d5c02);
$parcel$export($829f609a65d26a98$exports, "Y50", ()=>$829f609a65d26a98$export$8d9596b529bbb9cf);
$parcel$export($829f609a65d26a98$exports, "Y75", ()=>$829f609a65d26a98$export$41060a744ab0b7ae);
$parcel$export($829f609a65d26a98$exports, "Y100", ()=>$829f609a65d26a98$export$1cc05bbbf340e559);
$parcel$export($829f609a65d26a98$exports, "Y200", ()=>$829f609a65d26a98$export$5aae31392d72caaa);
$parcel$export($829f609a65d26a98$exports, "Y300", ()=>$829f609a65d26a98$export$c6930421db9fa514);
$parcel$export($829f609a65d26a98$exports, "Y400", ()=>$829f609a65d26a98$export$59619663aa6500fb);
$parcel$export($829f609a65d26a98$exports, "Y500", ()=>$829f609a65d26a98$export$874739ea9e379ab6);
$parcel$export($829f609a65d26a98$exports, "G50", ()=>$829f609a65d26a98$export$8a6341c32b6a3ee6);
$parcel$export($829f609a65d26a98$exports, "G75", ()=>$829f609a65d26a98$export$69a3bf21b9686c2b);
$parcel$export($829f609a65d26a98$exports, "G100", ()=>$829f609a65d26a98$export$afbce1382ec95f73);
$parcel$export($829f609a65d26a98$exports, "G200", ()=>$829f609a65d26a98$export$747d86715d4175de);
$parcel$export($829f609a65d26a98$exports, "G300", ()=>$829f609a65d26a98$export$940e1f69e873f202);
$parcel$export($829f609a65d26a98$exports, "G400", ()=>$829f609a65d26a98$export$a49394138f7b8e85);
$parcel$export($829f609a65d26a98$exports, "G500", ()=>$829f609a65d26a98$export$6fa04827ac68a4fb);
$parcel$export($829f609a65d26a98$exports, "B50", ()=>$829f609a65d26a98$export$226de0355ed012f8);
$parcel$export($829f609a65d26a98$exports, "B75", ()=>$829f609a65d26a98$export$8c13c2ee36917869);
$parcel$export($829f609a65d26a98$exports, "B100", ()=>$829f609a65d26a98$export$ecfc1dff386a3e7a);
$parcel$export($829f609a65d26a98$exports, "B200", ()=>$829f609a65d26a98$export$215b7063051591f1);
$parcel$export($829f609a65d26a98$exports, "B300", ()=>$829f609a65d26a98$export$e62e8919cca44969);
$parcel$export($829f609a65d26a98$exports, "B400", ()=>$829f609a65d26a98$export$b3de0ae08da2ce64);
$parcel$export($829f609a65d26a98$exports, "B500", ()=>$829f609a65d26a98$export$a6ee3d71bc067634);
$parcel$export($829f609a65d26a98$exports, "B600", ()=>$829f609a65d26a98$export$67f15409e87642a1);
$parcel$export($829f609a65d26a98$exports, "B700", ()=>$829f609a65d26a98$export$f0539dbc42dbb067);
$parcel$export($829f609a65d26a98$exports, "P50", ()=>$829f609a65d26a98$export$363e4475840015bb);
$parcel$export($829f609a65d26a98$exports, "P75", ()=>$829f609a65d26a98$export$4b0a4417ed59760f);
$parcel$export($829f609a65d26a98$exports, "P100", ()=>$829f609a65d26a98$export$6011520a64d9a187);
$parcel$export($829f609a65d26a98$exports, "P200", ()=>$829f609a65d26a98$export$a7175d4e9206bc80);
$parcel$export($829f609a65d26a98$exports, "P300", ()=>$829f609a65d26a98$export$b48405b95304b5a1);
$parcel$export($829f609a65d26a98$exports, "P400", ()=>$829f609a65d26a98$export$c71e4279dd54a18d);
$parcel$export($829f609a65d26a98$exports, "P500", ()=>$829f609a65d26a98$export$147899de772240ec);
$parcel$export($829f609a65d26a98$exports, "T50", ()=>$829f609a65d26a98$export$2cf711fe14c66f06);
$parcel$export($829f609a65d26a98$exports, "T75", ()=>$829f609a65d26a98$export$7a8f4d33f6f9772c);
$parcel$export($829f609a65d26a98$exports, "T100", ()=>$829f609a65d26a98$export$776c9c497ef08533);
$parcel$export($829f609a65d26a98$exports, "T200", ()=>$829f609a65d26a98$export$69cb3ca10c1e330e);
$parcel$export($829f609a65d26a98$exports, "T300", ()=>$829f609a65d26a98$export$9dc1ef0f3f48059b);
$parcel$export($829f609a65d26a98$exports, "T400", ()=>$829f609a65d26a98$export$7c835c78b6fcdef8);
$parcel$export($829f609a65d26a98$exports, "T500", ()=>$829f609a65d26a98$export$6036577f95c3877e);
$parcel$export($829f609a65d26a98$exports, "N0", ()=>$829f609a65d26a98$export$29a4f05edc054374);
$parcel$export($829f609a65d26a98$exports, "N10", ()=>$829f609a65d26a98$export$e655ef011c4aa1a2);
$parcel$export($829f609a65d26a98$exports, "N20", ()=>$829f609a65d26a98$export$9fdfb749e33cdf97);
$parcel$export($829f609a65d26a98$exports, "N30", ()=>$829f609a65d26a98$export$3e51e8f1440314ba);
$parcel$export($829f609a65d26a98$exports, "N40", ()=>$829f609a65d26a98$export$1ce2d1575d444ceb);
$parcel$export($829f609a65d26a98$exports, "N50", ()=>$829f609a65d26a98$export$8754fc7e6d8b3000);
$parcel$export($829f609a65d26a98$exports, "N60", ()=>$829f609a65d26a98$export$c2e91cbeada39b8b);
$parcel$export($829f609a65d26a98$exports, "N70", ()=>$829f609a65d26a98$export$5f71e19f3a7ad9de);
$parcel$export($829f609a65d26a98$exports, "N80", ()=>$829f609a65d26a98$export$eaa8c8a70ac22806);
$parcel$export($829f609a65d26a98$exports, "N90", ()=>$829f609a65d26a98$export$7c87d337fb91a97d);
$parcel$export($829f609a65d26a98$exports, "N100", ()=>$829f609a65d26a98$export$d6447c357493214f);
$parcel$export($829f609a65d26a98$exports, "N200", ()=>$829f609a65d26a98$export$f563ee71e9ee74cd);
$parcel$export($829f609a65d26a98$exports, "N300", ()=>$829f609a65d26a98$export$e2ef2124cf4024b1);
$parcel$export($829f609a65d26a98$exports, "N400", ()=>$829f609a65d26a98$export$eece5f492e429f08);
$parcel$export($829f609a65d26a98$exports, "N500", ()=>$829f609a65d26a98$export$c39c0fe0f26207ae);
$parcel$export($829f609a65d26a98$exports, "N600", ()=>$829f609a65d26a98$export$7090862dc3038a9f);
$parcel$export($829f609a65d26a98$exports, "N700", ()=>$829f609a65d26a98$export$ad0bacf983b7ddaa);
$parcel$export($829f609a65d26a98$exports, "N800", ()=>$829f609a65d26a98$export$7d61b15e0da132af);
$parcel$export($829f609a65d26a98$exports, "N900", ()=>$829f609a65d26a98$export$fbda843f0a27fe96);
$parcel$export($829f609a65d26a98$exports, "N10A", ()=>$829f609a65d26a98$export$791e57b27ca738ef);
$parcel$export($829f609a65d26a98$exports, "N20A", ()=>$829f609a65d26a98$export$8d43898f60b96a82);
$parcel$export($829f609a65d26a98$exports, "N30A", ()=>$829f609a65d26a98$export$920c0d5c49070351);
$parcel$export($829f609a65d26a98$exports, "N40A", ()=>$829f609a65d26a98$export$46987b49aab6d1a8);
$parcel$export($829f609a65d26a98$exports, "N50A", ()=>$829f609a65d26a98$export$7d3359664e8bb0e5);
$parcel$export($829f609a65d26a98$exports, "N60A", ()=>$829f609a65d26a98$export$d2d3bedfb276cac0);
$parcel$export($829f609a65d26a98$exports, "N70A", ()=>$829f609a65d26a98$export$6e5e7499ff054f6d);
$parcel$export($829f609a65d26a98$exports, "N80A", ()=>$829f609a65d26a98$export$59264b346c877fa7);
$parcel$export($829f609a65d26a98$exports, "N90A", ()=>$829f609a65d26a98$export$d092757d7c64925c);
$parcel$export($829f609a65d26a98$exports, "N100A", ()=>$829f609a65d26a98$export$486ab785e4bac3c6);
$parcel$export($829f609a65d26a98$exports, "N200A", ()=>$829f609a65d26a98$export$fba592bbc6419fea);
$parcel$export($829f609a65d26a98$exports, "N300A", ()=>$829f609a65d26a98$export$13609db0e6812ab8);
$parcel$export($829f609a65d26a98$exports, "N400A", ()=>$829f609a65d26a98$export$fbae4efaa892d883);
$parcel$export($829f609a65d26a98$exports, "N500A", ()=>$829f609a65d26a98$export$f13946d26f440ce6);
$parcel$export($829f609a65d26a98$exports, "N600A", ()=>$829f609a65d26a98$export$19f58225ac52a306);
$parcel$export($829f609a65d26a98$exports, "N700A", ()=>$829f609a65d26a98$export$6384f09da70e7b62);
$parcel$export($829f609a65d26a98$exports, "N800A", ()=>$829f609a65d26a98$export$d0dccc730683edc2);
$parcel$export($829f609a65d26a98$exports, "DN900", ()=>$829f609a65d26a98$export$2f6319cf7d736c2a);
$parcel$export($829f609a65d26a98$exports, "DN800", ()=>$829f609a65d26a98$export$95126bc8884d6634);
$parcel$export($829f609a65d26a98$exports, "DN700", ()=>$829f609a65d26a98$export$8338d8c35d44ff1);
$parcel$export($829f609a65d26a98$exports, "DN600", ()=>$829f609a65d26a98$export$5368d76ae4fbbda7);
$parcel$export($829f609a65d26a98$exports, "DN500", ()=>$829f609a65d26a98$export$9f0a771d16ec8d36);
$parcel$export($829f609a65d26a98$exports, "DN400", ()=>$829f609a65d26a98$export$abcf8c38338b4aa5);
$parcel$export($829f609a65d26a98$exports, "DN300", ()=>$829f609a65d26a98$export$97e81faa16a182c7);
$parcel$export($829f609a65d26a98$exports, "DN200", ()=>$829f609a65d26a98$export$bfe21c59123ecf33);
$parcel$export($829f609a65d26a98$exports, "DN100", ()=>$829f609a65d26a98$export$ee82aa2ea6a1558);
$parcel$export($829f609a65d26a98$exports, "DN90", ()=>$829f609a65d26a98$export$682927c6ed76be02);
$parcel$export($829f609a65d26a98$exports, "DN80", ()=>$829f609a65d26a98$export$91fd9994c5be9757);
$parcel$export($829f609a65d26a98$exports, "DN70", ()=>$829f609a65d26a98$export$d281fbfe3cff2df6);
$parcel$export($829f609a65d26a98$exports, "DN60", ()=>$829f609a65d26a98$export$3902c91d8b98a753);
$parcel$export($829f609a65d26a98$exports, "DN50", ()=>$829f609a65d26a98$export$8e8133c1ba5845b5);
$parcel$export($829f609a65d26a98$exports, "DN40", ()=>$829f609a65d26a98$export$33ff357f6620b39);
$parcel$export($829f609a65d26a98$exports, "DN30", ()=>$829f609a65d26a98$export$633847cf062b69e0);
$parcel$export($829f609a65d26a98$exports, "DN20", ()=>$829f609a65d26a98$export$9776a6e503bdbf76);
$parcel$export($829f609a65d26a98$exports, "DN10", ()=>$829f609a65d26a98$export$6c813d5160dff5db);
$parcel$export($829f609a65d26a98$exports, "DN0", ()=>$829f609a65d26a98$export$3d4f271a500072a);
$parcel$export($829f609a65d26a98$exports, "DN800A", ()=>$829f609a65d26a98$export$fe151285c22759ec);
$parcel$export($829f609a65d26a98$exports, "DN700A", ()=>$829f609a65d26a98$export$a101c6190d0da34);
$parcel$export($829f609a65d26a98$exports, "DN600A", ()=>$829f609a65d26a98$export$4b16f52932fda51);
$parcel$export($829f609a65d26a98$exports, "DN500A", ()=>$829f609a65d26a98$export$a74bcd7b9845b0d8);
$parcel$export($829f609a65d26a98$exports, "DN400A", ()=>$829f609a65d26a98$export$9209c179316d1751);
$parcel$export($829f609a65d26a98$exports, "DN300A", ()=>$829f609a65d26a98$export$88f55133f636c8dd);
$parcel$export($829f609a65d26a98$exports, "DN200A", ()=>$829f609a65d26a98$export$51126f85d1009512);
$parcel$export($829f609a65d26a98$exports, "DN100A", ()=>$829f609a65d26a98$export$a069b547bd23af78);
$parcel$export($829f609a65d26a98$exports, "DN90A", ()=>$829f609a65d26a98$export$40cce05af3b2600a);
$parcel$export($829f609a65d26a98$exports, "DN80A", ()=>$829f609a65d26a98$export$b78942185925a0d);
$parcel$export($829f609a65d26a98$exports, "DN70A", ()=>$829f609a65d26a98$export$e058fb44b8eaafca);
$parcel$export($829f609a65d26a98$exports, "DN60A", ()=>$829f609a65d26a98$export$92b0b8de26920c18);
$parcel$export($829f609a65d26a98$exports, "DN50A", ()=>$829f609a65d26a98$export$67564f7459d3fc9e);
$parcel$export($829f609a65d26a98$exports, "DN40A", ()=>$829f609a65d26a98$export$77868e1ca884ffa3);
$parcel$export($829f609a65d26a98$exports, "DN30A", ()=>$829f609a65d26a98$export$2afd71d7d3ea2cd5);
$parcel$export($829f609a65d26a98$exports, "DN20A", ()=>$829f609a65d26a98$export$f2a0acaab9972c70);
$parcel$export($829f609a65d26a98$exports, "DN10A", ()=>$829f609a65d26a98$export$9ab70e67d6475361);
$parcel$export($829f609a65d26a98$exports, "T30", ()=>$829f609a65d26a98$export$d2adc5dedf09fced);
$parcel$export($829f609a65d26a98$exports, "L50", ()=>$829f609a65d26a98$export$f51762073d2bb381);
$parcel$export($829f609a65d26a98$exports, "L75", ()=>$829f609a65d26a98$export$95e26d54def96891);
const $829f609a65d26a98$export$4b84fd0e2c617bd = '#FFEBE6';
const $829f609a65d26a98$export$b34b069eff244586 = '#FFBDAD';
const $829f609a65d26a98$export$be802dd0d8b57f35 = '#FF8F73';
const $829f609a65d26a98$export$60847d42f9da92a6 = '#FF7452';
const $829f609a65d26a98$export$4a7404f376229999 = '#FF5630';
const $829f609a65d26a98$export$2010d0fb4295b346 = '#DE350B';
const $829f609a65d26a98$export$2837b5a1a71d5c02 = '#BF2600';
const $829f609a65d26a98$export$8d9596b529bbb9cf = '#FFFAE6';
const $829f609a65d26a98$export$41060a744ab0b7ae = '#FFF0B3';
const $829f609a65d26a98$export$1cc05bbbf340e559 = '#FFE380';
const $829f609a65d26a98$export$5aae31392d72caaa = '#FFC400';
const $829f609a65d26a98$export$c6930421db9fa514 = '#FFAB00';
const $829f609a65d26a98$export$59619663aa6500fb = '#FF991F';
const $829f609a65d26a98$export$874739ea9e379ab6 = '#FF8B00';
const $829f609a65d26a98$export$8a6341c32b6a3ee6 = '#E3FCEF';
const $829f609a65d26a98$export$69a3bf21b9686c2b = '#ABF5D1';
const $829f609a65d26a98$export$afbce1382ec95f73 = '#79F2C0';
const $829f609a65d26a98$export$747d86715d4175de = '#57D9A3';
const $829f609a65d26a98$export$940e1f69e873f202 = '#36B37E';
const $829f609a65d26a98$export$a49394138f7b8e85 = '#00875A';
const $829f609a65d26a98$export$6fa04827ac68a4fb = '#006644';
const $829f609a65d26a98$export$226de0355ed012f8 = '#DEEBFF';
const $829f609a65d26a98$export$8c13c2ee36917869 = '#B3D4FF';
const $829f609a65d26a98$export$ecfc1dff386a3e7a = '#4C9AFF';
const $829f609a65d26a98$export$215b7063051591f1 = '#2684FF';
const $829f609a65d26a98$export$e62e8919cca44969 = '#0065FF';
const $829f609a65d26a98$export$b3de0ae08da2ce64 = '#0052CC';
const $829f609a65d26a98$export$a6ee3d71bc067634 = '#0747A6';
const $829f609a65d26a98$export$67f15409e87642a1 = '#669DF1';
const $829f609a65d26a98$export$f0539dbc42dbb067 = '#8FB8F6';
const $829f609a65d26a98$export$363e4475840015bb = '#EAE6FF';
const $829f609a65d26a98$export$4b0a4417ed59760f = '#C0B6F2';
const $829f609a65d26a98$export$6011520a64d9a187 = '#998DD9';
const $829f609a65d26a98$export$a7175d4e9206bc80 = '#8777D9';
const $829f609a65d26a98$export$b48405b95304b5a1 = '#6554C0';
const $829f609a65d26a98$export$c71e4279dd54a18d = '#5243AA';
const $829f609a65d26a98$export$147899de772240ec = '#403294';
const $829f609a65d26a98$export$2cf711fe14c66f06 = '#E6FCFF';
const $829f609a65d26a98$export$7a8f4d33f6f9772c = '#B3F5FF';
const $829f609a65d26a98$export$776c9c497ef08533 = '#79E2F2';
const $829f609a65d26a98$export$69cb3ca10c1e330e = '#00C7E6';
const $829f609a65d26a98$export$9dc1ef0f3f48059b = '#00B8D9';
const $829f609a65d26a98$export$7c835c78b6fcdef8 = '#00A3BF';
const $829f609a65d26a98$export$6036577f95c3877e = '#008DA6';
const $829f609a65d26a98$export$29a4f05edc054374 = '#FFFFFF';
const $829f609a65d26a98$export$e655ef011c4aa1a2 = '#FAFBFC';
const $829f609a65d26a98$export$9fdfb749e33cdf97 = '#F4F5F7';
const $829f609a65d26a98$export$3e51e8f1440314ba = '#EBECF0';
const $829f609a65d26a98$export$1ce2d1575d444ceb = '#DFE1E6';
const $829f609a65d26a98$export$8754fc7e6d8b3000 = '#C1C7D0';
const $829f609a65d26a98$export$c2e91cbeada39b8b = '#B3BAC5';
const $829f609a65d26a98$export$5f71e19f3a7ad9de = '#A5ADBA';
const $829f609a65d26a98$export$eaa8c8a70ac22806 = '#97A0AF';
const $829f609a65d26a98$export$7c87d337fb91a97d = '#8993A4';
const $829f609a65d26a98$export$d6447c357493214f = '#7A869A';
const $829f609a65d26a98$export$f563ee71e9ee74cd = '#6B778C';
const $829f609a65d26a98$export$e2ef2124cf4024b1 = '#5E6C84';
const $829f609a65d26a98$export$eece5f492e429f08 = '#505F79';
const $829f609a65d26a98$export$c39c0fe0f26207ae = '#42526E';
const $829f609a65d26a98$export$7090862dc3038a9f = '#344563';
const $829f609a65d26a98$export$ad0bacf983b7ddaa = '#253858';
const $829f609a65d26a98$export$7d61b15e0da132af = '#172B4D';
const $829f609a65d26a98$export$fbda843f0a27fe96 = '#091E42';
const $829f609a65d26a98$export$791e57b27ca738ef = 'rgba(9, 30, 66, 0.02)';
const $829f609a65d26a98$export$8d43898f60b96a82 = 'rgba(9, 30, 66, 0.04)';
const $829f609a65d26a98$export$920c0d5c49070351 = 'rgba(9, 30, 66, 0.08)';
const $829f609a65d26a98$export$46987b49aab6d1a8 = 'rgba(9, 30, 66, 0.13)';
const $829f609a65d26a98$export$7d3359664e8bb0e5 = 'rgba(9, 30, 66, 0.25)';
const $829f609a65d26a98$export$d2d3bedfb276cac0 = 'rgba(9, 30, 66, 0.31)';
const $829f609a65d26a98$export$6e5e7499ff054f6d = 'rgba(9, 30, 66, 0.36)';
const $829f609a65d26a98$export$59264b346c877fa7 = 'rgba(9, 30, 66, 0.42)';
const $829f609a65d26a98$export$d092757d7c64925c = 'rgba(9, 30, 66, 0.48)';
const $829f609a65d26a98$export$486ab785e4bac3c6 = 'rgba(9, 30, 66, 0.54)';
const $829f609a65d26a98$export$fba592bbc6419fea = 'rgba(9, 30, 66, 0.60)';
const $829f609a65d26a98$export$13609db0e6812ab8 = 'rgba(9, 30, 66, 0.66)';
const $829f609a65d26a98$export$fbae4efaa892d883 = 'rgba(9, 30, 66, 0.71)';
const $829f609a65d26a98$export$f13946d26f440ce6 = 'rgba(9, 30, 66, 0.77)';
const $829f609a65d26a98$export$19f58225ac52a306 = 'rgba(9, 30, 66, 0.82)';
const $829f609a65d26a98$export$6384f09da70e7b62 = 'rgba(9, 30, 66, 0.89)';
const $829f609a65d26a98$export$d0dccc730683edc2 = 'rgba(9, 30, 66, 0.95)';
const $829f609a65d26a98$export$2f6319cf7d736c2a = '#E6EDFA';
const $829f609a65d26a98$export$95126bc8884d6634 = '#DCE5F5';
const $829f609a65d26a98$export$8338d8c35d44ff1 = '#CED9EB';
const $829f609a65d26a98$export$5368d76ae4fbbda7 = '#B8C7E0';
const $829f609a65d26a98$export$9f0a771d16ec8d36 = '#ABBBD6';
const $829f609a65d26a98$export$abcf8c38338b4aa5 = '#9FB0CC';
const $829f609a65d26a98$export$97e81faa16a182c7 = '#8C9CB8';
const $829f609a65d26a98$export$bfe21c59123ecf33 = '#7988A3';
const $829f609a65d26a98$export$ee82aa2ea6a1558 = '#67758F';
const $829f609a65d26a98$export$682927c6ed76be02 = '#56637A';
const $829f609a65d26a98$export$91fd9994c5be9757 = '#455166';
const $829f609a65d26a98$export$d281fbfe3cff2df6 = '#3B475C';
const $829f609a65d26a98$export$3902c91d8b98a753 = '#313D52';
const $829f609a65d26a98$export$8e8133c1ba5845b5 = '#283447';
const $829f609a65d26a98$export$33ff357f6620b39 = '#202B3D';
const $829f609a65d26a98$export$633847cf062b69e0 = '#1B2638';
const $829f609a65d26a98$export$9776a6e503bdbf76 = '#121A29';
const $829f609a65d26a98$export$6c813d5160dff5db = '#0E1624';
const $829f609a65d26a98$export$3d4f271a500072a = '#0D1424';
const $829f609a65d26a98$export$fe151285c22759ec = 'rgba(13, 20, 36, 0.06)';
const $829f609a65d26a98$export$a101c6190d0da34 = 'rgba(13, 20, 36, 0.14)';
const $829f609a65d26a98$export$4b16f52932fda51 = 'rgba(13, 20, 36, 0.18)';
const $829f609a65d26a98$export$a74bcd7b9845b0d8 = 'rgba(13, 20, 36, 0.29)';
const $829f609a65d26a98$export$9209c179316d1751 = 'rgba(13, 20, 36, 0.36)';
const $829f609a65d26a98$export$88f55133f636c8dd = 'rgba(13, 20, 36, 0.40)';
const $829f609a65d26a98$export$51126f85d1009512 = 'rgba(13, 20, 36, 0.47)';
const $829f609a65d26a98$export$a069b547bd23af78 = 'rgba(13, 20, 36, 0.53)';
const $829f609a65d26a98$export$40cce05af3b2600a = 'rgba(13, 20, 36, 0.63)';
const $829f609a65d26a98$export$b78942185925a0d = 'rgba(13, 20, 36, 0.73)';
const $829f609a65d26a98$export$e058fb44b8eaafca = 'rgba(13, 20, 36, 0.78)';
const $829f609a65d26a98$export$92b0b8de26920c18 = 'rgba(13, 20, 36, 0.81)';
const $829f609a65d26a98$export$67564f7459d3fc9e = 'rgba(13, 20, 36, 0.85)';
const $829f609a65d26a98$export$77868e1ca884ffa3 = 'rgba(13, 20, 36, 0.89)';
const $829f609a65d26a98$export$2afd71d7d3ea2cd5 = 'rgba(13, 20, 36, 0.92)';
const $829f609a65d26a98$export$f2a0acaab9972c70 = 'rgba(13, 20, 36, 0.95)';
const $829f609a65d26a98$export$9ab70e67d6475361 = 'rgba(13, 20, 36, 0.97)';
const $829f609a65d26a98$export$d2adc5dedf09fced = '#292A2E';
const $829f609a65d26a98$export$f51762073d2bb381 = '#94C748';
const $829f609a65d26a98$export$95e26d54def96891 = '#B3DF72';
parcelRegister("eCBnN", function(module, exports) {
    $parcel$export(module.exports, "normalizeLanguage", ()=>$d00516a0f8207944$export$f38728011c178796);
    var $8QpSo;
    var $8Y2ZY;
    const $d00516a0f8207944$export$f38728011c178796 = (0, (0, parcelRequire("8QpSo")).default)((language)=>{
        if (!language) return '';
        const match = (0, (0, parcelRequire("8Y2ZY")).SUPPORTED_LANGUAGES).find((val)=>{
            return val.name === language || val.alias.includes(language);
        });
        return match ? match.value : 'text';
    });
});
parcelRegister("8Y2ZY", function(module, exports) {
    $parcel$export(module.exports, "SUPPORTED_LANGUAGES", ()=>$012a854d92762508$export$7c77c3a9bb93870d);
    const $012a854d92762508$export$7c77c3a9bb93870d = [
        {
            name: 'PHP',
            alias: [
                'php',
                'php3',
                'php4',
                'php5'
            ],
            value: 'php'
        },
        {
            name: 'Java',
            alias: [
                'java'
            ],
            value: 'java'
        },
        {
            name: 'CSharp',
            alias: [
                'csharp',
                'c#'
            ],
            value: 'cs'
        },
        {
            name: 'Python',
            alias: [
                'python',
                'py'
            ],
            value: 'python'
        },
        {
            name: 'JavaScript',
            alias: [
                'javascript',
                'js'
            ],
            value: 'javascript'
        },
        {
            name: 'Html',
            alias: [
                'html'
            ],
            value: 'xml'
        },
        {
            name: 'C++',
            alias: [
                'c++',
                'cpp',
                'clike'
            ],
            value: 'cpp'
        },
        {
            name: 'Ruby',
            alias: [
                'ruby',
                'rb',
                'duby'
            ],
            value: 'ruby'
        },
        {
            name: 'Objective-C',
            alias: [
                'objective-c',
                'objectivec',
                'obj-c',
                'objc'
            ],
            value: 'objectivec'
        },
        {
            name: 'C',
            alias: [
                'c'
            ],
            value: 'cpp'
        },
        {
            name: 'Swift',
            alias: [
                'swift'
            ],
            value: 'swift'
        },
        {
            name: 'TeX',
            alias: [
                'tex',
                'latex'
            ],
            value: 'tex'
        },
        {
            name: 'Shell',
            alias: [
                'shell',
                'bash',
                'sh',
                'ksh',
                'zsh'
            ],
            value: 'shell'
        },
        {
            name: 'Scala',
            alias: [
                'scala'
            ],
            value: 'scala'
        },
        {
            name: 'Go',
            alias: [
                'go'
            ],
            value: 'go'
        },
        {
            name: 'ActionScript',
            alias: [
                'actionscript',
                'actionscript3',
                'as'
            ],
            value: 'actionscript'
        },
        {
            name: 'ColdFusion',
            alias: [
                'coldfusion'
            ],
            value: 'xml'
        },
        {
            name: 'JavaFX',
            alias: [
                'javafx',
                'jfx'
            ],
            value: 'java'
        },
        {
            name: 'VbNet',
            alias: [
                'vbnet',
                'vb.net',
                'vfp',
                'clipper',
                'xbase'
            ],
            value: 'vbnet'
        },
        {
            name: 'JSON',
            alias: [
                'json'
            ],
            value: 'json'
        },
        {
            name: 'MATLAB',
            alias: [
                'matlab'
            ],
            value: 'matlab'
        },
        {
            name: 'Groovy',
            alias: [
                'groovy'
            ],
            value: 'groovy'
        },
        {
            name: 'SQL',
            alias: [
                'sql',
                'postgresql',
                'postgres',
                'plpgsql',
                'psql',
                'postgresql-console',
                'postgres-console',
                'tsql',
                't-sql',
                'mysql',
                'sqlite'
            ],
            value: 'sql'
        },
        {
            name: 'R',
            alias: [
                'r'
            ],
            value: 'r'
        },
        {
            name: 'Perl',
            alias: [
                'perl',
                'pl'
            ],
            value: 'perl'
        },
        {
            name: 'Lua',
            alias: [
                'lua'
            ],
            value: 'lua'
        },
        {
            name: 'Pascal',
            alias: [
                'pas',
                'pascal',
                'objectpascal',
                'delphi'
            ],
            value: 'pascal'
        },
        {
            name: 'XML',
            alias: [
                'xml'
            ],
            value: 'xml'
        },
        {
            name: 'TypeScript',
            alias: [
                'typescript',
                'ts'
            ],
            value: 'typescript'
        },
        {
            name: 'CoffeeScript',
            alias: [
                'coffeescript',
                'coffee-script',
                'coffee'
            ],
            value: 'coffeescript'
        },
        {
            name: 'Haskell',
            alias: [
                'haskell',
                'hs'
            ],
            value: 'haskell'
        },
        {
            name: 'Puppet',
            alias: [
                'puppet'
            ],
            value: 'puppet'
        },
        {
            name: 'Arduino',
            alias: [
                'arduino'
            ],
            value: 'arduino'
        },
        {
            name: 'Fortran',
            alias: [
                'fortran'
            ],
            value: 'fortran'
        },
        {
            name: 'Erlang',
            alias: [
                'erlang',
                'erl'
            ],
            value: 'erlang'
        },
        {
            name: 'PowerShell',
            alias: [
                'powershell',
                'posh',
                'ps1',
                'psm1'
            ],
            value: 'powershell'
        },
        {
            name: 'Haxe',
            alias: [
                'haxe',
                'hx',
                'hxsl'
            ],
            value: 'haxe'
        },
        {
            name: 'Elixir',
            alias: [
                'elixir',
                'ex',
                'exs'
            ],
            value: 'elixir'
        },
        {
            name: 'Verilog',
            alias: [
                'verilog',
                'v'
            ],
            value: 'verilog'
        },
        {
            name: 'Rust',
            alias: [
                'rust'
            ],
            value: 'rust'
        },
        {
            name: 'VHDL',
            alias: [
                'vhdl'
            ],
            value: 'vhdl'
        },
        {
            name: 'Sass',
            alias: [
                'sass'
            ],
            value: 'less'
        },
        {
            name: 'OCaml',
            alias: [
                'ocaml'
            ],
            value: 'ocaml'
        },
        {
            name: 'Dart',
            alias: [
                'dart'
            ],
            value: 'dart'
        },
        {
            name: 'CSS',
            alias: [
                'css'
            ],
            value: 'css'
        },
        {
            name: 'reStructuredText',
            alias: [
                'restructuredtext',
                'rst',
                'rest'
            ],
            value: 'rest'
        },
        {
            name: 'Kotlin',
            alias: [
                'kotlin'
            ],
            value: 'kotlin'
        },
        {
            name: 'D',
            alias: [
                'd'
            ],
            value: 'd'
        },
        {
            name: 'Octave',
            alias: [
                'octave'
            ],
            value: 'matlab'
        },
        {
            name: 'QML',
            alias: [
                'qbs',
                'qml'
            ],
            value: 'qml'
        },
        {
            name: 'Prolog',
            alias: [
                'prolog'
            ],
            value: 'prolog'
        },
        {
            name: 'FoxPro',
            alias: [
                'foxpro',
                'purebasic'
            ],
            value: 'purebasic'
        },
        {
            name: 'Scheme',
            alias: [
                'scheme',
                'scm'
            ],
            value: 'scheme'
        },
        {
            name: 'CUDA',
            alias: [
                'cuda',
                'cu'
            ],
            value: 'cpp'
        },
        {
            name: 'Julia',
            alias: [
                'julia',
                'jl'
            ],
            value: 'julia'
        },
        {
            name: 'Racket',
            alias: [
                'racket',
                'rkt'
            ],
            value: 'lisp'
        },
        {
            name: 'Ada',
            alias: [
                'ada',
                'ada95',
                'ada2005'
            ],
            value: 'ada'
        },
        {
            name: 'Tcl',
            alias: [
                'tcl'
            ],
            value: 'tcl'
        },
        {
            name: 'Mathematica',
            alias: [
                'mathematica',
                'mma',
                'nb'
            ],
            value: 'mathematica'
        },
        {
            name: 'Autoit',
            alias: [
                'autoit'
            ],
            value: 'autoit'
        },
        {
            name: 'StandardML',
            alias: [
                'standardmL',
                'sml',
                'standardml'
            ],
            value: 'sml'
        },
        {
            name: 'Objective-J',
            alias: [
                'objective-j',
                'objectivej',
                'obj-j',
                'objj'
            ],
            value: 'objectivec'
        },
        {
            name: 'Smalltalk',
            alias: [
                'smalltalk',
                'squeak',
                'st'
            ],
            value: 'smalltalk'
        },
        {
            name: 'Vala',
            alias: [
                'vala',
                'vapi'
            ],
            value: 'vala'
        },
        {
            name: 'ABAP',
            alias: [
                'abap'
            ],
            value: 'sql'
        },
        {
            name: 'LiveScript',
            alias: [
                'livescript',
                'live-script'
            ],
            value: 'livescript'
        },
        {
            name: 'XQuery',
            alias: [
                'xquery',
                'xqy',
                'xq',
                'xql',
                'xqm'
            ],
            value: 'xquery'
        },
        {
            name: 'PlainText',
            alias: [
                'text',
                'plaintext'
            ],
            value: 'text'
        },
        {
            name: 'Yaml',
            alias: [
                'yaml',
                'yml'
            ],
            value: 'yaml'
        },
        {
            name: 'GraphQL',
            alias: [
                'graphql'
            ],
            value: 'graphql'
        },
        {
            name: 'AppleScript',
            alias: [
                'applescript'
            ],
            value: 'applescript'
        },
        {
            name: 'Clojure',
            alias: [
                'clojure'
            ],
            value: 'clojure'
        },
        {
            name: 'Diff',
            alias: [
                'diff'
            ],
            value: 'diff'
        },
        {
            name: 'VisualBasic',
            alias: [
                'visualbasic'
            ],
            value: 'visual-basic'
        },
        {
            name: 'JSX',
            alias: [
                'jsx'
            ],
            value: 'jsx'
        },
        {
            name: 'TSX',
            alias: [
                'tsx'
            ],
            value: 'tsx'
        },
        {
            name: 'SplunkSPL',
            alias: [
                'splunk-spl'
            ],
            value: 'splunk-spl'
        },
        {
            name: 'Dockerfile',
            alias: [
                'docker',
                'dockerfile'
            ],
            value: 'dockerfile'
        },
        {
            name: 'HCL',
            alias: [
                'hcl',
                'terraform'
            ],
            value: 'hcl'
        },
        {
            name: 'NGINX',
            alias: [
                'nginx'
            ],
            value: 'nginx'
        },
        {
            name: 'Protocol Buffers',
            alias: [
                'protobuf',
                'proto'
            ],
            value: 'protobuf'
        }
    ];
});
parcelRegister("9Xp6L", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e296c4d390ff4a21$export$2e2bcd8739ae039);
    var $c8FD2;
    var $e296c4d390ff4a21$export$2e2bcd8739ae039 = (0, (0, parcelRequire("c8FD2")).default);
});
parcelRegister("c8FD2", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$64df4f4e00539958$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $lp89O;
    const $64df4f4e00539958$var$generator = (options)=>{
        const { loader: loader } = options;
        class AsyncHighlighter extends (0, ($parcel$interopDefault($5uXOq))).PureComponent {
            static{
                this.astGenerator = null;
            }
            static{
                this.highlightInstance = (0, (0, parcelRequire("lp89O")).default);
            }
            static preload() {
                return AsyncHighlighter.loadAstGenerator();
            }
            static loadAstGenerator() {
                AsyncHighlighter.astGeneratorPromise = loader().then((astGenerator)=>{
                    AsyncHighlighter.astGenerator = astGenerator;
                    return astGenerator;
                });
                return AsyncHighlighter.astGeneratorPromise;
            }
            componentDidMount() {
                if (!AsyncHighlighter.astGeneratorPromise) AsyncHighlighter.loadAstGenerator();
                if (!AsyncHighlighter.astGenerator && AsyncHighlighter.astGeneratorPromise) AsyncHighlighter.astGeneratorPromise.then(()=>{
                    this.forceUpdate();
                });
            }
            render() {
                return (0, ($parcel$interopDefault($5uXOq))).createElement(AsyncHighlighter.highlightInstance, {
                    ...this.props,
                    astGenerator: AsyncHighlighter.astGenerator
                });
            }
        }
        return AsyncHighlighter;
    };
    const $64df4f4e00539958$var$asyncGenerator = $64df4f4e00539958$var$generator({
        loader: ()=>(parcelRequire("kN6qz")).then((module)=>{
                return module.default || module;
            })
    });
    var $64df4f4e00539958$export$2e2bcd8739ae039 = $64df4f4e00539958$var$asyncGenerator;
});
parcelRegister("lp89O", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$a813ccd1d5fae376$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $iQkqt;
    var $jVB7m;
    const $a813ccd1d5fae376$var$Highlight = (0, $5uXOq.memo)(function Highlight({ language: language = 'text', testId: testId, text: text = '', codeTagProps: codeTagProps = {
        className: `language-${language}`
    }, showLineNumbers: showLineNumbers = false, firstLineNumber: firstLineNumber = 1, shouldCreateParentElementForLines: shouldCreateParentElementForLines = false, shouldWrapLongLines: shouldWrapLongLines = false, lineProps: lineProps = {}, codeBidiWarnings: codeBidiWarnings, codeBidiWarningLabel: codeBidiWarningLabel, codeBidiWarningTooltipEnabled: codeBidiWarningTooltipEnabled, astGenerator: astGenerator = null, ...rest }) {
        const code = text;
        const generatorClassName = 'prismjs';
        const containerProps = {
            ...rest,
            'data-testid': testId,
            className: rest.className ? `${generatorClassName} ${rest.className}` : generatorClassName
        };
        if (shouldWrapLongLines) codeTagProps.style = {
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
        };
        else codeTagProps.style = {
            whiteSpace: 'pre'
        };
        const codeBidiWarningConfig = {
            codeBidiWarnings: codeBidiWarnings,
            codeBidiWarningLabel: codeBidiWarningLabel,
            codeBidiWarningTooltipEnabled: codeBidiWarningTooltipEnabled
        };
        const rows = (0, (0, parcelRequire("iQkqt")).default)({
            astGenerator: astGenerator,
            code: code,
            language: language,
            shouldCreateParentElementForLines: shouldCreateParentElementForLines || !!shouldWrapLongLines,
            lineProps: lineProps,
            showLineNumbers: showLineNumbers,
            firstLineNumber: firstLineNumber
        });
        return (0, (0, parcelRequire("jVB7m")).default)({
            containerProps: containerProps,
            codeTagProps: codeTagProps,
            rows: rows,
            codeBidiWarningConfig: codeBidiWarningConfig
        });
    });
    var $a813ccd1d5fae376$export$2e2bcd8739ae039 = $a813ccd1d5fae376$var$Highlight;
});
parcelRegister("iQkqt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$55dc1424d34091d6$export$2e2bcd8739ae039);
    var $eJUOt;
    var $eTMIA;
    var $8bliZ;
    var $g6MVu;
    const $55dc1424d34091d6$var$newLineRegex = /\n/g;
    function $55dc1424d34091d6$var$getNewLines(str) {
        return str.match($55dc1424d34091d6$var$newLineRegex);
    }
    function $55dc1424d34091d6$export$2e2bcd8739ae039({ astGenerator: astGenerator, code: code, language: language, shouldCreateParentElementForLines: shouldCreateParentElementForLines, lineProps: lineProps, showLineNumbers: showLineNumbers, firstLineNumber: firstLineNumber = 1 }) {
        const codeTree = (0, (0, parcelRequire("g6MVu")).default)(language, code, astGenerator);
        const startingLineNumber = firstLineNumber > 0 ? firstLineNumber : 1;
        const createLine = (0, (0, parcelRequire("eJUOt")).default)(lineProps, shouldCreateParentElementForLines, showLineNumbers);
        const newTree = [];
        let lastLineBreakIndex = -1;
        let index = 0;
        const tree = (0, (0, parcelRequire("8bliZ")).default)(codeTree);
        while(index < tree.length){
            const testNode = tree[index];
            if (testNode.type === 'text') {
                index++;
                continue;
            }
            const node = testNode;
            const firstChildNode = node.children[0];
            if (firstChildNode.type === 'text') {
                const { value: value } = firstChildNode;
                const newLines = $55dc1424d34091d6$var$getNewLines(value);
                if (newLines) {
                    const splitValue = value.split('\n');
                    splitValue.forEach((text, i)=>{
                        const lineNumber = newTree.length + startingLineNumber;
                        const newChild = {
                            type: 'text',
                            value: `${text}\n`
                        };
                        if (i === 0) {
                            const children = tree.slice(lastLineBreakIndex + 1, index).concat((0, (0, parcelRequire("eTMIA")).default)({
                                children: [
                                    newChild
                                ],
                                className: node.properties.className,
                                lineNumber: lineNumber
                            }));
                            const line = createLine(children, lineNumber);
                            newTree.push(line);
                        } else if (i === splitValue.length - 1) {
                            const nextNode = tree[index + 1];
                            const stringChild = nextNode && nextNode.children && nextNode.children[0];
                            const lastLineInPreviousSpan = {
                                type: 'text',
                                value: `${text}`
                            };
                            if (stringChild) {
                                const newElem = (0, (0, parcelRequire("eTMIA")).default)({
                                    children: [
                                        lastLineInPreviousSpan
                                    ],
                                    className: node.properties.className,
                                    lineNumber: lineNumber
                                });
                                tree.splice(index + 1, 0, newElem);
                            } else {
                                const children = [
                                    lastLineInPreviousSpan
                                ];
                                const line = createLine(children, lineNumber, node.properties.className);
                                newTree.push(line);
                            }
                        } else {
                            const children = [
                                newChild
                            ];
                            const line = createLine(children, lineNumber, node.properties.className);
                            newTree.push(line);
                        }
                    });
                    lastLineBreakIndex = index;
                }
            }
            index++;
        }
        if (lastLineBreakIndex !== tree.length - 1) {
            const children = tree.slice(lastLineBreakIndex + 1, tree.length);
            if (children && children.length) {
                const lineNumber = newTree.length + startingLineNumber;
                const line = createLine(children, lineNumber);
                newTree.push(line);
            }
        }
        if (shouldCreateParentElementForLines) return newTree;
        return newTree.flat(1);
    }
});
parcelRegister("eJUOt", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b9ccdbf71436a137$export$2e2bcd8739ae039);
    var $eTMIA;
    var $5FOwT;
    function $b9ccdbf71436a137$var$createLineGenerator(lineProps, shouldCreateParentElementForLines, showLineNumbers) {
        return (children, lineNumber, className = [])=>{
            if (shouldCreateParentElementForLines || className.length > 0) return (0, (0, parcelRequire("eTMIA")).default)({
                children: children,
                lineNumber: lineNumber,
                showLineNumbers: showLineNumbers,
                lineProps: lineProps,
                className: className
            });
            else {
                if (showLineNumbers && lineNumber) children.unshift((0, (0, parcelRequire("5FOwT")).default)(lineNumber));
                return children;
            }
        };
    }
    var $b9ccdbf71436a137$export$2e2bcd8739ae039 = $b9ccdbf71436a137$var$createLineGenerator;
});
parcelRegister("eTMIA", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6df5b208b9d23752$export$2e2bcd8739ae039);
    var $5FOwT;
    function $6df5b208b9d23752$export$2e2bcd8739ae039({ children: children, lineNumber: lineNumber, showLineNumbers: showLineNumbers, lineProps: lineProps = {}, className: className = [] }) {
        const propsPassedInFromCodeBlock = typeof lineProps === 'function' ? lineProps(lineNumber) : lineProps;
        const properties = {
            ...propsPassedInFromCodeBlock,
            className: className
        };
        let currentChildren = children;
        if (lineNumber && showLineNumbers) {
            if (currentChildren.length === 1) currentChildren = [
                (0, (0, parcelRequire("5FOwT")).default)(lineNumber),
                ...currentChildren
            ];
            else currentChildren = [
                (0, (0, parcelRequire("5FOwT")).default)(lineNumber),
                {
                    type: 'element',
                    tagName: 'span',
                    properties: {
                        className: []
                    },
                    children: currentChildren
                }
            ];
        }
        return {
            type: 'element',
            tagName: 'span',
            properties: properties,
            children: currentChildren
        };
    }
});
parcelRegister("5FOwT", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c6257c3f70e94feb$export$2e2bcd8739ae039);
    function $c6257c3f70e94feb$export$2e2bcd8739ae039(lineNumber) {
        return {
            type: 'element',
            tagName: 'span',
            properties: {
                key: `line-number--${lineNumber}`,
                className: [
                    'comment',
                    'linenumber',
                    'ds-line-number'
                ],
                'data-ds--line-number': lineNumber
            },
            children: []
        };
    }
});
parcelRegister("8bliZ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c9f6debfd276481a$export$2e2bcd8739ae039);
    var $eTMIA;
    function $c9f6debfd276481a$export$2e2bcd8739ae039(tree, offset = 0, className = []) {
        let newTree = [];
        for(let i = 0; i < tree.length; i++){
            const node = tree[i];
            if (node.type === 'text') newTree.push((0, (0, parcelRequire("eTMIA")).default)({
                children: [
                    node
                ],
                lineNumber: offset,
                className: className
            }));
            else if (node.children) {
                const classNames = className.concat(node.properties.className || []);
                $c9f6debfd276481a$export$2e2bcd8739ae039(node.children, offset + 1, classNames).forEach((i)=>newTree.push(i));
            }
        }
        return newTree;
    }
});
parcelRegister("g6MVu", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8213b6c690eefe35$export$2e2bcd8739ae039);
    function $8213b6c690eefe35$export$2e2bcd8739ae039(language, code, astGenerator) {
        if (language === 'text' || !astGenerator) return [
            {
                type: 'text',
                value: code
            }
        ];
        try {
            return astGenerator.highlight(code, language);
        } catch (e) {
            return [
                {
                    type: 'text',
                    value: code
                }
            ];
        }
    }
});
parcelRegister("jVB7m", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b98c01cbb865d4c1$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $fBvqm;
    function $b98c01cbb865d4c1$export$2e2bcd8739ae039({ containerProps: containerProps, codeTagProps: codeTagProps, rows: rows, codeBidiWarningConfig: codeBidiWarningConfig }) {
        const renderedRows = rows.map((node, i)=>(0, (0, parcelRequire("fBvqm")).default)({
                node: node,
                codeBidiWarningConfig: codeBidiWarningConfig,
                key: `code-segment${i}`
            }));
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", containerProps, (0, ($parcel$interopDefault($5uXOq))).createElement("code", codeTagProps, renderedRows));
    }
});
parcelRegister("fBvqm", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2fdda3a2c4411588$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $lrHA4;
    var $31cgS;
    var $d3zI6;
    function $2fdda3a2c4411588$var$createClassNameString(classNames) {
        return classNames ? classNames.join(' ') : '';
    }
    function $2fdda3a2c4411588$export$2e2bcd8739ae039({ node: node, codeBidiWarningConfig: codeBidiWarningConfig, key: key }) {
        if (node.type === 'text') {
            const { value: value } = node;
            const textValue = `${value}`;
            if (codeBidiWarningConfig.codeBidiWarnings) {
                const decorated = (0, (0, parcelRequire("31cgS")).default)(textValue, ({ bidiCharacter: bidiCharacter, index: index })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lrHA4")).default), {
                        bidiCharacter: bidiCharacter,
                        key: index,
                        label: codeBidiWarningConfig.codeBidiWarningLabel,
                        tooltipEnabled: codeBidiWarningConfig.codeBidiWarningTooltipEnabled
                    }));
                return decorated;
            }
            return textValue;
        } else {
            const { properties: properties, tagName: tagName } = node;
            const childrenCreator = (0, (0, parcelRequire("d3zI6")).default)(codeBidiWarningConfig);
            const props = {
                ...properties,
                className: $2fdda3a2c4411588$var$createClassNameString(properties.className)
            };
            const children = childrenCreator(node.children);
            return (0, ($parcel$interopDefault($5uXOq))).createElement(tagName, {
                key: key,
                ...props
            }, children);
        }
    }
});
parcelRegister("lrHA4", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$2e6443bd1dfeaac7$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $3bDjY;
    var $abxiq;
    function $2e6443bd1dfeaac7$export$2e2bcd8739ae039({ testId: testId, bidiCharacter: bidiCharacter, skipChildren: skipChildren, tooltipEnabled: tooltipEnabled, label: label = 'Bidirectional characters change the order that text is rendered. This could be used to obscure malicious code.' }) {
        if (tooltipEnabled) return ((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: label,
            tag: $2e6443bd1dfeaac7$var$CustomizedTagWithRef
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("abxiq")).Decorator), {
            testId: testId,
            bidiCharacter: bidiCharacter
        }, skipChildren ? null : bidiCharacter)));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("abxiq")).Decorator), {
            testId: testId,
            bidiCharacter: bidiCharacter
        }, skipChildren ? null : bidiCharacter);
    }
    const $2e6443bd1dfeaac7$var$CustomizedTagWithRef = (0, ($parcel$interopDefault($5uXOq))).forwardRef((props, ref)=>{
        const { children: children, ...rest } = props;
        return (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
            ...rest,
            ref: ref
        }, children);
    });
});
parcelRegister("abxiq", function(module, exports) {
    $parcel$export(module.exports, "Decorator", ()=>$59fdc1b7b0ae96c9$export$a592b67c93d52467);
    var $5uXOq = parcelRequire("5uXOq");
    var $f8Sb3;
    var $jldZL;
    const $59fdc1b7b0ae96c9$var$decoration = (0, (0, parcelRequire("f8Sb3")).css)({
        position: 'relative',
        ':before': {
            display: 'inline-flex',
            padding: `${"var(--ds-space-0, 0px)"} ${"var(--ds-space-050, 4px)"}`,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            background: `var(--ds-background-warning, ${(0, $829f609a65d26a98$export$41060a744ab0b7ae)})`,
            color: "var(--ds-text-warning, #7F5F01)",
            content: '"<"attr(data-bidi-character-code)">"',
            fontSize: '14px',
            fontStyle: 'normal',
            lineHeight: '18px',
            pointerEvents: 'auto'
        },
        ':hover:before': {
            background: `var(--ds-background-warning-hovered, ${(0, $829f609a65d26a98$export$41060a744ab0b7ae)})`,
            color: "var(--ds-text-warning, #533F04)"
        }
    });
    function $59fdc1b7b0ae96c9$export$a592b67c93d52467({ bidiCharacter: bidiCharacter, children: children, testId: testId }) {
        const bidiCharacterCode = $59fdc1b7b0ae96c9$var$getBidiCharacterCode(bidiCharacter);
        return (0, (0, parcelRequire("f8Sb3")).jsx)((0, $5uXOq.Fragment), null, (0, (0, parcelRequire("f8Sb3")).jsx)("span", {
            css: $59fdc1b7b0ae96c9$var$decoration,
            "data-testid": testId,
            "data-bidi-character-code": bidiCharacterCode,
            "aria-hidden": "true"
        }, children), (0, (0, parcelRequire("f8Sb3")).jsx)((0, (0, parcelRequire("jldZL")).default), {
            testId: testId && `${testId}--visually-hidden`
        }, (0, (0, parcelRequire("f8Sb3")).jsx)("mark", null, bidiCharacterCode)));
    }
    function $59fdc1b7b0ae96c9$var$getBidiCharacterCode(bidiCharacter) {
        const bidiCharacterCode = bidiCharacter.codePointAt(0)?.toString(16);
        return `U+${bidiCharacterCode}`;
    }
});
parcelRegister("31cgS", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$e16562bd8c2aca9b$export$2e2bcd8739ae039);
    const $e16562bd8c2aca9b$export$ecb256e6a1f5ee7e = /[\u202A-\u202E\u2066-\u2069]/g;
    function $e16562bd8c2aca9b$export$2e2bcd8739ae039(originalText, decorate) {
        const matches = [
            ...originalText.matchAll($e16562bd8c2aca9b$export$ecb256e6a1f5ee7e)
        ];
        if (matches.length === 0) return originalText;
        let children = [];
        let mappedTo = 0;
        for (const match of matches){
            if (mappedTo !== match.index) children.push(originalText.substring(mappedTo, match.index));
            children.push(decorate({
                bidiCharacter: match[0],
                index: match.index
            }));
            mappedTo = match.index + match[0].length;
        }
        if (mappedTo !== originalText.length) children.push(originalText.substring(mappedTo, originalText.length));
        return children;
    }
});
parcelRegister("d3zI6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$dcbc4a00f80b5d8f$export$2e2bcd8739ae039);
    var $fBvqm;
    function $dcbc4a00f80b5d8f$export$2e2bcd8739ae039(codeBidiWarningConfig) {
        let childrenCount = 0;
        return (children)=>{
            childrenCount += 1;
            return children.map((child, i)=>(0, (0, parcelRequire("fBvqm")).default)({
                    node: child,
                    codeBidiWarningConfig: codeBidiWarningConfig,
                    key: `code-segment-${childrenCount}-${i}`
                }));
        };
    }
});
parcelRegister("kN6qz", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("as9qs").then(()=>parcelRequire('5zvIl'));
});
parcelRegister("lOZGq", function(module, exports) {
    $parcel$export(module.exports, "CopyAction", ()=>$b3c0013295f8779c$export$958df07cbf9735fc);
    $parcel$export(module.exports, "ActionContainer", ()=>$b3c0013295f8779c$export$37ab3bdebc286ca);
    var $5uXOq = parcelRequire("5uXOq");
    var $ldJSL;
    var $3Fq5M;
    var $dC5iT;
    var $8CUq3;
    var $8zOmE;
    var $fEogx;
    const $b3c0013295f8779c$export$2ce9877c9c1aa16 = {
        PROMPT: 'Copy',
        SUCCESS: 'Copied!',
        FAILURE: 'Copy to clipboard failed'
    };
    const $b3c0013295f8779c$export$958df07cbf9735fc = ({ source: source, actionSubjectId: actionSubjectId })=>{
        const [tooltipMessage, setTooltipMessage] = (0, $5uXOq.useState)($b3c0013295f8779c$export$2ce9877c9c1aa16.PROMPT);
        const handleSuccess = (0, $5uXOq.useCallback)(()=>{
            setTooltipMessage($b3c0013295f8779c$export$2ce9877c9c1aa16.SUCCESS);
        }, [
            setTooltipMessage
        ]);
        const handleError = (0, $5uXOq.useCallback)(()=>{
            setTooltipMessage($b3c0013295f8779c$export$2ce9877c9c1aa16.FAILURE);
        }, [
            setTooltipMessage
        ]);
        const writeToClipboard = (0, $5uXOq.useCallback)((_, analyticsEvent)=>{
            actionSubjectId && (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'clicked button', actionSubjectId);
            navigator.clipboard.writeText(source).then(handleSuccess, handleError);
        }, [
            source,
            handleSuccess,
            handleError,
            actionSubjectId
        ]);
        const resetMessage = (0, $5uXOq.useCallback)(()=>{
            setTooltipMessage($b3c0013295f8779c$export$2ce9877c9c1aa16.PROMPT);
        }, [
            setTooltipMessage
        ]);
        function shouldRender() {
            if (typeof document !== 'undefined') return Boolean(navigator?.clipboard?.writeText);
        }
        return shouldRender() ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fEogx")).Action), {
            icon: (0, ($parcel$interopDefault((0, parcelRequire("3Fq5M"))))),
            label: tooltipMessage,
            onClick: writeToClipboard,
            onMouseOver: resetMessage,
            onFocus: resetMessage,
            "aria-live": "assertive",
            "aria-atomic": "true",
            name: "Copy"
        }) : null;
    };
    const $b3c0013295f8779c$var$stopPropagation = (event)=>event.stopPropagation();
    const $b3c0013295f8779c$export$37ab3bdebc286ca = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dC5iT")).default), {
            xcss: $b3c0013295f8779c$var$absolutePositionWrapperStyles,
            onMouseDown: $b3c0013295f8779c$var$stopPropagation
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ldJSL")).default), {
            label: "actions"
        }, children));
    const $b3c0013295f8779c$var$absolutePositionWrapperStyles = (0, (0, parcelRequire("8CUq3")).xcss)({
        position: 'absolute',
        top: 'space.0',
        right: 'space.0',
        padding: 'space.050',
        zIndex: 'flag'
    });
});
parcelRegister("3Fq5M", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $2ab970d264bef26f$var$_react = $2ab970d264bef26f$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $2ab970d264bef26f$var$_copy = $2ab970d264bef26f$var$_interopRequireDefault((parcelRequire("8w53e")));
    var $2ab970d264bef26f$var$_copy2 = $2ab970d264bef26f$var$_interopRequireDefault((parcelRequire("eE13R")));
    function $2ab970d264bef26f$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $2ab970d264bef26f$var$CopyIcon = (props)=>$2ab970d264bef26f$var$_react.default.createElement($2ab970d264bef26f$var$_copy.default, Object.assign({
            LEGACY_fallbackIcon: $2ab970d264bef26f$var$_copy2.default
        }, props));
    $2ab970d264bef26f$var$CopyIcon.Name = 'CopyIconMigration';
    var $2ab970d264bef26f$var$_default = module.exports.default = $2ab970d264bef26f$var$CopyIcon;
});
parcelRegister("8w53e", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $63353af41c1756be$var$_react = $63353af41c1756be$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $63353af41c1756be$var$_UNSAFE_baseNew = $63353af41c1756be$var$_interopRequireDefault((parcelRequire("fEaCe")));
    function $63353af41c1756be$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $63353af41c1756be$var$CopyIcon = (props)=>$63353af41c1756be$var$_react.default.createElement($63353af41c1756be$var$_UNSAFE_baseNew.default, Object.assign({
            dangerouslySetGlyph: `<path stroke="currentcolor" stroke-width="1.5" d="M1.75 3c0-.69.56-1.25 1.25-1.25h8c.69 0 1.25.56 1.25 1.25v8c0 .69-.56 1.25-1.25 1.25H3c-.69 0-1.25-.56-1.25-1.25z"/><path stroke="currentcolor" stroke-width="1.5" d="M6 15.25h6.75a2.5 2.5 0 0 0 2.5-2.5V6"/>`
        }, props));
    $63353af41c1756be$var$CopyIcon.displayName = 'CopyIcon';
    var $63353af41c1756be$var$_default = module.exports.default = $63353af41c1756be$var$CopyIcon;
});
parcelRegister("eE13R", function(module, exports) {
    "use strict";
    Object.defineProperty(module.exports, "__esModule", {
        value: true
    });
    module.exports.default = void 0;
    var $aa959fe1f5a86e24$var$_react = $aa959fe1f5a86e24$var$_interopRequireDefault((parcelRequire("5uXOq")));
    var $c7fbr;
    var $aa959fe1f5a86e24$var$_copy = $aa959fe1f5a86e24$var$_interopRequireDefault((parcelRequire("8w53e")));
    function $aa959fe1f5a86e24$var$_interopRequireDefault(e) {
        return e && e.__esModule ? e : {
            default: e
        };
    }
    const $aa959fe1f5a86e24$var$CopyIcon = (props)=>$aa959fe1f5a86e24$var$_react.default.createElement((0, parcelRequire("c7fbr")).IconFacade, Object.assign({
            dangerouslySetGlyph: `<svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentcolor"><path d="M10 19h8V8h-8zM8 7.992C8 6.892 8.902 6 10.009 6h7.982C19.101 6 20 6.893 20 7.992v11.016c0 1.1-.902 1.992-2.009 1.992H10.01A2 2 0 0 1 8 19.008z"/><path d="M5 16V4.992C5 3.892 5.902 3 7.009 3H15v13zm2 0h8V5H7z"/></g></svg>`
        }, props, {
            newIcon: $aa959fe1f5a86e24$var$_copy.default
        }));
    $aa959fe1f5a86e24$var$CopyIcon.displayName = 'CopyIcon';
    var $aa959fe1f5a86e24$var$_default = module.exports.default = $aa959fe1f5a86e24$var$CopyIcon;
});
parcelRegister("fEogx", function(module, exports) {
    $parcel$export(module.exports, "Action", ()=>$e6da05ade6347218$export$e19cd5f9376f8cee);
    var $5uXOq = parcelRequire("5uXOq");
    var $42CjB;
    var $3bDjY;
    const $e6da05ade6347218$export$e19cd5f9376f8cee = ({ icon: icon, label: label, tooltipLabel: tooltipLabel, isLoading: isLoading, ...buttonProps })=>{
        const updateTooltip = (0, $5uXOq.useRef)();
        (0, $5uXOq.useLayoutEffect)(()=>{
            updateTooltip.current?.();
        }, [
            tooltipLabel,
            label
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3bDjY")).default), {
            content: ({ update: update })=>{
                updateTooltip.current = update;
                return tooltipLabel || label;
            },
            position: "top"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("42CjB")).default), {
            isLoading: isLoading,
            appearance: "subtle",
            icon: icon,
            label: label,
            spacing: "compact",
            ...buttonProps
        }));
    };
});
parcelRegister("ew1Lk", function(module, exports) {
    $parcel$export(module.exports, "DocumentTitle", ()=>$41b549fa18234081$export$dd5ad0b65cf665da);
    var $5uXOq = parcelRequire("5uXOq");
    var $6nkVm;
    class $41b549fa18234081$export$dd5ad0b65cf665da extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                TitleSubscriber: (0, (0, parcelRequire("6nkVm")).DocumentTitleSubscriber)
            };
        }
        shouldComponentUpdate(nextProps) {
            return nextProps.title !== this.props.title;
        }
        render() {
            const { title: title, TitleSubscriber: TitleSubscriber } = this.props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement(TitleSubscriber, null, ({ contextArray: contextArray }, { setTitle: setTitle, addDocumentTitle: addDocumentTitle, removeDocumentTitle: removeDocumentTitle })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6nkVm")).DocumentTitleHelper), {
                    title: title,
                    setTitle: setTitle,
                    addDocumentTitle: addDocumentTitle,
                    contextArray: contextArray,
                    removeDocumentTitle: removeDocumentTitle,
                    TitleSubscriber: TitleSubscriber
                }));
        }
    }
});
parcelRegister("bTyOW", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$5073396748f92b4e$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "viewBox", ()=>$5073396748f92b4e$export$a179c88182cb9d56);
    $parcel$export(module.exports, "srcLight", ()=>($parcel$interopDefault((parcelRequire("5I4tL")))));
    $parcel$export(module.exports, "srcDark", ()=>($parcel$interopDefault((parcelRequire("h8kvT")))));
    var $dCLgq;
    var $h8kvT = parcelRequire("h8kvT");
    var $5I4tL = parcelRequire("5I4tL");
    const $5073396748f92b4e$var$Illustration = (0, (0, parcelRequire("dCLgq")).createIllustration)({
        srcLight: ($parcel$interopDefault($5I4tL)),
        srcDark: ($parcel$interopDefault($h8kvT))
    });
    var $5073396748f92b4e$export$2e2bcd8739ae039 = $5073396748f92b4e$var$Illustration;
    const $5073396748f92b4e$export$a179c88182cb9d56 = [
        0,
        0,
        164,
        218
    ];
});
parcelRegister("h8kvT", function(module, exports) {
    module.exports = new URL("error-darkmode.9dba78cd.svg", import.meta.url).toString();
});
parcelRegister("5I4tL", function(module, exports) {
    module.exports = new URL("error.b7de7f77.svg", import.meta.url).toString();
});
parcelRegister("fC09Q", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$bb376757c4acc5ab$export$2e2bcd8739ae039);
    var $dCLgq;
    var $3pGSh = parcelRequire("3pGSh");
    const $bb376757c4acc5ab$var$Illustration = (0, (0, parcelRequire("dCLgq")).createIllustration)({
        srcLight: ($parcel$interopDefault($3pGSh)),
        srcDark: ($parcel$interopDefault($3pGSh))
    });
    var $bb376757c4acc5ab$export$2e2bcd8739ae039 = $bb376757c4acc5ab$var$Illustration;
    const $bb376757c4acc5ab$export$a179c88182cb9d56 = [
        0,
        0,
        167,
        226
    ];
});
parcelRegister("3pGSh", function(module, exports) {
    module.exports = new URL("search-error.a6df7c97.svg", import.meta.url).toString();
});
parcelRegister("4AdE6", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$135a9e70a6f11b3e$export$2e2bcd8739ae039);
    var $7VHMR;
    var $135a9e70a6f11b3e$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        notFoundDocumentTitleJsm: {
            "id": "servicedesk-spa-commons.common.utils.errors.not-found-document-title-jsm",
            "defaultMessage": "Project not found - Service project"
        },
        notFoundTitle: {
            "id": "servicedesk-spa-commons.common.utils.errors.not-found-title",
            "defaultMessage": "This project isn't available"
        },
        notFoundDescription: {
            "id": "servicedesk-spa-commons.common.utils.errors.not-found-description",
            "defaultMessage": "It may have been deleted or your permissions may have changed"
        },
        notFoundAction: {
            "id": "servicedesk-spa-commons.common.utils.errors.not-found-action",
            "defaultMessage": "View a list of all projects"
        },
        genericDocumentTitleJsm: {
            "id": "servicedesk-spa-commons.common.utils.errors.generic-document-title-jsm",
            "defaultMessage": "Error - Service project"
        },
        genericErrorTitle: {
            "id": "servicedesk-spa-commons.common.utils.errors.generic-error-title",
            "defaultMessage": "An error occurred"
        },
        genericErrorDescription: {
            "id": "servicedesk-spa-commons.common.utils.errors.generic-error-description",
            "defaultMessage": "An error occurred while loading this page"
        }
    });
});
parcelRegister("9kARR", function(module, exports) {
    $parcel$export(module.exports, "SuspenseTrackerProvider", ()=>$e05a0c2231be48ec$export$58f6796252ab3147);
    $parcel$export(module.exports, "SuspenseTrackerFallback", ()=>$e05a0c2231be48ec$export$8637c61d65a3606b);
    $parcel$export(module.exports, "useGetHasSuspended", ()=>$e05a0c2231be48ec$export$a143cd14d496de4d);
    var $5uXOq = parcelRequire("5uXOq");
    class $e05a0c2231be48ec$export$87993bf75925b3bd {
        getHasSuspended() {
            return this.suspendCount !== 0;
        }
        reset() {
            this.suspendCount = 0;
        }
        onSuspend() {
            this.suspendCount += 1;
        }
        constructor(){
            this.suspendCount = 0;
        }
    }
    const $e05a0c2231be48ec$var$SuspenseTrackerContext = (0, $5uXOq.createContext)(new $e05a0c2231be48ec$export$87993bf75925b3bd());
    const $e05a0c2231be48ec$export$58f6796252ab3147 = ({ children: children })=>{
        const suspenseTrackerRef = (0, $5uXOq.useRef)(new $e05a0c2231be48ec$export$87993bf75925b3bd());
        (0, $5uXOq.useEffect)(()=>()=>{
                suspenseTrackerRef.current?.reset();
            }, []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($e05a0c2231be48ec$var$SuspenseTrackerContext.Provider, {
            value: suspenseTrackerRef.current
        }, children);
    };
    const $e05a0c2231be48ec$export$8637c61d65a3606b = ()=>{
        const suspenseTracker = (0, $5uXOq.useContext)($e05a0c2231be48ec$var$SuspenseTrackerContext);
        (0, $5uXOq.useEffect)(()=>{
            suspenseTracker.onSuspend();
        }, [
            suspenseTracker
        ]);
        return null;
    };
    const $e05a0c2231be48ec$export$a143cd14d496de4d = ()=>{
        const suspenseTracker = (0, $5uXOq.useContext)($e05a0c2231be48ec$var$SuspenseTrackerContext);
        return suspenseTracker.getHasSuspended();
    };
});
parcelRegister("Gk4RP", function(module, exports) {
    $parcel$defineInteropFlag(module.exports);
    $parcel$export(module.exports, "default", ()=>$5618efe454cda5da$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "RapidBoardWithSLA", ()=>$5618efe454cda5da$export$c4c56a250bf4e3c5);
    var $5uXOq = parcelRequire("5uXOq");
    var $dwq0W;
    var $2i4sA;
    var $heNNl;
    var $co1wz;
    var $6jY6G;
    var $6XsPQ;
    var $9OXo1;
    var $l8ruj;
    var $ieDtd;
    var $fZpxR;
    var $5618efe454cda5da$export$2e2bcd8739ae039 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6XsPQ")).HistoryAdapter), null, (history)=>(0, ($parcel$interopDefault($5uXOq))).createElement($5618efe454cda5da$export$c4c56a250bf4e3c5, {
                history: history
            }));
    const $5618efe454cda5da$export$c4c56a250bf4e3c5 = ({ history: history })=>{
        const { appEditions: appEditions } = (0, (0, parcelRequire("9OXo1")).useTenantContext)();
        const { name: name } = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        const [rapidView = ''] = (0, (0, parcelRequire("ieDtd")).useQueryParam)('rapidView');
        const [projectKey = ''] = (0, (0, parcelRequire("ieDtd")).useQueryParam)('projectKey');
        const [chart = ''] = (0, (0, parcelRequire("ieDtd")).useQueryParam)('chart');
        const experienceId = (0, (0, parcelRequire("dwq0W")).getExperienceId)(rapidView, projectKey, name, chart);
        const additionalAttributes = (0, $5uXOq.useMemo)(()=>({
                synthetic: (0, (0, parcelRequire("co1wz")).ff)('jsw.pollinator.tenants'),
                isClassic: true,
                isSPA: true
            }), []);
        const experienceAttributes = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("dwq0W")).getExperienceAttributes)(name, appEditions.software, chart), [
            appEditions.software,
            chart,
            name
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("l8ruj")).default), {
            name: "rapid-board-with-sla"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2i4sA")).ViewExperienceTrackingProvider), {
            experienceId: experienceId,
            ...experienceAttributes,
            additionalAttributes: additionalAttributes
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: "rapid-board",
            packageName: "jiraClassicBoardAndBacklog"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fZpxR")).default), {
            history: history
        }))));
    };
});
parcelRegister("dwq0W", function(module, exports) {
    $parcel$export(module.exports, "isUserNetworkError", ()=>$f39a26ea3669828b$export$34bc86f6bf17f1e0);
    $parcel$export(module.exports, "getExperienceId", ()=>$f39a26ea3669828b$export$ef9f7d5361bca87b);
    $parcel$export(module.exports, "getStatusCode", ()=>$f39a26ea3669828b$export$68868794c9b58e55);
    $parcel$export(module.exports, "getDataAttributes", ()=>$f39a26ea3669828b$export$e5ac556b1404ae1e);
    $parcel$export(module.exports, "getFailedReason", ()=>$f39a26ea3669828b$export$18e1541d9ebd5731);
    $parcel$export(module.exports, "getExperienceAttributes", ()=>$f39a26ea3669828b$export$590fb51cb481d9ae);
    $parcel$export(module.exports, "isBoardSettings", ()=>$f39a26ea3669828b$export$14c18b73936afcc2);
    $parcel$export(module.exports, "getErrorMessage", ()=>$f39a26ea3669828b$export$82f46aa4e6535a45);
    $parcel$export(module.exports, "getDebugAttributes", ()=>$f39a26ea3669828b$export$af1f115af1bea29a);
    var $65Ltd;
    var $efvDm;
    var $bFkfl;
    const $f39a26ea3669828b$var$getReportExperienceName = (reportName)=>{
        if (reportName === undefined) return 'viewUnknownChart';
        return `view${reportName[0].toUpperCase() + reportName.substring(1)}`;
    };
    const $f39a26ea3669828b$export$34bc86f6bf17f1e0 = (errorName)=>errorName === 'TypeError' || errorName === 'AbortError' || errorName === 'ChunkLoadError';
    const $f39a26ea3669828b$export$ef9f7d5361bca87b = (rapidView, projectKey, name, chart)=>{
        const key = `RapidBoardSPA_${projectKey || ''}_${rapidView}_`;
        if (name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BACKLOG) || name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG)) return `${key}_backlog`;
        if (name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BOARD) || name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_USER_BOARD)) return `${key}_board`;
        return `${key}_${$f39a26ea3669828b$var$getReportExperienceName((0, (0, parcelRequire("bFkfl")).REPORT_NAMES)[chart])}`;
    };
    const $f39a26ea3669828b$export$68868794c9b58e55 = (error)=>{
        if (error !== null && error !== undefined) {
            const { statusCode: statusCode } = error || {};
            return statusCode === null || statusCode === undefined ? null : statusCode;
        }
        return null;
    };
    const $f39a26ea3669828b$export$e5ac556b1404ae1e = ()=>{
        const { GH: GH } = window;
        if (GH && GH.RapidViewConfig && GH.RapidBoard) {
            const rapidViewConfig = GH.RapidViewConfig.currentData.data;
            return {
                taskId: rapidViewConfig ? rapidViewConfig.id : -1,
                boardType: GH.RapidBoard.State.getBoardTypeForAnalytics()
            };
        }
        return null;
    };
    const $f39a26ea3669828b$export$18e1541d9ebd5731 = (error)=>{
        if (error !== null && error !== undefined) {
            const statusCode = $f39a26ea3669828b$export$68868794c9b58e55(error);
            return statusCode === null ? (0, (0, parcelRequire("bFkfl")).REASON_BOARD_CRITICAL_DATA_FAILED) : (0, (0, parcelRequire("bFkfl")).REASON_BOARD_CRITICAL_DATA_FAILED_WITH_STATUS_CODE);
        }
        return 0, (0, parcelRequire("bFkfl")).REASON_BOARD_CRITICAL_DATA_FAILED;
    };
    const $f39a26ea3669828b$export$590fb51cb481d9ae = (name, edition, chart)=>{
        if (name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BACKLOG)) return {
            experience: 'viewBacklog',
            analyticsSource: 'backlog',
            edition: edition,
            application: (0, (0, parcelRequire("efvDm")).JIRA_SOFTWARE)
        };
        if (name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BOARD)) return {
            experience: 'viewBoard',
            analyticsSource: 'board',
            edition: edition,
            application: (0, (0, parcelRequire("efvDm")).JIRA_SOFTWARE)
        };
        return {
            experience: $f39a26ea3669828b$var$getReportExperienceName((0, (0, parcelRequire("bFkfl")).REPORT_NAMES)[chart]),
            analyticsSource: 'board',
            edition: edition,
            application: (0, (0, parcelRequire("efvDm")).JIRA_SOFTWARE)
        };
    };
    const $f39a26ea3669828b$export$14c18b73936afcc2 = (config)=>{
        if (config !== null && config !== undefined) return true;
        return false;
    };
    const $f39a26ea3669828b$export$82f46aa4e6535a45 = (failedReason, status)=>status === null ? `${failedReason}-unknown` : `${failedReason}-${status}`;
    const $f39a26ea3669828b$export$af1f115af1bea29a = (mode, statusCode, error)=>({
            origin: mode,
            statusCode: statusCode === null ? 'unknown' : statusCode || 'unknown',
            userAgent: navigator ? navigator.userAgent : 'unknown',
            isSPA: true,
            windowErrors: error,
            href: window.location.href,
            referrer: document.referrer
        });
    const $f39a26ea3669828b$export$c4f28f26fa94e5c2 = (error)=>{
        if (error !== null && error !== undefined) {
            const statusCode = $f39a26ea3669828b$export$68868794c9b58e55(error);
            if (statusCode === null) return true;
            if (statusCode >= 400 && statusCode < 500) return false;
            if (statusCode >= 500) return true;
            return false;
        }
        return false;
    };
});
parcelRegister("bFkfl", function(module, exports) {
    $parcel$export(module.exports, "REPORT_NAMES", ()=>$d176862c1fdab7f6$export$4a5d90f20a52884d);
    $parcel$export(module.exports, "REASON_BOARD_CRITICAL_DATA_FAILED", ()=>$d176862c1fdab7f6$export$ca7951b3c8688166);
    $parcel$export(module.exports, "REASON_BOARD_CRITICAL_DATA_FAILED_WITH_STATUS_CODE", ()=>$d176862c1fdab7f6$export$1a0ab76190c8276);
    $parcel$export(module.exports, "UNKNOWN_STATUSCODE", ()=>$d176862c1fdab7f6$export$58851cb488bddad0);
    const $d176862c1fdab7f6$export$4a5d90f20a52884d = {
        burndownChart: 'sprintBurndown',
        burnupChart: 'burnup',
        sprintRetrospective: 'sprintReport',
        cumulativeFlowDiagram: 'cumulativeFlowDiagram',
        velocityChart: 'velocity',
        unknown: 'unknown'
    };
    const $d176862c1fdab7f6$export$ca7951b3c8688166 = 'board-critical-data-failed-unknown-reason';
    const $d176862c1fdab7f6$export$1a0ab76190c8276 = 'board-critical-data-failed-with-status-code';
    const $d176862c1fdab7f6$export$58851cb488bddad0 = '[unknown]';
});
parcelRegister("6XsPQ", function(module, exports) {
    $parcel$export(module.exports, "HistoryAdapter", ()=>$c364a1ba7d823379$export$d852f51b8427aefc);
    var $5uXOq = parcelRequire("5uXOq");
    var $1bQGJ;
    var $8oKIZ;
    class $c364a1ba7d823379$var$HistoryAdapterComponent extends (0, $5uXOq.Component) {
        constructor(props){
            super(props);
            this.compatibleHistory = (0, (0, parcelRequire("8oKIZ")).createReactRouterCompatibleHistory)(props.history);
        }
        shouldComponentUpdate(nextProps) {
            return nextProps.children !== this.props.children;
        }
        componentWillUnmount() {
            this.compatibleHistory.destroy && this.compatibleHistory.destroy();
        }
        render() {
            const { children: children } = this.props;
            return children(this.compatibleHistory);
        }
    }
    const $c364a1ba7d823379$export$d852f51b8427aefc = (0, (0, parcelRequire("1bQGJ")).withRouter)($c364a1ba7d823379$var$HistoryAdapterComponent);
});
parcelRegister("8oKIZ", function(module, exports) {
    $parcel$export(module.exports, "createReactRouterCompatibleHistory", ()=>$e043da36553b4806$export$2df73cd79c2401bf);
    var $bxYf9 = parcelRequire("bxYf9");
    const $e043da36553b4806$export$2df73cd79c2401bf = (history)=>{
        let cleaned = false;
        let listeners = [];
        const listen = (callback)=>{
            if (cleaned) return ()=>{};
            const unsubscribe = (0, ($parcel$interopDefault($bxYf9)))(history.listen(callback));
            const listener = {
                unsubscribe: unsubscribe
            };
            listeners.push(listener);
            return (0, ($parcel$interopDefault($bxYf9)))(()=>{
                listener.unsubscribe();
            });
        };
        const cleanup = ()=>{
            listeners.forEach(({ unsubscribe: unsubscribe })=>{
                unsubscribe();
            });
            cleaned = true;
            listeners = [];
        };
        return {
            get length () {
                return history.length;
            },
            get location () {
                return history.location;
            },
            get action () {
                return history.action;
            },
            get index () {
                return history.index;
            },
            get entries () {
                return history.entries;
            },
            push: (path, state)=>history.push(path, state),
            replace: (path, state)=>history.replace(path, state),
            go: (n)=>history.go(n),
            goBack: ()=>history.goBack(),
            goForward: ()=>history.goForward(),
            createHref: (...args)=>history.createHref(...args),
            canGo: (n)=>{
                if (history.canGo) return history.canGo(n);
                return true;
            },
            listen: listen,
            block: (blocker)=>history.block(blocker),
            destroy: cleanup
        };
    };
});
parcelRegister("fZpxR", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$3a73208335792398$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    parcelRequire("2Geeh");
    var $ebyGx = parcelRequire("ebyGx");
    var $kHCwz;
    var $ep9XM;
    var $arHWD;
    var $8Sklq;
    var $1WToZ;
    var $1TPn7;
    var $a55mQ;
    var $6ST1G;
    var $he4ys;
    var $2lwYH;
    var $2mSJU;
    var $j3a01;
    var $3EGEn;
    var $9tdxX;
    var $eJfvV;
    var $65Ltd;
    var $7Q2kM;
    var $66eb8;
    var $heNNl;
    var $co1wz;
    var $6jY6G;
    var $3pxen;
    var $gEzFY;
    var $9dbF6;
    var $7tQej;
    var $ieDtd;
    var $doZTA;
    var $dW6W0;
    var $3a73208335792398$export$2e2bcd8739ae039 = ({ history: history })=>{
        const { isRapidBoardsReady: isRapidBoardsReady, mode: mode, isBentoEnabled: isBentoEnabled, shouldUserSeeBoard: shouldUserSeeBoard } = (0, (0, parcelRequire("arHWD")).useRapidBoard)(history);
        const contentLoadedStatus = (0, (0, parcelRequire("kHCwz")).useRapidBoardContentListener)();
        if ((0, (0, parcelRequire("co1wz")).ff)('jsw.classic.board.api-calls.modern-frontend')) (0, (0, parcelRequire("ep9XM")).useRapidBoardDataLoader)();
        const isClassicReportsPage = (0, (0, parcelRequire("dW6W0")).useIsReportsPage)();
        const isRendered = contentLoadedStatus.ttr[mode] && isRapidBoardsReady;
        const isInteractive = contentLoadedStatus.tti[mode] && isRapidBoardsReady;
        const [modal] = (0, (0, parcelRequire("ieDtd")).useQueryParam)('modal');
        const [selectedIssue] = (0, (0, parcelRequire("ieDtd")).useQueryParam)('selectedIssue');
        const [view] = (0, (0, parcelRequire("ieDtd")).useQueryParam)('view');
        const [config] = (0, (0, parcelRequire("ieDtd")).useQueryParam)('config');
        const isConfigPage = typeof config === 'string' && config.length > 0;
        const [routerState, routerActions] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const { name: name } = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        (0, $5uXOq.useEffect)(()=>{
            if (!false) {
                const queryParams = (0, $ebyGx.parse)(routerState.location.search);
                const quickFilters = queryParams.quickFilter;
                const activeQuickFilters = (0, (0, parcelRequire("3pxen")).getActiveQuickFilters)(name);
                if (!quickFilters?.length && activeQuickFilters?.length) {
                    queryParams.quickFilter = activeQuickFilters;
                    routerActions.replace(`${routerState.location.pathname}?${(0, $ebyGx.stringify)(queryParams)}`);
                }
            }
        }, [
            routerState,
            routerActions,
            name
        ]);
        (0, (0, parcelRequire("gEzFY")).useRapidBoardLongTasks)(name);
        if (isClassicReportsPage) (0, (0, parcelRequire("66eb8")).asyncRunInitBridge)();
        const shouldIssueViewLoad = (0, (0, parcelRequire("eJfvV")).shouldIssueViewAppBeRendered)({
            modal: modal,
            selectedIssue: selectedIssue,
            view: view
        }) || isInteractive;
        const subProductAnalytic = name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_BOARDS) ? 'serviceDesk' : 'software';
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, isRapidBoardsReady && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1WToZ")).DocumentTitle), null), !shouldUserSeeBoard && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7tQej")).Redirect), {
            to: "/jira/boards?showFlag=jsw.board-not-found"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9dbF6")).SubProductUpdater), {
            subProduct: subProductAnalytic
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("a55mQ")).PerformanceMarks), {
            isRendered: isRendered,
            isInteractive: isInteractive
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1TPn7")).NextGenRedirect), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3EGEn")).RoadmapSettingRedirect), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6ST1G")).PrettyUrlRedirect), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Sklq")).ArchivedProjectRedirect), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("j3a01")).RapidboardContent), {
            isBentoEnabled: isBentoEnabled,
            isRapidBoardsReady: isRapidBoardsReady,
            mode: mode
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9tdxX")).SLATracker), null), shouldIssueViewLoad ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2lwYH")).default), {
            shouldLoad: shouldIssueViewLoad
        }) : null, isInteractive ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("he4ys")).RapidBoardApps), {
            shouldLoad: isInteractive
        }) : null, isConfigPage ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2mSJU")).RapidBoardConfig), {
            shouldLoad: isConfigPage
        }) : null, isInteractive && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            packageName: "jiraDevelopmentDetailsLoader",
            id: "devDetailsDialogLoader",
            extraEventData: {
                teamName: 'fusion-isotopes'
            }
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7Q2kM")).DevDetailsDialogLoaderAsync), {
            scopeId: "software-board.board"
        })));
    };
});
parcelRegister("kHCwz", function(module, exports) {
    $parcel$export(module.exports, "useRapidBoardContentListener", ()=>$4839a2d34ac7509b$export$fa8a94d1c9579b89);
    $parcel$export(module.exports, "useRapidBoardContentLoadListener", ()=>$4839a2d34ac7509b$export$6d02a4e7b983d16d);
    var $5uXOq = parcelRequire("5uXOq");
    var $4oDnX;
    var $1pMQS;
    var $4hXjG;
    var $egTPT;
    const $4839a2d34ac7509b$export$fa8a94d1c9579b89 = ()=>{
        const [isFirstLoad, setIsFirstLoad] = (0, $5uXOq.useState)(true);
        const [contentLoadStatus, setHasContentLoaded] = (0, $5uXOq.useState)((0, (0, parcelRequire("egTPT")).initialState));
        const [, { setAppReady: setAppReady }] = (0, (0, parcelRequire("4oDnX")).useSpaStateActions)();
        const { mode: mode, boardId: boardId, chartView: chartView } = (0, (0, parcelRequire("4hXjG")).useUrlState)();
        (0, $5uXOq.useEffect)(()=>{
            let ttrListener;
            if (isFirstLoad) {
                ttrListener = (0, (0, parcelRequire("egTPT")).generateContentLoadingListener)(setHasContentLoaded);
                window.addEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_CONTENT_LOADING), ttrListener);
                setIsFirstLoad(false);
            }
            const ttiListener = (0, (0, parcelRequire("egTPT")).generateContentDoneListener)(setHasContentLoaded, setAppReady);
            window.addEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_CONTENT_READY), ttiListener);
            return ()=>{
                ttrListener && window.removeEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_CONTENT_LOADING), ttrListener);
                window.removeEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_CONTENT_READY), ttiListener);
            };
        }, [
            setAppReady,
            boardId,
            mode,
            chartView,
            isFirstLoad
        ]);
        return contentLoadStatus;
    };
    const $4839a2d34ac7509b$export$6d02a4e7b983d16d = ()=>{
        const [isAppContentLoaded, setIsAppContentLoaded] = (0, $5uXOq.useState)(false);
        (0, $5uXOq.useEffect)(()=>{
            const appLoadedListener = ()=>{
                !isAppContentLoaded && setIsAppContentLoaded(true);
            };
            window.addEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_CONTENT_READY), appLoadedListener);
            return ()=>{
                window.removeEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_CONTENT_READY), appLoadedListener);
            };
        }, [
            isAppContentLoaded
        ]);
        return [
            isAppContentLoaded,
            setIsAppContentLoaded
        ];
    };
});
parcelRegister("1pMQS", function(module, exports) {
    $parcel$export(module.exports, "WORK", ()=>$59a745e430111b54$export$77d6f70978850641);
    $parcel$export(module.exports, "PLAN", ()=>$59a745e430111b54$export$c303e491e026ebc6);
    $parcel$export(module.exports, "INITIAL_DATA", ()=>$59a745e430111b54$export$b89e93f49fd2d1eb);
    $parcel$export(module.exports, "CONFIG_DATA", ()=>$59a745e430111b54$export$5711a66d592b156a);
    $parcel$export(module.exports, "REPORT", ()=>$59a745e430111b54$export$e3d31417f21c1ce7);
    $parcel$export(module.exports, "SUCCESS", ()=>$59a745e430111b54$export$638cc6e36b2a737a);
    $parcel$export(module.exports, "FAILURE", ()=>$59a745e430111b54$export$5b1e245d5eac107);
    $parcel$export(module.exports, "ALL_DATA_PROMISE", ()=>$59a745e430111b54$export$79529b1d8f537dc7);
    $parcel$export(module.exports, "GH_SPA_RESOURCES_READY", ()=>$59a745e430111b54$export$7d798b3d2b3dfe6e);
    $parcel$export(module.exports, "GH_SPA_RB_READY", ()=>$59a745e430111b54$export$af91ef97c134b44c);
    $parcel$export(module.exports, "GH_SPA_CONTENT_READY", ()=>$59a745e430111b54$export$f7f9968a603665fb);
    $parcel$export(module.exports, "GH_SPA_CONTENT_LOADING", ()=>$59a745e430111b54$export$dbfa398ce856dd34);
    $parcel$export(module.exports, "BENTO_ROOT", ()=>$59a745e430111b54$export$cd8e3f3a08b0ddda);
    const $59a745e430111b54$export$77d6f70978850641 = 'work';
    const $59a745e430111b54$export$c303e491e026ebc6 = 'plan';
    const $59a745e430111b54$export$b89e93f49fd2d1eb = 'initial-data';
    const $59a745e430111b54$export$5711a66d592b156a = 'config-data';
    const $59a745e430111b54$export$e3d31417f21c1ce7 = 'report';
    const $59a745e430111b54$export$638cc6e36b2a737a = 'success';
    const $59a745e430111b54$export$5b1e245d5eac107 = 'failure';
    const $59a745e430111b54$export$79529b1d8f537dc7 = 'all-data-promise';
    const $59a745e430111b54$export$7d798b3d2b3dfe6e = 'GH_SPA_RESOURCES_READY';
    const $59a745e430111b54$export$af91ef97c134b44c = 'GH_SPA_RB_READY';
    const $59a745e430111b54$export$f7f9968a603665fb = 'GH_SPA_SPINNER_HIDE';
    const $59a745e430111b54$export$dbfa398ce856dd34 = 'GH_SPA_SPINNER_SHOW';
    const $59a745e430111b54$export$cd8e3f3a08b0ddda = 'ghx-issue-fragment';
    const $59a745e430111b54$export$7ed3b6fb15486a80 = [
        'wrc!jira.webresources:skate',
        'wrc!jira.webresources:util-lite',
        'wrc!jira.webresources:util',
        'wrc!jira.webresources:feature-flags',
        'wrc!jira.webresources:dialogs',
        'wrc!jira.webresources:jira-global',
        'wrc!com.atlassian.auiplugin:aui-spinner',
        'wrc!com.atlassian.jira.jira-client-analytics-plugin:analytics-web-client',
        'wrc!jira.webresources:key-commands',
        'wrc!jira.webresources:header',
        'wrc!com.atlassian.jira.jira-atlaskit-plugin:sidebar',
        'wrc!com.atlassian.jira.jira-atlaskit-plugin:overrides-general',
        'wrc!com.atlassian.jira.jira-atlaskit-plugin:overrides-dialogs',
        'wrc!com.atlassian.jira.jira-atlaskit-plugin:breadcrumbs',
        'wrc!com.atlassian.jira.jira-atlaskit-plugin:profilecard',
        'wrc!com.atlassian.jira.jira-atlaskit-plugin:ipblocklisting',
        'wrc!com.atlassian.jira.jira-atlaskit-plugin:async-modals',
        'wrc!jira.webresources:global-static',
        'wrc!com.atlassian.jira.plugins.jira-browser-metrics:contrib',
        'wrc!com.atlassian.plugins.browser.metrics.browser-metrics-plugin:impl',
        'wrc!com.atlassian.plugins.browser.metrics.browser-metrics-plugin:api',
        'wrc!com.atlassian.analytics.analytics-client:js-events'
    ];
});
parcelRegister("4hXjG", function(module, exports) {
    $parcel$export(module.exports, "useUrlState", ()=>$e0f62cfcc4b85d58$export$6a582373474709af);
    var $doZTA;
    var $lzIoE;
    const $e0f62cfcc4b85d58$export$6a582373474709af = ()=>{
        const [{ query: query, route: route, match: match }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const { rapidView: rapidView, chart: chart, view: view, config: config, projectKey: queryProjectKey, locationless: locationless } = query;
        const { accountId: accountId } = match.params;
        const isBoardLocationless = !!locationless;
        const shouldUsePrettyUrlState = !isBoardLocationless;
        const mode = shouldUsePrettyUrlState ? (0, (0, parcelRequire("lzIoE")).getModeV2)(route) : (0, (0, parcelRequire("lzIoE")).getMode)(view);
        const boardId = shouldUsePrettyUrlState ? match.params.boardId : rapidView;
        const projectKey = shouldUsePrettyUrlState ? match.params.projectKey : queryProjectKey;
        const chartView = shouldUsePrettyUrlState ? (0, (0, parcelRequire("lzIoE")).toCamelCaseChartView)(match.params.chartView) : chart;
        return {
            accountId: accountId,
            mode: mode,
            boardId: boardId,
            chartView: chartView,
            projectKey: projectKey,
            routeName: route.name,
            config: config,
            isBoardLocationless: isBoardLocationless
        };
    };
});
parcelRegister("lzIoE", function(module, exports) {
    $parcel$export(module.exports, "toCamelCaseChartView", ()=>$e82cb2563304145d$export$f1cb745981250009);
    $parcel$export(module.exports, "getMode", ()=>$e82cb2563304145d$export$1fc0f6205829e19c);
    $parcel$export(module.exports, "getModeV2", ()=>$e82cb2563304145d$export$cc65f5a30b63da10);
    var $e4eHl = parcelRequire("e4eHl");
    var $65Ltd;
    var $1pMQS;
    const $e82cb2563304145d$export$f1cb745981250009 = (chartView)=>(0, ($parcel$interopDefault($e4eHl)))(chartView);
    const $e82cb2563304145d$export$1fc0f6205829e19c = (view)=>{
        switch(view){
            case 'planning':
            case 'planning.nodetail':
                return 0, (0, parcelRequire("1pMQS")).PLAN;
            case 'reporting':
                return 0, (0, parcelRequire("1pMQS")).REPORT;
            default:
                return 0, (0, parcelRequire("1pMQS")).WORK;
        }
    };
    const $e82cb2563304145d$export$cc65f5a30b63da10 = (route)=>{
        if (!route) return 0, (0, parcelRequire("1pMQS")).WORK;
        switch(route.name){
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BACKLOG:
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG:
                return 0, (0, parcelRequire("1pMQS")).PLAN;
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_REPORT:
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_USER_REPORT:
                return 0, (0, parcelRequire("1pMQS")).REPORT;
            default:
                return 0, (0, parcelRequire("1pMQS")).WORK;
        }
    };
});
parcelRegister("egTPT", function(module, exports) {
    $parcel$export(module.exports, "initialState", ()=>$eecb747e3ffbb619$export$d4c72bab9d6cc13a);
    $parcel$export(module.exports, "generateContentLoadingListener", ()=>$eecb747e3ffbb619$export$cc4e514b345931fd);
    $parcel$export(module.exports, "generateContentDoneListener", ()=>$eecb747e3ffbb619$export$187951dcf58edfbe);
    var $c9CKH;
    var $5Jsi2;
    var $1pMQS;
    const $eecb747e3ffbb619$var$defaultState = {
        [(0, (0, parcelRequire("1pMQS")).WORK)]: false,
        [(0, (0, parcelRequire("1pMQS")).PLAN)]: false,
        [(0, (0, parcelRequire("1pMQS")).REPORT)]: false
    };
    const $eecb747e3ffbb619$export$d4c72bab9d6cc13a = {
        ttr: {
            ...$eecb747e3ffbb619$var$defaultState
        },
        tti: {
            ...$eecb747e3ffbb619$var$defaultState
        }
    };
    const $eecb747e3ffbb619$export$cc4e514b345931fd = (setHasContentLoaded)=>function ttrListener(ev) {
            setHasContentLoaded({
                ttr: {
                    ...$eecb747e3ffbb619$var$defaultState,
                    [ev.mode]: true
                },
                tti: {
                    ...$eecb747e3ffbb619$var$defaultState
                }
            });
            window.removeEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_CONTENT_LOADING), ttrListener);
        };
    const $eecb747e3ffbb619$export$187951dcf58edfbe = (setHasContentLoaded, setAppReady)=>function ttiListener(ev) {
            setHasContentLoaded({
                ttr: {
                    ...$eecb747e3ffbb619$var$defaultState
                },
                tti: {
                    ...$eecb747e3ffbb619$var$defaultState,
                    [ev.mode]: true
                }
            });
            setAppReady();
            window.dispatchEvent((0, (0, parcelRequire("c9CKH")).default)((0, (0, parcelRequire("5Jsi2")).CLEAR_SEARCH_SESSION)));
            window.removeEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_CONTENT_READY), ttiListener);
        };
});
parcelRegister("c9CKH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$cb779c21ddd2b986$export$2e2bcd8739ae039);
    var $cb779c21ddd2b986$export$2e2bcd8739ae039 = (eventType, eventConstructor = 'Event', bubbles = true, cancelable = true)=>{
        const event = document.createEvent(eventConstructor);
        event.initEvent(eventType, bubbles, cancelable);
        return event;
    };
});
parcelRegister("ep9XM", function(module, exports) {
    $parcel$export(module.exports, "useRapidBoardDataLoader", ()=>$2d6e112a46ba9d9b$export$69547ed814592891);
    var $5uXOq = parcelRequire("5uXOq");
    var $x9xyg;
    var $hXKq5;
    var $f5kpo;
    var $eJfvV;
    var $9S1TY;
    var $4hXjG;
    var $hHJ9y;
    const $2d6e112a46ba9d9b$export$69547ed814592891 = ()=>{
        const { mode: mode } = (0, (0, parcelRequire("4hXjG")).useUrlState)();
        const { promise: promise, loading: loading, refresh: refresh, update: update } = (0, (0, parcelRequire("9S1TY")).useRapidBoardResource)({
            resource: (0, (0, parcelRequire("f5kpo")).getRapidBoardDataResource)(),
            retryPredicate: (0, (0, parcelRequire("eJfvV")).isAllDataErrorValid)
        });
        const [deferredPromise, setDeferredPromise] = (0, $5uXOq.useState)(null);
        const [initiateRefresh, setInitiateRefresh] = (0, $5uXOq.useState)(false);
        const onLoadData = (0, $5uXOq.useCallback)(({ isRefresh: isRefresh, mode: callerMode, deferred: deferred })=>{
            if (!deferred) return;
            setInitiateRefresh(mode === callerMode && isRefresh);
            setDeferredPromise({
                deferred: deferred,
                callerMode: callerMode
            });
            (0, (0, parcelRequire("x9xyg")).setMark)((0, (0, parcelRequire("hXKq5")).getPrefixedMarkName)(`rapidboard_${mode}_data_requested`));
        }, [
            mode
        ]);
        const onUpdateData = (0, $5uXOq.useCallback)((data)=>{
            update((oldData)=>({
                    ...oldData,
                    data: new Promise((resolve)=>resolve(data))
                }));
        }, [
            update
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (initiateRefresh && !loading) refresh();
            if (initiateRefresh) setInitiateRefresh(false);
        }, [
            initiateRefresh,
            loading,
            refresh
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (deferredPromise === null || initiateRefresh && !loading) return;
            const { deferred: deferred, callerMode: callerMode } = deferredPromise;
            if (mode === callerMode) {
                (0, (0, parcelRequire("hHJ9y")).resolveDeferredWithPromise)(promise, deferred);
                setDeferredPromise(null);
                (0, (0, parcelRequire("x9xyg")).setMark)((0, (0, parcelRequire("hXKq5")).getPrefixedMarkName)(`rapidboard_${mode}_data_resolved`));
            }
        }, [
            promise,
            deferredPromise,
            mode,
            initiateRefresh,
            loading
        ]);
        (0, $5uXOq.useEffect)(()=>{
            (0, (0, parcelRequire("hHJ9y")).setupRapidBoardDataLoader)(onLoadData, onUpdateData);
        }, [
            onLoadData,
            onUpdateData
        ]);
    };
});
parcelRegister("eJfvV", function(module, exports) {
    $parcel$export(module.exports, "isPlanningMode", ()=>$b7e38b7c7c8a1e6d$export$2f3aae63e9b6fcd4);
    $parcel$export(module.exports, "isWorkMode", ()=>$b7e38b7c7c8a1e6d$export$a048503e6027751b);
    $parcel$export(module.exports, "isAllDataErrorValid", ()=>$b7e38b7c7c8a1e6d$export$f0e61c8225ce57eb);
    $parcel$export(module.exports, "shouldIssueViewAppBeRendered", ()=>$b7e38b7c7c8a1e6d$export$eff701308e2e1aa6);
    var $j8DrX;
    var $1pMQS;
    const $b7e38b7c7c8a1e6d$export$2f3aae63e9b6fcd4 = (mode)=>mode === (0, (0, parcelRequire("1pMQS")).PLAN);
    const $b7e38b7c7c8a1e6d$export$a048503e6027751b = (mode)=>mode === (0, (0, parcelRequire("1pMQS")).WORK);
    const $b7e38b7c7c8a1e6d$export$f0e61c8225ce57eb = (error)=>!!(error && error.statusCode !== 400 && error.statusCode !== 404 && error.statusCode !== 500);
    const $b7e38b7c7c8a1e6d$export$eff701308e2e1aa6 = ({ modal: modal, selectedIssue: selectedIssue, view: view })=>{
        if (!false) {
            const isUserBentoEnabled = (0, (0, parcelRequire("j8DrX")).default)('ghx-user-bento-enabled') === 'true';
            const isIssueViewOpen = (modal === 'detail' || view === 'detail' || view === 'planning') && selectedIssue;
            return isUserBentoEnabled && !!isIssueViewOpen;
        }
        return false;
    };
});
parcelRegister("9S1TY", function(module, exports) {
    $parcel$export(module.exports, "useRapidBoardResource", ()=>$aea09ce80a86bf4a$export$8235e1f44255471f);
    var $5uXOq = parcelRequire("5uXOq");
    var $pa9q9;
    var $2YP9W;
    var $jZ6IX;
    const $aea09ce80a86bf4a$var$ERROR_RETRY_LIMIT = 3;
    const $aea09ce80a86bf4a$export$8235e1f44255471f = ({ resource: resource, shouldShowFlag: shouldShowFlag = true, retryPredicate: retryPredicate = (error)=>error && error.statusCode !== 404 })=>{
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const [retryNumber, setRetryNumber] = (0, $5uXOq.useState)(0);
        const { promise: promise, data: data, loading: loading, error: error, refresh: refresh, update: update, clear: clear, clearAll: clearAll } = (0, (0, parcelRequire("2YP9W")).useResource)(resource);
        const flagId = (0, $5uXOq.useRef)(null);
        const shouldAttemptRefetch = !!(!loading && error && retryPredicate(error) && retryNumber < $aea09ce80a86bf4a$var$ERROR_RETRY_LIMIT);
        (0, $5uXOq.useEffect)(()=>{
            if (shouldAttemptRefetch) {
                refresh();
                setRetryNumber(retryNumber + 1);
            }
        }, [
            refresh,
            retryNumber,
            shouldAttemptRefetch
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (shouldShowFlag && !loading && retryNumber >= $aea09ce80a86bf4a$var$ERROR_RETRY_LIMIT && flagId.current === null) flagId.current = showFlag((0, (0, parcelRequire("jZ6IX")).ErrorFlag)());
        }, [
            loading,
            retryNumber,
            shouldShowFlag,
            showFlag
        ]);
        return {
            promise: promise,
            data: data,
            loading: loading,
            error: error,
            hasFinishedRetrying: !shouldAttemptRefetch,
            update: update,
            refresh: refresh,
            clear: clear,
            clearAll: clearAll
        };
    };
});
parcelRegister("jZ6IX", function(module, exports) {
    $parcel$export(module.exports, "ErrorFlag", ()=>$a04ab0261f7f013f$export$518ba8e0e60f145f);
    var $kPPMx;
    const $a04ab0261f7f013f$export$518ba8e0e60f145f = ()=>({
            type: 'error',
            title: (0, (0, parcelRequire("kPPMx")).default).title,
            description: (0, (0, parcelRequire("kPPMx")).default).description,
            isAutoDismiss: false
        });
});
parcelRegister("kPPMx", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$07c280f03a553990$export$2e2bcd8739ae039);
    var $7VHMR;
    var $07c280f03a553990$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "classic-software.common.error-flag.title",
            "defaultMessage": "Oops! Something went wrong"
        },
        description: {
            "id": "classic-software.common.error-flag.description",
            "defaultMessage": "We cannot access this page at the moment. Please try again later."
        }
    });
});
parcelRegister("hHJ9y", function(module, exports) {
    $parcel$export(module.exports, "resolveDeferredWithPromise", ()=>$9ddb5fcf9afdbe4d$export$4fbe07c44244a10);
    $parcel$export(module.exports, "setupRapidBoardDataLoader", ()=>$9ddb5fcf9afdbe4d$export$b04990c14596734);
    var $1pMQS;
    const $9ddb5fcf9afdbe4d$export$4fbe07c44244a10 = async (promise, deferred)=>{
        if (!promise) {
            deferred.rejectWith(null);
            return;
        }
        try {
            const response = await promise;
            if (!response?.data) {
                deferred.rejectWith(null);
                return;
            }
            const data = await response.data;
            deferred.resolveWith(null, [
                data
            ]);
        } catch (error) {
            const { GH: GH } = window;
            deferred.rejectWith(null, [
                GH.Ajax._convertFrameworkErrors(error),
                {
                    status: error.statusCode
                }
            ]);
        }
    };
    const $9ddb5fcf9afdbe4d$export$b3bddb6595120042 = ()=>{
        const { AJS: AJS } = window;
        return AJS.$.Deferred();
    };
    const $9ddb5fcf9afdbe4d$var$generateRapidBoardReadyListener = (onLoadData, onUpdateData)=>()=>{
            const { GH: GH } = window;
            GH.RapidBoard.loadData = onLoadData;
            GH.RapidBoard.updateData = onUpdateData;
        };
    const $9ddb5fcf9afdbe4d$export$b04990c14596734 = (onLoadData, onUpdateData)=>{
        const { GH: GH } = window;
        const setupRapidBoard = $9ddb5fcf9afdbe4d$var$generateRapidBoardReadyListener(onLoadData, onUpdateData);
        if (GH?.RapidBoard) setupRapidBoard();
        else {
            const listener = ()=>{
                window.removeEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_RB_READY), listener);
                setupRapidBoard();
            };
            window.addEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_RB_READY), listener);
        }
    };
    const $9ddb5fcf9afdbe4d$export$79f5fef719ded8cd = ()=>{
        const { GH: GH } = window;
        if (GH?.RapidBoard) {
            delete GH.RapidBoard.loadData;
            delete GH.RapidBoard.updateData;
        }
    };
});
parcelRegister("arHWD", function(module, exports) {
    $parcel$export(module.exports, "useRapidBoard", ()=>$35d30b1d91715507$export$264220cfe099885c);
    var $4hXjG;
    var $eJS09;
    const $35d30b1d91715507$export$264220cfe099885c = false ? (_history)=>{
        const { mode: mode } = (0, (0, parcelRequire("4hXjG")).useUrlState)();
        return {
            isRapidBoardsReady: false,
            isBentoEnabled: false,
            mode: mode,
            shouldUserSeeBoard: true
        };
    } : (0, (0, parcelRequire("eJS09")).useRapidBoard);
});
parcelRegister("eJS09", function(module, exports) {
    $parcel$export(module.exports, "useRapidBoard", ()=>$e1c26ee9263ce4b2$export$264220cfe099885c);
    var $5uXOq = parcelRequire("5uXOq");
    var $co1wz;
    var $4DPZY;
    var $i6CNv;
    var $f5kpo;
    var $gQ2J4;
    var $1pMQS;
    var $eJfvV;
    var $kHCwz;
    var $9S1TY;
    var $4hXjG;
    var $1RCC1;
    var $2of3O;
    var $UKAH0;
    var $k4RKv;
    let $e1c26ee9263ce4b2$var$resolveInstallAppsPromise;
    const $e1c26ee9263ce4b2$var$installAppsPromise = new Promise((resolve)=>{
        $e1c26ee9263ce4b2$var$resolveInstallAppsPromise = resolve;
    });
    (()=>{
        (parcelRequire("eFW1U")).then((rapidBoardApps)=>{
            $e1c26ee9263ce4b2$var$resolveInstallAppsPromise(rapidBoardApps);
        });
    })();
    const $e1c26ee9263ce4b2$var$startApps = ()=>{
        $e1c26ee9263ce4b2$var$installAppsPromise.then((rapidBoardApps)=>rapidBoardApps.startApps());
    };
    const $e1c26ee9263ce4b2$var$useConfigResource = (boardId)=>{
        const { promise: promise, refresh: refresh, loading: loading, update: update, data: data } = (0, (0, parcelRequire("9S1TY")).useRapidBoardResource)({
            resource: (0, (0, parcelRequire("i6CNv")).boardConfigApiResource),
            shouldShowFlag: false
        });
        const key = `rapidViewConfig-${boardId}`;
        return (0, $5uXOq.useMemo)(()=>({
                key: key,
                data: data,
                promise: promise,
                refresh: refresh,
                update: update,
                loading: loading
            }), [
            key,
            data,
            promise,
            refresh,
            update,
            loading
        ]);
    };
    const $e1c26ee9263ce4b2$export$179c0f1288f7b075 = ()=>{
        const [hasLayoutLoaded, setHasLayoutLoaded] = (0, $5uXOq.useState)(false);
        const { data: rapidboardData, loading: rapidboardResourceLoading } = (0, (0, parcelRequire("9S1TY")).useRapidBoardResource)({
            resource: (0, (0, parcelRequire("gQ2J4")).rapidboardResource)
        });
        const [{ data: projectData }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        const isBentoOn = !!rapidboardData?.isNewIssueViewEnabled;
        const hasProjectDataLoaded = !!projectData;
        const projectKey = projectData && projectData.project && projectData.project.key;
        const projectId = projectData && projectData.project && projectData.project.id;
        (0, $5uXOq.useLayoutEffect)(()=>()=>{
                (0, (0, parcelRequire("UKAH0")).cleanupGH)(window, document)();
            }, []);
        (0, $5uXOq.useLayoutEffect)(()=>{
            let metaTags = [];
            let initalClassList = [
                'adg3'
            ];
            if (!rapidboardResourceLoading && hasProjectDataLoaded) {
                initalClassList = Array.from(document.body ? document.body.classList : []);
                metaTags = (0, (0, parcelRequire("k4RKv")).initDOM)(isBentoOn, projectKey, String(projectId));
                setHasLayoutLoaded(true);
            }
            return ()=>{
                setHasLayoutLoaded(false);
                (0, (0, parcelRequire("co1wz")).ff)('jsw.classic.board.api-calls.modern-frontend') && (0, (0, parcelRequire("UKAH0")).cleanupResources)([
                    (0, (0, parcelRequire("1pMQS")).CONFIG_DATA)
                ]);
                (0, (0, parcelRequire("UKAH0")).cleanupDOM)(document)(initalClassList, metaTags, isBentoOn);
            };
        }, [
            rapidboardResourceLoading,
            hasProjectDataLoaded,
            isBentoOn,
            projectId,
            projectKey
        ]);
        (0, $5uXOq.useEffect)(()=>()=>{
                !rapidboardResourceLoading && (0, (0, parcelRequire("UKAH0")).hideGhControllers)();
            }, [
            rapidboardResourceLoading
        ]);
        return [
            hasLayoutLoaded,
            isBentoOn
        ];
    };
    const $e1c26ee9263ce4b2$var$handleBackToBackNavigation = (mode)=>{
        const { GH: GH } = window;
        if ((0, (0, parcelRequire("co1wz")).ff)('jsw.classic.board.api-calls.modern-frontend') && GH.RapidBoard.State?.isFirstLoad() === false) GH.RapidBoard.State.setMode(mode);
    };
    const $e1c26ee9263ce4b2$export$264220cfe099885c = (history)=>{
        const [pastRapidView, setPastRapidView] = (0, $5uXOq.useState)(null);
        const [pastChartView, setPastChartView] = (0, $5uXOq.useState)(undefined);
        const [areAppsInstalled, setAreAppsInstalled] = (0, $5uXOq.useState)(null);
        const [isRapidBoardsReady, setIsRapidBoardsReady] = (0, $5uXOq.useState)(false);
        const [shouldDeferToMonolith, setShouldDeferToMonolith] = (0, $5uXOq.useState)(false);
        const { accountId: accountId, mode: mode, boardId: boardId, chartView: chartView, projectKey: projectKey, routeName: routeName, isBoardLocationless: isBoardLocationless } = (0, (0, parcelRequire("4hXjG")).useUrlState)();
        const [hasLayoutLoaded, isBentoEnabled] = $e1c26ee9263ce4b2$export$179c0f1288f7b075();
        const [isAppContentLoaded, setIsAppContentLoaded] = (0, (0, parcelRequire("kHCwz")).useRapidBoardContentLoadListener)();
        (0, (0, parcelRequire("2of3O")).useCalendarSideEffects)();
        const { hasGHContextLoaded: hasGHContextLoaded } = (0, (0, parcelRequire("1RCC1")).useWrmBundleLoader)();
        const { promise: allDataPromise, data: data, loading: allDataLoading, error: allDataError, hasFinishedRetrying: allDataAttemptFinished } = (0, (0, parcelRequire("9S1TY")).useRapidBoardResource)({
            resource: (0, (0, parcelRequire("f5kpo")).getRapidBoardDataResource)(),
            retryPredicate: (0, (0, parcelRequire("eJfvV")).isAllDataErrorValid)
        });
        const { data: allData, route: initialRoute } = data ?? {};
        const shouldUserSeeBoard = !(allDataError && allDataError.statusCode != null && allDataError.statusCode === 404 || allData && allData.errors && allData.errors.rapidViewId);
        let haveResourcesLoaded = !!(hasGHContextLoaded && hasLayoutLoaded && (allDataPromise || !allDataLoading));
        let configResource;
        if ((0, (0, parcelRequire("co1wz")).ff)('jsw.classic.board.api-calls.modern-frontend')) {
            configResource = $e1c26ee9263ce4b2$var$useConfigResource(boardId == null ? '' : boardId);
            haveResourcesLoaded = !!(hasGHContextLoaded && hasLayoutLoaded && (allDataPromise || !allDataLoading) && (configResource.promise || !configResource.loading));
        }
        (0, $5uXOq.useEffect)(()=>{
            const { GH: GH } = window;
            const hasUserSwitchedRouteDuringLoad = hasGHContextLoaded && initialRoute && initialRoute !== routeName && GH?.RapidBoard?.State?.isFirstLoad();
            if (hasUserSwitchedRouteDuringLoad && isRapidBoardsReady) {
                GH?.RapidBoard?.State?.changeFirstLoadState?.();
                GH?.RapidBoard?.loadNewBoard?.(boardId);
            }
        }, [
            routeName,
            isRapidBoardsReady,
            boardId,
            initialRoute,
            hasGHContextLoaded
        ]);
        (0, $5uXOq.useEffect)(()=>{
            const { GH: GH } = window;
            if (hasGHContextLoaded && allDataAttemptFinished && allDataError?.statusCode === 500) {
                GH.RapidBoard.State.changeFirstLoadState();
                setShouldDeferToMonolith(true);
            }
        }, [
            allDataAttemptFinished,
            allDataError,
            hasGHContextLoaded
        ]);
        (0, $5uXOq.useEffect)(()=>{
            if (shouldDeferToMonolith || haveResourcesLoaded && (allData || mode === (0, (0, parcelRequire("1pMQS")).REPORT) || pastRapidView)) {
                const { GH: GH, AJS: AJS } = window;
                const dataPromise = new Promise((r)=>r(allData));
                if (mode !== (0, (0, parcelRequire("1pMQS")).REPORT) && allData) (0, (0, parcelRequire("k4RKv")).setUpWrmData)(dataPromise);
                if ((0, (0, parcelRequire("co1wz")).ff)('jsw.classic.board.api-calls.modern-frontend')) {
                    const { key: configKey, promise: configPromise, update: configUpdate } = configResource;
                    (0, (0, parcelRequire("k4RKv")).setupConfigResources)(configPromise, configKey, configUpdate);
                }
                (0, (0, parcelRequire("k4RKv")).setUpHistory)(history, {
                    accountId: accountId,
                    mode: mode,
                    boardId: boardId,
                    rapidView: boardId,
                    chartView: chartView,
                    projectKey: projectKey,
                    isBoardLocationless: isBoardLocationless
                });
                if (pastRapidView === boardId && pastChartView === chartView && GH?.RapidBoard?.State?.getMode?.() === mode) return;
                if (pastRapidView == null && window.GH?.RapidBoard && String(window.GH.RapidBoard?.State?.data?.rapidViewId) === boardId) {
                    (0, (0, parcelRequire("k4RKv")).renderRapidboardDom)(mode === (0, (0, parcelRequire("1pMQS")).WORK));
                    GH.RapidBoard.ViewController.setMode(mode, true);
                    (0, (0, parcelRequire("k4RKv")).afterRenderSetup)();
                    setIsRapidBoardsReady(true);
                    setPastRapidView(boardId);
                    return;
                }
                if (pastRapidView === boardId) {
                    if (isAppContentLoaded && isRapidBoardsReady && GH.RapidBoard.State.getDataValue('mode') && mode !== GH.RapidBoard.State.getMode()) GH.RapidBoard.ViewController.setMode(mode);
                    if (pastChartView !== chartView) {
                        setIsAppContentLoaded(false);
                        GH.RapidBoard.init();
                        setPastChartView(chartView);
                    }
                } else {
                    (0, (0, parcelRequire("k4RKv")).resetWorkControllerFilters)();
                    setIsRapidBoardsReady(false);
                    setIsAppContentLoaded(false);
                    $e1c26ee9263ce4b2$var$handleBackToBackNavigation(mode);
                    GH.RapidBoard.init().then(()=>{
                        setIsRapidBoardsReady(true);
                        !areAppsInstalled && $e1c26ee9263ce4b2$var$startApps();
                        setAreAppsInstalled(true);
                        setShouldDeferToMonolith(false);
                        if (mode === (0, (0, parcelRequire("1pMQS")).REPORT) && AJS) AJS.$(window).trigger('resize.rapidboardSpa');
                        (0, (0, parcelRequire("k4RKv")).afterRenderSetup)();
                    });
                    setPastRapidView(boardId);
                    setPastChartView(chartView);
                }
            }
        }, [
            haveResourcesLoaded,
            allData,
            mode,
            history,
            areAppsInstalled,
            pastRapidView,
            boardId,
            pastChartView,
            chartView,
            isRapidBoardsReady,
            projectKey,
            isAppContentLoaded,
            setIsAppContentLoaded,
            shouldDeferToMonolith,
            accountId,
            configResource,
            isBoardLocationless
        ]);
        return {
            isRapidBoardsReady: isRapidBoardsReady,
            mode: mode,
            isBentoEnabled: !!isBentoEnabled,
            shouldUserSeeBoard: shouldUserSeeBoard
        };
    };
});
parcelRegister("1RCC1", function(module, exports) {
    $parcel$export(module.exports, "useWrmBundleLoader", ()=>$5bb71be054b7d5fb$export$d4d2c76eab941a24);
    var $5uXOq = parcelRequire("5uXOq");
    var $dJl1T;
    var $fox5Q;
    var $2YP9W;
    const $5bb71be054b7d5fb$var$actions = {
        initGhContext: ()=>({ setState: setState, getState: getState })=>{
                const { hasGHContextLoaded: hasGHContextLoaded, isGhContextLoading: isGhContextLoading } = getState();
                if (!hasGHContextLoaded && !isGhContextLoading) {
                    setState({
                        isGhContextLoading: true
                    });
                    setState({
                        hasGHContextLoaded: true,
                        isGhContextLoading: false
                    });
                }
            }
    };
    const $5bb71be054b7d5fb$export$d4d2c76eab941a24 = ()=>{
        const { data: wrmBundleDescriptor, error: errorGHContext } = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("fox5Q")).wrmBundleResource));
        (0, $5uXOq.useEffect)(()=>{
            if (errorGHContext != null) (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('classic.wrm.bundle.error', 'Failed to load WRM bundle for Rapidboards', errorGHContext);
        }, [
            errorGHContext
        ]);
        return {
            hasGHContextLoaded: !!wrmBundleDescriptor,
            errorGHContext: errorGHContext
        };
    };
});
parcelRegister("2of3O", function(module, exports) {
    $parcel$export(module.exports, "useCalendarSideEffects", ()=>$722930d1d2c65bca$export$460514a547ad8b3d);
    var $5uXOq = parcelRequire("5uXOq");
    var $DVljb;
    var $kHCwz;
    const $722930d1d2c65bca$export$460514a547ad8b3d = ()=>{
        const [haveMonkeyPatchedStartSprint, setHaveMonkeyPatchedStartSprint] = (0, $5uXOq.useState)(false);
        const locale = (0, (0, parcelRequire("DVljb")).useLocale)();
        const contentLoadedStatus = (0, (0, parcelRequire("kHCwz")).useRapidBoardContentListener)();
        (0, $5uXOq.useEffect)(()=>{
            try {
                const { moment: moment, Calendar: Calendar, GH: GH } = window;
                if (!moment || !Calendar) return;
                const langData = moment.localeData('jira');
                if (!langData) return;
                Calendar._DN = langData._weekdays.concat(langData._weekdays[0]);
                Calendar._SDN = langData._weekdaysShort.concat(langData._weekdaysShort[0]);
                Calendar._MN = [].concat(langData._months);
                Calendar._SMN = [].concat(langData._monthsShort);
                if (GH?.SprintController?.openStartSprintDialog && locale === 'ja_JP' && !haveMonkeyPatchedStartSprint) {
                    const originalFunction = GH.SprintController.openStartSprintDialog;
                    GH.SprintController.openStartSprintDialog = (...args)=>{
                        Calendar._TT.AM = '\u5348\u524D';
                        Calendar._TT.PM = '\u5348\u5F8C';
                        Calendar._TT.am = '\u5348\u524D';
                        Calendar._TT.pm = '\u5348\u5F8C';
                        originalFunction(...args);
                    };
                    setHaveMonkeyPatchedStartSprint(true);
                }
            } catch (e) {}
        }, [
            haveMonkeyPatchedStartSprint,
            contentLoadedStatus,
            locale
        ]);
    };
});
parcelRegister("UKAH0", function(module, exports) {
    $parcel$export(module.exports, "cleanupResources", ()=>$7c901c2fad4fadef$export$687fcdb18310652e);
    $parcel$export(module.exports, "cleanupDOM", ()=>$7c901c2fad4fadef$export$94699122a7a283af);
    $parcel$export(module.exports, "cleanupGH", ()=>$7c901c2fad4fadef$export$f2a8626034055d0);
    $parcel$export(module.exports, "hideGhControllers", ()=>$7c901c2fad4fadef$export$2ac2fbe6032ae006);
    var $7nsd3 = parcelRequire("7nsd3");
    var $4R6GH;
    var $1pMQS;
    const $7c901c2fad4fadef$export$687fcdb18310652e = (resources)=>{
        if (resources && resources.length > 0) resources.forEach((resource)=>{
            if (window.GH?.spa) delete window.GH.spa[resource];
        });
    };
    const $7c901c2fad4fadef$export$94699122a7a283af = (doc)=>(initialClasslist, metaTags, isBentoOn)=>{
            if (doc.body) {
                const documentBody = doc.body;
                metaTags.forEach((metaTag)=>{
                    metaTag.parentNode && metaTag.parentNode.removeChild(metaTag);
                });
                if (isBentoOn === true) {
                    const bentoRoot = doc.getElementById((0, (0, parcelRequire("1pMQS")).BENTO_ROOT));
                    bentoRoot && (0, $7nsd3.unmountComponentAtNode)(bentoRoot);
                }
                const currentClassList = Array.from(documentBody.classList);
                currentClassList.forEach((className)=>{
                    documentBody.classList.remove(className);
                });
                initialClasslist.filter((className)=>className !== 'deferred').forEach((className)=>{
                    documentBody.classList.add(className);
                });
            }
        };
    const $7c901c2fad4fadef$var$WINDOW_EVENTS = [
        'confluence',
        'hashchange',
        'messageClose',
        'remove',
        'statechange',
        'blur.rapidboardSpa',
        'popstate.rapidboardSpa',
        'resize.rapidboardSpa',
        'unload.rapidboardSpa',
        'focus.rapidboardSpa'
    ];
    const $7c901c2fad4fadef$export$f2a8626034055d0 = (win, doc)=>()=>{
            const { GH: GH, AJS: AJS } = win;
            if (AJS && (AJS.$ || !(0, (0, parcelRequire("4R6GH")).fg)('fix_cleanupgh_type_error'))) {
                AJS.$(win).off($7c901c2fad4fadef$var$WINDOW_EVENTS.join(' '));
                AJS.$(doc.body).off('click.rapidboardSpa');
                AJS.$('[draggable=true]').die('dragstart');
            }
            if (GH) {
                GH.spa = null;
                if (GH.Poller) {
                    GH.Poller.removeAll && GH.Poller.removeAll();
                    GH.Poller.removePoller && GH.Poller.removePoller('WorkPoller');
                    GH.Poller.removePoller && GH.Poller.removePoller('linkedPagePoller');
                }
            }
            win.require('jira/common/header').initialize();
        };
    const $7c901c2fad4fadef$export$2ac2fbe6032ae006 = ()=>{
        try {
            const { GH: GH } = window;
            if (GH) {
                GH.WorkController && GH.WorkController.hide();
                GH.ReportController && GH.ReportController.hide();
                GH.PlanController && GH.PlanController.hide();
            }
        } catch (e) {}
    };
    const $7c901c2fad4fadef$export$de863c629cb9919d = (initialClasslist, metaTags, isBentoOn)=>{
        try {
            $7c901c2fad4fadef$export$94699122a7a283af(document)(initialClasslist, metaTags, isBentoOn);
            $7c901c2fad4fadef$export$f2a8626034055d0(window, document)();
        } catch (e) {}
    };
});
parcelRegister("k4RKv", function(module, exports) {
    $parcel$export(module.exports, "setupConfigResources", ()=>$c44af90bce5f6a61$export$963012c7d2677232);
    $parcel$export(module.exports, "setUpWrmData", ()=>$c44af90bce5f6a61$export$275cbf368775940c);
    $parcel$export(module.exports, "setUpHistory", ()=>$c44af90bce5f6a61$export$7d0780c27da6c901);
    $parcel$export(module.exports, "initDOM", ()=>$c44af90bce5f6a61$export$e96bbd4368cedb83);
    $parcel$export(module.exports, "resetWorkControllerFilters", ()=>$c44af90bce5f6a61$export$674b9bcb259a86ae);
    $parcel$export(module.exports, "renderRapidboardDom", ()=>$c44af90bce5f6a61$export$87937eecd895a618);
    $parcel$export(module.exports, "afterRenderSetup", ()=>$c44af90bce5f6a61$export$3d209c045bb39744);
    var $91VA7 = parcelRequire("91VA7");
    var $4Y8b7 = parcelRequire("4Y8b7");
    var $dfl35 = parcelRequire("dfl35");
    var $3eCoK = parcelRequire("3eCoK");
    var $1Gn0l = parcelRequire("1Gn0l");
    var $1sWm7 = parcelRequire("1sWm7");
    var $183uB = parcelRequire("183uB");
    var $lbWm4 = parcelRequire("lbWm4");
    var $fn8B5 = parcelRequire("fn8B5");
    var $561FV = parcelRequire("561FV");
    var $gccSB = parcelRequire("gccSB");
    var $ajpes = parcelRequire("ajpes");
    var $k0qYG = parcelRequire("k0qYG");
    var $agVdz = parcelRequire("agVdz");
    var $3T6Xo = parcelRequire("3T6Xo");
    var $66FNL = parcelRequire("66FNL");
    var $hYfgW = parcelRequire("hYfgW");
    var $80afZ = parcelRequire("80afZ");
    var $a66wu = parcelRequire("a66wu");
    var $3NMrl = parcelRequire("3NMrl");
    var $5Ot1C = parcelRequire("5Ot1C");
    var $7qDUb = parcelRequire("7qDUb");
    var $34ck9 = parcelRequire("34ck9");
    var $c9CKH;
    var $dJl1T;
    var $1pMQS;
    var $fQzST;
    var $l6Fjd;
    var $hfCUF;
    const $c44af90bce5f6a61$var$doHandshake = ()=>{
        window.dispatchEvent((0, (0, parcelRequire("c9CKH")).default)((0, (0, parcelRequire("1pMQS")).GH_SPA_RESOURCES_READY)));
        window.removeEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_RB_READY), $c44af90bce5f6a61$var$doHandshake);
    };
    const $c44af90bce5f6a61$export$963012c7d2677232 = (dataPromise, key, update)=>{
        const { GH: GH } = window;
        if (!GH) return;
        GH.spa = GH.spa || {
            [(0, (0, parcelRequire("1pMQS")).INITIAL_DATA)]: {}
        };
        GH.spa[0, (0, parcelRequire("1pMQS")).CONFIG_DATA] = GH.spa[0, (0, parcelRequire("1pMQS")).CONFIG_DATA] || {};
        if (dataPromise) {
            GH.spa[0, (0, parcelRequire("1pMQS")).CONFIG_DATA][key] = dataPromise;
            GH.spa[0, (0, parcelRequire("1pMQS")).CONFIG_DATA].key = key;
            GH.spa[0, (0, parcelRequire("1pMQS")).CONFIG_DATA].refresh = (0, (0, parcelRequire("hfCUF")).refresh);
            GH.spa[0, (0, parcelRequire("1pMQS")).CONFIG_DATA].update = update;
        }
    };
    const $c44af90bce5f6a61$export$e402d0e4003b41fb = (win)=>(allDataPromise)=>{
            const { GH: GH } = win;
            if (!GH) return;
            GH.spa = GH.spa || {
                [(0, (0, parcelRequire("1pMQS")).INITIAL_DATA)]: {}
            };
            if (allDataPromise) {
                GH.spa[0, (0, parcelRequire("1pMQS")).INITIAL_DATA][0, (0, parcelRequire("1pMQS")).ALL_DATA_PROMISE] = allDataPromise;
                win.addEventListener((0, (0, parcelRequire("1pMQS")).GH_SPA_RB_READY), $c44af90bce5f6a61$var$doHandshake);
                win.dispatchEvent((0, (0, parcelRequire("c9CKH")).default)((0, (0, parcelRequire("1pMQS")).GH_SPA_RESOURCES_READY)));
            }
        };
    const $c44af90bce5f6a61$export$275cbf368775940c = $c44af90bce5f6a61$export$e402d0e4003b41fb(window);
    const $c44af90bce5f6a61$export$d1f98e05eef0997a = (win)=>(history, urlState)=>{
            const { GH: GH } = win;
            if (!GH) return;
            GH.spa = GH.spa || {
                [(0, (0, parcelRequire("1pMQS")).INITIAL_DATA)]: {}
            };
            GH.spa.history = history;
            GH.spa.urlState = {
                ...urlState
            };
            GH.spa.lodash = {
                each: ($parcel$interopDefault($3eCoK)),
                forEach: ($parcel$interopDefault($lbWm4)),
                compact: ($parcel$interopDefault($4Y8b7)),
                reject: ($parcel$interopDefault($5Ot1C)),
                extend: ($parcel$interopDefault($1Gn0l)),
                isString: ($parcel$interopDefault($agVdz)),
                clone: ($parcel$interopDefault($91VA7)),
                isEmpty: ($parcel$interopDefault($gccSB)),
                kebabCase: ($parcel$interopDefault($3T6Xo)),
                min: ($parcel$interopDefault($a66wu)),
                sortBy: ($parcel$interopDefault($7qDUb)),
                last: ($parcel$interopDefault($hYfgW)),
                groupBy: ($parcel$interopDefault($fn8B5)),
                isObject: ($parcel$interopDefault($k0qYG)),
                map: ($parcel$interopDefault($80afZ)),
                keys: ($parcel$interopDefault($66FNL)),
                defaults: ($parcel$interopDefault($dfl35)),
                find: ($parcel$interopDefault($1sWm7)),
                pick: ($parcel$interopDefault($3NMrl)),
                has: ($parcel$interopDefault($561FV)),
                throttle: ($parcel$interopDefault($34ck9)),
                flatten: ($parcel$interopDefault($183uB)),
                isEqual: ($parcel$interopDefault($ajpes))
            };
            GH.spa.slaUtil = (0, (0, parcelRequire("fQzST")).getSLAResult);
        };
    const $c44af90bce5f6a61$export$7d0780c27da6c901 = $c44af90bce5f6a61$export$d1f98e05eef0997a(window);
    const $c44af90bce5f6a61$export$eb9b2e269373b6d3 = (doc)=>(isBentoOn, projectKey, projectId)=>{
            const sidebarMeta = (0, (0, parcelRequire("l6Fjd")).addMetaToHead)('ajs-sidebar-enabled', 'true');
            const atlGeneralMeta = (0, (0, parcelRequire("l6Fjd")).addMetaToHead)('decorator', 'atl.general');
            const projectIdMeta = (0, (0, parcelRequire("l6Fjd")).addMetaToHead)('ghx-project-id', projectId || '');
            const metaTags = [
                sidebarMeta,
                atlGeneralMeta,
                projectIdMeta
            ];
            if (projectKey != null && projectKey !== '') {
                const projectKeyMeta = (0, (0, parcelRequire("l6Fjd")).addMetaToHead)('ghx-project-key', projectKey || '');
                metaTags.push(projectKeyMeta);
            } else {
                const projectKeyMeta = document.querySelector('meta[name="ghx-project-key"]');
                projectKeyMeta && projectKeyMeta.parentNode && projectKeyMeta.parentNode.removeChild(projectKeyMeta);
                const userMetaTag = (0, (0, parcelRequire("l6Fjd")).addMetaToHead)('ghx-container-type', 'USER');
                metaTags.push(userMetaTag);
            }
            if (doc.body) {
                const documentBody = doc.body;
                documentBody.classList.remove('adg3');
                [
                    'aui-layout',
                    'aui-theme-default',
                    'adg3',
                    'ghx-agile'
                ].forEach((className)=>{
                    documentBody.classList.add(className);
                });
                const bentoMeta = (0, (0, parcelRequire("l6Fjd")).addMetaToHead)('ghx-user-bento-enabled', 'true');
                metaTags.push(bentoMeta);
                (0, (0, parcelRequire("l6Fjd")).addDivToDom)((0, (0, parcelRequire("1pMQS")).BENTO_ROOT));
                documentBody.classList.add('bento-enabled');
                documentBody.classList.add('ghx-ondemand');
            } else (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('rapidboard-status.utils.set-up', 'body not in the dom');
            return metaTags;
        };
    const $c44af90bce5f6a61$export$e96bbd4368cedb83 = $c44af90bce5f6a61$export$eb9b2e269373b6d3(document);
    const $c44af90bce5f6a61$export$674b9bcb259a86ae = ()=>{
        const { GH: GH } = window;
        if (GH && GH.WorkController) {
            GH.WorkController.activeFilters.searchText = '';
            GH.WorkController.activeFilters.assigneeList = [];
        }
    };
    const $c44af90bce5f6a61$export$87937eecd895a618 = (isWorkMode = false)=>{
        const { AJS: AJS, GH: GH } = window;
        AJS.$('body').removeClass('ghx-board-configure');
        AJS.$('#gh').html(GH.tpl.board.x.renderRapidBoard({
            isWorkMode: isWorkMode
        }));
        AJS.$('#ghx-operations').append(GH.tpl.viewcontroller.renderOperationsBar());
    };
    const $c44af90bce5f6a61$export$3d209c045bb39744 = ()=>{
        const { GH: GH, AJS: AJS } = window;
        AJS.$(window).off([
            'blur.rapidboardSpa',
            'unload.rapidboardSpa',
            'focus.rapidboardSpa'
        ].join(' '));
        GH?.windowActivityNotifier?._bindFocusBlur();
        window.require('jira/common/header').unbind();
    };
});
parcelRegister("91VA7", function(module, exports) {
    var $7MmCC = parcelRequire("7MmCC");
    var $6930df867851495d$var$CLONE_SYMBOLS_FLAG = 4;
    function $6930df867851495d$var$clone(value) {
        return $7MmCC(value, $6930df867851495d$var$CLONE_SYMBOLS_FLAG);
    }
    module.exports = $6930df867851495d$var$clone;
});
parcelRegister("4Y8b7", function(module, exports) {
    function $39e31c105bacff45$var$compact(array) {
        var index = -1, length = array == null ? 0 : array.length, resIndex = 0, result = [];
        while(++index < length){
            var value = array[index];
            if (value) result[resIndex++] = value;
        }
        return result;
    }
    module.exports = $39e31c105bacff45$var$compact;
});
parcelRegister("3eCoK", function(module, exports) {
    module.exports = (parcelRequire("lbWm4"));
});
parcelRegister("1Gn0l", function(module, exports) {
    module.exports = (parcelRequire("4RHcR"));
});
parcelRegister("4RHcR", function(module, exports) {
    var $4NkAS = parcelRequire("4NkAS");
    var $9MM2g = parcelRequire("9MM2g");
    var $lbyKY = parcelRequire("lbyKY");
    var $38ada0910bc35ca9$var$assignIn = $9MM2g(function(object, source) {
        $4NkAS(source, $lbyKY(source), object);
    });
    module.exports = $38ada0910bc35ca9$var$assignIn;
});
parcelRegister("1sWm7", function(module, exports) {
    var $bptzf = parcelRequire("bptzf");
    var $fW7cr = parcelRequire("fW7cr");
    var $1115ac3ae60cc7bc$var$find = $bptzf($fW7cr);
    module.exports = $1115ac3ae60cc7bc$var$find;
});
parcelRegister("bptzf", function(module, exports) {
    var $bxDef = parcelRequire("bxDef");
    var $hTJwX = parcelRequire("hTJwX");
    var $66FNL = parcelRequire("66FNL");
    function $84e8f705a13e859f$var$createFind(findIndexFunc) {
        return function(collection, predicate, fromIndex) {
            var iterable = Object(collection);
            if (!$hTJwX(collection)) {
                var iteratee = $bxDef(predicate, 3);
                collection = $66FNL(collection);
                predicate = function(key) {
                    return iteratee(iterable[key], key, iterable);
                };
            }
            var index = findIndexFunc(collection, predicate, fromIndex);
            return index > -1 ? iterable[iteratee ? collection[index] : index] : undefined;
        };
    }
    module.exports = $84e8f705a13e859f$var$createFind;
});
parcelRegister("fW7cr", function(module, exports) {
    var $b5dwA = parcelRequire("b5dwA");
    var $bxDef = parcelRequire("bxDef");
    var $kPZpS = parcelRequire("kPZpS");
    var $b9a1db7d7901532f$var$nativeMax = Math.max;
    function $b9a1db7d7901532f$var$findIndex(array, predicate, fromIndex) {
        var length = array == null ? 0 : array.length;
        if (!length) return -1;
        var index = fromIndex == null ? 0 : $kPZpS(fromIndex);
        if (index < 0) index = $b9a1db7d7901532f$var$nativeMax(length + index, 0);
        return $b5dwA(array, $bxDef(predicate, 3), index);
    }
    module.exports = $b9a1db7d7901532f$var$findIndex;
});
parcelRegister("a66wu", function(module, exports) {
    var $la2vn = parcelRequire("la2vn");
    var $48eTY = parcelRequire("48eTY");
    var $etyzk = parcelRequire("etyzk");
    function $759f863f9fd7b392$var$min(array) {
        return array && array.length ? $la2vn(array, $etyzk, $48eTY) : undefined;
    }
    module.exports = $759f863f9fd7b392$var$min;
});
parcelRegister("la2vn", function(module, exports) {
    var $4faK3 = parcelRequire("4faK3");
    function $f67c7999c5b61dcb$var$baseExtremum(array, iteratee, comparator) {
        var index = -1, length = array.length;
        while(++index < length){
            var value = array[index], current = iteratee(value);
            if (current != null && (computed === undefined ? current === current && !$4faK3(current) : comparator(current, computed))) var computed = current, result = value;
        }
        return result;
    }
    module.exports = $f67c7999c5b61dcb$var$baseExtremum;
});
parcelRegister("48eTY", function(module, exports) {
    function $302377fd2186d815$var$baseLt(value, other) {
        return value < other;
    }
    module.exports = $302377fd2186d815$var$baseLt;
});
parcelRegister("5Ot1C", function(module, exports) {
    var $2GdQn = parcelRequire("2GdQn");
    var $8BRGu = parcelRequire("8BRGu");
    var $bxDef = parcelRequire("bxDef");
    var $8LQ84 = parcelRequire("8LQ84");
    var $jGJpR = parcelRequire("jGJpR");
    function $43b82310b1b35f52$var$reject(collection, predicate) {
        var func = $8LQ84(collection) ? $2GdQn : $8BRGu;
        return func(collection, $jGJpR($bxDef(predicate, 3)));
    }
    module.exports = $43b82310b1b35f52$var$reject;
});
parcelRegister("8BRGu", function(module, exports) {
    var $e7pfQ = parcelRequire("e7pfQ");
    function $644b6c0ae483a6c5$var$baseFilter(collection, predicate) {
        var result = [];
        $e7pfQ(collection, function(value, index, collection) {
            if (predicate(value, index, collection)) result.push(value);
        });
        return result;
    }
    module.exports = $644b6c0ae483a6c5$var$baseFilter;
});
parcelRegister("fQzST", function(module, exports) {
    $parcel$export(module.exports, "getSLAResult", ()=>$844e857d304a3421$export$e631974996a4cdc0);
    var $1pMQS;
    var $eJfvV;
    const $844e857d304a3421$var$isBoardLoadComplete = (rapidBoard)=>{
        if (rapidBoard === null) return false;
        const columns = rapidBoard.querySelectorAll('.ghx-swimlane.ghx-first .ghx-columns .ghx-column');
        const issues = rapidBoard.querySelectorAll('.ghx-issue');
        const emptyColumns = rapidBoard.querySelectorAll('.ghx-sad-columns .ghx-column');
        const emptyBoard = rapidBoard.querySelectorAll('.ghx-no-active-sprint-message');
        const { GH: GH } = window;
        const filters = GH && GH.WorkController?.activeFilters;
        const haveFiltersApplied = filters && filters?.assigneeList && filters.assigneeList.length > 0 || filters?.issueTypeList && filters.issueTypeList.length > 0 || filters?.searchText && filters.searchText.length > 0;
        const haveQuickFiltersApplied = GH?.WorkControls?.quickfilters && GH.WorkControls.quickfilters.getQuickFilters().filter((filter)=>filter.isActive).length > 0;
        return emptyBoard.length === 1 || columns.length > 0 && (issues.length > 0 || haveFiltersApplied || haveQuickFiltersApplied) || emptyColumns.length > 0 && issues.length === 0;
    };
    const $844e857d304a3421$var$isBackLogLoadComplete = (rapidBoard)=>{
        if (rapidBoard === null) return false;
        const issues = rapidBoard.querySelectorAll('.ghx-backlog-card');
        const emptyBacklogMessage = rapidBoard.querySelectorAll('.ghx-onboarding-fillyourbacklog-group');
        const backlogHeader = rapidBoard.querySelectorAll('.ghx-backlog-header.js-marker-backlog-header');
        return issues.length > 0 || emptyBacklogMessage.length > 0 || issues.length === 0 && backlogHeader && backlogHeader.length >= 1;
    };
    const $844e857d304a3421$export$e631974996a4cdc0 = (statusCode, mode)=>{
        const { GH: GH } = window;
        if (GH !== null && GH !== undefined) {
            if ((0, (0, parcelRequire("eJfvV")).isWorkMode)(mode) && $844e857d304a3421$var$isBoardLoadComplete(document.getElementById('ghx-work')) || (0, (0, parcelRequire("eJfvV")).isPlanningMode)(mode) && $844e857d304a3421$var$isBackLogLoadComplete(document.getElementById('ghx-backlog'))) return {
                state: (0, (0, parcelRequire("1pMQS")).SUCCESS),
                statusCode: statusCode,
                href: window.location.href
            };
        }
        if (statusCode !== null) {
            if (statusCode >= 400 && statusCode < 500) return {
                state: (0, (0, parcelRequire("1pMQS")).SUCCESS),
                statusCode: statusCode,
                href: window.location.href
            };
        }
        return {
            state: (0, (0, parcelRequire("1pMQS")).FAILURE),
            statusCode: statusCode,
            href: window.location.href
        };
    };
});
parcelRegister("l6Fjd", function(module, exports) {
    $parcel$export(module.exports, "addMetaToHead", ()=>$bd0c72fe4c17704a$export$9e7e16a3dbced495);
    $parcel$export(module.exports, "addDivToDom", ()=>$bd0c72fe4c17704a$export$d0a7e6e6dd396a00);
    const $bd0c72fe4c17704a$export$9e7e16a3dbced495 = (name, value)=>{
        const existingMetaTag = document.querySelector(`meta[name="${name}"]`);
        const metaTag = existingMetaTag || document.createElement('meta');
        metaTag.setAttribute('name', name);
        metaTag.setAttribute('content', value);
        if (!existingMetaTag) document.head && document.head.insertBefore(metaTag, document.head.childNodes[0]);
        return metaTag;
    };
    const $bd0c72fe4c17704a$export$d0a7e6e6dd396a00 = (id)=>{
        if (document.body && !document.getElementById(id)) {
            const documentBody = document.body;
            const div = document.createElement('div');
            div.setAttribute('id', id);
            documentBody.insertBefore(div, documentBody.lastChild);
        }
    };
});
parcelRegister("hfCUF", function(module, exports) {
    $parcel$export(module.exports, "refresh", ()=>$ffa50cee0da982e2$export$2d6e9b4c68afb6c8);
    var $7f4tk;
    const $ffa50cee0da982e2$var$getUrl = (url, query)=>{
        const queryParams = new URLSearchParams(query).toString();
        return url.indexOf('?') > 0 ? `${url}&${queryParams}` : `${url}?${queryParams}`;
    };
    const $ffa50cee0da982e2$export$dbfe50710819f38 = 300;
    const $ffa50cee0da982e2$var$refreshWrapper = ({ stallTimeout: stallTimeout })=>{
        const reqCache = {};
        const getXHRData = (key)=>{
            const data = {
                timestamp: 0
            };
            if (reqCache[key]) return reqCache[key];
            reqCache[key] = data;
            return reqCache[key];
        };
        const clearXHRMap = (key)=>{
            const reqCacheKeyMap = getXHRData(key);
            if (reqCacheKeyMap.xhr) {
                delete reqCacheKeyMap.xhr;
                delete reqCacheKeyMap.abortController;
            }
        };
        const executeRequest = (key, finalUrl)=>{
            const reqCacheKeyMap = getXHRData(key);
            const abortController = new AbortController();
            reqCacheKeyMap.abortController = abortController;
            return (0, (0, parcelRequire("7f4tk")).default)(finalUrl, {
                method: 'GET',
                signal: reqCacheKeyMap.abortController.signal
            }).then((res)=>{
                clearXHRMap(key);
                return res;
            }).catch((err)=>{
                clearXHRMap(key);
                throw err;
            });
        };
        const makeAPICall = (options)=>{
            const { key: key, url: url, query: query } = options;
            const reqCacheKeyMap = getXHRData(key);
            if (reqCacheKeyMap.xhr) {
                reqCacheKeyMap.abortController?.abort();
                delete reqCacheKeyMap.xhr;
                clearTimeout(reqCacheKeyMap.timeoutId);
                reqCacheKeyMap.timeoutId = null;
            }
            const { timestamp: timestamp } = reqCacheKeyMap;
            reqCacheKeyMap.timestamp = Date.now();
            if (reqCacheKeyMap.timestamp - timestamp < stallTimeout) {
                reqCacheKeyMap.timeoutId = setTimeout(()=>{
                    reqCacheKeyMap.xhr = makeAPICall(options);
                }, stallTimeout);
                clearXHRMap(key);
                reqCacheKeyMap.xhr = Promise.resolve();
            } else reqCacheKeyMap.xhr = executeRequest(key, $ffa50cee0da982e2$var$getUrl(url, query));
            return reqCacheKeyMap.xhr;
        };
        return {
            makeAPICall: makeAPICall,
            getXHRData: getXHRData
        };
    };
    let $ffa50cee0da982e2$var$refreshCache = null;
    const $ffa50cee0da982e2$var$getRefreshCache = ({ stallTimeout: stallTimeout })=>{
        const stallTime = stallTimeout !== undefined ? stallTimeout : $ffa50cee0da982e2$export$dbfe50710819f38;
        if ($ffa50cee0da982e2$var$refreshCache === null) $ffa50cee0da982e2$var$refreshCache = $ffa50cee0da982e2$var$refreshWrapper({
            stallTimeout: stallTime
        });
        return $ffa50cee0da982e2$var$refreshCache;
    };
    const $ffa50cee0da982e2$export$2d6e9b4c68afb6c8 = (options)=>{
        const cache = $ffa50cee0da982e2$var$getRefreshCache({
            stallTimeout: options.stallTimeout
        });
        return cache.makeAPICall(options);
    };
    const $ffa50cee0da982e2$export$624505b4e8def1c0 = (key, stallTimeout)=>{
        const cache = $ffa50cee0da982e2$var$getRefreshCache({
            stallTimeout: stallTimeout
        });
        return cache.getXHRData(key);
    };
    const $ffa50cee0da982e2$export$fd16eac4b058c4b4 = ()=>{
        $ffa50cee0da982e2$var$refreshCache = null;
    };
});
parcelRegister("eFW1U", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("bcehj").then(()=>parcelRequire('73mZu'));
});
parcelRegister("8Sklq", function(module, exports) {
    $parcel$export(module.exports, "ArchivedProjectRedirect", ()=>$aeceecd368677e67$export$6e1a5929022745db);
    var $5uXOq = parcelRequire("5uXOq");
    var $4DPZY;
    var $3mYHL;
    const $aeceecd368677e67$export$6e1a5929022745db = ()=>{
        const [{ data: data, error: error }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        const projectData = data && data.project || {};
        const { key: projectKey } = projectData;
        if (error || !data) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3mYHL")).default), {
            projectKey: projectKey || null
        });
    };
});
parcelRegister("1WToZ", function(module, exports) {
    $parcel$export(module.exports, "DocumentTitle", ()=>$09de9dcc9e26082c$export$dd5ad0b65cf665da);
    var $5uXOq = parcelRequire("5uXOq");
    var $ew1Lk;
    var $aWse0;
    var $4DPZY;
    var $9LVHX;
    const $09de9dcc9e26082c$export$dd5ad0b65cf665da = ({ useIntl: useIntl = (0, (0, parcelRequire("aWse0")).useIntlV2), useContainerContext: useContainerContext = (0, (0, parcelRequire("4DPZY")).useContainerContext), GlobalDocumentTitle: GlobalDocumentTitle = (0, (0, parcelRequire("ew1Lk")).DocumentTitle) })=>{
        const { formatMessage: formatMessage } = useIntl();
        const [{ data: data, error: error }] = useContainerContext();
        const boardName = data?.scope?.name;
        if (boardName == null || error) return null;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(GlobalDocumentTitle, {
            title: `${boardName} - ${formatMessage((0, (0, parcelRequire("9LVHX")).messages).agileBoard)}`
        });
    };
});
parcelRegister("9LVHX", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$33a5bff125f72d52$export$defe85febe8b728c);
    var $7VHMR;
    const $33a5bff125f72d52$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        agileBoard: {
            "id": "classic-software.document-title.agile-board",
            "defaultMessage": "Agile Board"
        }
    });
});
parcelRegister("1TPn7", function(module, exports) {
    $parcel$export(module.exports, "NextGenRedirect", ()=>$a0b7310f7ff5be32$export$a35e559b9dc401ee);
    var $5uXOq = parcelRequire("5uXOq");
    var $4DPZY;
    var $7tQej;
    var $4hXjG;
    const $a0b7310f7ff5be32$export$a35e559b9dc401ee = ()=>{
        const [{ data: data, error: error }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        const { boardId: boardId } = (0, (0, parcelRequire("4hXjG")).useUrlState)();
        if (error || !data) return null;
        const scopeId = data.scope && data.scope.id;
        if (data.project && data.project.isSimplified && scopeId != null && scopeId === Number(boardId)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7tQej")).Redirect), {
            to: `/jira/software/projects/${data.project.key}/boards/${scopeId}`
        });
        return null;
    };
});
parcelRegister("a55mQ", function(module, exports) {
    $parcel$export(module.exports, "PerformanceMarks", ()=>$54b64e1d891fb78d$export$c336aabab9fe0470);
    var $5uXOq = parcelRequire("5uXOq");
    var $fNobW;
    var $co1wz;
    var $feJRr;
    var $5rqex;
    var $f5kpo;
    var $doZTA;
    var $9S1TY;
    var $1RCC1;
    var $fzbpE;
    const $54b64e1d891fb78d$export$c336aabab9fe0470 = ({ isRendered: isRendered, isInteractive: isInteractive })=>{
        const [{ route: route, query: query }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const { hasGHContextLoaded: hasGHContextLoaded } = (0, (0, parcelRequire("1RCC1")).useWrmBundleLoader)();
        const { data: data } = (0, (0, parcelRequire("9S1TY")).useRapidBoardResource)({
            resource: (0, (0, parcelRequire("f5kpo")).getRapidBoardDataResource)()
        });
        const metric = (0, (0, parcelRequire("fzbpE")).getBM3Metric)(route.name);
        const allDataRequestTraceId = data?.allDataTraceId;
        const metricTag = metric && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("5rqex")).default), {
            appName: route.name,
            key: route.name,
            extra: {
                allDataRequestTraceId: allDataRequestTraceId,
                ...(0, (0, parcelRequire("fzbpE")).getApdexAttributes)(query)
            },
            metric: metric,
            emitOnAnimationFrame: (0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences')
        });
        if (!metricTag) console.warn('Performance metric not defined for this route');
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fNobW")).default), {
            name: "is-interactive",
            hold: !isInteractive
        }), hasGHContextLoaded && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("feJRr")).default), {
            name: "wrm_done"
        }), isRendered && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("feJRr")).default), {
            name: "classic_software_ttr"
        }), isInteractive && route && metricTag);
    };
});
parcelRegister("feJRr", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$aa35a5a19d0a4620$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $bNvU3;
    var $8Qrwl;
    class $aa35a5a19d0a4620$export$2e2bcd8739ae039 extends (0, $5uXOq.Component) {
        render() {
            const { name: name } = this.props;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("8Qrwl")).default).Consumer, null, ({ pageId: pageId, onCustomMark: onCustomMark })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bNvU3")).default), {
                    op: onCustomMark,
                    args: [
                        name,
                        pageId
                    ]
                }));
        }
    }
});
parcelRegister("5rqex", function(module, exports) {
    $parcel$export(module.exports, "checkAndEmitApdexSignal", ()=>$ab527e70454bab7e$export$3082c88b693a5230);
    $parcel$export(module.exports, "default", ()=>$ab527e70454bab7e$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "baseDefaultProps", ()=>$ab527e70454bab7e$export$9b3029ee6188a41f);
    var $5uXOq = parcelRequire("5uXOq");
    var $99gkn;
    var $aOr3t;
    var $1u4q7;
    var $co1wz;
    var $1AZST;
    var $6G6w0;
    var $3wLoG;
    var $gtdbK;
    var $zbAs4;
    var $3B7oW;
    const $ab527e70454bab7e$export$3082c88b693a5230 = (appName, metricKey)=>{
        var emitSignal;
    };
    class $ab527e70454bab7e$export$2e2bcd8739ae039 extends (0, $5uXOq.Component) {
        static{
            this.defaultProps = {
                isExpectedToHaveSsr: false,
                withMarks: [],
                extra: {},
                emitOnAnimationFrame: false
            };
        }
        render() {
            const { appName: appName, metricKey: metricKey, extra: extra, metric: metric, emitOnAnimationFrame: emitOnAnimationFrame } = this.props;
            $ab527e70454bab7e$export$3082c88b693a5230(appName, metricKey);
            const isUFODefined = (0, (0, parcelRequire("co1wz")).ff)('uip.ufo') && (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).loadingPageLoadId !== 'UNKNOWN';
            if (isUFODefined) (0, (0, parcelRequire("zbAs4")).addUFOSSRDoneAsFMPToInteraction)();
            if (metric) {
                (0, (0, parcelRequire("gtdbK")).addBM3SSRDoneAsFMPToInteraction)(metric);
                (0, (0, parcelRequire("3B7oW")).trackBM3FeatureFlagsAccessed)(metric);
            }
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("99gkn")).default), {
                data: extra
            }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("aOr3t")).UFOBM3TimingsToUFO), {
                marks: metric?.getData()?.marks,
                timings: metric?.getData()?.config?.timings
            }), isUFODefined ? (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3wLoG")).StopUfo), {
                appName: appName,
                customData: extra,
                key: (0, (0, parcelRequire("1u4q7")).getInteractionId)().current
            }) : metric && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6G6w0")).StopBrowserMetrics3), {
                metric: metric,
                customData: extra,
                emitOnAnimationFrame: emitOnAnimationFrame
            }));
        }
    }
    const $ab527e70454bab7e$export$9b3029ee6188a41f = $ab527e70454bab7e$export$2e2bcd8739ae039.defaultProps;
});
parcelRegister("aOr3t", function(module, exports) {
    $parcel$export(module.exports, "getBm3Timings", ()=>$a3e67858f0c2f8da$export$f02972da195a43bb);
    $parcel$export(module.exports, "UFOBM3TimingsToUFO", ()=>$a3e67858f0c2f8da$export$30f0b5e5acd268bf);
    $parcel$export(module.exports, "addBM3TimingsToUFO", ()=>$a3e67858f0c2f8da$export$d93d677ef2f33c5c);
    var $5uXOq = parcelRequire("5uXOq");
    var $1tcYS;
    var $1u4q7;
    var $bFU4G;
    function $a3e67858f0c2f8da$export$f02972da195a43bb(marks, timingConfigs) {
        const bm3Timings = {};
        if (!marks || !timingConfigs) return bm3Timings;
        timingConfigs.forEach((item)=>{
            if (!item.startMark || !item.endMark) return;
            const startTime = marks[item.startMark];
            if (!startTime) return;
            const endTime = marks[item.endMark];
            if (!endTime) return;
            bm3Timings[item.key] = {
                startTime: startTime,
                endTime: endTime
            };
        });
        return bm3Timings;
    }
    function $a3e67858f0c2f8da$export$30f0b5e5acd268bf({ marks: marks, timings: timings }) {
        const interactionContext = (0, $5uXOq.useContext)((0, (0, parcelRequire("1tcYS")).default));
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)().current;
        (0, $5uXOq.useMemo)(()=>{
            if (interactionContext != null && interactionId != null && marks != null && timings != null) {
                const interactionType = (0, (0, parcelRequire("bFU4G")).getCurrentInteractionType)(interactionId);
                if (interactionType === 'press') return;
                const bm3Timings = $a3e67858f0c2f8da$export$f02972da195a43bb(marks, timings);
                interactionContext.addCustomTimings(bm3Timings);
            }
        }, [
            interactionContext,
            interactionId,
            marks,
            timings
        ]);
        return null;
    }
    function $a3e67858f0c2f8da$export$d93d677ef2f33c5c(marks, timingsConfig) {
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)().current;
        if (interactionId) {
            const interactionType = (0, (0, parcelRequire("bFU4G")).getCurrentInteractionType)(interactionId);
            if (interactionType === 'press') return;
            const bm3Timings = $a3e67858f0c2f8da$export$f02972da195a43bb(marks, timingsConfig);
            (0, (0, parcelRequire("bFU4G")).addCustomTiming)(interactionId, [], bm3Timings);
        }
    }
});
parcelRegister("6G6w0", function(module, exports) {
    $parcel$export(module.exports, "StopBrowserMetrics3", ()=>$84d0d168ebb35c8a$export$575e93c0f9905999);
    var $5uXOq = parcelRequire("5uXOq");
    var $1tcYS;
    var $21pfT;
    var $x9xyg;
    var $7N30e;
    var $co1wz;
    var $4dRjY;
    var $2SVPM;
    var $6b6Tk;
    const $84d0d168ebb35c8a$var$emitMetrics = (metric, customData, stopTime)=>{
        !(0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences') && (0, (0, parcelRequire("2SVPM")).stopInitialPageLoadTimingFromPerformanceMarkStart)('product', (0, (0, parcelRequire("4dRjY")).PRODUCT_START_MARK), true);
        (0, (0, parcelRequire("2SVPM")).setInitialPageLoadTimingFromPerformanceMarks)('batch-js-heritage.eval', 'jira.heritage_batch_file_eval:start', 'jira.heritage_batch_file_eval:end');
        if (stopTime !== undefined) {
            if (!(0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task')) {
                metric.mark((0, $ce556f125dee8174$export$99c08ec92759c71c));
                (0, (0, parcelRequire("x9xyg")).setMark)((0, $ce556f125dee8174$export$99c08ec92759c71c));
                (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI_RAF', 'BROWSER_METRICS_TTI_START', (0, $ce556f125dee8174$export$99c08ec92759c71c));
            }
            metric.stop({
                customData: customData,
                stopTime: stopTime
            });
        } else metric.stop({
            customData: customData
        });
        if ((0, (0, parcelRequire("6b6Tk")).isAutomaticExposureCollectionEnabled)()) (0, (0, parcelRequire("7N30e")).stopLowPriorityEventDelay)();
    };
    class $84d0d168ebb35c8a$export$575e93c0f9905999 extends (0, $5uXOq.Component) {
        componentDidMount() {
            const { metric: metric, customData: customData, emitOnAnimationFrame: emitOnAnimationFrame } = this.props;
            if (!metric) return;
            const start = (0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task') ? (0, (0, parcelRequire("21pfT")).browserMetrics).getPageLoadMetric().getData()?.start || 0 : 0;
            (0, (0, parcelRequire("x9xyg")).setMark)('BROWSER_METRICS_TTI_START', {
                startTime: start
            });
            (0, (0, parcelRequire("x9xyg")).setMark)('BROWSER_METRICS_TTI_END');
            (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI', 'BROWSER_METRICS_TTI_START', 'BROWSER_METRICS_TTI_END');
            const bm3Data = metric.getData();
            if (emitOnAnimationFrame) {
                const stopTime = performance.now();
                (0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences') && (0, (0, parcelRequire("2SVPM")).stopInitialPageLoadTimingFromPerformanceMarkStart)('product', (0, (0, parcelRequire("4dRjY")).PRODUCT_START_MARK), true);
                if (bm3Data) this.context?.addApdex({
                    key: bm3Data.key,
                    startTime: bm3Data.start,
                    stopTime: stopTime
                });
                if (typeof queueMicrotask !== 'undefined') queueMicrotask(()=>{
                    (0, (0, parcelRequire("x9xyg")).setMark)('BROWSER_METRICS_TTI_TASK_END');
                    (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI_TASK', 'BROWSER_METRICS_TTI_START', 'BROWSER_METRICS_TTI_TASK_END');
                });
                requestAnimationFrame(()=>{
                    if ((0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task')) {
                        metric.mark((0, $ce556f125dee8174$export$99c08ec92759c71c));
                        (0, (0, parcelRequire("x9xyg")).setMark)((0, $ce556f125dee8174$export$99c08ec92759c71c));
                        (0, (0, parcelRequire("x9xyg")).setMeasure)('BROWSER_METRICS_TTI_RAF', 'BROWSER_METRICS_TTI_START', (0, $ce556f125dee8174$export$99c08ec92759c71c));
                    }
                    if ((0, (0, parcelRequire("co1wz")).ff)('bm3.wait-for-long-task')) setTimeout(()=>{
                        $84d0d168ebb35c8a$var$emitMetrics(metric, customData, stopTime);
                    }, 500);
                    else $84d0d168ebb35c8a$var$emitMetrics(metric, customData, stopTime);
                });
            } else {
                if (bm3Data) this.context?.addApdex({
                    key: bm3Data.key,
                    startTime: bm3Data.start,
                    stopTime: performance.now()
                });
                (0, (0, parcelRequire("co1wz")).ff)('bm3.emit-on-raf.top-experiences') && (0, (0, parcelRequire("2SVPM")).stopInitialPageLoadTimingFromPerformanceMarkStart)('product', (0, (0, parcelRequire("4dRjY")).PRODUCT_START_MARK), true);
                $84d0d168ebb35c8a$var$emitMetrics(metric, customData);
            }
        }
        static{
            this.contextType = (0, (0, parcelRequire("1tcYS")).default);
        }
        render() {
            return null;
        }
    }
});
parcelRegister("4dRjY", function(module, exports) {
    $parcel$export(module.exports, "PRODUCT_START_MARK", ()=>$9953c23a4f71adac$export$8e54fca0a680fdfd);
    $parcel$export(module.exports, "MarkProductStart", ()=>$9953c23a4f71adac$export$2a999c5f84501025);
    var $x9xyg;
    const $9953c23a4f71adac$export$8e54fca0a680fdfd = 'PRODUCT_START';
    const $9953c23a4f71adac$export$2a999c5f84501025 = ()=>{
        if (!false) {
            performance.clearMarks($9953c23a4f71adac$export$8e54fca0a680fdfd);
            (0, (0, parcelRequire("x9xyg")).setMark)($9953c23a4f71adac$export$8e54fca0a680fdfd);
        }
        return null;
    };
});
const $ce556f125dee8174$export$56940a9488e85199 = 'jira.fe.spa-';
const $ce556f125dee8174$export$45c4b6bf7935c69a = `${$ce556f125dee8174$export$56940a9488e85199}start`;
const $ce556f125dee8174$export$135c96ab5afee429 = 'ssr.spa.rendered';
const $ce556f125dee8174$export$ff834eccc35b9089 = 'appBundleReady';
const $ce556f125dee8174$export$86b05e34d462ead8 = 'prefetchApiReady';
const $ce556f125dee8174$export$d6a604a7d9a390fb = 'prefetchApiStart';
const $ce556f125dee8174$export$1211adfff27a31fe = 'issueViewInitialDataReady';
const $ce556f125dee8174$export$3ae43cc1782d058f = 'jswRenderStart';
const $ce556f125dee8174$export$363f6703ffa600be = 'jswCreateReduxStoreStart';
const $ce556f125dee8174$export$e87b74567b79c8 = 'jswCreateReduxStoreEnd';
const $ce556f125dee8174$export$99fe642459a8b02 = 'jswTransformCriticalDataStart';
const $ce556f125dee8174$export$ab1f5705fc8e0571 = 'jswTransformCriticalDataEnd';
const $ce556f125dee8174$export$8c3a9046da692c35 = 'jswReduxSoftwareAppLoadedStart';
const $ce556f125dee8174$export$5aa497d1efdcc5bf = 'jswReduxSoftwareAppLoadedEnd';
const $ce556f125dee8174$export$99c08ec92759c71c = 'tti-raf-mark';
const $ce556f125dee8174$export$48552baa9db8ce1d = 'tti-raf';
parcelRegister("3wLoG", function(module, exports) {
    $parcel$export(module.exports, "StopUfo", ()=>$bc9c50f4292c4530$export$d91154f099475c5);
    var $5uXOq = parcelRequire("5uXOq");
    var $1tcYS;
    var $1AZST;
    class $bc9c50f4292c4530$export$d91154f099475c5 extends (0, $5uXOq.Component) {
        componentDidMount() {
            const { customData: customData, appName: appName } = this.props;
            (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).success({
                force: true,
                metadata: customData
            });
            const name = appName || (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).loadingPageLoadId;
            if (name) this.context?.addApdex({
                key: name,
                stopTime: performance.now()
            });
        }
        static{
            this.contextType = (0, (0, parcelRequire("1tcYS")).default);
        }
        render() {
            return null;
        }
    }
});
parcelRegister("gtdbK", function(module, exports) {
    $parcel$export(module.exports, "addBM3SSRDoneAsFMPToInteraction", ()=>$9088c71f7c9189f4$export$bf239ac2bfb04967);
    var $1u4q7;
    var $bFU4G;
    function $9088c71f7c9189f4$export$bf239ac2bfb04967(metric) {
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = metric.getData();
        const config = data?.config;
        if (config && data?.isInitial) (0, (0, parcelRequire("bFU4G")).addMetadata)(currentInteractionId, {
            __legacy__bm3ConfigSSRDoneAsFmp: config.ssr?.doneAsFmp || false
        });
    }
});
parcelRegister("zbAs4", function(module, exports) {
    $parcel$export(module.exports, "addUFOSSRDoneAsFMPToInteraction", ()=>$30d390cd1767d57f$export$e63862cd888fe05a);
    var $1u4q7;
    var $bFU4G;
    var $1AZST;
    var $49z18;
    async function $30d390cd1767d57f$export$e63862cd888fe05a() {
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = await (0, (0, parcelRequire("1AZST")).GlobalPageLoadExperience).exportData();
        const fmpMark = data.metrics.marks.find((mark)=>mark.name === 'fmp') || (0, (0, parcelRequire("49z18")).payloadPublisher).ssr?.getDoneMark();
        if (data.initial && fmpMark) (0, (0, parcelRequire("bFU4G")).addMetadata)(currentInteractionId, {
            __legacy__ufoConfigSSRDoneAsFmp: true
        });
    }
});
parcelRegister("3B7oW", function(module, exports) {
    $parcel$export(module.exports, "trackBM3FeatureFlagsAccessed", ()=>$afa764d83f81f55e$export$f00f1e874ce869e9);
    var $1u4q7;
    var $y7eb2;
    const $afa764d83f81f55e$var$startsAsObject = (value)=>{
        try {
            if (value.charAt(0) === '{') return true;
        } catch (e) {
            return false;
        }
        return false;
    };
    const $afa764d83f81f55e$var$isObject = (value)=>value && typeof value === 'object' && value.constructor === Object;
    const $afa764d83f81f55e$var$isString = (input)=>{
        let result;
        try {
            result = typeof input === 'string' || input instanceof String;
        } catch (err) {
            result = false;
        }
        return result;
    };
    const $afa764d83f81f55e$export$7b4abba432e57989 = (value)=>$afa764d83f81f55e$var$isString(value) && $afa764d83f81f55e$var$startsAsObject(value) || $afa764d83f81f55e$var$isObject(value);
    const $afa764d83f81f55e$export$fcc03ee4c944962f = (featureFlagValue)=>$afa764d83f81f55e$export$7b4abba432e57989(featureFlagValue) ? 'non_boolean' : featureFlagValue;
    const $afa764d83f81f55e$export$d2818866b4acbea4 = new Map();
    const $afa764d83f81f55e$export$f00f1e874ce869e9 = (metric)=>{
        const interactionId = (0, (0, parcelRequire("1u4q7")).getInteractionId)();
        const currentInteractionId = interactionId.current;
        if (!currentInteractionId) return;
        const data = metric.getData();
        const config = data?.config;
        if (config && config?.featureFlags?.length) {
            const allFeatureFlags = (0, (0, parcelRequire("y7eb2")).default)();
            config.featureFlags.forEach((featureFlagName)=>{
                const value = allFeatureFlags[featureFlagName];
                if (value === undefined) return;
                const featureFlagValue = $afa764d83f81f55e$export$fcc03ee4c944962f(value);
                $afa764d83f81f55e$export$d2818866b4acbea4.set(featureFlagName, featureFlagValue);
            });
        }
    };
});
parcelRegister("fzbpE", function(module, exports) {
    $parcel$export(module.exports, "getApdexAttributes", ()=>$8e83a1a1ba3a856a$export$aea4445805b46987);
    $parcel$export(module.exports, "getBM3Metric", ()=>$8e83a1a1ba3a856a$export$8c234930b6ba46bd);
    var $65Ltd;
    var $dJl1T;
    var $dZw8H;
    var $2FBLc;
    const $8e83a1a1ba3a856a$var$LOCATION = 'spa.rapidboard';
    const $8e83a1a1ba3a856a$var$PREFETCH_USER_LOCATIONS = new Set([
        `${(0, (0, parcelRequire("65Ltd")).ROUTE_GROUPS_DIRECTORIES)}:${(0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_PROJECTS_DIRECTORY)}`,
        `${(0, (0, parcelRequire("65Ltd")).ROUTE_GROUPS_ISSUE)}:${(0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_ISSUE)}`
    ]);
    const $8e83a1a1ba3a856a$var$isPrefetchRoute = ()=>{
        const lastUserLocation = (0, (0, parcelRequire("dZw8H")).getSpaStateLastUserLocation)();
        return $8e83a1a1ba3a856a$var$PREFETCH_USER_LOCATIONS.has(lastUserLocation);
    };
    const $8e83a1a1ba3a856a$export$aea4445805b46987 = (query)=>{
        const { GH: GH } = window;
        try {
            const { modal: modal, selectedIssue: selectedIssue, view: view, quickFilter: quickFilter = '', assignee: assignee = '' } = query;
            const defaultAttributes = {
                isDetailView: (modal === 'detail' || view === 'detail' || view === 'planning') && selectedIssue !== '',
                isQuickFilterView: quickFilter !== '' || assignee !== '',
                isPrefetchRoute: $8e83a1a1ba3a856a$var$isPrefetchRoute()
            };
            return GH && GH.RapidBoard && GH.RapidBoard.State ? {
                ...defaultAttributes,
                ...GH.RapidBoard.State.getApdexAttributes()
            } : defaultAttributes;
        } catch (err) {
            (0, (0, parcelRequire("dJl1T")).default).safeWarnWithoutCustomerData($8e83a1a1ba3a856a$var$LOCATION, 'Error getting rapidboard apdex attributes', err);
            return {};
        }
    };
    const $8e83a1a1ba3a856a$export$8c234930b6ba46bd = (routeName)=>{
        switch(routeName){
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BACKLOG:
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG:
                return 0, (0, parcelRequire("2FBLc")).classicBacklog;
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BOARD:
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_USER_BOARD:
                return 0, (0, parcelRequire("2FBLc")).classicBoard;
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_REPORT:
            case 0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_USER_REPORT:
                return 0, (0, parcelRequire("2FBLc")).classicReport;
            default:
                return undefined;
        }
    };
});
parcelRegister("2FBLc", function(module, exports) {
    $parcel$export(module.exports, "classicBoard", ()=>$b7f773fc933a8da6$export$d4f7403fc0b93706);
    $parcel$export(module.exports, "classicBacklog", ()=>$b7f773fc933a8da6$export$cda6491ffae325ef);
    $parcel$export(module.exports, "classicReport", ()=>$b7f773fc933a8da6$export$f3b1b3a5ead4897d);
    $parcel$export(module.exports, "classicBoardInsightButton", ()=>$b7f773fc933a8da6$export$75e19a7330a91f11);
    var $dGrdR;
    const $b7f773fc933a8da6$export$d4f7403fc0b93706 = (0, (0, parcelRequire("dGrdR")).metrics).pageLoad({
        key: 'classic-board',
        featureFlags: [
            'jsw.classic.rapid.board.refresh.button',
            'jsw.classic.board.api-calls.modern-frontend'
        ]
    });
    const $b7f773fc933a8da6$export$cda6491ffae325ef = (0, (0, parcelRequire("dGrdR")).metrics).pageLoad({
        key: 'classic-backlog',
        featureFlags: [
            'jsw.classic.board.api-calls.modern-frontend'
        ]
    });
    const $b7f773fc933a8da6$export$f3b1b3a5ead4897d = (0, (0, parcelRequire("dGrdR")).metrics).pageLoad({
        key: 'classic-report'
    });
    const $b7f773fc933a8da6$export$75e19a7330a91f11 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: (0, $dccdd050fc806f24$export$6c88c619664a31e5)
    });
});
const $dccdd050fc806f24$export$6c88c619664a31e5 = 'jira.frontend.classic-board.insight-button';
const $dccdd050fc806f24$export$e4716a51ada35df4 = 'LOAD_START_INSIGHT_BUTTON_MARK_KEY';
const $dccdd050fc806f24$export$f600aeb37520c5ee = 'READY_START_INSIGHT_BUTTON_MARK_KEY';
parcelRegister("6ST1G", function(module, exports) {
    $parcel$export(module.exports, "PrettyUrlRedirect", ()=>$65dd3b9f963d37da$export$e8ad4dd123297db2);
    var $5uXOq = parcelRequire("5uXOq");
    var $3T6Xo = parcelRequire("3T6Xo");
    var $ebyGx = parcelRequire("ebyGx");
    var $4DPZY;
    var $7OYJs;
    var $doZTA;
    const $65dd3b9f963d37da$var$LEGACY_PATH = '/secure/RapidBoard.jspa';
    const $65dd3b9f963d37da$export$e8ad4dd123297db2 = ()=>{
        const [{ query: query, location: location }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const [{ data: data, error: error }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        if (!data || error) return null;
        const isLegacyRoute = location.pathname === $65dd3b9f963d37da$var$LEGACY_PATH;
        if (!isLegacyRoute) return null;
        if (query.locationless) return null;
        const { scope: scope } = data;
        const boardId = scope?.id ?? '';
        const prettyUrlQueryParams = {
            ...query
        };
        const mode = prettyUrlQueryParams.view || '';
        let url = '';
        if (!data.project) url += `/jira/people/${data.user.accountId}`;
        else url += `/jira/software/c/projects/${data.project.key}`;
        url += `/boards/${boardId}`;
        if (mode.includes('planning')) url += '/backlog';
        else if (mode.includes('reporting')) url += `/reports/${(0, ($parcel$interopDefault($3T6Xo)))(prettyUrlQueryParams.chart)}`;
        delete prettyUrlQueryParams.view;
        delete prettyUrlQueryParams.chart;
        delete prettyUrlQueryParams.rapidView;
        delete prettyUrlQueryParams.projectKey;
        if (mode === 'planning') prettyUrlQueryParams.view = 'detail';
        const queryParams = (0, $ebyGx.stringify)(prettyUrlQueryParams);
        url += queryParams ? `?${queryParams}` : '';
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7OYJs")).ScreenRedirect), {
            to: url,
            persistSearchParams: false
        });
    };
});
parcelRegister("he4ys", function(module, exports) {
    $parcel$export(module.exports, "RapidBoardApps", ()=>$249029a2c2afa33f$export$9c5ad1ed47ff812a);
    var $5uXOq = parcelRequire("5uXOq");
    var $heNNl;
    var $lfTZh;
    var $kxZPA;
    var $6Lg6A;
    const $249029a2c2afa33f$var$LazyRapidApps = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("8CZdO")), {
        ssr: false,
        moduleId: "./src/packages/classic-software/src/ui/rapidboard-app/index.tsx"
    });
    const $249029a2c2afa33f$export$9c5ad1ed47ff812a = ({ shouldLoad: shouldLoad })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: "async.rapidboard-button.bridge.defer",
            packageName: "jiraClassicBoardAndBacklog"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6Lg6A")).LazyWait), {
            until: shouldLoad
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "rapid-apps",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($249029a2c2afa33f$var$LazyRapidApps, null))));
});
parcelRegister("8CZdO", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("3CBRe").then(()=>parcelRequire('5xA4E'));
});
parcelRegister("2lwYH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b8f0036180c51854$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gAeCk;
    var $heNNl;
    var $lfTZh;
    var $kxZPA;
    var $6Lg6A;
    const $b8f0036180c51854$var$IssueView = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("7QQW9")), {
        ssr: false,
        moduleId: "./src/packages/classic-software/src/ui/rapidboard-app/issue-view/index.tsx"
    });
    var $b8f0036180c51854$export$2e2bcd8739ae039 = ({ shouldLoad: shouldLoad })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: "async.issue-view.bridge.defer",
            packageName: "jiraClassicBoardAndBacklog"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6Lg6A")).LazyWait), {
            until: shouldLoad
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "issue-view",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gAeCk")).default), {
                metricKey: (0, $93e7e619ff4805e8$export$94060b41ddf911d)
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($b8f0036180c51854$var$IssueView, null))));
});
const $93e7e619ff4805e8$export$4068827414983759 = 'jira.frontend.classic-board.issue-view';
const $93e7e619ff4805e8$export$94060b41ddf911d = 'LOAD_START_ISSUE_VIEW_RAPIDBOARD_MARK_KEY';
const $93e7e619ff4805e8$export$3b8d28547514aa88 = 'READY_START_ISSUE_VIEW_RAPIDBOARD_MARK_KEY';
parcelRegister("7QQW9", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("kfXhT"),
        $6fhBS("2Lfdl"),
        $6fhBS("dsiAd"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("5Uz7Q")
    ]).then(()=>parcelRequire('24dCL'));
});
parcelRegister("2mSJU", function(module, exports) {
    $parcel$export(module.exports, "RapidBoardConfig", ()=>$fd7bc1143a91f6aa$export$86140101e99ba5df);
    var $5uXOq = parcelRequire("5uXOq");
    var $heNNl;
    var $lfTZh;
    var $kxZPA;
    var $6Lg6A;
    const $fd7bc1143a91f6aa$var$LazyRapidApps = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("8A3Um")), {
        ssr: false,
        moduleId: "./src/packages/classic-software/src/ui/rapidboard-config/index.tsx"
    });
    const $fd7bc1143a91f6aa$export$86140101e99ba5df = ({ shouldLoad: shouldLoad })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: "async.rapidboard-config.bridge.defer",
            packageName: "jira.insights"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("6Lg6A")).LazyWait), {
            until: shouldLoad
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "rapid-board-config",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($fd7bc1143a91f6aa$var$LazyRapidApps, null))));
});
parcelRegister("8A3Um", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("6g2Jc").then(()=>parcelRequire('jTnst'));
});
parcelRegister("j3a01", function(module, exports) {
    $parcel$export(module.exports, "RapidboardContent", ()=>$c596f0fc84c217e3$export$da1744159516b653);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    var $jS4lj;
    var $65Ltd;
    var $iepkZ;
    var $6jY6G;
    var $4DPZY;
    var $3U6yu;
    var $baPf2;
    var $7ApQ5;
    var $iGsri;
    var $eJfvV;
    var $03jjx;
    var $dkttM;
    var $67qQJ;
    var $6ot3i;
    var $k1boH;
    var $7EqN2;
    var $2wpxU;
    const $c596f0fc84c217e3$export$da1744159516b653 = (0, $5uXOq.memo)(({ isRapidBoardsReady: isRapidBoardsReady, isBentoEnabled: isBentoEnabled, mode: mode })=>{
        const route = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        const [{ data: data }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        const projectData = data;
        const projectKey = projectData?.project?.key;
        const projectId = projectData?.project?.id;
        (0, (0, parcelRequire("iGsri")).useGICSidebarForcer)();
        const Wrapper = route.name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_SERVICEDESK_BOARDS) ? (0, (0, parcelRequire("67qQJ")).LicenseCheckGeneric) : (0, (0, parcelRequire("6ot3i")).LicenseCheck);
        return (0, ($parcel$interopDefault($5uXOq))).createElement(Wrapper, null, !isRapidBoardsReady && route.skeleton && (0, ($parcel$interopDefault($5uXOq))).createElement(route.skeleton, null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("k1boH")).LocationlessDialog), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7ApQ5")).KeyboardShortcuts), {
            isBentoEnabled: isBentoEnabled
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($c596f0fc84c217e3$export$f0e43f4dfe473c20, {
            shouldHide: !isRapidBoardsReady
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3U6yu")).StorageLimitsBanner), null), isRapidBoardsReady && ((0, (0, parcelRequire("eJfvV")).isPlanningMode)(mode) || (0, (0, parcelRequire("eJfvV")).isWorkMode)(mode)) && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("baPf2")).UpFlowPersistentUpgradeBanner), {
            isBannerEnabled: true
        }), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("03jjx")).BreadcrumbsWrapper), {
            "data-testid": "rapidboard-breadcrumbs"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("jS4lj")).default), null)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("2wpxU")).StyleReset), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dkttM")).Breadcrumbs), null), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7EqN2")).RapidBoardRoot), null), projectKey != null && projectId != null && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iepkZ")).AsyncLaunchToolchainSpotlight), {
            projectId: projectId,
            projectKey: projectKey
        })))));
    });
    const $c596f0fc84c217e3$export$f0e43f4dfe473c20 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        const { shouldHide: shouldHide, ...__cmpldp } = __cmplp;
        return (0, ($parcel$interopDefault($5uXOq))).createElement(C, {
            ...__cmpldp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "",
                __cmplp.shouldHide ? "_1e0cglyw" : "_1e0c1ule",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("iepkZ", function(module, exports) {
    $parcel$export(module.exports, "AsyncLaunchToolchainSpotlight", ()=>$744e85c113b2a238$export$9ef3659da7a0bb31);
    var $5uXOq = parcelRequire("5uXOq");
    var $lfTZh;
    var $kxZPA;
    const $744e85c113b2a238$var$ContainerLinking = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("4slvf")), {
        moduleId: "./src/packages/devops/launch-toolchain-spotlight/src/ui/main.tsx"
    });
    const $744e85c113b2a238$export$9ef3659da7a0bb31 = ({ projectId: projectId, projectKey: projectKey })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "cotainer-linking",
            fallback: null
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($744e85c113b2a238$var$ContainerLinking, {
            projectId: projectId,
            projectKey: projectKey
        }));
});
parcelRegister("4slvf", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("8gDqy")
    ]).then(()=>parcelRequire('ikW1t'));
});
parcelRegister("3U6yu", function(module, exports) {
    $parcel$export(module.exports, "StorageLimitsBanner", ()=>$281484b8f4d3a170$export$3250e2c90ae56dd9);
    var $5uXOq = parcelRequire("5uXOq");
    var $heNNl;
    var $lfTZh;
    var $kxZPA;
    const $281484b8f4d3a170$var$LazyStorageLimitBanner = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("54Nx6")), {
        ssr: false,
        moduleId: "./src/packages/growth/storage-limits/src/ui/index.tsx"
    });
    const $281484b8f4d3a170$export$3250e2c90ae56dd9 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: (0, $59503b90ac06f221$export$5dec3c05c01dac30),
            packageName: (0, $59503b90ac06f221$export$b8b9d90f9e5bd72b),
            teamName: "growth-tako",
            render: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "storage-limits-banner",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($281484b8f4d3a170$var$LazyStorageLimitBanner, props)));
});
const $59503b90ac06f221$export$bab748d4654ac4a5 = 0.9;
const $59503b90ac06f221$export$b8b9d90f9e5bd72b = 'jiraGrowthStorageLimits';
const $59503b90ac06f221$export$df9953733f41292a = 'storageLimitsBanner';
const $59503b90ac06f221$export$5dec3c05c01dac30 = 'asyncStorageLimitsBanner';
const $59503b90ac06f221$export$3aad3de2cc377789 = 'jira_software_free_is_ccp_billing_admins';
parcelRegister("54Nx6", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("8o7lR").then(()=>parcelRequire('iMYa9'));
});
parcelRegister("baPf2", function(module, exports) {
    $parcel$export(module.exports, "UpFlowPersistentUpgradeBanner", ()=>$56fd693f7a702d43$export$39832316603e4613);
    var $5uXOq = parcelRequire("5uXOq");
    var $heNNl;
    var $lfTZh;
    var $kxZPA;
    var $eSynq;
    const $56fd693f7a702d43$var$LazyUpFlowPersistentUpgradeBanner = (0, (0, parcelRequire("kxZPA")).lazyAfterPaint)(()=>(parcelRequire("8QRNO")), {
        ssr: false,
        moduleId: "./src/packages/growth/up-flow/persistent-upgrade-banner/src/main.tsx"
    });
    const $56fd693f7a702d43$export$39832316603e4613 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: (0, (0, parcelRequire("eSynq")).PERSISTENT_UPGRADE_ANALYTICS_SOURCE),
            packageName: (0, (0, parcelRequire("eSynq")).PERSISTENT_UPGRADE_TOUCHPOINT_BANNER),
            render: ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "up-flow-persistent-upgrade-banner",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($56fd693f7a702d43$var$LazyUpFlowPersistentUpgradeBanner, props)));
});
parcelRegister("eSynq", function(module, exports) {
    $parcel$export(module.exports, "PERSISTENT_UPGRADE_PRE_TRIAL_FREE_EDITION", ()=>$4cf197dc11aa7836$export$75e961f06f21e171);
    $parcel$export(module.exports, "PERSISTENT_UPGRADE_ANALYTICS_SOURCE", ()=>$4cf197dc11aa7836$export$bebf43b5d37dab0);
    $parcel$export(module.exports, "PERSISTENT_UPGRADE_ANALYTICS_UPGRADE_FLOW", ()=>$4cf197dc11aa7836$export$cbfd75a8d4af5ce5);
    $parcel$export(module.exports, "PERSISTENT_UPGRADE_UPFLOW_MODAL_FLOW", ()=>$4cf197dc11aa7836$export$a2684b22124cc502);
    $parcel$export(module.exports, "PERSISTENT_UPGRADE_TOUCHPOINT_BANNER", ()=>$4cf197dc11aa7836$export$e54a73280ddbd89);
    $parcel$export(module.exports, "PERSISTENT_UPGRADE_TOUCHPOINT_BANNER_VIEWED", ()=>$4cf197dc11aa7836$export$96913d5371f745ad);
    $parcel$export(module.exports, "MAX_CADENCE_INDEX", ()=>$4cf197dc11aa7836$export$b8743c23616e54ee);
    $parcel$export(module.exports, "KEY_USER_PROPERTIES_JSW", ()=>$4cf197dc11aa7836$export$6a69b5601e7d435);
    $parcel$export(module.exports, "KEY_USER_PROPERTIES_JSM", ()=>$4cf197dc11aa7836$export$364c2b43630f5cb5);
    $parcel$export(module.exports, "BANNER_CADENCE_INFO", ()=>$4cf197dc11aa7836$export$ec6ce1b450620e5c);
    $parcel$export(module.exports, "SEND_OPERATIONAL_EVENT", ()=>$4cf197dc11aa7836$export$8b4682bf35504518);
    $parcel$export(module.exports, "API_SUCCESS", ()=>$4cf197dc11aa7836$export$6d01cae610456af2);
    $parcel$export(module.exports, "API_FAIL", ()=>$4cf197dc11aa7836$export$6a238c78062d3b8d);
    $parcel$export(module.exports, "GET_USER_PROPERTY_API_CALL", ()=>$4cf197dc11aa7836$export$d094ee3fcda994eb);
    $parcel$export(module.exports, "GET_USER_PROPERTY_API_CALL_SUCCESS", ()=>$4cf197dc11aa7836$export$8357ebd56fd8e5ea);
    $parcel$export(module.exports, "GET_USER_PROPERTY_API_CALL_FAIL", ()=>$4cf197dc11aa7836$export$252ee5e4ed4943dc);
    $parcel$export(module.exports, "SET_USER_PROPERTY_API_CALL", ()=>$4cf197dc11aa7836$export$f3310dd86ae4284b);
    $parcel$export(module.exports, "SET_USER_PROPERTY_API_CALL_SUCCESS", ()=>$4cf197dc11aa7836$export$5e4635b476d2b497);
    $parcel$export(module.exports, "SET_USER_PROPERTY_API_CALL_FAIL", ()=>$4cf197dc11aa7836$export$b49d6161d5ea4a0a);
    $parcel$export(module.exports, "PERSISTENT_PRETRIAL_BANNER_CADENCES_MS", ()=>$4cf197dc11aa7836$export$55aa1fd7f74b53);
    const $4cf197dc11aa7836$export$75e961f06f21e171 = 'pre-trial';
    const $4cf197dc11aa7836$export$bebf43b5d37dab0 = 'persistentUpgrade';
    const $4cf197dc11aa7836$export$cbfd75a8d4af5ce5 = 'upgradeFlow';
    const $4cf197dc11aa7836$export$a2684b22124cc502 = 'persistent-upgrade';
    const $4cf197dc11aa7836$export$e54a73280ddbd89 = 'persistentUpgradeBanner';
    const $4cf197dc11aa7836$export$96913d5371f745ad = 'persistentUpgradeBanner viewed';
    const $4cf197dc11aa7836$export$b8743c23616e54ee = 3;
    const $4cf197dc11aa7836$export$6a69b5601e7d435 = 'growth-puet-banner-cadence';
    const $4cf197dc11aa7836$export$364c2b43630f5cb5 = 'growth-puet-banner-cadence-jsm';
    const $4cf197dc11aa7836$export$ec6ce1b450620e5c = 'Banner Cadence Info';
    const $4cf197dc11aa7836$export$8b4682bf35504518 = 'sendOperationalEvent';
    const $4cf197dc11aa7836$export$6d01cae610456af2 = 'success';
    const $4cf197dc11aa7836$export$6a238c78062d3b8d = 'failed';
    const $4cf197dc11aa7836$export$d094ee3fcda994eb = 'getUserPropertyApiCall';
    const $4cf197dc11aa7836$export$8357ebd56fd8e5ea = 'getUserPropertyApiCall success';
    const $4cf197dc11aa7836$export$252ee5e4ed4943dc = 'getUserPropertyApiCall failed';
    const $4cf197dc11aa7836$export$f3310dd86ae4284b = 'setUserPropertyApiCall';
    const $4cf197dc11aa7836$export$5e4635b476d2b497 = 'setUserPropertyApiCall success';
    const $4cf197dc11aa7836$export$b49d6161d5ea4a0a = 'setUserPropertyApiCall failed';
    const $4cf197dc11aa7836$export$55aa1fd7f74b53 = [
        0,
        604800000,
        1814400000,
        3628800000
    ];
});
parcelRegister("8QRNO", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("PpXIG").then(()=>parcelRequire('iLKFx'));
});
parcelRegister("7ApQ5", function(module, exports) {
    $parcel$export(module.exports, "KeyboardShortcuts", ()=>$72652c60c4b07585$export$5176144edf2bef2b);
    var $5uXOq = parcelRequire("5uXOq");
    var $3Tty1;
    const $72652c60c4b07585$var$invokeGhShortcut = (shortcutFunc, params)=>{
        const { GH: GH } = window;
        if (GH && GH.Shortcut) GH.Shortcut[shortcutFunc](...params || []);
    };
    const $72652c60c4b07585$var$invokeGlobalShortcut = (shortcutFunc, params)=>{
        const { gh: gh } = window;
        if (gh && gh.app && gh.app.globalkeyboardshortcuts) gh.app.globalkeyboardshortcuts[shortcutFunc](...params || []);
    };
    const $72652c60c4b07585$var$baseKeyMap = {
        'g g': {
            callback: ()=>{
                const { JIRA: JIRA, requestAnimationFrame: requestAnimationFrame } = window;
                requestAnimationFrame(()=>JIRA?.Shifter?.show?.());
            }
        },
        'g h': {
            callback: ()=>$72652c60c4b07585$var$invokeGlobalShortcut('goToAgile')
        },
        'g a': {
            callback: ()=>$72652c60c4b07585$var$invokeGlobalShortcut('goToAgile')
        },
        's t': {
            callback: ()=>$72652c60c4b07585$var$invokeGlobalShortcut('sendToTop', [
                    'KeyboardShortcut',
                    null,
                    'st'
                ])
        },
        's b': {
            callback: ()=>$72652c60c4b07585$var$invokeGlobalShortcut('sendToBottom', [
                    'KeyboardShortcut',
                    null,
                    'sb'
                ])
        },
        b: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('focusBoardSearch', [
                    'b'
                ])
        },
        n: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('nextBox', [
                    'n'
                ])
        },
        p: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('previousBox', [
                    'p'
                ])
        },
        t: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('toggleIssue', [
                    't'
                ])
        },
        j: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('nextIssue', [
                    'j'
                ])
        },
        k: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('previousIssue', [
                    'k'
                ])
        },
        o: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('viewIssue')
        },
        e: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('editIssue', [
                    'e'
                ])
        },
        '.': {
            callback: ()=>{
                const { JIRA: JIRA, GH: GH, requestAnimationFrame: requestAnimationFrame } = window;
                if (GH?.IssueOperationShortcuts?.getSelectedIssueKey?.()) requestAnimationFrame(()=>$72652c60c4b07585$var$invokeGhShortcut('showOperationsDialog', [
                        '.'
                    ]));
                else requestAnimationFrame(()=>JIRA?.Shifter?.show?.());
            }
        },
        z: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('projector', [
                    'z'
                ])
        },
        '-': {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('toggleSwimlanes', [
                    '-'
                ])
        },
        '1': {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('planMode', [
                    '1'
                ])
        },
        '2': {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('workMode', [
                    '2'
                ])
        },
        '3': {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('reportMode', [
                    '3'
                ])
        }
    };
    const $72652c60c4b07585$var$legacyIssueKeyMap = {
        ...$72652c60c4b07585$var$baseKeyMap,
        a: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('assignIssue', [
                    'a'
                ])
        },
        i: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('assignIssueToMe', [
                    'i'
                ])
        },
        m: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('commentIssue', [
                    'm'
                ])
        },
        l: {
            callback: ()=>$72652c60c4b07585$var$invokeGhShortcut('editIssueLabels', [
                    'l'
                ])
        }
    };
    const $72652c60c4b07585$export$5176144edf2bef2b = ({ children: children, isBentoEnabled: isBentoEnabled })=>{
        const keyMap = isBentoEnabled ? $72652c60c4b07585$var$baseKeyMap : $72652c60c4b07585$var$legacyIssueKeyMap;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("3Tty1")).default), {
            keyMap: keyMap
        }), children);
    };
    var $72652c60c4b07585$export$2e2bcd8739ae039 = $72652c60c4b07585$export$5176144edf2bef2b;
});
parcelRegister("3Tty1", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$6ba57d5dc6c0de0a$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $9IeM0;
    var $4lkOX;
    var $h0GOb;
    var $k4r7E;
    var $9fuVs;
    var $kspvB;
    let $6ba57d5dc6c0de0a$var$shortcutsCount = 0;
    let $6ba57d5dc6c0de0a$var$subscription = null;
    const $6ba57d5dc6c0de0a$var$ShortcutsSubscriber = false ? ()=>null : ({ keyMap: keyMap, label: label, location: location })=>{
        const parentRegistry = (0, $5uXOq.useContext)((0, (0, parcelRequire("9fuVs")).ShortcutRegistryContext)) || (0, (0, parcelRequire("k4r7E")).rootRegistry);
        const addKeyMap = (0, $5uXOq.useCallback)(()=>parentRegistry.addKeyMap(()=>({
                    keyMap: (0, (0, parcelRequire("kspvB")).bindKeyMapWithErrorHandling)(keyMap, location),
                    label: label
                })), [
            label,
            parentRegistry,
            keyMap,
            location
        ]);
        (0, $5uXOq.useEffect)(()=>{
            $6ba57d5dc6c0de0a$var$shortcutsCount += 1;
            const removeKeyMap = addKeyMap();
            return ()=>{
                removeKeyMap();
                $6ba57d5dc6c0de0a$var$shortcutsCount -= 1;
                if ($6ba57d5dc6c0de0a$var$subscription && $6ba57d5dc6c0de0a$var$shortcutsCount === 0) {
                    $6ba57d5dc6c0de0a$var$subscription.unsubscribe();
                    $6ba57d5dc6c0de0a$var$subscription = null;
                }
            };
        }, [
            addKeyMap
        ]);
        (0, $5uXOq.useEffect)(()=>{
            $6ba57d5dc6c0de0a$var$subscription = $6ba57d5dc6c0de0a$var$subscription || (0, (0, parcelRequire("h0GOb")).default)().subscribe((0, (0, parcelRequire("4lkOX")).default));
        }, [
            parentRegistry,
            keyMap
        ]);
        return null;
    };
    var $6ba57d5dc6c0de0a$export$2e2bcd8739ae039 = ({ useKeyboardShortcutsToggle: useKeyboardShortcutsToggle = (0, (0, parcelRequire("9IeM0")).useKeyboardShortcutsToggle), ...props })=>{
        const [{ isEnabled: isEnabled }] = useKeyboardShortcutsToggle();
        return isEnabled && !false ? (0, ($parcel$interopDefault($5uXOq))).createElement($6ba57d5dc6c0de0a$var$ShortcutsSubscriber, props) : null;
    };
});
parcelRegister("9IeM0", function(module, exports) {
    $parcel$export(module.exports, "useKeyboardShortcutsToggle", ()=>$dc712fc9bec05022$export$a33a81f1fc5458ad);
    var $jcw0u;
    var $eusud;
    var $j8DrX;
    var $8zOmE;
    var $3sQ5x;
    var $iXqE5;
    const $dc712fc9bec05022$var$URL_FRAGMENT = '/rest/internal/latest/user/preferences/keyboardShortcuts?turn=';
    const $dc712fc9bec05022$export$b62f67be35e65859 = (analyticsEvent)=>async ({ setState: setState, getState: getState })=>{
            const currentState = getState().isEnabled;
            const status = currentState ? 'off' : 'on';
            const url = `${$dc712fc9bec05022$var$URL_FRAGMENT}${status}`;
            try {
                await (0, (0, parcelRequire("eusud")).performPutRequest)(url);
                setState({
                    isEnabled: !currentState,
                    error: null
                });
                (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'keyboardShortcutsToggle', {
                    isEnabled: !currentState
                });
            } catch (err) {
                setState({
                    error: err
                });
                (0, (0, parcelRequire("jcw0u")).default)({
                    meta: {
                        id: 'keyboardShortcutToggleFailure',
                        packageName: 'jiraCommonLegacyDoNotAddAnythingNew'
                    },
                    error: err
                });
            }
        };
    const $dc712fc9bec05022$var$Store = (0, (0, parcelRequire("iXqE5")).createStore)({
        initialState: {
            isEnabled: false ? false : !((0, (0, parcelRequire("j8DrX")).default)('ajs-keyboard-shortcuts-enabled') === 'false'),
            error: null
        },
        actions: {
            toggle: $dc712fc9bec05022$export$b62f67be35e65859
        }
    });
    const $dc712fc9bec05022$export$a33a81f1fc5458ad = (0, (0, parcelRequire("3sQ5x")).createHook)($dc712fc9bec05022$var$Store);
});
parcelRegister("4lkOX", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$f11d50ddafbb6f85$export$2e2bcd8739ae039);
    var $h0GOb;
    var $k4r7E;
    const $f11d50ddafbb6f85$var$matches = (prefix)=>(definedSequence)=>definedSequence.startsWith(`${prefix} `);
    var $f11d50ddafbb6f85$export$2e2bcd8739ae039 = (keySequence)=>{
        const { sequence: sequence, e: e } = keySequence || {};
        const activeKeyMap = (0, (0, parcelRequire("k4r7E")).rootRegistry).getActiveKeyMap();
        if (sequence !== undefined && activeKeyMap[sequence]) {
            activeKeyMap[sequence].callback(e);
            (0, (0, parcelRequire("h0GOb")).flush)();
        } else {
            const isFutureMatchPossible = sequence !== undefined && Object.keys(activeKeyMap).some($f11d50ddafbb6f85$var$matches(sequence));
            if (!isFutureMatchPossible) (0, (0, parcelRequire("h0GOb")).flush)();
        }
    };
});
parcelRegister("h0GOb", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$849cd54ac4017470$export$2e2bcd8739ae039);
    $parcel$export(module.exports, "flush", ()=>$849cd54ac4017470$export$4909b86c8034bf73);
    var $kVLXA = parcelRequire("kVLXA");
    var $cab1K = parcelRequire("cab1K");
    parcelRequire("jG6Un");
    parcelRequire("eSGL3");
    parcelRequire("1oQta");
    parcelRequire("4kRRr");
    parcelRequire("d0pqD");
    parcelRequire("iQCJJ");
    parcelRequire("fjPtF");
    parcelRequire("2R2to");
    var $lIZQG;
    const $849cd54ac4017470$var$ignoredKeys = {
        Control: true,
        Meta: true,
        Shift: true,
        Alt: true,
        AltGraph: true,
        CapsLock: true,
        Fn: true,
        FnLock: true,
        Hyper: true,
        NumLock: true,
        OS: true,
        ScrollLock: true,
        Super: true,
        Symbol: true,
        SymbolLock: true
    };
    const $849cd54ac4017470$var$isNonTextInput = ({ inputMode: inputMode = '', type: type })=>inputMode === 'none' || type === 'checkbox';
    const $849cd54ac4017470$var$isEditable = (node)=>node.nodeName === 'INPUT' && !$849cd54ac4017470$var$isNonTextInput(node) || node.nodeName === 'TEXTAREA' || node.isContentEditable === true;
    const $849cd54ac4017470$var$isRelevantKeyEvent = (e)=>typeof e.key === 'string' && (e.key === 'Escape' || !$849cd54ac4017470$var$isEditable(e.target) && !$849cd54ac4017470$var$ignoredKeys[e.key]);
    const $849cd54ac4017470$export$25d1b8252b4c49b7 = ()=>{
        const elBlankets = document.querySelectorAll('.aui-blanket');
        if (elBlankets.length === 0) return true;
        const isAllHidden = Array.from(elBlankets || []).every((el)=>{
            if (el.getAttribute('aria-hidden') === 'true' || el.getAttribute('hidden') !== null) return true;
            return false;
        });
        return isAllHidden;
    };
    let $849cd54ac4017470$var$key$;
    const $849cd54ac4017470$var$flush$ = new (0, $cab1K.Subject)();
    var $849cd54ac4017470$export$2e2bcd8739ae039 = ()=>{
        if (!$849cd54ac4017470$var$key$) {
            $849cd54ac4017470$var$key$ = false ? new (0, $cab1K.Subject)() : (0, $kVLXA.Observable).fromEvent(document, 'keydown').filter($849cd54ac4017470$var$isRelevantKeyEvent).filter($849cd54ac4017470$export$25d1b8252b4c49b7).map((0, (0, parcelRequire("lIZQG")).toKeyWithModifiers));
            const sequence$ = $849cd54ac4017470$var$key$.scan((seq, key)=>{
                if (!key || !seq) return undefined;
                return {
                    sequence: `${String(seq.sequence)} ${String(key.sequence)}`,
                    e: key.e
                };
            });
            $849cd54ac4017470$var$key$ = $849cd54ac4017470$var$key$.debounceTime(1000).startWith(null).merge($849cd54ac4017470$var$flush$).switchMapTo(sequence$);
        }
        return $849cd54ac4017470$var$key$;
    };
    const $849cd54ac4017470$export$4909b86c8034bf73 = ()=>{
        $849cd54ac4017470$var$flush$.next();
    };
});
parcelRegister("lIZQG", function(module, exports) {
    $parcel$export(module.exports, "toKeyWithModifiers", ()=>$ca3344250c7b7406$export$8cf0d2c3d060a0c2);
    $parcel$export(module.exports, "isValidShortcut", ()=>$ca3344250c7b7406$export$8e231506c5580162);
    $parcel$export(module.exports, "isShortcutCollision", ()=>$ca3344250c7b7406$export$4add9e50cb49d130);
    const $ca3344250c7b7406$var$isNonCharacterKey = (e)=>e.key.length > 1 || e.key === ' ';
    const $ca3344250c7b7406$var$modifiers = [
        {
            name: 'ctrl',
            test: (e)=>e.ctrlKey
        },
        {
            name: 'shift',
            test: (e)=>e.shiftKey && $ca3344250c7b7406$var$isNonCharacterKey(e)
        },
        {
            name: 'meta',
            test: (e)=>e.metaKey || e.getModifierState && e.getModifierState('OS')
        }
    ];
    const $ca3344250c7b7406$var$toKey = (e)=>{
        if ($ca3344250c7b7406$var$isNonCharacterKey(e)) return e.key.toLowerCase();
        return e.shiftKey ? e.key.toUpperCase() : e.key.toLowerCase();
    };
    const $ca3344250c7b7406$export$8cf0d2c3d060a0c2 = (e)=>{
        if (!e || !e.key) return {
            sequence: '',
            e: e
        };
        return {
            sequence: $ca3344250c7b7406$var$modifiers.filter((modifier)=>modifier.test(e)).map((modifier)=>modifier.name).concat($ca3344250c7b7406$var$toKey(e)).join('+'),
            e: e
        };
    };
    const $ca3344250c7b7406$var$orSpecialChars = ' |arrowdown|arrowleft|arrowright|arrowup|backspace|clear|delete|end|enter|escape|home|insert|pagedown|pageup|pause|printscreen|tab';
    const $ca3344250c7b7406$var$singleShortcutEntry = `(ctrl\\+)?(shift\\+(?=(?:(?:meta\\+)?(?:${$ca3344250c7b7406$var$orSpecialChars}))))?(meta\\+)?(${$ca3344250c7b7406$var$orSpecialChars}|[!-~])`;
    const $ca3344250c7b7406$var$shortcutRegex = RegExp(`^${$ca3344250c7b7406$var$singleShortcutEntry}( ${$ca3344250c7b7406$var$singleShortcutEntry})*$`);
    const $ca3344250c7b7406$export$8e231506c5580162 = (shortcut)=>$ca3344250c7b7406$var$shortcutRegex.test(shortcut);
    const $ca3344250c7b7406$export$4add9e50cb49d130 = (leftShortcut, rightShortcut)=>{
        if (!leftShortcut || !rightShortcut) return leftShortcut === rightShortcut;
        if (rightShortcut === leftShortcut) return true;
        if (leftShortcut.indexOf(rightShortcut) === 0) return leftShortcut[rightShortcut.length] === ' ';
        if (rightShortcut.indexOf(leftShortcut) === 0) return rightShortcut[leftShortcut.length] === ' ';
        return false;
    };
});
parcelRegister("k4r7E", function(module, exports) {
    $parcel$export(module.exports, "ShortcutRegistry", ()=>$a65fecdbaed23de2$export$ca1c75b575abf5ff);
    $parcel$export(module.exports, "rootRegistry", ()=>$a65fecdbaed23de2$export$1dd4c61d1ce1fa15);
    var $lbWm4 = parcelRequire("lbWm4");
    var $561FV = parcelRequire("561FV");
    var $hGgHi = parcelRequire("hGgHi");
    var $FISJv = parcelRequire("FISJv");
    var $lIZQG;
    const $a65fecdbaed23de2$var$validateKeyMaps = (keyMaps)=>{
        (0, ($parcel$interopDefault($lbWm4)))(keyMaps, (keyMap, keyMapIndex)=>{
            const shortcuts = Object.keys(keyMap);
            (0, ($parcel$interopDefault($lbWm4)))(shortcuts, (shortcut, shortcutIndex)=>{
                if (!(0, (0, parcelRequire("lIZQG")).isValidShortcut)(shortcut)) console.error(`"${shortcut}" is an incorrect shortcut. Please, update it according to a playbook entry on shortcuts.`);
                for(let indexForOtherKeymaps = keyMapIndex + 1; indexForOtherKeymaps < keyMaps.length; indexForOtherKeymaps += 1){
                    const anotherKeyMap = keyMaps[indexForOtherKeymaps];
                    (0, ($parcel$interopDefault($lbWm4)))(anotherKeyMap, (anotherAction, anotherShortcut)=>{
                        if ((0, (0, parcelRequire("lIZQG")).isShortcutCollision)(anotherShortcut, shortcut)) {
                            if ((0, ($parcel$interopDefault($hGgHi)))(keyMap[shortcut].priority) && (0, ($parcel$interopDefault($hGgHi)))(anotherKeyMap[anotherShortcut].priority) || keyMap[shortcut].priority === anotherKeyMap[anotherShortcut].priority) console.error(`"${shortcut}" has a collision with "${anotherShortcut}" shortcut from another keyMap.`);
                        }
                    });
                }
                for(let indexToCompareSelf = shortcutIndex + 1; indexToCompareSelf < shortcuts.length; indexToCompareSelf += 1)if ((0, (0, parcelRequire("lIZQG")).isShortcutCollision)(shortcut, shortcuts[indexToCompareSelf])) console.error(`"${shortcut}" has a collision with "${shortcuts[indexToCompareSelf]}" shortcut from the same keyMap.`);
            });
        });
    };
    const $a65fecdbaed23de2$var$mergeKeyMaps = (keyMapsToMerge)=>keyMapsToMerge.reduce((prev, keyMap)=>({
                ...prev,
                ...Object.keys(keyMap).reduce((newAccumulator, newKey)=>{
                    if (!(0, ($parcel$interopDefault($561FV)))(prev, newKey) || (prev[newKey].priority ?? 0) < (keyMap[newKey].priority ?? 1)) return {
                        ...newAccumulator,
                        [newKey]: keyMap[newKey]
                    };
                    return newAccumulator;
                }, {})
            }), {});
    class $a65fecdbaed23de2$export$ca1c75b575abf5ff {
        addChildRegistry(child) {
            this.children.push(child);
            return ()=>{
                (0, ($parcel$interopDefault($FISJv)))(this.children, child);
            };
        }
        addKeyMap(getKeyMap) {
            this.keyMapRetrievers.push(getKeyMap);
            return ()=>{
                (0, ($parcel$interopDefault($FISJv)))(this.keyMapRetrievers, getKeyMap);
            };
        }
        getActiveKeyMap() {
            const keyMaps = this.children.length ? this.children.map((child)=>child.getActiveKeyMap()) : this.keyMapRetrievers.map((getKeyMap)=>getKeyMap().keyMap);
            return {
                ...$a65fecdbaed23de2$var$mergeKeyMaps(keyMaps)
            };
        }
        getKeyMapInfo() {
            const info = this.children.length ? this.children.map((child)=>child.getKeyMapInfo()) : this.keyMapRetrievers.map((getInfo)=>getInfo());
            const flattenedInfo = [].concat(...info);
            return flattenedInfo;
        }
        constructor(){
            this.children = [];
            this.keyMapRetrievers = [];
        }
    }
    const $a65fecdbaed23de2$export$1dd4c61d1ce1fa15 = new $a65fecdbaed23de2$export$ca1c75b575abf5ff();
});
parcelRegister("9fuVs", function(module, exports) {
    $parcel$export(module.exports, "ShortcutRegistryContext", ()=>$1dd87679d9ca363b$export$ebbb59327adb551a);
    $parcel$export(module.exports, "default", ()=>$1dd87679d9ca363b$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $k4r7E;
    const $1dd87679d9ca363b$export$ebbb59327adb551a = (0, $5uXOq.createContext)((0, (0, parcelRequire("k4r7E")).rootRegistry));
    const $1dd87679d9ca363b$var$ShortcutScope = ({ children: children = null })=>{
        const registry = (0, $5uXOq.useMemo)(()=>new (0, (0, parcelRequire("k4r7E")).ShortcutRegistry)(), []);
        const parentRegistry = (0, $5uXOq.useContext)($1dd87679d9ca363b$export$ebbb59327adb551a) || (0, (0, parcelRequire("k4r7E")).rootRegistry);
        (0, $5uXOq.useEffect)(()=>{
            const removeRegistry = parentRegistry.addChildRegistry(registry);
            return removeRegistry;
        }, []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement($1dd87679d9ca363b$export$ebbb59327adb551a.Provider, {
            value: registry
        }, children);
    };
    var $1dd87679d9ca363b$export$2e2bcd8739ae039 = false ? ({ children: children = null })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, children) : $1dd87679d9ca363b$var$ShortcutScope;
});
parcelRegister("kspvB", function(module, exports) {
    $parcel$export(module.exports, "bindKeyMapWithErrorHandling", ()=>$f9e30ab2465f6f16$export$77477772a9b904da);
    var $dJl1T;
    const $f9e30ab2465f6f16$var$wrapCallbackWithErrorHandling = (key, callback, location = 'keyboard-shortcuts.common')=>(e)=>{
            try {
                callback(e);
            } catch (error) {
                (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData(location, `Error firing shortcut '${key}': ${error.message} (commandPaletteBlockNativeShortcutFF=true)`, error);
            }
        };
    const $f9e30ab2465f6f16$export$77477772a9b904da = (keyMap, location)=>Object.keys(keyMap).reduce((map, key)=>({
                ...map,
                [key]: {
                    ...keyMap[key],
                    callback: $f9e30ab2465f6f16$var$wrapCallbackWithErrorHandling(key, keyMap[key].callback, location)
                }
            }), {});
});
parcelRegister("iGsri", function(module, exports) {
    $parcel$export(module.exports, "useGICSidebarForcer", ()=>$f475e233e908ecbe$export$23d2fbe817b1786c);
    var $5uXOq = parcelRequire("5uXOq");
    var $5eGnF;
    var $9svjh;
    var $4gftw;
    var $5rzqz;
    const $f475e233e908ecbe$export$23d2fbe817b1786c = ()=>{
        const [{ minimizableModalView: minimizableModalView, isModalOpen: isModalOpen }] = (0, (0, parcelRequire("5rzqz")).useTriggerIssueCreateModal)();
        const { subscribe: subscribe } = (0, (0, parcelRequire("9svjh")).useGICLifeCycleEvents)();
        (0, $5uXOq.useEffect)(()=>{
            if (isModalOpen && (minimizableModalView === 'mini' || minimizableModalView === 'minimized')) (0, (0, parcelRequire("4gftw")).default).setIsGlobalIssueCreateOpen(true);
        }, [
            isModalOpen,
            minimizableModalView
        ]);
        (0, $5uXOq.useEffect)(()=>subscribe((0, (0, parcelRequire("5eGnF")).GIC_LIFECYCLE_EVENTS).MODAL_CLOSED, async ()=>{
                (0, (0, parcelRequire("4gftw")).default).setIsGlobalIssueCreateOpen(false);
            }), [
            subscribe
        ]);
    };
});
parcelRegister("5eGnF", function(module, exports) {
    $parcel$export(module.exports, "GIC_LIFECYCLE_EVENTS", ()=>$700db8c378cd19d9$export$876003a5b887f37e);
    const $700db8c378cd19d9$export$876003a5b887f37e = {
        MODAL_OPENED: 'MODAL_OPENED',
        MODAL_CLOSED: 'MODAL_CLOSED',
        MODAL_KEEP_EDITING_EXISTING_ISSUE: 'MODAL_KEEP_EDITING',
        MODAL_DISCARD_EXISTING_IN_PROGRESS_ISSUE: 'MODAL_DISCARD',
        MODAL_DISCARD_SUGGESTED_ISSUE: 'MODAL_DISCARD_SUGGESTED_ISSUE',
        MODAL_DISCARD_SUGGESTED_ISSUE_CHANGES: 'MODAL_DISCARD_SUGGESTED_ISSUE_CHANGES',
        MODAL_DISCARD_ALL_SUGGESTED_ISSUES: 'MODAL_DISCARD_ALL_SUGGESTED_ISSUES',
        CREATE_FORM_FIELD_CONFIG_UPDATE: 'CREATE_FORM_FIELD_CONFIG_UPDATE',
        CREATE_FORM_FIELD_VALUE_CHANGE: 'CREATE_FORM_FIELD_VALUE_CHANGE',
        ISSUE_CREATE_SUCCESS: 'ISSUE_CREATE_SUCCESS',
        PROJECT_FIELD_VALUE_CHANGE: 'PROJECT_FIELD_VALUE_CHANGE',
        ISSUE_TYPE_FIELD_VALUE_CHANGE: 'ISSUE_TYPE_FIELD_VALUE_CHANGE',
        RESET_FORM_FIELDS_FOR_CREATE_ANOTHER: 'RESET_FORM_FIELDS_FOR_CREATE_ANOTHER',
        USE_TRIGGER_ISSUE_CREATE_MODAL_STATE_CHANGE: 'USE_TRIGGER_ISSUE_CREATE_MODAL_STATE_CHANGE',
        STATUS_FIELD_STATE_CHANGE: 'STATUS_FIELD_STATE_CHANGE',
        CREATE_FORM_FIELD_BLUR: 'CREATE_FORM_FIELD_BLUR',
        CLASSIFICATION_LEVEL_FIELD_CHANGE: 'CLASSIFICATION_LEVEL_FIELD_CHANGE',
        FORM_CONTEXT_STATE_CHANGE: 'FORM_CONTEXT_STATE_CHANGE'
    };
});
parcelRegister("9svjh", function(module, exports) {
    $parcel$export(module.exports, "useGICLifeCycleEvents", ()=>$d9b2e43e4699b192$export$e3ff72f196296fcc);
    var $1mrD8;
    const $d9b2e43e4699b192$var$GICLifeCycleEventsService = new (0, (0, parcelRequire("1mrD8")).PubSubHub)();
    const $d9b2e43e4699b192$export$e3ff72f196296fcc = ()=>({
            ...$d9b2e43e4699b192$var$GICLifeCycleEventsService
        });
});
parcelRegister("1mrD8", function(module, exports) {
    $parcel$export(module.exports, "PubSubHub", ()=>$6e9a69c6f6f4fcfe$export$e3607828f8f2c01e);
    class $6e9a69c6f6f4fcfe$export$e3607828f8f2c01e {
        constructor(){
            this.subscribers = {};
            this.subscribe = (event, subscriber)=>{
                let subs = this.subscribers[event];
                if (!subs) {
                    subs = [];
                    this.subscribers[event] = subs;
                }
                subs?.push(subscriber);
                return ()=>this.unsubscribe(event, subscriber);
            };
            this.unsubscribe = (event, subscriber)=>{
                const subs = this.subscribers[event];
                if (subs) this.subscribers[event] = subs.filter((subscription)=>subscription !== subscriber);
            };
            this.publish = (event, data)=>{
                const subs = this.subscribers[event];
                if (subs?.length) subs?.forEach((subscriber)=>subscriber(data));
            };
            this.clearAll = ()=>{
                this.subscribers = {};
            };
        }
    }
});
parcelRegister("4gftw", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ec3927de1ac306d7$export$2e2bcd8739ae039);
    var $dJl1T;
    const $ec3927de1ac306d7$var$emitEventToLegacyApp = (event, data)=>{
        const container = document.getElementById('ghx-issue-fragment');
        if (window.AJS && container) window.AJS.$(container).trigger(event, data);
        else (0, (0, parcelRequire("dJl1T")).default).safeErrorWithoutCustomerData('issue.rapid-board', 'Could not find container to send event for rapid board');
    };
    const $ec3927de1ac306d7$var$BOARD_EVENT = {
        REFRESH_SPRINT_ON_BOARD: 'refresh-sprints-on-board',
        HIDE: 'hide',
        VIEW_ISSUE_MODAL: 'issue.details.view.switch-to-modal',
        VIEW_ISSUE_SIDEBAR: 'issue.details.view.switch-to-sidebar',
        SET_IS_GLOBAL_ISSUE_CREATE_OPEN: 'issue.details.view.set-is-global-issue-create-open'
    };
    const $ec3927de1ac306d7$var$refreshSprintOnBoard = (sprintId)=>$ec3927de1ac306d7$var$emitEventToLegacyApp($ec3927de1ac306d7$var$BOARD_EVENT.REFRESH_SPRINT_ON_BOARD, {
            sprintIds: [
                sprintId
            ]
        });
    const $ec3927de1ac306d7$var$hideIssueView = ()=>$ec3927de1ac306d7$var$emitEventToLegacyApp($ec3927de1ac306d7$var$BOARD_EVENT.HIDE);
    const $ec3927de1ac306d7$var$switchIssueViewToModal = ()=>$ec3927de1ac306d7$var$emitEventToLegacyApp($ec3927de1ac306d7$var$BOARD_EVENT.VIEW_ISSUE_MODAL);
    const $ec3927de1ac306d7$var$switchIssueViewToSidebar = ()=>$ec3927de1ac306d7$var$emitEventToLegacyApp($ec3927de1ac306d7$var$BOARD_EVENT.VIEW_ISSUE_SIDEBAR);
    const $ec3927de1ac306d7$var$setIsGlobalIssueCreateOpen = (newValue)=>$ec3927de1ac306d7$var$emitEventToLegacyApp($ec3927de1ac306d7$var$BOARD_EVENT.SET_IS_GLOBAL_ISSUE_CREATE_OPEN, newValue);
    var $ec3927de1ac306d7$export$2e2bcd8739ae039 = {
        refreshSprintOnBoard: $ec3927de1ac306d7$var$refreshSprintOnBoard,
        hideIssueView: $ec3927de1ac306d7$var$hideIssueView,
        switchIssueViewToModal: $ec3927de1ac306d7$var$switchIssueViewToModal,
        switchIssueViewToSidebar: $ec3927de1ac306d7$var$switchIssueViewToSidebar,
        setIsGlobalIssueCreateOpen: $ec3927de1ac306d7$var$setIsGlobalIssueCreateOpen
    };
});
parcelRegister("5rzqz", function(module, exports) {
    $parcel$export(module.exports, "useTriggerIssueCreateModal", ()=>$0a31d64d52b4b5e9$export$71e0eb7b8a91a156);
    $parcel$export(module.exports, "useOpenIssueCreateModal", ()=>$0a31d64d52b4b5e9$export$47cf258b1046080b);
    $parcel$export(module.exports, "withNewGICProvider", ()=>$0a31d64d52b4b5e9$export$558a195c5bfe3787);
    var $5uXOq = parcelRequire("5uXOq");
    var $7Jxgt = parcelRequire("7Jxgt");
    var $co1wz;
    var $4R6GH;
    var $1Nspv;
    var $bGrWH;
    var $8zOmE;
    var $2NMNM;
    var $f5nIZ;
    var $2YP9W;
    var $3sQ5x;
    var $iXqE5;
    var $51nlc;
    var $hULKg;
    var $cKWWX;
    var $6k7U1;
    const $0a31d64d52b4b5e9$export$d4c72bab9d6cc13a = {
        inProgressIssueID: undefined,
        isModalOpen: false,
        isSubsequentLoad: false,
        isSubTaskCreationOpen: false,
        isCreateLinkedIssue: false,
        wasStateRestored: false,
        payload: null,
        id: 'create-issue-dialog',
        title: null,
        sectionMessage: null,
        submitErrorMessage: undefined,
        globalEventNamespaces: [
            (0, (0, parcelRequire("bGrWH")).GLOBAL_EVENT_NAMESPACE)
        ],
        fieldsToAddInPublishedData: [],
        alertsToBeLinked: undefined,
        onIssueCreate: null,
        onClose: null,
        displayOnlyRequiredFields: false,
        disableCreationSuccessFlag: false,
        displayCreateAnotherIssueToggle: true,
        displaySitePicker: false,
        disableProjectDropdown: false,
        disableIssueTypeDropdown: false,
        triggerPointKey: (0, (0, parcelRequire("hULKg")).TRIGGER_POINT_KEY_NOT_DEFINED),
        context: undefined,
        minimizableModalView: 'modal',
        persistentModalView: undefined,
        shouldPushMiniModalDiscoverability: undefined,
        shouldShowMiniModalSpotlight: undefined,
        wasModalRetriggered: false,
        openIssueCreateModalNextState: {},
        shouldInitFetchConfig: false,
        interactedWithModalChangerButtons: false,
        isModalLoadInContext: false,
        disableMinimizableModal: false,
        displayMinimizableButtons: true,
        displayHeaderActionContainer: true,
        overrideUseRequestTypeFieldsToggleState: undefined,
        containerStyle: {},
        isMiniDesignEnabled: false,
        isEnrolledAndTargetedForGicMultipleLayouts: false,
        sessionId: undefined,
        FieldWrapperComponent: undefined,
        ExternalControls: undefined,
        isCustomBoardWithIcc: false,
        jqlSwimlaneId: undefined,
        swimlaneMode: undefined,
        prefilledCustomFields: {},
        ecosystemCallback: undefined,
        product: undefined,
        actionSource: undefined
    };
    const $0a31d64d52b4b5e9$export$e324594224ef24da = {
        restoreState: (stateToRestore)=>({ setState: setState, getState: getState })=>{
                const { isSubsequentLoad: isSubsequentLoad, isModalOpen: isModalOpen } = getState();
                if (!isModalOpen && stateToRestore.isModalOpen) (0, (0, parcelRequire("6k7U1")).startGlobalIssueCreateMetrics)(isSubsequentLoad);
                setState(stateToRestore);
            },
        openIssueCreateModal: (nextState)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                if (currentState.isModalOpen) setState({
                    wasModalRetriggered: true,
                    openIssueCreateModalNextState: nextState
                });
                else {
                    (0, (0, parcelRequire("6k7U1")).startGlobalIssueCreateMetrics)(currentState.isSubsequentLoad);
                    const isDiscoverabilityPushEnabled = !currentState.isEnrolledAndTargetedForGicMultipleLayouts;
                    const isDiscoPushTriggerPoint = isDiscoverabilityPushEnabled && (0, (0, parcelRequire("6k7U1")).isPartOfDiscoverabilityTriggers)(nextState?.triggerPointKey);
                    const nextModalView = isDiscoverabilityPushEnabled ? (0, (0, parcelRequire("6k7U1")).getNextModalView)(isDiscoPushTriggerPoint, currentState.persistentModalView, $0a31d64d52b4b5e9$export$d4c72bab9d6cc13a.minimizableModalView) : 'modal';
                    setState({
                        ...$0a31d64d52b4b5e9$export$d4c72bab9d6cc13a,
                        ...isDiscoverabilityPushEnabled && {
                            minimizableModalView: nextModalView,
                            persistentModalView: currentState.persistentModalView,
                            ...(0, (0, parcelRequire("6k7U1")).discoverabilityPushModalViewOverride)(currentState.shouldPushMiniModalDiscoverability, isDiscoPushTriggerPoint, nextModalView)
                        },
                        inProgressIssueID: (0, ($parcel$interopDefault($7Jxgt))).v4(),
                        isSubsequentLoad: currentState.isSubsequentLoad,
                        isModalOpen: true,
                        wasModalRetriggered: currentState.isModalOpen,
                        ...nextState
                    });
                }
            },
        closeIssueCreateModal: (hasIssueCreated)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                const isDiscoverabilityPushEnabled = !currentState.isEnrolledAndTargetedForGicMultipleLayouts;
                const isValidDiscoPushTriggerPoint = isDiscoverabilityPushEnabled && (0, (0, parcelRequire("6k7U1")).isPartOfDiscoverabilityTriggers)(currentState.triggerPointKey);
                const shouldUpdatePersistentModalView = isDiscoverabilityPushEnabled && hasIssueCreated && isValidDiscoPushTriggerPoint;
                const shouldPersistDiscoverabilityState = isDiscoverabilityPushEnabled && currentState.shouldPushMiniModalDiscoverability && !isValidDiscoPushTriggerPoint;
                setState({
                    ...$0a31d64d52b4b5e9$export$d4c72bab9d6cc13a,
                    callbackPayload: undefined,
                    ...shouldPersistDiscoverabilityState && {
                        shouldPushMiniModalDiscoverability: true
                    },
                    ...isDiscoverabilityPushEnabled && {
                        persistentModalView: currentState.persistentModalView
                    },
                    ...shouldUpdatePersistentModalView && {
                        persistentModalView: currentState.minimizableModalView === 'mini' ? 'mini' : 'modal'
                    },
                    isModalOpen: false,
                    isSubsequentLoad: true
                });
                (0, (0, parcelRequire("1Nspv")).CreateIssueUFOExperience).abort({
                    metadata: {
                        wasStateRestored: getState().wasStateRestored
                    }
                });
            },
        clearSectionMessage: ()=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                setState({
                    ...currentState,
                    sectionMessage: null
                });
            },
        setDisplayOnlyRequiredFields: (nextState)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                setState({
                    ...currentState,
                    displayOnlyRequiredFields: nextState
                });
            },
        setMinimizableModalView: (minimizableModalView)=>({ setState: setState })=>{
                setState({
                    minimizableModalView: minimizableModalView,
                    interactedWithModalChangerButtons: true
                });
            },
        resetWasModalRetriggered: (applyNextState = false)=>({ setState: setState, getState: getState })=>{
                if (applyNextState) {
                    const currentState = getState();
                    (0, (0, parcelRequire("6k7U1")).startGlobalIssueCreateMetrics)(currentState.isSubsequentLoad);
                    setState({
                        ...$0a31d64d52b4b5e9$export$d4c72bab9d6cc13a,
                        ...!currentState.isEnrolledAndTargetedForGicMultipleLayouts && {
                            persistentModalView: currentState.persistentModalView
                        },
                        inProgressIssueID: currentState.inProgressIssueID,
                        isSubsequentLoad: currentState.isSubsequentLoad,
                        isModalOpen: true,
                        wasModalRetriggered: false,
                        minimizableModalView: 'modal',
                        openIssueCreateModalNextState: {},
                        shouldInitFetchConfig: true,
                        ...currentState.openIssueCreateModalNextState
                    });
                } else setState({
                    minimizableModalView: 'modal',
                    wasModalRetriggered: false,
                    openIssueCreateModalNextState: {}
                });
            },
        resetShouldInitFetchConfig: ()=>({ setState: setState })=>{
                setState({
                    shouldInitFetchConfig: false
                });
            },
        setCallbackPayload: (callbackPayload)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                setState({
                    callbackPayload: {
                        id: currentState.callbackPayload?.id ?? '',
                        data: callbackPayload
                    }
                });
            },
        setShouldPushMiniModalDiscoverability: (shouldPushMiniModalDiscoverability)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                if (currentState.isEnrolledAndTargetedForGicMultipleLayouts) return;
                setState({
                    shouldPushMiniModalDiscoverability: shouldPushMiniModalDiscoverability
                });
            },
        dismissModalSpotlight: ()=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                if (currentState.isEnrolledAndTargetedForGicMultipleLayouts) return;
                setState({
                    shouldShowMiniModalSpotlight: false
                });
            },
        setPersistentModalView: (persistentModalView)=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                if (currentState.isEnrolledAndTargetedForGicMultipleLayouts) return;
                setState({
                    persistentModalView: persistentModalView
                });
            },
        removeAlertFromAlertsToBeLinked: (alertId)=>({ setState: setState, getState: getState })=>{
                if (!(0, (0, parcelRequire("co1wz")).ff)('linked-alerts-in-gic_onvr6')) return;
                const currentState = getState();
                const alertsToBeLinked = currentState.alertsToBeLinked?.filter((entry)=>entry !== alertId);
                setState({
                    alertsToBeLinked: alertsToBeLinked
                });
            },
        setSessionId: (sessionId)=>({ setState: setState })=>{
                setState({
                    sessionId: sessionId
                });
            }
    };
    const $0a31d64d52b4b5e9$var$store = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'issue-create.issue-create-form.use-trigger-issue-create-modal',
        initialState: $0a31d64d52b4b5e9$export$d4c72bab9d6cc13a,
        actions: $0a31d64d52b4b5e9$export$e324594224ef24da
    });
    const $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalState = (0, (0, parcelRequire("3sQ5x")).createStateHook)($0a31d64d52b4b5e9$var$store);
    const $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalActions = (0, (0, parcelRequire("3sQ5x")).createActionsHook)($0a31d64d52b4b5e9$var$store);
    const $0a31d64d52b4b5e9$export$71e0eb7b8a91a156 = ()=>{
        const createState = $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalState();
        const createActions = $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalActions();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const { openIssueCreateModal: openIssueCreateModal, ...otherActions } = createActions;
        const openIssueCreateModalWithAnalytics = (0, $5uXOq.useCallback)((nextState)=>{
            const analyticsEvent = createAnalyticsEvent({});
            const attributes = {
                gicViewState: nextState?.minimizableModalView,
                isSubtask: nextState?.isSubTaskCreationOpen,
                triggerPointKey: nextState?.triggerPointKey,
                ...(0, (0, parcelRequire("4R6GH")).fg)('new-attributes-for-issue-create-anywhere') ? {
                    ...nextState?.product ? {
                        product: nextState.product
                    } : {},
                    ...nextState?.actionSource ? {
                        actionSource: nextState.actionSource
                    } : {}
                } : {}
            };
            (0, (0, parcelRequire("8zOmE")).fireTrackAnalyticsDeferred)(analyticsEvent, 'issueCreateModal triggered', attributes);
            openIssueCreateModal(nextState);
        }, [
            createAnalyticsEvent,
            openIssueCreateModal
        ]);
        return [
            createState,
            {
                ...otherActions,
                openIssueCreateModal: openIssueCreateModalWithAnalytics
            }
        ];
    };
    const $0a31d64d52b4b5e9$export$47cf258b1046080b = ()=>{
        const { openIssueCreateModal: openIssueCreateModal } = $0a31d64d52b4b5e9$var$useTriggerIssueCreateModalActions();
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const [, { setSitePickerState: setSitePickerState }] = (0, (0, parcelRequire("cKWWX")).useSitePickerContext)();
        return (0, $5uXOq.useCallback)((nextState)=>{
            const analyticsEvent = createAnalyticsEvent({});
            const attributes = {
                gicViewState: nextState?.minimizableModalView,
                isSubtask: nextState?.isSubTaskCreationOpen,
                triggerPointKey: nextState?.triggerPointKey,
                ...(0, (0, parcelRequire("4R6GH")).fg)('new-attributes-for-issue-create-anywhere') ? {
                    ...nextState?.product ? {
                        product: nextState.product
                    } : {},
                    ...nextState?.actionSource ? {
                        actionSource: nextState.actionSource
                    } : {}
                } : {}
            };
            (0, (0, parcelRequire("8zOmE")).fireTrackAnalyticsDeferred)(analyticsEvent, 'issueCreateModal triggered', attributes);
            if (nextState?.payload?.overrideCloudId && (0, (0, parcelRequire("4R6GH")).fg)('jira_ai_issue_create_site_user_preference')) setSitePickerState({
                selectedCloudId: nextState?.payload?.overrideCloudId
            });
            openIssueCreateModal(nextState);
        }, [
            createAnalyticsEvent,
            openIssueCreateModal,
            setSitePickerState
        ]);
    };
    const $0a31d64d52b4b5e9$export$4c674fe824b8f278 = (0, (0, parcelRequire("51nlc")).createSubscriber)($0a31d64d52b4b5e9$var$store);
    function $0a31d64d52b4b5e9$export$558a195c5bfe3787(WrappedComponent) {
        return (props)=>{
            const [, { openIssueCreateModal: openIssueCreateModal, closeIssueCreateModal: closeIssueCreateModal }] = $0a31d64d52b4b5e9$export$71e0eb7b8a91a156();
            const { data: data } = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("f5nIZ")).gicOptInStatusResources));
            const currentIssueCreateVisibility = data && data?.gicOptInStatus;
            return (0, ($parcel$interopDefault($5uXOq))).createElement(WrappedComponent, {
                openIssueCreateModal: openIssueCreateModal,
                closeIssueCreateModal: closeIssueCreateModal,
                currentIssueCreateVisibility: currentIssueCreateVisibility?.isEnabled || false,
                ...props
            });
        };
    }
});
parcelRegister("1Nspv", function(module, exports) {
    $parcel$export(module.exports, "CreateIssueUFOExperience", ()=>$ff7fb599b8d98642$export$cd75c112ac11a83b);
    $parcel$export(module.exports, "StatusFieldLoad", ()=>$ff7fb599b8d98642$export$e890e797a25e655c);
    $parcel$export(module.exports, "GlobalIssueCreateModalSubmitUFOExperience", ()=>$ff7fb599b8d98642$export$70208657c8d471a7);
    $parcel$export(module.exports, "EcosystemAPIPrefillingUFOExperience", ()=>$ff7fb599b8d98642$export$b2d8f8edec75f571);
    var $atdLB;
    var $duUGa;
    const $ff7fb599b8d98642$export$cd75c112ac11a83b = new (0, (0, parcelRequire("duUGa")).UFOExperience)('global-issue-create.create-issue', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).Custom,
        category: 'global-issue-create.create-issue'
    });
    const $ff7fb599b8d98642$export$e890e797a25e655c = new (0, (0, parcelRequire("duUGa")).UFOExperience)('global-issue-create.create-issue.status-field', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Load,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).PageSegmentLoad
    });
    const $ff7fb599b8d98642$export$70208657c8d471a7 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('modern-global-issue-create.modal.issue-submit', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).Custom,
        category: 'modern-global-issue-create.modal.issue-submit'
    });
    const $ff7fb599b8d98642$export$b2d8f8edec75f571 = new (0, (0, parcelRequire("duUGa")).UFOExperience)('ecosystem-api-new-gic-prefilling', {
        type: (0, (0, parcelRequire("atdLB")).ExperienceTypes).Experience,
        performanceType: (0, (0, parcelRequire("atdLB")).ExperiencePerformanceTypes).Custom,
        category: 'ecosystem-api-new-gic-prefilling'
    });
});
parcelRegister("bGrWH", function(module, exports) {
    $parcel$export(module.exports, "REQUIRED", ()=>$4aabf2a0e09eb856$export$b1c05b34d317c074);
    $parcel$export(module.exports, "FORM_DROPZONE_CONTAINER_ID", ()=>$4aabf2a0e09eb856$export$1a020bd73f33e5a);
    $parcel$export(module.exports, "MINIMISED_DROPZONE_CONTAINER_ID", ()=>$4aabf2a0e09eb856$export$9eadc594a5383580);
    $parcel$export(module.exports, "LOG_WORK_TIME_SPENT_ERROR_KEY", ()=>$4aabf2a0e09eb856$export$a1a7caa1e4a73c9f);
    $parcel$export(module.exports, "LOG_WORK_TIME_REMAINING_ERROR_KEY", ()=>$4aabf2a0e09eb856$export$cf70f9220e6a2f50);
    $parcel$export(module.exports, "LOG_WORK_DATE_ERROR_KEY", ()=>$4aabf2a0e09eb856$export$aac4661200ba4104);
    $parcel$export(module.exports, "LOG_WORK_DESCRIPTION_ERROR_KEY", ()=>$4aabf2a0e09eb856$export$a23c32d5cf54cba0);
    $parcel$export(module.exports, "COMMON_LOG_WORK_ERROR_KEYS", ()=>$4aabf2a0e09eb856$export$74ab3898ae683660);
    $parcel$export(module.exports, "MULTI_INPUT_FIELDS_ERROR_KEYS", ()=>$4aabf2a0e09eb856$export$983715bb9b0f9b2c);
    $parcel$export(module.exports, "DEFAULT_SCROLL_OPTIONS", ()=>$4aabf2a0e09eb856$export$40f6793dd75f4e52);
    $parcel$export(module.exports, "UNSUPPORTED_FIELD_TYPES_IN_GIC", ()=>$4aabf2a0e09eb856$export$3bf5bce483cc2c30);
    $parcel$export(module.exports, "ISSUE_CREATE_TYPE", ()=>$4aabf2a0e09eb856$export$30cc1fd33e7c7258);
    $parcel$export(module.exports, "GLOBAL_EVENT_NAMESPACE", ()=>$4aabf2a0e09eb856$export$8170252f0ea43511);
    const $4aabf2a0e09eb856$export$17bcab948893a9ba = 'worklog';
    const $4aabf2a0e09eb856$export$b1c05b34d317c074 = 'REQUIRED';
    const $4aabf2a0e09eb856$export$f10b32dfda12e96e = 'unknown';
    const $4aabf2a0e09eb856$export$e5e4b8c32e68dd0b = 'networkError';
    const $4aabf2a0e09eb856$export$2d06efb8146cd043 = 'graphQLError';
    const $4aabf2a0e09eb856$export$1a020bd73f33e5a = 'issue-create-modal-dropzone-container';
    const $4aabf2a0e09eb856$export$9eadc594a5383580 = 'minimised-issue-create-dropzone-container';
    const $4aabf2a0e09eb856$export$a1a7caa1e4a73c9f = 'worklog_timeLogged';
    const $4aabf2a0e09eb856$export$cf70f9220e6a2f50 = 'worklog_newEstimate';
    const $4aabf2a0e09eb856$export$aac4661200ba4104 = 'worklog_startDate';
    const $4aabf2a0e09eb856$export$a23c32d5cf54cba0 = 'worklog_description';
    const $4aabf2a0e09eb856$export$74ab3898ae683660 = [
        'worklog_adjustmentAmount',
        'worklog_adjustEstimate',
        'worklog_activate'
    ];
    const $4aabf2a0e09eb856$export$63fd567c1d0aca19 = [
        ...$4aabf2a0e09eb856$export$74ab3898ae683660,
        $4aabf2a0e09eb856$export$a1a7caa1e4a73c9f,
        $4aabf2a0e09eb856$export$cf70f9220e6a2f50,
        $4aabf2a0e09eb856$export$aac4661200ba4104,
        $4aabf2a0e09eb856$export$a23c32d5cf54cba0
    ];
    const $4aabf2a0e09eb856$export$983715bb9b0f9b2c = {
        [$4aabf2a0e09eb856$export$17bcab948893a9ba]: $4aabf2a0e09eb856$export$63fd567c1d0aca19
    };
    const $4aabf2a0e09eb856$export$40f6793dd75f4e52 = {
        behavior: 'auto',
        scrollMode: 'if-needed',
        block: 'start'
    };
    const $4aabf2a0e09eb856$export$3bf5bce483cc2c30 = [
        'com.atlassian.servicedesk:sd-request-participants'
    ];
    const $4aabf2a0e09eb856$export$30cc1fd33e7c7258 = {
        linkedIssue: 'LINKED_ISSUE_CREATE',
        standard: 'STANDARD'
    };
    const $4aabf2a0e09eb856$export$8170252f0ea43511 = 'QuickCreateIssue';
});
parcelRegister("hULKg", function(module, exports) {
    $parcel$export(module.exports, "GENERIC_FORGE_USE_PRODUCT_MODALS", ()=>$62659eb193cd5c9c$export$a05673a22505d5ee);
    $parcel$export(module.exports, "GENERIC_GLOBAL_CREATE_BUTTON", ()=>$62659eb193cd5c9c$export$c78d329777576aa0);
    $parcel$export(module.exports, "MOBILE_GLOBAL_CREATE_BUTTON", ()=>$62659eb193cd5c9c$export$992ab052fd10f8e8);
    $parcel$export(module.exports, "GENERIC_KEYBOARD_SHORTCUT", ()=>$62659eb193cd5c9c$export$affdc3af7a9a4677);
    $parcel$export(module.exports, "JSW_ISSUE_VIEW_CREATE_ISSUE_IN_EPIC", ()=>$62659eb193cd5c9c$export$7e9cfd1f59e60910);
    $parcel$export(module.exports, "JSW_ISSUE_VIEW_CREATE_SUBTASK", ()=>$62659eb193cd5c9c$export$708499dcd016e5e9);
    $parcel$export(module.exports, "JSW_ISSUE_VIEW_AI_BREAKDOWN_CHILD_ISSUE", ()=>$62659eb193cd5c9c$export$e1c8789144c4c810);
    $parcel$export(module.exports, "JSW_ISSUE_VIEW_AI_BREAKDOWN_SUBTASK", ()=>$62659eb193cd5c9c$export$c5afabb7a17e802);
    $parcel$export(module.exports, "JSW_RELEASES_CREATE_ISSUE", ()=>$62659eb193cd5c9c$export$2086a63173b79b56);
    $parcel$export(module.exports, "JSW_ROADMAP_CREATE_ISSUE", ()=>$62659eb193cd5c9c$export$fe1e5e4597147709);
    $parcel$export(module.exports, "JSW_SECURITY_CREATE_ISSUE", ()=>$62659eb193cd5c9c$export$9bc326812a93707);
    $parcel$export(module.exports, "JSM_CALENDAR_CREATE_CHANGE_REQUEST", ()=>$62659eb193cd5c9c$export$f352934e06f44599);
    $parcel$export(module.exports, "JSM_CREATE_INCIDENT_FROM_ALERT_DETAIL", ()=>$62659eb193cd5c9c$export$6ae07c77acc64360);
    $parcel$export(module.exports, "JSM_CREATE_INCIDENT_FROM_ALERT_LIST", ()=>$62659eb193cd5c9c$export$b41d0684e4b6ab1);
    $parcel$export(module.exports, "JSM_EMPTY_QUEUE_CREATE_REQUEST", ()=>$62659eb193cd5c9c$export$be9b2b1329ff7ec9);
    $parcel$export(module.exports, "JSW_CREATE_ISSUE_DIALOG", ()=>$62659eb193cd5c9c$export$aa348159181703a0);
    $parcel$export(module.exports, "ISSUE_VIEW_CREATE_LINKED_ISSUE", ()=>$62659eb193cd5c9c$export$f90eec3841adf619);
    $parcel$export(module.exports, "COMMAND_PALETTE_CREATE_LINKED_ISSUE", ()=>$62659eb193cd5c9c$export$718822a309bffada);
    $parcel$export(module.exports, "SERVICE_DESK_LINKED_ISSUE_TRIGEER", ()=>$62659eb193cd5c9c$export$2753dafac81e805c);
    $parcel$export(module.exports, "JSW_INCIDENTS_CREATE_JSM_PIR_TRIGGER", ()=>$62659eb193cd5c9c$export$7c4d5cc5711d9294);
    $parcel$export(module.exports, "JPO_DIALOGS_CREATE_ISSUE_TRIGGER", ()=>$62659eb193cd5c9c$export$998dfc72404b9e14);
    $parcel$export(module.exports, "JSW_ADAPTED_GLOBAL_ISSUE_CREATE", ()=>$62659eb193cd5c9c$export$fc180a4b5785d2f4);
    $parcel$export(module.exports, "JSW_CREATE_EPIC_ISSUE", ()=>$62659eb193cd5c9c$export$946ec0b21cb84fd0);
    $parcel$export(module.exports, "CREATE_ISSUE_ACTIVE_SPRINT_EMPTY_VIEW", ()=>$62659eb193cd5c9c$export$5b97e2844e620e54);
    $parcel$export(module.exports, "CREATE_SUBTASK_NIN_TRIGGER", ()=>$62659eb193cd5c9c$export$b99842c2cf46f6be);
    $parcel$export(module.exports, "TRIGGER_POINT_KEY_NOT_DEFINED", ()=>$62659eb193cd5c9c$export$8d987f6e80b74000);
    $parcel$export(module.exports, "ISSUE_PARENT_SWITCHER_FIELD_CREATE_ISSUE", ()=>$62659eb193cd5c9c$export$8de9fa43731c4b5b);
    $parcel$export(module.exports, "POLARIS_CREATE_DELIVERY_ISSUE", ()=>$62659eb193cd5c9c$export$87249ccbfd10fb78);
    $parcel$export(module.exports, "HOME_PAGE_DEFAULT_OPEN_GIC", ()=>$62659eb193cd5c9c$export$c0eeeb31d264de76);
    $parcel$export(module.exports, "ISSUE_CREATE_EMBEDDABLE_TRIGGER", ()=>$62659eb193cd5c9c$export$f546e18e46478c84);
    $parcel$export(module.exports, "DEVELOPER_ESCALATIONS_ISSUE_CREATE_TRIGGER", ()=>$62659eb193cd5c9c$export$1784e634ba79f1f9);
    $parcel$export(module.exports, "JWM_INLINE_CREATE", ()=>$62659eb193cd5c9c$export$145eb8935a34e85e);
    $parcel$export(module.exports, "POLARIS_GLOBAL_SUBMIT_IDEA_SWITCH_PROJECTS_TRIGGER", ()=>$62659eb193cd5c9c$export$32648b574a536c8b);
    $parcel$export(module.exports, "ALLOWED_EXTERNAL_CONTROLS", ()=>$62659eb193cd5c9c$export$c0e2ab74dfaa67cc);
    const $62659eb193cd5c9c$export$a05673a22505d5ee = 'forge-open-issue-create';
    const $62659eb193cd5c9c$export$c78d329777576aa0 = 'global-create-button';
    const $62659eb193cd5c9c$export$992ab052fd10f8e8 = 'mobile-global-create-button';
    const $62659eb193cd5c9c$export$affdc3af7a9a4677 = 'keyboard-shortcut';
    const $62659eb193cd5c9c$export$7e9cfd1f59e60910 = 'jsw-issue-view-create-issue-in-epic';
    const $62659eb193cd5c9c$export$708499dcd016e5e9 = 'jsw-issue-view-create-subtask';
    const $62659eb193cd5c9c$export$e1c8789144c4c810 = 'jsw-issue-view-ai-breakdown-child-issue';
    const $62659eb193cd5c9c$export$c5afabb7a17e802 = 'jsw-issue-view-ai-breakdown-subtask';
    const $62659eb193cd5c9c$export$2086a63173b79b56 = 'jsw-releases-create-issue';
    const $62659eb193cd5c9c$export$fe1e5e4597147709 = 'jsw-roadmap-create-issue';
    const $62659eb193cd5c9c$export$9bc326812a93707 = 'jsw-security-create-issue';
    const $62659eb193cd5c9c$export$f352934e06f44599 = 'jsm-calendar-create-change-request';
    const $62659eb193cd5c9c$export$6ae07c77acc64360 = 'jsm-create-incident-from-alert-detail';
    const $62659eb193cd5c9c$export$b41d0684e4b6ab1 = 'jsm-create-incident-from-alert-list';
    const $62659eb193cd5c9c$export$be9b2b1329ff7ec9 = 'jsm-empty-queue-create-request';
    const $62659eb193cd5c9c$export$235b856f54db8916 = 'jwm-board-create-issue';
    const $62659eb193cd5c9c$export$ebbb81a0a3406bcb = 'jwm-calendar-create-issue';
    const $62659eb193cd5c9c$export$8e135b62470e5f9a = 'jwm-list-create-issue';
    const $62659eb193cd5c9c$export$21ea58e7361c33ba = 'jwm-list-create-subtask';
    const $62659eb193cd5c9c$export$d2cf44b8c67cca33 = 'jwm-timeline-create-issue';
    const $62659eb193cd5c9c$export$894285d6d13375fd = 'jwm-timeline-create-subtask';
    const $62659eb193cd5c9c$export$aa348159181703a0 = 'software-create-issue-dialog';
    const $62659eb193cd5c9c$export$f90eec3841adf619 = 'create-linked-issue-issue-view';
    const $62659eb193cd5c9c$export$718822a309bffada = 'create-linked-issue-command-palette';
    const $62659eb193cd5c9c$export$2753dafac81e805c = 'service-desk-linked-issue-trigger';
    const $62659eb193cd5c9c$export$7c4d5cc5711d9294 = 'jsw-incidents-create-jsm-pir-trigger';
    const $62659eb193cd5c9c$export$6eb7318fea2f8ce0 = 'jsw-incidents-create-issue-trigger';
    const $62659eb193cd5c9c$export$998dfc72404b9e14 = 'jpo-dialogs-jira-create-issue';
    const $62659eb193cd5c9c$export$fc180a4b5785d2f4 = 'jsw-adapted-global-issue-create-trigger';
    const $62659eb193cd5c9c$export$946ec0b21cb84fd0 = 'jsw-create-epic-issue';
    const $62659eb193cd5c9c$export$dba86c91ec4fdbf0 = 'create-issue-backlog-trigger';
    const $62659eb193cd5c9c$export$b5cdfd7f3bb79a7 = 'create-epic-issue-backlog-trigger';
    const $62659eb193cd5c9c$export$a8199da3cfab0e8 = 'create-issue-in-epic-backlog-trigger';
    const $62659eb193cd5c9c$export$5b97e2844e620e54 = 'create-issue-active-sprint-empty-view-trigger';
    const $62659eb193cd5c9c$export$569f5ff8afa08896 = 'create-linked-issue-issue-navigator-trigger';
    const $62659eb193cd5c9c$export$bafd52474e9ff4e = 'jwm-summary-create-first-issue-trigger';
    const $62659eb193cd5c9c$export$87f86192eaf0fdf = 'create-subtask-issue-navigator-trigger';
    const $62659eb193cd5c9c$export$b99842c2cf46f6be = 'create-subtask-new-issue-navigator-trigger';
    const $62659eb193cd5c9c$export$1e467d0a3f53a410 = 'jwm-board-embed-issue-create';
    const $62659eb193cd5c9c$export$8d987f6e80b74000 = 'trigger-point-key-not-defined';
    const $62659eb193cd5c9c$export$867e9a641a3c1457 = 'jwm-list-embed-issue-create';
    const $62659eb193cd5c9c$export$8de9fa43731c4b5b = 'issue-parent-switcher-field-create-issue';
    const $62659eb193cd5c9c$export$87249ccbfd10fb78 = 'polaris-create-delivery-issue';
    const $62659eb193cd5c9c$export$c0eeeb31d264de76 = 'home-page-default-open-gic';
    const $62659eb193cd5c9c$export$f546e18e46478c84 = 'issue-create-embed-trigger';
    const $62659eb193cd5c9c$export$1784e634ba79f1f9 = 'developer-escalations-issue-create-trigger';
    const $62659eb193cd5c9c$export$145eb8935a34e85e = 'jwm-inline-create';
    const $62659eb193cd5c9c$export$32648b574a536c8b = 'polaris-global-submit-idea-switch-project-trigger';
    const $62659eb193cd5c9c$export$818f24c6c32e18a = 'jira-calendar-create-issue';
    const $62659eb193cd5c9c$export$f352408886aa304b = 'connect-app-ecosystem';
    const $62659eb193cd5c9c$export$c0e2ab74dfaa67cc = {
        FIELD_IDS: [
            'description'
        ],
        TRIGGER_POINTS: [
            'service-desk-gen-ai-pir'
        ]
    };
});
parcelRegister("cKWWX", function(module, exports) {
    $parcel$export(module.exports, "useSitePickerContext", ()=>$c8055d212e869a3c$export$f8b5aae013e4cf77);
    var $3sQ5x;
    var $iXqE5;
    const $c8055d212e869a3c$export$d4c72bab9d6cc13a = {
        selectedCloudId: '',
        siteDomain: ''
    };
    const $c8055d212e869a3c$export$e324594224ef24da = {
        setSitePickerState: ({ selectedCloudId: selectedCloudId, siteDomain: siteDomain })=>({ setState: setState, getState: getState })=>{
                const currentState = getState();
                setState({
                    ...currentState,
                    selectedCloudId: selectedCloudId || '',
                    siteDomain: siteDomain || ''
                });
            }
    };
    const $c8055d212e869a3c$export$6f57813fe9f31bf9 = (0, (0, parcelRequire("iXqE5")).createStore)({
        name: 'issue.fields.site-picker',
        initialState: $c8055d212e869a3c$export$d4c72bab9d6cc13a,
        actions: $c8055d212e869a3c$export$e324594224ef24da
    });
    const $c8055d212e869a3c$export$f8b5aae013e4cf77 = (0, (0, parcelRequire("3sQ5x")).createHook)($c8055d212e869a3c$export$6f57813fe9f31bf9);
});
parcelRegister("6k7U1", function(module, exports) {
    $parcel$export(module.exports, "startGlobalIssueCreateMetrics", ()=>$8a2e4df39a48d5af$export$550b19803bc962ec);
    $parcel$export(module.exports, "isPartOfDiscoverabilityTriggers", ()=>$8a2e4df39a48d5af$export$f6bc17a4d9120156);
    $parcel$export(module.exports, "discoverabilityPushModalViewOverride", ()=>$8a2e4df39a48d5af$export$8d246e5e04f0b424);
    $parcel$export(module.exports, "getNextModalView", ()=>$8a2e4df39a48d5af$export$720579602bb055a2);
    var $eTXmK;
    var $1Nspv;
    var $gEaAu;
    var $huY3x;
    const $8a2e4df39a48d5af$export$550b19803bc962ec = (isSubsequentLoad)=>{
        (0, (0, parcelRequire("1Nspv")).CreateIssueUFOExperience).start();
        const globalIssueCreateModalLoadMetric = isSubsequentLoad ? (0, (0, parcelRequire("gEaAu")).globalIssueCreateModalLoadSubsequent) : (0, (0, parcelRequire("gEaAu")).globalIssueCreateModalLoad);
        if (isSubsequentLoad) (0, (0, parcelRequire("eTXmK")).default)('global-issue.create-modal-subsequent');
        else (0, (0, parcelRequire("eTXmK")).default)('global-issue.create-modal');
        globalIssueCreateModalLoadMetric.start();
        (0, (0, parcelRequire("huY3x")).startGlobalIssueCreateTTIWithAdjustments)();
    };
    const $8a2e4df39a48d5af$export$f6bc17a4d9120156 = (triggerPoint)=>{
        if (!triggerPoint) return false;
        const acceptedTriggerPoints = [
            'global-create-button',
            'keyboard-shortcut'
        ];
        return acceptedTriggerPoints.includes(triggerPoint);
    };
    const $8a2e4df39a48d5af$export$8d246e5e04f0b424 = (shouldPushDiscoverability, isTriggerAccepted, defaultView = 'modal')=>{
        if (!shouldPushDiscoverability) return undefined;
        return {
            minimizableModalView: isTriggerAccepted ? 'mini' : defaultView,
            shouldPushMiniModalDiscoverability: true,
            shouldShowMiniModalSpotlight: isTriggerAccepted
        };
    };
    const $8a2e4df39a48d5af$export$720579602bb055a2 = (isTriggerAccepted, persistentView, defaultView = 'modal')=>{
        if (isTriggerAccepted) return persistentView || defaultView;
        return defaultView;
    };
});
parcelRegister("gEaAu", function(module, exports) {
    $parcel$export(module.exports, "globalIssueCreateModalLoad", ()=>$ee246606338fc99e$export$5a01a7fa84b239c8);
    $parcel$export(module.exports, "globalIssueCreateModalLoadSubsequent", ()=>$ee246606338fc99e$export$c50ad3c31b6fcfc6);
    $parcel$export(module.exports, "successFlagDisplay", ()=>$ee246606338fc99e$export$4b2382574525bb0f);
    $parcel$export(module.exports, "successFlagAddToSprintAction", ()=>$ee246606338fc99e$export$7839bdaa2f6a2385);
    $parcel$export(module.exports, "successFlagClearFiltersAction", ()=>$ee246606338fc99e$export$c1592e4d8d98963e);
    var $dGrdR;
    const $ee246606338fc99e$export$5a01a7fa84b239c8 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-modal'
    });
    const $ee246606338fc99e$export$c50ad3c31b6fcfc6 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-modal-subsequent'
    });
    const $ee246606338fc99e$export$4b2382574525bb0f = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-dynamic-success-flag-display'
    });
    const $ee246606338fc99e$export$7839bdaa2f6a2385 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-dynamic-success-flag-add-to-sprint-action'
    });
    const $ee246606338fc99e$export$c1592e4d8d98963e = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-dynamic-success-flag-clear-filters-action'
    });
});
parcelRegister("huY3x", function(module, exports) {
    $parcel$export(module.exports, "globalIssueCreateTTIWithAdjustments", ()=>$06c7e44c59602f0c$export$9f3d6f17e1da7874);
    $parcel$export(module.exports, "startGlobalIssueCreateTTIWithAdjustments", ()=>$06c7e44c59602f0c$export$457c9fb71ad5b3a9);
    $parcel$export(module.exports, "markGicTti", ()=>$06c7e44c59602f0c$export$ef664dac649a716c);
    $parcel$export(module.exports, "stopTTI", ()=>$06c7e44c59602f0c$export$30ca4c461fab486c);
    $parcel$export(module.exports, "cancelTTI", ()=>$06c7e44c59602f0c$export$27f2445288b528c);
    $parcel$export(module.exports, "markIframeLoad", ()=>$06c7e44c59602f0c$export$7a2709fc1e33401a);
    $parcel$export(module.exports, "markFieldsRegistered", ()=>$06c7e44c59602f0c$export$cf3893335c93e33b);
    $parcel$export(module.exports, "markOnInitResolved", ()=>$06c7e44c59602f0c$export$d86f4868694f2aa7);
    var $dGrdR;
    var $a1HyB;
    const $06c7e44c59602f0c$export$9f3d6f17e1da7874 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'global-issue-create-tti-with-adjustments',
        timings: [
            {
                key: 'gic-tti-delta',
                startMark: 'gic-tti'
            },
            {
                key: 'script-execution',
                startMark: 'iframe-load'
            },
            {
                key: 'loading-indicators-visibility',
                startMark: 'fields-registered'
            },
            {
                key: 'time-until-oninit-resolved',
                endMark: 'oninit-resolved'
            },
            {
                key: 'gic-all-fields-tti',
                endMark: 'gic-all-fields-loaded'
            }
        ],
        featureFlags: (0, (0, parcelRequire("a1HyB")).TTIFFs)
    });
    const $06c7e44c59602f0c$export$457c9fb71ad5b3a9 = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.start();
    };
    const $06c7e44c59602f0c$export$ef664dac649a716c = ()=>$06c7e44c59602f0c$export$9f3d6f17e1da7874.mark('gic-tti');
    const $06c7e44c59602f0c$export$30ca4c461fab486c = (customData)=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.stop({
            customData: customData
        });
    };
    const $06c7e44c59602f0c$export$27f2445288b528c = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.cancel();
    };
    const $06c7e44c59602f0c$export$7a2709fc1e33401a = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.mark('iframe-load');
    };
    const $06c7e44c59602f0c$export$cf3893335c93e33b = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.mark('fields-registered');
    };
    const $06c7e44c59602f0c$export$d86f4868694f2aa7 = ()=>{
        $06c7e44c59602f0c$export$9f3d6f17e1da7874.mark('oninit-resolved');
    };
});
parcelRegister("a1HyB", function(module, exports) {
    $parcel$export(module.exports, "TTIFFs", ()=>$aec371b77b607203$export$c396ba1504d97f3f);
    const $aec371b77b607203$export$c396ba1504d97f3f = [];
});
parcelRegister("03jjx", function(module, exports) {
    $parcel$export(module.exports, "BreadcrumbsWrapper", ()=>$20ae68c05a664e27$export$85f84040e645a2c9);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $20ae68c05a664e27$export$85f84040e645a2c9 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_kqswh2mm _1pbycs5v _18s8as9j _4t3i1tcg _pvkq8isj",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("dkttM", function(module, exports) {
    $parcel$export(module.exports, "Breadcrumbs", ()=>$43b5951c2eff0e64$export$2dc68d50d56fbbd);
    var $5uXOq = parcelRequire("5uXOq");
    var $4DPZY;
    var $doZTA;
    var $4hXjG;
    var $4dqPp;
    var $gYklg;
    const $43b5951c2eff0e64$export$2dc68d50d56fbbd = ()=>{
        const [{ query: query, match: match }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const baseUrl = '';
        const [{ data: data, error: error }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        const { chartView: chartView } = (0, (0, parcelRequire("4hXjG")).useUrlState)();
        if (!data || error) return null;
        let boardId;
        if (match && match.params && match.params.boardId !== undefined) boardId = Number(match.params.boardId);
        else boardId = Number(query.rapidView);
        const boardName = data.scope && data.scope.name || '';
        const isReportsView = query && query.view === 'reporting';
        if (data.user) {
            const accountId = match.params.accountId || '';
            const isLocationlessBoard = !!query.locationless;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gYklg")).UserBreadcrumbs), {
                accountId: accountId,
                boardId: boardId,
                boardName: boardName,
                isReportsView: isReportsView,
                profileUrl: data.user.profileUrl,
                userName: data.user.name,
                chart: chartView,
                isLocationlessBoard: isLocationlessBoard
            });
        }
        const { project: project } = data;
        const projectKey = project.key;
        const projectName = project.name;
        const projectId = project.id;
        const isSimplifiedProject = project.isSimplified;
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4dqPp")).ProjectBreadcrumbs), {
            baseUrl: baseUrl,
            boardId: boardId,
            boardName: boardName,
            chart: chartView,
            projectKey: projectKey,
            projectName: projectName,
            projectId: projectId,
            isSimplifiedProject: isSimplifiedProject
        });
    };
});
parcelRegister("4dqPp", function(module, exports) {
    $parcel$export(module.exports, "ProjectBreadcrumbs", ()=>$f339d6c7abfa1840$export$38f8c59e2af08a87);
    var $5uXOq = parcelRequire("5uXOq");
    var $c2D8L;
    var $gEFwt;
    var $koVbs;
    var $bxv9E;
    const $f339d6c7abfa1840$export$38f8c59e2af08a87 = (0, $5uXOq.memo)(({ baseUrl: baseUrl, boardId: boardId, boardName: boardName, projectKey: projectKey, projectName: projectName, projectId: projectId, isSimplifiedProject: isSimplifiedProject, chart: chart })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gEFwt")).default), {
            baseUrl: baseUrl,
            projectKey: projectKey,
            projectName: projectName,
            projectId: projectId,
            isSimplifiedProject: isSimplifiedProject
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c2D8L")).default), {
            breadcrumb: {
                text: boardName,
                href: `/jira/software/c/projects/${projectKey}/boards/${boardId}`,
                type: 'boardName',
                analyticsAttributes: {
                    nextGenProject: isSimplifiedProject,
                    projectConfig: isSimplifiedProject ? 'next-gen' : 'classic'
                },
                analyticsFields: {
                    name: 'navigateToSubProject',
                    containerId: projectId,
                    containerType: 'project'
                }
            }
        }, chart && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c2D8L")).default), {
            breadcrumb: {
                text: formatMessage((0, (0, parcelRequire("bxv9E")).messages).projectReportBreadcrumb),
                href: `/projects/${projectKey}?selectedItem=com.atlassian.jira.jira-projects-plugin:report-page`
            }
        })));
    });
});
parcelRegister("bxv9E", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$74ad6184f87e3622$export$defe85febe8b728c);
    var $7VHMR;
    const $74ad6184f87e3622$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        projectReportBreadcrumb: {
            "id": "classic-software.rapidboard-content.breadcrumbs.project.project-report-breadcrumb",
            "defaultMessage": "Reports"
        }
    });
});
parcelRegister("gYklg", function(module, exports) {
    $parcel$export(module.exports, "UserBreadcrumbs", ()=>$3f7779ce9041362c$export$74fdd5a0187b08ef);
    var $5uXOq = parcelRequire("5uXOq");
    var $c2D8L;
    var $75PvK;
    var $koVbs;
    var $2OIQ0;
    const $3f7779ce9041362c$export$74fdd5a0187b08ef = (0, $5uXOq.memo)(({ accountId: accountId, boardId: boardId, boardName: boardName, profileUrl: profileUrl, userName: userName, chart: chart, isLocationlessBoard: isLocationlessBoard })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        if (isLocationlessBoard) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("75PvK")).default), {
            profileUrl: profileUrl,
            name: userName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c2D8L")).default), {
            breadcrumb: {
                text: boardName,
                href: `/secure/RapidBoard.jspa?rapidView=${boardId}&locationless=true`,
                type: 'boardName'
            }
        }, chart && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c2D8L")).default), {
            breadcrumb: {
                text: formatMessage((0, (0, parcelRequire("2OIQ0")).messages).userReportBreadcrumb),
                href: `/secure/RapidBoard.jspa?rapidView=${boardId}&view=reporting&chart=${chart}&locationless=true`
            }
        })));
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("75PvK")).default), {
            profileUrl: profileUrl,
            name: userName
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c2D8L")).default), {
            breadcrumb: {
                text: boardName,
                href: `/jira/people/${accountId}/boards/${boardId}`,
                type: 'boardName'
            }
        }, chart && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c2D8L")).default), {
            breadcrumb: {
                text: formatMessage((0, (0, parcelRequire("2OIQ0")).messages).userReportBreadcrumb),
                href: `/jira/people/${accountId}/boards/${boardId}/reports/${chart}`
            }
        })));
    });
});
parcelRegister("2OIQ0", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$f431e2a3ae360d6b$export$defe85febe8b728c);
    var $7VHMR;
    const $f431e2a3ae360d6b$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        userReportBreadcrumb: {
            "id": "classic-software.rapidboard-content.breadcrumbs.user.user-report-breadcrumb",
            "defaultMessage": "Reports"
        }
    });
});
parcelRegister("67qQJ", function(module, exports) {
    $parcel$export(module.exports, "LicenseCheckGeneric", ()=>$ddaf107751e7a6eb$export$b2d39e859fdd5656);
    var $5uXOq = parcelRequire("5uXOq");
    var $beRD7;
    var $ef5Xn;
    var $dKwVp;
    const $ddaf107751e7a6eb$export$b2d39e859fdd5656 = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dKwVp")).LicenseStateCheck), {
            type: "servicedesk"
        }, ({ licenseState: licenseState })=>{
            if (licenseState === (0, (0, parcelRequire("ef5Xn")).LICENSE_STATE_ACTIVE)) return children;
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("beRD7")).GenericPermissionErrorPageAsync), null);
        });
});
parcelRegister("dKwVp", function(module, exports) {
    $parcel$export(module.exports, "LicenseStateCheck", ()=>$689f679434973539$export$ce623a03a46f6b64);
    var $5uXOq = parcelRequire("5uXOq");
    var $ef5Xn;
    var $9OXo1;
    const $689f679434973539$var$appLicenseMapper = {
        software: 'software',
        servicedesk: 'serviceDesk'
    };
    const $689f679434973539$export$17fd9ec24bb0187f = (tenantContext, type)=>{
        if (tenantContext.licenseStates) return tenantContext.licenseStates[$689f679434973539$var$appLicenseMapper[type]];
        return 0, (0, parcelRequire("ef5Xn")).LICENSE_STATE_UNLICENSED;
    };
    const $689f679434973539$export$ce623a03a46f6b64 = ({ children: children, type: type })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("9OXo1")).TenantContextSubscriber), null, ({ tenantContext: tenantContext })=>{
            const licenseState = $689f679434973539$export$17fd9ec24bb0187f(tenantContext, type);
            return children({
                licenseState: licenseState
            });
        });
});
parcelRegister("6ot3i", function(module, exports) {
    $parcel$export(module.exports, "LicenseCheck", ()=>$72410b48a237c273$export$944be0b3bf2a6feb);
    var $5uXOq = parcelRequire("5uXOq");
    var $ef5Xn;
    var $d31Su;
    var $gmp1S;
    var $dKwVp;
    const $72410b48a237c273$export$944be0b3bf2a6feb = ({ children: children })=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("dKwVp")).LicenseStateCheck), {
            type: "software"
        }, ({ licenseState: licenseState })=>{
            if (licenseState === (0, (0, parcelRequire("ef5Xn")).LICENSE_STATE_ACTIVE)) return children;
            if (licenseState === (0, (0, parcelRequire("ef5Xn")).LICENSE_STATE_SUSPENDED)) return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gmp1S")).AsyncSuspendedSoftwareLicense), null);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("d31Su")).AsyncNoSoftwareLicense), null);
        });
});
parcelRegister("d31Su", function(module, exports) {
    $parcel$export(module.exports, "AsyncNoSoftwareLicense", ()=>$dcd2c1aef082694d$export$52d8964299339cae);
    var $5uXOq = parcelRequire("5uXOq");
    var $kb4zh;
    var $lfTZh;
    var $kxZPA;
    const $dcd2c1aef082694d$var$LazyNoSoftwareLicense = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("1qjZt")), {
        moduleId: "./src/packages/spa-apps/common/src/error-screens/no-software-license/index.tsx"
    });
    const $dcd2c1aef082694d$export$52d8964299339cae = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "no-software-license",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kb4zh")).default), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($dcd2c1aef082694d$var$LazyNoSoftwareLicense, null));
});
parcelRegister("1qjZt", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("lzKF1").then(()=>parcelRequire('eX102'));
});
parcelRegister("gmp1S", function(module, exports) {
    $parcel$export(module.exports, "AsyncSuspendedSoftwareLicense", ()=>$507e5a2719a80114$export$a2b6823ff422b6ba);
    var $5uXOq = parcelRequire("5uXOq");
    var $kb4zh;
    var $lfTZh;
    var $kxZPA;
    const $507e5a2719a80114$var$LazySuspendedSoftwareLicense = (0, (0, parcelRequire("kxZPA")).lazyForPaint)(()=>(parcelRequire("ammVF")), {
        moduleId: "./src/packages/spa-apps/common/src/error-screens/suspended-software-license/index.tsx"
    });
    const $507e5a2719a80114$export$a2b6823ff422b6ba = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "suspended-software-license",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("kb4zh")).default), null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($507e5a2719a80114$var$LazySuspendedSoftwareLicense, null));
});
parcelRegister("ammVF", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = $6fhBS("bPGOX").then(()=>parcelRequire('6gs7x'));
});
parcelRegister("k1boH", function(module, exports) {
    $parcel$export(module.exports, "LocationlessDialog", ()=>$481ecef23c36e845$export$35c86e8920989ff4);
    var $5uXOq = parcelRequire("5uXOq");
    var $4DPZY;
    var $ieDtd;
    var $err48;
    const $481ecef23c36e845$export$35c86e8920989ff4 = ()=>{
        const baseUrl = '';
        const [rapidView] = (0, (0, parcelRequire("ieDtd")).useQueryParam)('rapidView');
        const [{ loading: loading, data: data }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        if (!loading && !data) {
            const rapidViewId = Number(rapidView);
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("err48")).default), {
                baseUrl: baseUrl,
                rapidViewId: rapidViewId
            });
        }
        return null;
    };
});
parcelRegister("err48", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$c406f56aabae5d86$export$2e2bcd8739ae039);
    var $5uXOq = parcelRequire("5uXOq");
    var $gAeCk;
    var $heNNl;
    var $lfTZh;
    var $kxZPA;
    const $c406f56aabae5d86$var$LOAD_START_LOCATION_LESS_DIALOG_MARK_KEY = 'LOAD_START_LOCATION_LESS_DIALOG_MARK_KEY';
    const $c406f56aabae5d86$var$LazyLocationlessDialogApp = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("kQH2l")), {
        moduleId: "./src/packages/board-location-dialogs/locationless/src/app.tsx"
    });
    var $c406f56aabae5d86$export$2e2bcd8739ae039 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("heNNl")).default), {
            id: "async.jira-board-location-dialogs-locationless.defer",
            packageName: "jiraClassicBoardAndBacklog"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "locationless-dialog-app",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("gAeCk")).default), {
                metricKey: $c406f56aabae5d86$var$LOAD_START_LOCATION_LESS_DIALOG_MARK_KEY
            })
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($c406f56aabae5d86$var$LazyLocationlessDialogApp, props)));
});
parcelRegister("kQH2l", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("boUnd")
    ]).then(()=>parcelRequire('h6olf'));
});
parcelRegister("7EqN2", function(module, exports) {
    $parcel$export(module.exports, "RapidBoardRoot", ()=>$9434a51aa0532408$export$434e4e9fbd0546b9);
    var $5uXOq = parcelRequire("5uXOq");
    var $4xUTr;
    const $9434a51aa0532408$export$434e4e9fbd0546b9 = ()=>(0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            id: "content",
            className: "z-index-content"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            id: "gh",
            className: "ghx-gh aui-page-panel"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            id: "ghx-header"
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            id: "ghx-content-main",
            className: "ghx-content-main"
        })), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("4xUTr")).IssueActions), null));
});
parcelRegister("4xUTr", function(module, exports) {
    $parcel$export(module.exports, "IssueActions", ()=>$a757658517940ccc$export$8f0a14bb9a7ce353);
    var $5uXOq = parcelRequire("5uXOq");
    var $koVbs;
    var $gQ2J4;
    var $2YP9W;
    var $2IOAD;
    const $a757658517940ccc$export$8f0a14bb9a7ce353 = ()=>{
        const { data: data } = (0, (0, parcelRequire("2YP9W")).useResource)((0, (0, parcelRequire("gQ2J4")).rapidboardResource));
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const wrappedFormatMessage = (...params)=>formatMessage(...params, {
                '0': '{0}'
            });
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("div", {
            className: "hidden js-issueaction-container-dot"
        }, data?.issueOperations?.map((action)=>(0, ($parcel$interopDefault($5uXOq))).createElement("a", {
                key: action.id + action.style,
                id: action.id,
                className: `js-issueaction ${action.style}`,
                href: action.url
            }, (0, ($parcel$interopDefault($5uXOq))).createElement("span", {
                className: "hidden"
            }, "Link for ", action.id)))), (0, ($parcel$interopDefault($5uXOq))).createElement("fieldset", {
            className: "hidden parameters"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_updated",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueUpdated)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_transitioned",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueUpdated)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_assigned",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueAssigned)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_commented",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueCommented)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_worklogged",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueWorklogged)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_voted",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueVoted)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_watched",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueWatched)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_moved",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueMoved)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_linked",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueLinked)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_cloned",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueCloned)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_labelled",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueLabelled)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_deleted",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueDeleted)
        }), (0, ($parcel$interopDefault($5uXOq))).createElement("input", {
            type: "hidden",
            id: "thanks_issue_attached",
            value: wrappedFormatMessage((0, (0, parcelRequire("2IOAD")).messages).issueAttached)
        })));
    };
});
parcelRegister("2IOAD", function(module, exports) {
    $parcel$export(module.exports, "messages", ()=>$54978c8c94edc1b4$export$defe85febe8b728c);
    var $7VHMR;
    const $54978c8c94edc1b4$export$defe85febe8b728c = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        issueUpdated: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-updated",
            "defaultMessage": "{0} has been updated."
        },
        issueTransitioned: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-transitioned",
            "defaultMessage": "{0} has been updated."
        },
        issueAssigned: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-assigned",
            "defaultMessage": "{0} has been assigned."
        },
        issueCommented: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-commented",
            "defaultMessage": "{0} has been updated with your comment."
        },
        issueWorklogged: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-worklogged",
            "defaultMessage": "Work has been logged on {0}."
        },
        issueVoted: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-voted",
            "defaultMessage": "Your vote on {0} has been updated."
        },
        issueWatched: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-watched",
            "defaultMessage": "Your watcher status on {0} has been updated."
        },
        issueMoved: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-moved",
            "defaultMessage": "{0} has been moved."
        },
        issueLinked: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-linked",
            "defaultMessage": "The links on {0} have been updated."
        },
        issueCloned: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-cloned",
            "defaultMessage": "{0} has been cloned."
        },
        issueLabelled: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-labelled",
            "defaultMessage": "The labels on {0} have been updated."
        },
        issueDeleted: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-deleted",
            "defaultMessage": "{0} has been deleted."
        },
        issueAttached: {
            "id": "classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-attached",
            "defaultMessage": "The file(s) have been attached to {0}."
        }
    });
});
parcelRegister("2wpxU", function(module, exports) {
    $parcel$export(module.exports, "StyleReset", ()=>$004a5b30a10bb490$export$f56c26255765fd48);
    var $5uXOq = parcelRequire("5uXOq");
    var $bwpsq;
    const $004a5b30a10bb490$export$f56c26255765fd48 = (0, $5uXOq.forwardRef)(({ as: C = "div", style: __cmpls, ...__cmplp }, __cmplr)=>{
        return $5uXOq.createElement(C, {
            ...__cmplp,
            style: __cmpls,
            ref: __cmplr,
            className: (0, (0, parcelRequire("bwpsq")).default)([
                "_wmvt1wug",
                __cmplp.className
            ])
        });
    });
});
parcelRegister("3EGEn", function(module, exports) {
    $parcel$export(module.exports, "RoadmapSettingRedirect", ()=>$46f1476d5372965f$export$c59f8e851148ddf9);
    var $5uXOq = parcelRequire("5uXOq");
    var $ebyGx = parcelRequire("ebyGx");
    var $4DPZY;
    var $7OYJs;
    var $doZTA;
    const $46f1476d5372965f$var$SOFTWARE_TIMELINE_CLASSIC_REGEX = /\/jira\/software\/c\/projects\/(\w+)\/boards\/(\d+)/;
    const $46f1476d5372965f$export$c59f8e851148ddf9 = ()=>{
        const [{ data: data, error: error }] = (0, (0, parcelRequire("4DPZY")).useContainerContext)();
        const [{ query: { config: config, ...params }, location: location }] = (0, (0, parcelRequire("doZTA")).useRouter)();
        const match = $46f1476d5372965f$var$SOFTWARE_TIMELINE_CLASSIC_REGEX.exec(location.pathname);
        if (!data || error) return null;
        if (match && config === 'roadmapConfig') {
            let url = `${location.pathname}?config=timelineConfig`;
            const queryParams = (0, $ebyGx.stringify)(params);
            url += queryParams ? `&${queryParams}` : '';
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("7OYJs")).ScreenRedirect), {
                to: url,
                persistSearchParams: false
            });
        }
        return null;
    };
});
parcelRegister("9tdxX", function(module, exports) {
    $parcel$export(module.exports, "SLATracker", ()=>$7acc9dc22a1f7d24$export$ccc9bce94d001ecf);
    var $5uXOq = parcelRequire("5uXOq");
    var $g0Gtc;
    var $1pMQS;
    var $6ob06;
    var $fQzST;
    var $4hXjG;
    var $bFkfl;
    var $dwq0W;
    const $7acc9dc22a1f7d24$export$ccc9bce94d001ecf = ()=>{
        const { slaFailure: slaFailure, hasFinishedRetrying: hasFinishedRetrying } = (0, (0, parcelRequire("6ob06")).useSLAHandler)();
        const { mode: mode, config: config } = (0, (0, parcelRequire("4hXjG")).useUrlState)();
        if (hasFinishedRetrying && slaFailure !== null && slaFailure !== undefined && !(0, (0, parcelRequire("dwq0W")).isBoardSettings)(config)) {
            const { name: name = '' } = slaFailure;
            if ((0, (0, parcelRequire("dwq0W")).isUserNetworkError)(name)) return null;
            const failedReason = (0, (0, parcelRequire("dwq0W")).getFailedReason)(slaFailure);
            const status = (0, (0, parcelRequire("dwq0W")).getStatusCode)(slaFailure);
            const slaResult = (0, (0, parcelRequire("fQzST")).getSLAResult)(status, mode);
            const isSLAFailureDetected = slaResult.state === (0, (0, parcelRequire("1pMQS")).FAILURE);
            if (isSLAFailureDetected) {
                const dataAttributes = (0, (0, parcelRequire("dwq0W")).getDataAttributes)();
                return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g0Gtc")).ExperienceFailureTracker), {
                    location: failedReason,
                    failureEventAttributes: {
                        statusCode: status === null ? (0, (0, parcelRequire("bFkfl")).UNKNOWN_STATUSCODE) : status,
                        errorMessage: (0, (0, parcelRequire("dwq0W")).getErrorMessage)(failedReason, status),
                        debugAttribute: (0, (0, parcelRequire("dwq0W")).getDebugAttributes)(mode, status, slaFailure),
                        ...dataAttributes
                    }
                });
            }
            return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("g0Gtc")).ExperienceSuccessTracker), {
                location: failedReason
            });
        }
        return null;
    };
});
parcelRegister("6ob06", function(module, exports) {
    $parcel$export(module.exports, "useSLAHandler", ()=>$6157e94034e4d525$export$a66926222dff2756);
    var $f5kpo;
    var $eJfvV;
    var $9S1TY;
    var $1RCC1;
    const $6157e94034e4d525$export$a66926222dff2756 = ()=>{
        const { loading: dataLoading, error: dataError, hasFinishedRetrying: hasFinishedRetrying } = (0, (0, parcelRequire("9S1TY")).useRapidBoardResource)({
            resource: (0, (0, parcelRequire("f5kpo")).getRapidBoardDataResource)(),
            shouldShowFlag: false,
            retryPredicate: (0, (0, parcelRequire("eJfvV")).isAllDataErrorValid)
        });
        const { errorGHContext: errorGHContext } = (0, (0, parcelRequire("1RCC1")).useWrmBundleLoader)();
        const isResourceOrApiLoading = dataLoading;
        const slaError = (0, (0, parcelRequire("eJfvV")).isAllDataErrorValid)(dataError) ? dataError : null;
        return {
            slaFailure: !isResourceOrApiLoading ? slaError : null,
            dataError: dataError,
            wrmError: errorGHContext,
            hasFinishedRetrying: hasFinishedRetrying
        };
    };
});
parcelRegister("7Q2kM", function(module, exports) {
    $parcel$export(module.exports, "DevDetailsDialogLoaderAsync", ()=>$1e951f8230b07137$export$f5ffc0f323543e16);
    var $5uXOq = parcelRequire("5uXOq");
    var $8Rkzz = parcelRequire("8Rkzz");
    var $lfTZh;
    var $kxZPA;
    const $1e951f8230b07137$var$DevDetailDialogLoaderWithContainer = (0, (0, parcelRequire("kxZPA")).lazy)(()=>(parcelRequire("g0ZeL")), {
        ssr: false,
        moduleId: "./src/packages/development/details-loader/src/ui/index.tsx"
    });
    const $1e951f8230b07137$export$f5ffc0f323543e16 = (props)=>(0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lfTZh")).default), {
            name: "dev-detail-dialog-loader-with-container",
            fallback: (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($1e951f8230b07137$var$DevDetailDialogLoaderWithContainer, props));
    $1e951f8230b07137$export$f5ffc0f323543e16.defaultProps = {
        onCloseDevDetailDialog: (0, ($parcel$interopDefault($8Rkzz)))
    };
});
parcelRegister("g0ZeL", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("7P2LB")
    ]).then(()=>parcelRequire('ffvXN'));
});
parcelRegister("66eb8", function(module, exports) {
    $parcel$export(module.exports, "asyncRunInitBridge", ()=>$fb8cedc5f5c10c66$export$da4dfe182afd32b5);
    var $6pYuQ;
    const $fb8cedc5f5c10c66$export$da4dfe182afd32b5 = ()=>{
        (0, (0, parcelRequire("6pYuQ")).initBridge)();
    };
});
parcelRegister("6pYuQ", function(module, exports) {
    $parcel$export(module.exports, "initBridge", ()=>$6c50c6f690dc60ce$export$7c01844c99427400);
    var $5uXOq = parcelRequire("5uXOq");
    var $7nsd3 = parcelRequire("7nsd3");
    var $6BZYo;
    var $lxnx9;
    const $6c50c6f690dc60ce$export$7c01844c99427400 = ()=>{
        const editSprintButtonBridge = {
            render: (domObj, props)=>{
                if (document.body && document.body.contains(domObj)) (0, $7nsd3.render)((0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("lxnx9")).EditSprintButtonApp), props), domObj);
            },
            unmount: (container)=>{
                if (document.body && document.body.contains(container)) (0, $7nsd3.unmountComponentAtNode)(container);
            }
        };
        (0, (0, parcelRequire("6BZYo")).loadBridge)({
            name: 'jira-agile/rapid/ui/sprint-edit-modal-bridge'
        }).then((bridge)=>bridge.resolve(editSprintButtonBridge));
    };
});
parcelRegister("lxnx9", function(module, exports) {
    $parcel$export(module.exports, "EditSprintButtonApp", ()=>$b5708dc732daf896$export$355ec03fbecca550);
    var $5uXOq = parcelRequire("5uXOq");
    var $bvUB1;
    var $5OX1e;
    var $39xOx;
    var $koVbs;
    var $1nUas;
    var $iXVV6;
    var $8zOmE;
    var $lhVtI;
    var $2NMNM;
    var $fjcgS;
    var $fiv1r;
    var $eCm1X;
    var $3KLL7;
    var $d5YAu;
    var $hWnRN = parcelRequire("hWnRN");
    const $b5708dc732daf896$export$355ec03fbecca550 = (props)=>{
        const onEvent = (0, $5uXOq.useCallback)((event)=>{
            (0, (0, parcelRequire("fjcgS")).getAnalyticsWebClientPromise)().then((client)=>{
                if (client) client.sendEvent((0, (0, parcelRequire("lhVtI")).getEvent)(event));
            });
        }, []);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("39xOx")).JSErrorBoundary), {
            id: "editSprintModalLegacyBridgeEditButtonApp",
            packageName: "jiraEditSprintModalLegacyBridge",
            teamName: "a4t-pandora"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("iXVV6")).ContextualAnalyticsData), {
            sourceType: (0, $4d5e0871c06cee03$export$f636b5b56f1d624a),
            sourceName: "cmpReports"
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("bvUB1")).default), {
            channel: "*",
            onEvent: onEvent
        }, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("fiv1r")).default), null, (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("1nUas")).IntlProvider), {
            messages: $hWnRN,
            locale: (0, (0, parcelRequire("5OX1e")).default)(props.locale)
        }, (0, ($parcel$interopDefault($5uXOq))).createElement($b5708dc732daf896$export$4c5785b6a33764d0, props))))));
    };
    const $b5708dc732daf896$export$4c5785b6a33764d0 = ({ boardId: boardId, sprintId: sprintId, onSubmit: onSubmit })=>{
        const { formatMessage: formatMessage } = (0, (0, parcelRequire("koVbs")).default)();
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject, stopMetric: stopMetric, editSprintModalTriggerRef: editSprintModalTriggerRef } = (0, (0, parcelRequire("3KLL7")).useEditSprintModalEntryPoint)(boardId, sprintId, true);
        const { createAnalyticsEvent: createAnalyticsEvent } = (0, (0, parcelRequire("2NMNM")).useAnalyticsEvents)();
        const handleOpenEditSprint = (0, $5uXOq.useCallback)((e)=>{
            e.preventDefault();
            const analyticsEvent = createAnalyticsEvent({
                action: 'clicked',
                actionSubject: 'dropdownItem'
            });
            (0, (0, parcelRequire("8zOmE")).fireUIAnalytics)(analyticsEvent, 'openEditSprint');
        }, [
            createAnalyticsEvent
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, ($parcel$interopDefault($5uXOq))).Fragment, null, (0, ($parcel$interopDefault($5uXOq))).createElement("a", {
            id: "ghx-edit-sprint",
            role: "button",
            href: "#",
            ref: editSprintModalTriggerRef,
            onClick: handleOpenEditSprint
        }, formatMessage((0, (0, parcelRequire("d5YAu")).default).editSprint)), (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("eCm1X")).EditSprintModalEntryPointContainer), {
            boardId: boardId,
            isCompanyManaged: true,
            entryPointReferenceSubject: entryPointReferenceSubject,
            entryPointActions: entryPointActions,
            onReady: stopMetric,
            onClose: ()=>{},
            onSubmit: onSubmit || (()=>{})
        }));
    };
});
parcelRegister("5OX1e", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$90859c0b844c53f2$export$2e2bcd8739ae039);
    var $90859c0b844c53f2$export$2e2bcd8739ae039 = (locale)=>locale ? locale.substring(0, locale.indexOf('_')) : '';
});
parcelRegister("eCm1X", function(module, exports) {
    $parcel$export(module.exports, "EditSprintModalEntryPointContainer", ()=>$f893e281e5a7b120$export$385f16077f730dc3);
    var $5uXOq = parcelRequire("5uXOq");
    var $ivDCs;
    var $467lJ;
    var $pa9q9;
    var $dXq4S;
    var $koeKL;
    const $f893e281e5a7b120$export$385f16077f730dc3 = ({ boardId: boardId, isCompanyManaged: isCompanyManaged, entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject, triggerRef: triggerRef, onSubmit: onSubmit, onReady: onReady, onClose: onClose })=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const { showFlag: showFlag } = (0, (0, parcelRequire("pa9q9")).useFlagsService)();
        const boardAri = (0, (0, parcelRequire("dXq4S")).createAri)({
            resourceId: String(boardId),
            resourceType: 'board',
            resourceOwner: 'jira-software',
            cloudId: cloudId
        });
        const handleSubmit = (0, $5uXOq.useCallback)((data, requestPromise)=>{
            requestPromise.catch(()=>{
                showFlag((0, (0, parcelRequire("467lJ")).default).genericError());
                return {
                    editSprint: null
                };
            });
            onSubmit(data, requestPromise);
        }, [
            onSubmit,
            showFlag
        ]);
        const props = (0, $5uXOq.useMemo)(()=>({
                boardAri: boardAri,
                isCompanyManaged: isCompanyManaged,
                triggerRef: triggerRef,
                fetchSprints: true,
                onSubmit: handleSubmit,
                ...onReady && {
                    onReady: onReady
                },
                ...onClose && {
                    onClose: onClose
                }
            }), [
            boardAri,
            isCompanyManaged,
            handleSubmit,
            onReady,
            onClose,
            triggerRef
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("ivDCs")).ModalEntryPointContainer), {
            id: "edit-sprint-modal",
            teamName: "a4t-pandora",
            packageName: "jiraSoftwareEditSprintModalRelay",
            entryPointActions: entryPointActions,
            entryPointReferenceSubject: entryPointReferenceSubject,
            runtimeProps: props
        });
    };
});
parcelRegister("ivDCs", function(module, exports) {
    $parcel$export(module.exports, "ModalEntryPointContainer", ()=>$832a9e21b9f9d7ff$export$4d67c6ee49f3d14d);
    var $5uXOq = parcelRequire("5uXOq");
    var $i8Qvm;
    var $8Bm0k;
    var $c5vfY;
    const $832a9e21b9f9d7ff$export$4d67c6ee49f3d14d = ({ entryPointActions: { unload: unload }, entryPointReferenceSubject: entryPointReferenceSubject, errorFallback: errorFallback, runtimeProps: runtimeProps, ...containerProps })=>{
        const entryPointReference = (0, (0, parcelRequire("8Bm0k")).useEntryPointSubscription)(entryPointReferenceSubject);
        const props = (0, $5uXOq.useMemo)(()=>({
                ...runtimeProps,
                onClose () {
                    runtimeProps.onClose?.();
                    unload();
                }
            }), [
            runtimeProps,
            unload
        ]);
        return (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("i8Qvm")).default), null, entryPointReference && (0, ($parcel$interopDefault($5uXOq))).createElement((0, (0, parcelRequire("c5vfY")).InternalEntryPointContainer), {
            ...containerProps,
            entryPointReference: entryPointReference,
            errorFallback: errorFallback || 'flag',
            placeholderName: "modal-entry-point-container",
            runtimeProps: props
        }));
    };
});
parcelRegister("467lJ", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$dc639d9ac2372482$export$2e2bcd8739ae039);
    var $8BkAH;
    var $8hiuq;
    var $l7mS2;
    var $5jDRa;
    var $dc639d9ac2372482$export$2e2bcd8739ae039 = {
        forbidden: (0, parcelRequire("8BkAH")).default,
        genericError: (0, parcelRequire("8hiuq")).default,
        serverError: (0, parcelRequire("l7mS2")).default,
        unauthorized: (0, parcelRequire("5jDRa")).default
    };
});
parcelRegister("8BkAH", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$47acdb71562a5159$export$2e2bcd8739ae039);
    var $7VHMR;
    const $47acdb71562a5159$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "platform.ui.flags.flags.errors.forbidden.title",
            "defaultMessage": "You don't have permission to do that"
        },
        description: {
            "id": "platform.ui.flags.flags.errors.forbidden.description",
            "defaultMessage": "Ask your Jira admin for help."
        }
    });
    var $47acdb71562a5159$export$2e2bcd8739ae039 = ()=>({
            type: 'error',
            title: $47acdb71562a5159$var$messages.title,
            description: $47acdb71562a5159$var$messages.description
        });
});
parcelRegister("8hiuq", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$ffd4533bf12eb3f1$export$2e2bcd8739ae039);
    var $7VHMR;
    const $ffd4533bf12eb3f1$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "platform.ui.flags.flags.errors.generic-error.title",
            "defaultMessage": "Something's gone wrong"
        },
        description: {
            "id": "platform.ui.flags.flags.errors.generic-error.description",
            "defaultMessage": "Refresh the page and try again."
        }
    });
    var $ffd4533bf12eb3f1$export$2e2bcd8739ae039 = ()=>({
            type: 'error',
            title: $ffd4533bf12eb3f1$var$messages.title,
            description: $ffd4533bf12eb3f1$var$messages.description
        });
});
parcelRegister("l7mS2", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$31a915dd4c1d2142$export$2e2bcd8739ae039);
    var $7VHMR;
    const $31a915dd4c1d2142$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "platform.ui.flags.flags.errors.server-error.title",
            "defaultMessage": "We're having some trouble"
        },
        description: {
            "id": "platform.ui.flags.flags.errors.server-error.description",
            "defaultMessage": "Something went wrong on our end. Refresh the page and try again."
        }
    });
    var $31a915dd4c1d2142$export$2e2bcd8739ae039 = ()=>({
            type: 'error',
            title: $31a915dd4c1d2142$var$messages.title,
            description: $31a915dd4c1d2142$var$messages.description
        });
});
parcelRegister("5jDRa", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$8754751d720d57d4$export$2e2bcd8739ae039);
    var $7VHMR;
    const $8754751d720d57d4$var$messages = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        title: {
            "id": "platform.ui.flags.flags.errors.unauthorized.title",
            "defaultMessage": "You're logged out"
        },
        description: {
            "id": "platform.ui.flags.flags.errors.unauthorized.description",
            "defaultMessage": "Log back in and try again."
        }
    });
    var $8754751d720d57d4$export$2e2bcd8739ae039 = ()=>({
            type: 'error',
            title: $8754751d720d57d4$var$messages.title,
            description: $8754751d720d57d4$var$messages.description
        });
});
parcelRegister("3KLL7", function(module, exports) {
    $parcel$export(module.exports, "useEditSprintModalEntryPoint", ()=>$891dbcaefc4d45a6$export$6009493f90785e59);
    var $5uXOq = parcelRequire("5uXOq");
    var $47gW9;
    var $4mO32;
    var $dXq4S;
    var $koeKL;
    var $bsWqi;
    var $cYsq9;
    const $891dbcaefc4d45a6$export$6009493f90785e59 = (boardId, sprintId, isCompanyManaged)=>{
        const cloudId = (0, (0, parcelRequire("koeKL")).useCloudId)();
        const boardAri = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("dXq4S")).createAri)({
                resourceOwner: 'jira-software',
                cloudId: cloudId,
                resourceType: 'board',
                resourceId: String(boardId)
            }), [
            boardId,
            cloudId
        ]);
        const sprintAri = (0, $5uXOq.useMemo)(()=>(0, (0, parcelRequire("dXq4S")).createAri)({
                resourceOwner: 'jira-software',
                cloudId: cloudId,
                resourceType: 'sprint',
                resourceId: sprintId
            }), [
            cloudId,
            sprintId
        ]);
        const entryPointParams = (0, $5uXOq.useMemo)(()=>({
                boardId: boardAri,
                sprintId: sprintAri,
                fetchSprints: false,
                isCompanyManaged: isCompanyManaged
            }), [
            boardAri,
            sprintAri,
            isCompanyManaged
        ]);
        const { entryPointActions: entryPointActions, entryPointReferenceSubject: entryPointReferenceSubject, stopMetric: stopMetric } = (0, (0, parcelRequire("4mO32")).useEntryPoint)((0, (0, parcelRequire("bsWqi")).editSprintModalEntryPoint), entryPointParams, (0, (0, parcelRequire("cYsq9")).editSprintModalLoad));
        const editSprintModalTriggerRef = (0, (0, parcelRequire("47gW9")).useEntryPointButtonTrigger)(entryPointActions);
        return {
            entryPointActions: entryPointActions,
            entryPointReferenceSubject: entryPointReferenceSubject,
            stopMetric: stopMetric,
            editSprintModalTriggerRef: editSprintModalTriggerRef
        };
    };
});
parcelRegister("47gW9", function(module, exports) {
    $parcel$export(module.exports, "useEntryPointButtonTrigger", ()=>$1be876415a945060$export$25d2524a63315ed);
    $parcel$export(module.exports, "getDefaultEntrypointActionEventMap", ()=>$1be876415a945060$export$5f13515bd3a35325);
    var $5uXOq = parcelRequire("5uXOq");
    var $iRKpN;
    const $1be876415a945060$export$25d2524a63315ed = (actions, isActive = true, eventMap = $1be876415a945060$export$5f13515bd3a35325(actions))=>{
        const removePreviousListeners = (0, $5uXOq.useRef)(null);
        const isMounted = (0, $5uXOq.useRef)(true);
        (0, $5uXOq.useEffect)(()=>()=>{
                removePreviousListeners.current?.();
                removePreviousListeners.current = null;
                isMounted.current = false;
            }, []);
        return (0, $5uXOq.useCallback)((element)=>{
            if (!isMounted.current) return;
            removePreviousListeners.current?.();
            if (element && isActive) {
                (0, (0, parcelRequire("iRKpN")).modifyListeners)({
                    element: element,
                    operationName: 'add',
                    eventMap: eventMap
                });
                removePreviousListeners.current = ()=>{
                    (0, (0, parcelRequire("iRKpN")).modifyListeners)({
                        element: element,
                        operationName: 'remove',
                        eventMap: eventMap
                    });
                };
            } else removePreviousListeners.current = null;
        }, [
            eventMap,
            isActive
        ]);
    };
    const $1be876415a945060$export$5f13515bd3a35325 = (actions)=>({
            click: actions.load,
            mouseenter: actions.preload,
            mouseleave: actions.cancelPreload,
            focusin: actions.preload,
            focusout: actions.cancelPreload
        });
});
parcelRegister("iRKpN", function(module, exports) {
    $parcel$export(module.exports, "modifyListeners", ()=>$62bae43418420b8a$export$15257a36a7e3f9d6);
    function $62bae43418420b8a$export$15257a36a7e3f9d6(params) {
        const { element: element, operationName: operationName, eventMap: eventMap } = params;
        const functionName = operationName === 'add' ? 'addEventListener' : 'removeEventListener';
        if (element) Object.keys(eventMap).forEach((eventName)=>element[functionName](eventName, eventMap[eventName]));
    }
});
parcelRegister("bsWqi", function(module, exports) {
    $parcel$export(module.exports, "editSprintModalEntryPoint", ()=>$505b0c872e6b9936$export$62497640baecc927);
    var $58I1r;
    var $eufEc;
    var $fEDBv;
    const $505b0c872e6b9936$export$62497640baecc927 = (0, (0, parcelRequire("fEDBv")).createEntryPoint)({
        root: (0, (0, parcelRequire("eufEc")).JSResourceForInteraction)(()=>(parcelRequire("ebu5E")), {
            moduleId: "c7ce165f"
        }),
        getPreloadProps: ({ boardId: boardId, sprintId: sprintId, fetchSprints: fetchSprints })=>({
                queries: {
                    editSprintModal: {
                        options: {
                            fetchPolicy: 'network-only'
                        },
                        parameters: (0, parcelRequire("58I1r")).default,
                        variables: {
                            boardId: boardId,
                            sprintId: sprintId,
                            fetchSprints: fetchSprints
                        }
                    }
                }
            })
    });
});
parcelRegister("58I1r", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$b86022cd8e5ea5c2$export$2e2bcd8739ae039);
    const $b86022cd8e5ea5c2$var$node = {
        "kind": "PreloadableConcreteRequest",
        "params": {
            "id": "90bffcc09f8865bee8f7861946866baaa79e714bb9afc19568bc71a86050977b",
            "metadata": {},
            "name": "uiJiraSoftwareEditSprintModalQuery",
            "operationKind": "query",
            "text": null
        }
    };
    var $b86022cd8e5ea5c2$export$2e2bcd8739ae039 = $b86022cd8e5ea5c2$var$node;
});
parcelRegister("ebu5E", function(module, exports) {
    var $6fhBS = parcelRequire("6fhBS");
    module.exports = Promise.all([
        $6fhBS("hPzOn"),
        $6fhBS("cM9em"),
        $6fhBS("fr9NE"),
        $6fhBS("h2MrD"),
        $6fhBS("bYT2S"),
        $6fhBS("3EQwu")
    ]).then(()=>parcelRequire('cqAIL'));
});
parcelRegister("cYsq9", function(module, exports) {
    $parcel$export(module.exports, "clickEditSprintInteraction", ()=>$c290b89d2730547b$export$9c57106ef5795d9a);
    $parcel$export(module.exports, "editSprintModalLoad", ()=>$c290b89d2730547b$export$c39d6b5415c8a491);
    var $dGrdR;
    const $c290b89d2730547b$export$9c57106ef5795d9a = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'sprint-edit-click'
    });
    const $c290b89d2730547b$export$c39d6b5415c8a491 = (0, (0, parcelRequire("dGrdR")).metrics).interaction({
        key: 'edit-sprint-modal-load'
    });
});
parcelRegister("d5YAu", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$1bcc7eb4fde9eb7f$export$2e2bcd8739ae039);
    var $7VHMR;
    var $1bcc7eb4fde9eb7f$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        editSprint: {
            "id": "edit-sprint-modal-legacy-bridge.edit-sprint-button.edit-sprint",
            "defaultMessage": "Edit sprint"
        }
    });
});
parcelRegister("3pxen", function(module, exports) {
    $parcel$export(module.exports, "getActiveQuickFilters", ()=>$a84023e2615bfb43$export$40e8efdeac3c81db);
    var $65Ltd;
    const $a84023e2615bfb43$var$ACTIVE_QUICK_FILTERS = 'activeQuickFilters';
    const $a84023e2615bfb43$var$PLAN_QUICK_FILTERS = 'planQuickFilters';
    const $a84023e2615bfb43$export$40e8efdeac3c81db = (name)=>{
        const { GH: GH } = window;
        if (GH?.RapidBoard?.State) {
            if (name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BOARD)) return GH.RapidBoard.State.getBoardSettings()[$a84023e2615bfb43$var$ACTIVE_QUICK_FILTERS];
            if (name === (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_BACKLOG)) return GH.RapidBoard.State.getBoardSettings()[$a84023e2615bfb43$var$PLAN_QUICK_FILTERS];
        }
        return '';
    };
});
parcelRegister("gEzFY", function(module, exports) {
    $parcel$export(module.exports, "useRapidBoardLongTasks", ()=>$1d1402c25759233e$export$3ff0c45230113a8c);
    var $5uXOq = parcelRequire("5uXOq");
    var $7wqBZ;
    var $bv8GQ;
    const $1d1402c25759233e$export$3ff0c45230113a8c = (mode)=>{
        (0, $5uXOq.useEffect)(()=>{
            if (mode !== 'rapidboard-board' && mode !== 'rapidboard-backlog') return undefined;
            const startEvent = mode === 'rapidboard-board' ? 'jira:gh:work:drag-and-drop:start' : 'jira:gh:plan:drag-and-drop:start';
            const endEvent = mode === 'rapidboard-board' ? 'jira:gh:work:drag-and-drop:stop' : 'jira:gh:plan:drag-and-drop:stop';
            const onDragStart = ()=>{
                (0, (0, parcelRequire("7wqBZ")).getLongTasksMetrics)('dnd').start(mode);
            };
            const onDragStop = ()=>{
                (0, (0, parcelRequire("7wqBZ")).getLongTasksMetrics)('dnd').stop((0, (0, parcelRequire("bv8GQ")).softwareGenericLongTasksReporter)('dnd'));
            };
            window.addEventListener(startEvent, onDragStart);
            window.addEventListener(endEvent, onDragStop);
            return ()=>{
                window.removeEventListener(startEvent, onDragStart);
                window.removeEventListener(endEvent, onDragStop);
                (0, (0, parcelRequire("7wqBZ")).getLongTasksMetrics)('dnd').stop((0, (0, parcelRequire("bv8GQ")).softwareGenericLongTasksReporter)('dnd'));
            };
        }, [
            mode
        ]);
    };
});
parcelRegister("7wqBZ", function(module, exports) {
    $parcel$export(module.exports, "getLongTasksMetrics", ()=>$f85af650a825558e$export$b092a8653a66aa5e);
    var $gJBT6 = parcelRequire("gJBT6");
    var $x9xyg;
    var $6RCeq;
    var $cJpFl;
    let $f85af650a825558e$var$observer = null;
    const $f85af650a825558e$var$stopObserver = ()=>{
        if ($f85af650a825558e$var$observer) {
            $f85af650a825558e$var$observer.disconnect();
            $f85af650a825558e$var$observer = null;
        }
    };
    const $f85af650a825558e$export$b092a8653a66aa5e = (0, ($parcel$interopDefault($gJBT6)))((type)=>{
        const clearData = {
            type: type
        };
        let isStarted = false;
        let observationData = {
            ...clearData
        };
        const isDnd = /dnd$/.test(type.toLowerCase());
        return {
            start: (appName, startPerformanceMark, extra = {})=>{
                if (window.PerformanceObserver && !isStarted) try {
                    const startTimestamp = (0, (0, parcelRequire("6RCeq")).default).now();
                    observationData = {
                        type: observationData.type,
                        startTimestamp: startTimestamp,
                        dragStartEndTimestamp: startTimestamp,
                        eventStartPerformanceMark: startPerformanceMark,
                        events: [],
                        appName: appName,
                        extra: extra
                    };
                    isStarted = true;
                    $f85af650a825558e$var$stopObserver();
                    $f85af650a825558e$var$observer = new window.PerformanceObserver((list)=>{
                        list.getEntries().forEach((entry)=>{
                            if (entry.entryType === 'longtask') {
                                if (!(0, (0, parcelRequire("cJpFl")).isExpandedObservationData)(observationData) || observationData.events.find((item)=>item.timestamp === entry.startTime && item.duration === entry.duration)) return;
                                observationData.events.push({
                                    timestamp: entry.startTime,
                                    duration: entry.duration
                                });
                            } else if (entry.entryType === 'event' && entry.name === 'dragstart') observationData.dragStartEndTimestamp = entry.startTime + entry.duration;
                        });
                    });
                    $f85af650a825558e$var$observer.observe({
                        type: 'longtask'
                    });
                    if (isDnd) $f85af650a825558e$var$observer.observe({
                        type: 'event',
                        durationThreshold: 48
                    });
                } catch (e) {}
            },
            stop: (callback, extra = {})=>{
                if ((0, (0, parcelRequire("6RCeq")).default).now && isStarted) {
                    const stopTimestamp = (0, (0, parcelRequire("6RCeq")).default).now();
                    setTimeout(()=>{
                        $f85af650a825558e$var$stopObserver();
                        callback({
                            ...observationData
                        }, extra, stopTimestamp);
                        observationData = {
                            ...clearData
                        };
                        isStarted = false;
                    });
                }
            },
            stopIfNoMark: (performanceMark)=>{
                if ($f85af650a825558e$var$observer && performanceMark) {
                    const perfMark = (0, (0, parcelRequire("x9xyg")).getMark)(performanceMark);
                    if (!perfMark) {
                        $f85af650a825558e$var$stopObserver();
                        observationData = {
                            ...clearData
                        };
                        isStarted = false;
                    }
                }
            }
        };
    });
});
parcelRegister("cJpFl", function(module, exports) {
    $parcel$export(module.exports, "isExpandedObservationData", ()=>$3e1de7ace69512d1$export$3d38a87309e205a4);
    const $3e1de7ace69512d1$export$3d38a87309e205a4 = (observationData)=>observationData !== undefined && 'events' in observationData && observationData.events !== undefined;
});
parcelRegister("bv8GQ", function(module, exports) {
    $parcel$export(module.exports, "softwareGenericLongTasksReporter", ()=>$2795c9afa9443b8c$export$b78c92c0ba709033);
    var $gZFbt = parcelRequire("gZFbt");
    var $if7bx;
    var $cJpFl;
    var $4R6GH;
    var $fjcgS;
    const $2795c9afa9443b8c$var$aggregatedDataTimeout = {};
    const $2795c9afa9443b8c$export$b78c92c0ba709033 = (observationType, observationEndTime)=>(observationData)=>{
            if ((0, (0, parcelRequire("4R6GH")).fg)('jsw_refactor_long-tasks_monitoring')) {
                if (!(0, (0, parcelRequire("cJpFl")).isExpandedObservationData)(observationData) || !('appName' in observationData)) return;
                const { appName: appName, startTimestamp: startTimestamp, events: events } = observationData;
                const startTime = startTimestamp;
                const endTime = observationEndTime ?? performance.now();
                const duration = endTime - startTime;
                if (duration < 100) return;
                const rawLongTasks = events ?? [];
                const longTasks = rawLongTasks.filter((event)=>event.timestamp >= startTime && event.timestamp + event.duration <= endTime).map((event)=>({
                        timestamp: event.timestamp - startTime,
                        duration: event.duration
                    }));
                const minimumBlockedDuration = longTasks.reduce((sum, longTask)=>sum + longTask.duration, 0);
                const frameRateTarget = 60;
                const budgetPerFrame = 1000 / frameRateTarget;
                const timeOverBudget = longTasks.map((event)=>event.duration - budgetPerFrame).reduce((sum, time)=>sum + time, 0);
                const summary = {
                    qualityIndex: 1 - timeOverBudget / duration,
                    hasLongTasks: longTasks.length > 0,
                    hasExtraLongTasks: longTasks.filter((l)=>l.duration >= 100).length > 0,
                    longTasks: longTasks,
                    rawLongTaskCount: rawLongTasks.length,
                    longTaskCount: longTasks.length,
                    minimumBlockedDuration: minimumBlockedDuration,
                    timeOverBudget: timeOverBudget,
                    duration: duration,
                    startTime: startTime,
                    endTime: endTime
                };
                (0, (0, parcelRequire("fjcgS")).getAnalyticsWebClientPromise)().then((client)=>{
                    client.sendEvent({
                        type: (0, $4d5e0871c06cee03$export$12346a674644c156),
                        payload: {
                            source: appName,
                            action: 'longTask',
                            actionSubject: 'ui',
                            actionSubjectId: `${appName}-${observationType}`,
                            attributes: {
                                key: appName,
                                ...summary
                            }
                        }
                    });
                });
            } else {
                if (!(0, (0, parcelRequire("cJpFl")).isExpandedObservationData)(observationData)) return;
                const startTime = observationData.events.map((event)=>event.timestamp);
                const analyticsData = {
                    ...(0, (0, parcelRequire("if7bx")).generateLongTaskAnalyticsContinuousActionSummary)(observationType, observationData.events),
                    measureTimespan: performance.now() - Math.min(observationData.startTimestamp, ...startTime)
                };
                const { appName: appName } = observationData;
                if ($2795c9afa9443b8c$var$aggregatedDataTimeout[`software.${appName}.${observationType}`]) $2795c9afa9443b8c$var$aggregatedDataTimeout[`software.${appName}.${observationType}`].data.push(analyticsData);
                else $2795c9afa9443b8c$var$aggregatedDataTimeout[`software.${appName}.${observationType}`] = {
                    data: [
                        analyticsData
                    ],
                    extra: observationData.extra,
                    timeout: setTimeout(()=>{
                        (0, (0, parcelRequire("fjcgS")).getAnalyticsWebClientPromise)().then((client)=>{
                            const { data: data, extra: extraData } = $2795c9afa9443b8c$var$aggregatedDataTimeout[`software.${appName}.${observationType}`];
                            client.sendEvent({
                                type: (0, $4d5e0871c06cee03$export$12346a674644c156),
                                payload: {
                                    source: appName,
                                    action: 'longTask',
                                    actionSubject: 'ui',
                                    actionSubjectId: `${appName}-${observationType}`,
                                    attributes: {
                                        key: appName,
                                        ...(0, (0, parcelRequire("if7bx")).getAverageResultsFromContinuousActions)(observationType, data),
                                        ...extraData,
                                        avgScrollTimespan: (0, ($parcel$interopDefault($gZFbt)))(data, 'measureTimespan')
                                    }
                                }
                            });
                            delete $2795c9afa9443b8c$var$aggregatedDataTimeout[`software.${appName}.${observationType}`];
                        });
                    }, 7000)
                };
            }
        };
});
parcelRegister("gZFbt", function(module, exports) {
    var $bxDef = parcelRequire("bxDef");
    var $520Yb = parcelRequire("520Yb");
    function $c5f24a2590a3cbef$var$meanBy(array, iteratee) {
        return $520Yb(array, $bxDef(iteratee, 2));
    }
    module.exports = $c5f24a2590a3cbef$var$meanBy;
});
parcelRegister("520Yb", function(module, exports) {
    var $gBzAF = parcelRequire("gBzAF");
    var $3a9de433504d679a$var$NAN = 0 / 0;
    function $3a9de433504d679a$var$baseMean(array, iteratee) {
        var length = array == null ? 0 : array.length;
        return length ? $gBzAF(array, iteratee) / length : $3a9de433504d679a$var$NAN;
    }
    module.exports = $3a9de433504d679a$var$baseMean;
});
parcelRegister("gBzAF", function(module, exports) {
    function $c16bb58eedefe4cd$var$baseSum(array, iteratee) {
        var result, index = -1, length = array.length;
        while(++index < length){
            var current = iteratee(array[index]);
            if (current !== undefined) result = result === undefined ? current : result + current;
        }
        return result;
    }
    module.exports = $c16bb58eedefe4cd$var$baseSum;
});
parcelRegister("if7bx", function(module, exports) {
    $parcel$export(module.exports, "triggerAnalytics", ()=>$3a39af364070fc96$export$2c57de8a70f64057);
    $parcel$export(module.exports, "generateLongTaskAnalyticsSummary", ()=>$3a39af364070fc96$export$2be2635781d23aab);
    $parcel$export(module.exports, "generateLongTaskAnalyticsSummaryWithSlo", ()=>$3a39af364070fc96$export$c674a20081e82c6a);
    $parcel$export(module.exports, "getAverageResultsFromContinuousActions", ()=>$3a39af364070fc96$export$c36fdc326d0c207c);
    $parcel$export(module.exports, "generateLongTaskAnalyticsContinuousActionSummary", ()=>$3a39af364070fc96$export$7e5f2df95eca342a);
    var $gZFbt = parcelRequire("gZFbt");
    var $iThRn = parcelRequire("iThRn");
    var $6oxJH = parcelRequire("6oxJH");
    const $3a39af364070fc96$export$2c57de8a70f64057 = (_appName)=>(_eventNameSuffix, _eventData)=>Promise.resolve(undefined);
    const $3a39af364070fc96$export$2be2635781d23aab = (prefix, longTasks)=>{
        const durations = longTasks.map((task)=>task.duration);
        return {
            [`${prefix}LongTaskCount`]: longTasks.length,
            [`${prefix}LongTaskAvg`]: longTasks.length > 0 ? durations.reduce((sumAcc, duration)=>sumAcc + duration, 0) / longTasks.length : 0,
            [`${prefix}LongTaskMax`]: longTasks.length > 0 ? Math.max(...durations) : 0
        };
    };
    const $3a39af364070fc96$export$c674a20081e82c6a = (prefix, longTasks, sloSettings)=>{
        const summary = $3a39af364070fc96$export$2be2635781d23aab(prefix, longTasks);
        if (!sloSettings) return summary;
        const framerate = summary[`${prefix}LongTaskCount`] === 0 ? 20 : 1000 / summary[`${prefix}LongTaskAvg`];
        const blocktime = summary[`${prefix}LongTaskCount`] === 0 ? 0 : summary[`${prefix}LongTaskCount`] * summary[`${prefix}LongTaskAvg`];
        const isSuccess = framerate >= sloSettings.framerateThreshold && blocktime < sloSettings.blocktimeThreshold;
        return {
            [`${prefix}LongTaskSloIsSuccess`]: isSuccess,
            ...summary
        };
    };
    const $3a39af364070fc96$export$c36fdc326d0c207c = (prefix, data)=>({
            [`${prefix}ClusterCount`]: (0, ($parcel$interopDefault($6oxJH)))(data, `${prefix}ClusterCount`) || 0,
            [`${prefix}ClusterCountAvg`]: (0, ($parcel$interopDefault($gZFbt)))(data, `${prefix}ClusterCount`) || 0,
            [`${prefix}ClusterAvg`]: (0, ($parcel$interopDefault($gZFbt)))(data, `${prefix}ClusterAvg`) || 0,
            [`${prefix}ClusterDistanceAvg`]: (0, ($parcel$interopDefault($gZFbt)))(data, `${prefix}ClusterDistanceAvg`) || 0,
            [`${prefix}ClusterAvgLongTaskCount`]: (0, ($parcel$interopDefault($gZFbt)))(data, `${prefix}ClusterAvgLongTaskCount`) || 0,
            [`${prefix}ClusterAvgLongTaskAvg`]: data.length > 0 ? (0, ($parcel$interopDefault($iThRn)))(data.map((entry)=>entry[`${prefix}ClusterAvgLongTaskCount`] * entry[`${prefix}ClusterAvgLongTaskAvg`])) / (0, ($parcel$interopDefault($6oxJH)))(data, `${prefix}ClusterAvgLongTaskCount`) : 0,
            [`${prefix}Count`]: data.length
        });
    const $3a39af364070fc96$export$7e5f2df95eca342a = (prefix, longTasks)=>{
        const clusters = longTasks.reduce((foundClusters, task)=>{
            if (foundClusters.length === 0) foundClusters.push({
                tasks: [],
                start: 0,
                stop: 0
            });
            const prevCluster = foundClusters[foundClusters.length - 1];
            const taskEnd = task.timestamp + task.duration;
            if (prevCluster.tasks.length === 0) {
                prevCluster.tasks.push(task);
                prevCluster.start = task.timestamp;
                prevCluster.stop = taskEnd;
            } else if (task.timestamp - prevCluster.stop < 100) {
                prevCluster.tasks.push(task);
                prevCluster.stop = taskEnd;
            } else foundClusters.push({
                tasks: [
                    task
                ],
                start: task.timestamp,
                stop: taskEnd
            });
            return foundClusters;
        }, []);
        const clusterDurations = clusters.map((cluster)=>cluster.stop - cluster.start);
        const tasksDurations = longTasks.map((task)=>task.duration);
        const gapsBetweenClusters = clusters.length > 1 ? clusters.reduce((gaps, cluster, i)=>{
            if (gaps.length === 0) {
                gaps.push({
                    start: cluster.stop,
                    stop: null
                });
                return gaps;
            }
            gaps[gaps.length - 1].stop = cluster.start;
            if (i !== clusters.length - 1) gaps.push({
                start: cluster.stop,
                stop: null
            });
            return gaps;
        }, []).map((gap)=>gap.stop - gap.start) : [];
        return {
            [`${prefix}ClusterCount`]: clusters.length,
            [`${prefix}ClusterAvg`]: clusters.length > 0 ? clusterDurations.reduce((sumAcc, duration)=>sumAcc + duration, 0) / clusters.length : 0,
            [`${prefix}ClusterDistanceAvg`]: gapsBetweenClusters.length > 0 ? gapsBetweenClusters.reduce((sumAcc, duration)=>sumAcc + duration, 0) / gapsBetweenClusters.length : 0,
            [`${prefix}ClusterAvgLongTaskCount`]: clusters.length > 0 ? clusters.map((cluster)=>cluster.tasks.length).reduce((sumAcc, count)=>sumAcc + count, 0) / clusters.length : 0,
            [`${prefix}ClusterAvgLongTaskAvg`]: longTasks.length > 0 ? tasksDurations.reduce((sumAcc, duration)=>sumAcc + duration, 0) / longTasks.length : 0
        };
    };
});
parcelRegister("iThRn", function(module, exports) {
    var $gBzAF = parcelRequire("gBzAF");
    var $etyzk = parcelRequire("etyzk");
    function $dc0b37ac120f9bb6$var$sum(array) {
        return array && array.length ? $gBzAF(array, $etyzk) : 0;
    }
    module.exports = $dc0b37ac120f9bb6$var$sum;
});
parcelRegister("6oxJH", function(module, exports) {
    var $bxDef = parcelRequire("bxDef");
    var $gBzAF = parcelRequire("gBzAF");
    function $4a7f273a61dd10a7$var$sumBy(array, iteratee) {
        return array && array.length ? $gBzAF(array, $bxDef(iteratee, 2)) : 0;
    }
    module.exports = $4a7f273a61dd10a7$var$sumBy;
});
parcelRegister("dW6W0", function(module, exports) {
    $parcel$export(module.exports, "useIsReportsPage", ()=>$415cf33c3312e540$export$7f768b3cf3a7f9a4);
    var $65Ltd;
    var $6jY6G;
    const $415cf33c3312e540$export$391661b031b686a4 = (name, callback)=>{
        window.addEventListener(name, callback);
        return ()=>window.removeEventListener(name, callback);
    };
    const $415cf33c3312e540$export$7f768b3cf3a7f9a4 = ()=>{
        const route = (0, (0, parcelRequire("6jY6G")).useCurrentRoute)();
        return (0, (0, parcelRequire("65Ltd")).ROUTE_NAMES_RAPIDBOARD_REPORT) === route.name;
    };
});
const $8e4c2c85f99b85c0$export$f14ca5709988143a = 'spa-apps-servicedesk-legacy-board-settings.ui.workflows-link';
parcelRegister("7Q7Lr", function(module, exports) {
    $parcel$export(module.exports, "default", ()=>$be2e6346e8328cbd$export$2e2bcd8739ae039);
    var $7VHMR;
    var $be2e6346e8328cbd$export$2e2bcd8739ae039 = (0, (0, parcelRequire("7VHMR")).defineMessages)({
        columnConfigDescriptionCmp: {
            "id": "spa-apps-servicedesk-legacy-board-settings.column-config-description-cmp",
            "defaultMessage": "Add, rename, or remove columns on this board. Columns are represented by a global status, with each containing the statuses belonging to workflows created in this project. <a>View workflows to edit statuses</a>."
        },
        columnConfigDescriptionTmp: {
            "id": "spa-apps-servicedesk-legacy-board-settings.column-config-description-tmp",
            "defaultMessage": "Add, rename, or remove columns on this board. Columns are represented by a global status, with each containing the statuses belonging to workflows created in this project. <a>View workflows for each request type to edit statuses</a>."
        },
        columnConstraintGroupInstructions: {
            "id": "spa-apps-servicedesk-legacy-board-settings.column-constraint-group-instructions",
            "defaultMessage": "Minimum and maximum constraints can be set for each mapped column."
        }
    });
});
const $4cff3ad70ea80f75$export$6c8cb487d6ffc522 = 'QueryError';
const $4cff3ad70ea80f75$export$b171488d52070cb3 = 'JiraIssueFieldUnsupportedErrorExtension';
const $4cff3ad70ea80f75$export$14b50ab9fbf2d370 = 'JiraIssueFieldValueValidationErrorExtension';
const $4cff3ad70ea80f75$export$3d1cda9bd50505e8 = 'SERVICE_REQUEST';
const $4cff3ad70ea80f75$export$48c144830383423 = 'INCIDENT_MANAGEMENT';
const $4cff3ad70ea80f75$export$1ea6ead7bcee4494 = 'PROBLEM_MANAGEMENT';
const $4cff3ad70ea80f75$export$b92ccb6dc3cdb548 = 'CHANGE_MANAGEMENT';
const $4cff3ad70ea80f75$export$a4a741b969f6509c = 'POST_INCIDENT_REVIEW';
const $4cff3ad70ea80f75$export$81e62964cc725098 = 'DEVELOPER_ESCALATION';
const $4cff3ad70ea80f75$export$92ab80c1dc9baf44 = 'REQUEST_TYPE';
const $4cff3ad70ea80f75$export$ebb48b08205007c5 = 'ISSUE_TYPE';
const $4cff3ad70ea80f75$export$c835df30ebbd529d = 'UNASSIGNED_REQUEST_TYPE';
