import React, { useCallback, useMemo } from 'react';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import type { EntryPointActions } from '@atlassian/jira-entry-point/src/controllers/utils/types.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { errorFlags, useFlagsService } from '@atlassian/jira-flags';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { editSprintModalEntryPoint } from '@atlassian/jira-software-edit-sprint-modal-relay/entrypoint.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { OnSubmit } from '../../types.tsx';

export interface EditSprintModalEntryPointProps {
	boardId: number;
	isCompanyManaged: boolean;
	entryPointActions: EntryPointActions;
	entryPointReferenceSubject: EntryPointReferenceSubject<typeof editSprintModalEntryPoint>;
	triggerRef?: React.RefObject<HTMLElement>;
	onSubmit: OnSubmit;
	onReady?: () => void;
	onClose?: () => void;
}

export const EditSprintModalEntryPointContainer = ({
	boardId,
	isCompanyManaged,
	entryPointActions,
	entryPointReferenceSubject,
	triggerRef,
	onSubmit,
	onReady,
	onClose,
}: EditSprintModalEntryPointProps) => {
	const cloudId = useCloudId();
	const { showFlag } = useFlagsService();

	const boardAri = createAri({
		resourceId: String(boardId),
		resourceType: 'board',
		resourceOwner: 'jira-software',
		cloudId,
	});

	const handleSubmit = useCallback(
		(data: Parameters<OnSubmit>[0], requestPromise: Parameters<OnSubmit>[1]) => {
			// Show a generic error flag on request failure
			requestPromise.catch(() => {
				showFlag(errorFlags.genericError());
				return { editSprint: null };
			});

			onSubmit(data, requestPromise);
		},
		[onSubmit, showFlag],
	);

	const props = useMemo(
		() => ({
			boardAri,
			isCompanyManaged,
			triggerRef,
			fetchSprints: true,
			onSubmit: handleSubmit,
			...(onReady && { onReady }),
			...(onClose && { onClose }),
		}),
		[boardAri, isCompanyManaged, handleSubmit, onReady, onClose, triggerRef],
	);

	return (
		<ModalEntryPointContainer
			id="edit-sprint-modal"
			teamName="a4t-pandora"
			packageName="jiraSoftwareEditSprintModalRelay"
			entryPointActions={entryPointActions}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
		/>
	);
};
