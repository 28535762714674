import React, { type ReactNode } from 'react';
import { GenericPermissionErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import { LICENSE_STATE_ACTIVE } from '@atlassian/jira-shared-types/src/license-state.tsx';
import { LicenseStateCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/license-state-check/index.tsx';

type Props = {
	children: ReactNode;
};

export const LicenseCheckGeneric = ({ children }: Props) => (
	<LicenseStateCheck type="servicedesk">
		{/* @ts-expect-error - TS2322 - Type '({ licenseState }: { licenseState: ApplicationLicenseState; }) => ReactNode' is not assignable to type '(arg1: { licenseState: ApplicationLicenseState; }) => ReactElement<any, string | JSXElementConstructor<any>>'. */}
		{({ licenseState }) => {
			if (licenseState === LICENSE_STATE_ACTIVE) {
				return children;
			}
			return <GenericPermissionErrorPageAsync />;
		}}
	</LicenseStateCheck>
);
