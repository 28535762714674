import React from 'react';
import { DocumentTitle as GlobalDocumentTitleDI } from '@atlassian/jira-global-document-title/src/index.tsx';
import { useIntlV2 as useIntlDI } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useContainerContext as useContainerContextDI } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { messages } from './messages.tsx';
import type { Props } from './types.tsx';

export const DocumentTitle = ({
	useIntl = useIntlDI,
	useContainerContext = useContainerContextDI,
	// @ts-expect-error - TS2322 - Type 'typeof DocumentTitle' is not assignable to type 'ComponentType<{ title: string; }>'.
	GlobalDocumentTitle = GlobalDocumentTitleDI,
}: Props) => {
	const { formatMessage } = useIntl();
	const [{ data, error }] = useContainerContext();

	const boardName = data?.scope?.name;
	if (boardName == null || error) {
		return null;
	}

	return <GlobalDocumentTitle title={`${boardName} - ${formatMessage(messages.agileBoard)}`} />;
};
