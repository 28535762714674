import React from 'react';
import { IssueActions } from './issue-actions/index.tsx';

export const RapidBoardRoot = () => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<div id="content" className="z-index-content">
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
		<div id="gh" className="ghx-gh aui-page-panel">
			<div id="ghx-header" />
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div id="ghx-content-main" className="ghx-content-main" />
		</div>
		<IssueActions />
	</div>
);
