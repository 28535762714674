import React, { type ReactNode } from 'react';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';

const invokeGhShortcut = (shortcutFunc: string, params?: string[]) => {
	const { GH } = window;
	if (GH && GH.Shortcut) {
		GH.Shortcut[shortcutFunc](...(params || []));
	}
};

const invokeGlobalShortcut = (shortcutFunc: string, params?: (null | string)[]) => {
	const { gh } = window;
	if (gh && gh.app && gh.app.globalkeyboardshortcuts) {
		gh.app.globalkeyboardshortcuts[shortcutFunc](...(params || []));
	}
};

const baseKeyMap = {
	'g g': {
		callback: () => {
			const { JIRA, requestAnimationFrame } = window;
			requestAnimationFrame(() => JIRA?.Shifter?.show?.());
		},
	},
	'g h': {
		callback: () => invokeGlobalShortcut('goToAgile'),
	},
	'g a': {
		callback: () => invokeGlobalShortcut('goToAgile'),
	},
	's t': {
		callback: () => invokeGlobalShortcut('sendToTop', ['KeyboardShortcut', null, 'st']),
	},
	's b': {
		callback: () => invokeGlobalShortcut('sendToBottom', ['KeyboardShortcut', null, 'sb']),
	},
	b: {
		callback: () => invokeGhShortcut('focusBoardSearch', ['b']),
	},
	n: {
		callback: () => invokeGhShortcut('nextBox', ['n']),
	},
	p: {
		callback: () => invokeGhShortcut('previousBox', ['p']),
	},
	t: {
		callback: () => invokeGhShortcut('toggleIssue', ['t']),
	},
	j: {
		callback: () => invokeGhShortcut('nextIssue', ['j']),
	},
	k: {
		callback: () => invokeGhShortcut('previousIssue', ['k']),
	},
	o: {
		callback: () => invokeGhShortcut('viewIssue'),
	},
	e: {
		callback: () => invokeGhShortcut('editIssue', ['e']),
	},
	'.': {
		callback: () => {
			const { JIRA, GH, requestAnimationFrame } = window;
			if (GH?.IssueOperationShortcuts?.getSelectedIssueKey?.()) {
				requestAnimationFrame(() => invokeGhShortcut('showOperationsDialog', ['.']));
			} else {
				requestAnimationFrame(() => JIRA?.Shifter?.show?.());
			}
		},
	},
	z: {
		callback: () => invokeGhShortcut('projector', ['z']),
	},
	'-': {
		callback: () => invokeGhShortcut('toggleSwimlanes', ['-']),
	},
	'1': {
		callback: () => invokeGhShortcut('planMode', ['1']),
	},
	'2': {
		callback: () => invokeGhShortcut('workMode', ['2']),
	},
	'3': {
		callback: () => invokeGhShortcut('reportMode', ['3']),
	},
} as const;

const legacyIssueKeyMap = {
	...baseKeyMap,
	a: {
		callback: () => invokeGhShortcut('assignIssue', ['a']),
	},
	i: {
		callback: () => invokeGhShortcut('assignIssueToMe', ['i']),
	},
	m: {
		callback: () => invokeGhShortcut('commentIssue', ['m']),
	},
	l: {
		callback: () => invokeGhShortcut('editIssueLabels', ['l']),
	},
} as const;

export const KeyboardShortcuts = ({
	children,
	isBentoEnabled,
}: {
	children: ReactNode;
	isBentoEnabled: boolean;
}) => {
	const keyMap = isBentoEnabled ? baseKeyMap : legacyIssueKeyMap;

	return (
		<>
			<Shortcuts keyMap={keyMap} />
			{children}
		</>
	);
};

export default KeyboardShortcuts;
