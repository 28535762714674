export const WORK = 'work' as const;
export const PLAN = 'plan' as const;
export const INITIAL_DATA = 'initial-data' as const;
export const CONFIG_DATA = 'config-data' as const;
export const REPORT = 'report' as const;

export const SUCCESS = 'success';
export const FAILURE = 'failure';
export const ALL_DATA_PROMISE = 'all-data-promise';

export const GH_SPA_RESOURCES_READY = 'GH_SPA_RESOURCES_READY';
export const GH_SPA_RB_READY = 'GH_SPA_RB_READY';
export const GH_SPA_CONTENT_READY = 'GH_SPA_SPINNER_HIDE';
export const GH_SPA_CONTENT_LOADING = 'GH_SPA_SPINNER_SHOW';

export const BENTO_ROOT = 'ghx-issue-fragment';

export const WRM_HERITAGE_RESOURCES_ARRAY = [
	// superbatch resources

	// SKATE HAS TO COME FIRST OR ELSE THE OTHER MODULES WILL FAIL
	'wrc!jira.webresources:skate',
	'wrc!jira.webresources:util-lite',
	'wrc!jira.webresources:util',
	'wrc!jira.webresources:feature-flags',
	'wrc!jira.webresources:dialogs',
	'wrc!jira.webresources:jira-global',
	'wrc!com.atlassian.auiplugin:aui-spinner',

	// analytics
	'wrc!com.atlassian.jira.jira-client-analytics-plugin:analytics-web-client',

	// dependencies from HeaderFooterRendering
	'wrc!jira.webresources:key-commands',
	'wrc!jira.webresources:header',

	// overrides for AUI, styles and bridge apis
	'wrc!com.atlassian.jira.jira-atlaskit-plugin:sidebar',
	'wrc!com.atlassian.jira.jira-atlaskit-plugin:overrides-general',
	'wrc!com.atlassian.jira.jira-atlaskit-plugin:overrides-dialogs',
	'wrc!com.atlassian.jira.jira-atlaskit-plugin:breadcrumbs',
	'wrc!com.atlassian.jira.jira-atlaskit-plugin:profilecard',
	'wrc!com.atlassian.jira.jira-atlaskit-plugin:ipblocklisting',
	'wrc!com.atlassian.jira.jira-atlaskit-plugin:async-modals',

	'wrc!jira.webresources:global-static',

	// browser metrics
	'wrc!com.atlassian.jira.plugins.jira-browser-metrics:contrib',
	'wrc!com.atlassian.plugins.browser.metrics.browser-metrics-plugin:impl',
	'wrc!com.atlassian.plugins.browser.metrics.browser-metrics-plugin:api',

	// old analytics library
	'wrc!com.atlassian.analytics.analytics-client:js-events',
];
