import React from 'react';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { Redirect } from '@atlassian/react-resource-router';
import { useUrlState } from '../../controllers/url-state/index.tsx';

export const NextGenRedirect = () => {
	const [{ data, error }] = useContainerContext();
	const { boardId } = useUrlState();

	if (error || !data) {
		return null;
	}

	const scopeId = data.scope && data.scope.id;
	if (
		// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
		data.project &&
		// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
		data.project.isSimplified &&
		scopeId != null &&
		scopeId === Number(boardId)
	) {
		// @ts-expect-error - TS2339 - Property 'project' does not exist on type 'ContainerContext'.
		return <Redirect to={`/jira/software/projects/${data.project.key}/boards/${scopeId}`} />;
	}

	return null;
};
