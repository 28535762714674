import React from 'react';
import {
	ExperienceFailureTracker as ViewExperienceFailureTracker,
	ExperienceSuccessTracker as ViewExperienceSuccessTracker,
} from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { FAILURE } from '../../constants.tsx';
import { useSLAHandler } from '../../controllers/sla-handler/main.tsx';
import { getSLAResult } from '../../controllers/sla-handler/utils.tsx';
import { useUrlState } from '../../controllers/url-state/index.tsx';
import { UNKNOWN_STATUSCODE } from './constants.tsx';
import {
	getFailedReason,
	getStatusCode,
	getDataAttributes,
	isUserNetworkError,
	getErrorMessage,
	getDebugAttributes,
	isBoardSettings,
} from './utils.tsx';

export const SLATracker = () => {
	const { slaFailure, hasFinishedRetrying } = useSLAHandler();
	const { mode, config } = useUrlState();
	if (
		hasFinishedRetrying &&
		slaFailure !== null &&
		slaFailure !== undefined &&
		!isBoardSettings(config)
	) {
		const { name = '' } = slaFailure;
		if (isUserNetworkError(name)) {
			return null;
		}

		const failedReason = getFailedReason(slaFailure);
		const status = getStatusCode(slaFailure);

		const slaResult = getSLAResult(status, mode);
		const isSLAFailureDetected = slaResult.state === FAILURE;

		if (isSLAFailureDetected) {
			/* The task and boardType wont be available in case wrm fails
            The only solution to solve above is to copy paste those code here and retrieve from route resource board config which is kind of hacky
            */
			const dataAttributes = getDataAttributes();

			return (
				<ViewExperienceFailureTracker
					location={failedReason}
					failureEventAttributes={{
						statusCode: status === null ? UNKNOWN_STATUSCODE : status,
						errorMessage: getErrorMessage(failedReason, status),
						debugAttribute: getDebugAttributes(mode, status, slaFailure),
						...dataAttributes,
					}}
				/>
			);
		}
		return <ViewExperienceSuccessTracker location={failedReason} />;
	}
	return null;
};
