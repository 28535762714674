import {
	ROUTE_NAMES_RAPIDBOARD_BOARD,
	ROUTE_NAMES_RAPIDBOARD_BACKLOG,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';

const ACTIVE_QUICK_FILTERS = 'activeQuickFilters';
const PLAN_QUICK_FILTERS = 'planQuickFilters';

export const getActiveQuickFilters = (name: string) => {
	const { GH } = window;
	if (GH?.RapidBoard?.State) {
		if (name === ROUTE_NAMES_RAPIDBOARD_BOARD) {
			return GH.RapidBoard.State.getBoardSettings()[ACTIVE_QUICK_FILTERS];
		}
		if (name === ROUTE_NAMES_RAPIDBOARD_BACKLOG) {
			return GH.RapidBoard.State.getBoardSettings()[PLAN_QUICK_FILTERS];
		}
	}
	return '';
};
