import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issueUpdated: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-updated',
		defaultMessage: '{0} has been updated.',
		description: 'Issue updated in classic software project',
	},
	issueTransitioned: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-transitioned',
		defaultMessage: '{0} has been updated.',
		description: 'Issue transitioned in classic software project',
	},
	issueAssigned: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-assigned',
		defaultMessage: '{0} has been assigned.',
		description: 'Issue assigned in classic software project',
	},
	issueCommented: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-commented',
		defaultMessage: '{0} has been updated with your comment.',
		description: 'Issue commented in classic software project',
	},
	issueWorklogged: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-worklogged',
		defaultMessage: 'Work has been logged on {0}.',
		description: 'Work logged on issue in classic software project',
	},
	issueVoted: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-voted',
		defaultMessage: 'Your vote on {0} has been updated.',
		description: 'Issue voted on in classic software project',
	},
	issueWatched: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-watched',
		defaultMessage: 'Your watcher status on {0} has been updated.',
		description: 'Issue watched in classic software project',
	},
	issueMoved: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-moved',
		defaultMessage: '{0} has been moved.',
		description: 'Issue moved in classic software project',
	},
	issueLinked: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-linked',
		defaultMessage: 'The links on {0} have been updated.',
		description: 'Issue linked in classic software project',
	},
	issueCloned: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-cloned',
		defaultMessage: '{0} has been cloned.',
		description: 'Issue cloned in classic software project',
	},
	issueLabelled: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-labelled',
		defaultMessage: 'The labels on {0} have been updated.',
		description: 'Issue labelled in classic software project',
	},
	issueDeleted: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-deleted',
		defaultMessage: '{0} has been deleted.',
		description: 'Issue deleted in classic software project',
	},
	issueAttached: {
		id: 'classic-software.rapidboard-content.rapidboard-root.issue-actions.issue-attached',
		defaultMessage: 'The file(s) have been attached to {0}.',
		description: 'Files attached to issue in classic software project',
	},
});
