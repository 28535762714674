import { metrics } from '@atlassian/browser-metrics';
import { INSIGHT_BUTTON_MARK_KEY } from '../../ui/rapidboard-app/insights-button/utils.tsx';

export const classicBoard = metrics.pageLoad({
	key: 'classic-board',
	featureFlags: [
		'jsw.classic.rapid.board.refresh.button',
		'jsw.classic.board.api-calls.modern-frontend',
	],
});

export const classicBacklog = metrics.pageLoad({
	key: 'classic-backlog',
	featureFlags: ['jsw.classic.board.api-calls.modern-frontend'],
});

export const classicReport = metrics.pageLoad({
	key: 'classic-report',
});

export const classicBoardInsightButton = metrics.interaction({
	key: INSIGHT_BUTTON_MARK_KEY,
});
