import React from 'react';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazySuspendedSoftwareLicense = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-suspended-software-license", jiraPriority: 'ondemand' */ './index'
		),
);

export const AsyncSuspendedSoftwareLicense = () => (
	<Placeholder name="suspended-software-license" fallback={<Spinner />}>
		<LazySuspendedSoftwareLicense />
	</Placeholder>
);
