import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

type Options = {
	key: string;
	url: string;
	query:
		| string
		| {
				[key: string]: string;
		  };
	stallTimeout?: number;
};

const getUrl = (
	url: string,
	query:
		| string
		| {
				[key: string]: string;
		  },
): string => {
	const queryParams = new URLSearchParams(query).toString();
	return url.indexOf('?') > 0 ? `${url}&${queryParams}` : `${url}?${queryParams}`;
};

export const STALL_TIMEOUT = 300; // Avoid subsequent call happening within 300ms

const refreshWrapper = ({ stallTimeout }: { stallTimeout: number }) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const reqCache: Record<string, any> = {};

	const getXHRData = (key: string) => {
		const data = { timestamp: 0 };
		if (reqCache[key]) {
			return reqCache[key];
		}
		reqCache[key] = data;
		return reqCache[key];
	};

	const clearXHRMap = (key: string) => {
		const reqCacheKeyMap = getXHRData(key);
		if (reqCacheKeyMap.xhr) {
			delete reqCacheKeyMap.xhr;
			delete reqCacheKeyMap.abortController;
		}
	};

	const executeRequest = <T,>(key: string, finalUrl: string): Promise<T | FetchError> => {
		const reqCacheKeyMap = getXHRData(key);
		const abortController = new AbortController();
		reqCacheKeyMap.abortController = abortController;
		return fetchJson(finalUrl, {
			method: 'GET',
			signal: reqCacheKeyMap.abortController.signal,
		})
			.then((res) => {
				clearXHRMap(key);
				return res;
			})
			.catch((err) => {
				clearXHRMap(key);
				// request successfully completed, remove xhr object from registry
				throw err;
			});
	};

	const makeAPICall = <T,>(options: Options): Promise<T | FetchError | undefined> => {
		const { key, url, query } = options;

		const reqCacheKeyMap = getXHRData(key);
		if (reqCacheKeyMap.xhr) {
			// Call is already in progress so kill the previous one
			reqCacheKeyMap.abortController?.abort();
			delete reqCacheKeyMap.xhr;
			clearTimeout(reqCacheKeyMap.timeoutId); //
			reqCacheKeyMap.timeoutId = null;
		}

		// Check whether call is made within stallTimeout
		const { timestamp } = reqCacheKeyMap;

		reqCacheKeyMap.timestamp = Date.now();

		if (reqCacheKeyMap.timestamp - timestamp < stallTimeout) {
			// Call is make recently
			// WIll schedule the next call after stallTimeout  time
			reqCacheKeyMap.timeoutId = setTimeout(() => {
				reqCacheKeyMap.xhr = makeAPICall(options);
			}, stallTimeout);
			// Add mock xhr value to reqCacheKeyMap so that if the call happens within 5 min it will clear all those
			clearXHRMap(key);
			reqCacheKeyMap.xhr = Promise.resolve();
		} else {
			reqCacheKeyMap.xhr = executeRequest(key, getUrl(url, query));
		}

		return reqCacheKeyMap.xhr;
	};

	return { makeAPICall, getXHRData };
};

let refreshCache: ReturnType<typeof refreshWrapper> | null = null;

const getRefreshCache = ({ stallTimeout }: { stallTimeout: number | undefined }) => {
	const stallTime = stallTimeout !== undefined ? stallTimeout : STALL_TIMEOUT;
	if (refreshCache === null) {
		refreshCache = refreshWrapper({
			stallTimeout: stallTime,
		});
	}
	return refreshCache;
};

export const refresh = <T,>(options: Options): Promise<T | FetchError | undefined> => {
	const cache = getRefreshCache({ stallTimeout: options.stallTimeout });

	return cache.makeAPICall<T>(options);
};

export const getXHRData = (key: string, stallTimeout?: number) => {
	const cache = getRefreshCache({ stallTimeout });
	return cache.getXHRData(key);
};

// Ideally only needed for test clearance
export const clearRefreshCache = (): void => {
	refreshCache = null;
};
