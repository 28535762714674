import React, { memo } from 'react';
import { styled } from '@compiled/react';
import BreadcrumbRenderer from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import { ROUTE_NAMES_SERVICEDESK_BOARDS } from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { AsyncLaunchToolchainSpotlight } from '@atlassian/jira-devops-launch-toolchain-spotlight/src/async.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';
import { StorageLimitsBanner } from '@atlassian/jira-storage-limits-banner/src/ui/async.tsx';
import { UpFlowPersistentUpgradeBanner } from '@atlassian/jira-up-flow-persistent-upgrade-banner/src/async.tsx';
import { KeyboardShortcuts } from '../../controllers/keyboard-shortcuts/index.tsx';
import { useGICSidebarForcer } from '../../controllers/use-gic-sidebar-forcer/index.tsx';
import { isPlanningMode, isWorkMode } from '../../utils.tsx';
import { BreadcrumbsWrapper } from './breadcrumbs/index.tsx';
import { Breadcrumbs } from './breadcrumbs/main.tsx';
import { LicenseCheckGeneric } from './generic-license-check/index.tsx';
import { LicenseCheck } from './licence-check/index.tsx';
import { LocationlessDialog } from './locationless-dialog/index.tsx';
import { RapidBoardRoot } from './rapidboard-root/index.tsx';
import { StyleReset } from './style-reset/index.tsx';

type Props = {
	isRapidBoardsReady: boolean;
	isBentoEnabled: boolean;
	mode: string;
};

type ProjectData = {
	project?: {
		id?: number;
		key?: string;
	};
};

export const RapidboardContent = memo<Props>(
	({ isRapidBoardsReady, isBentoEnabled, mode }: Props) => {
		const route = useCurrentRoute();
		const [{ data }] = useContainerContext();
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const projectData = data as ProjectData;
		const projectKey = projectData?.project?.key;
		const projectId = projectData?.project?.id;

		useGICSidebarForcer();

		const Wrapper =
			route.name === ROUTE_NAMES_SERVICEDESK_BOARDS ? LicenseCheckGeneric : LicenseCheck;

		return (
			<Wrapper>
				{!isRapidBoardsReady && route.skeleton && <route.skeleton />}
				<LocationlessDialog />
				<KeyboardShortcuts isBentoEnabled={isBentoEnabled}>
					<HideContent shouldHide={!isRapidBoardsReady}>
						<StorageLimitsBanner />
						{isRapidBoardsReady && (isPlanningMode(mode) || isWorkMode(mode)) && (
							<UpFlowPersistentUpgradeBanner isBannerEnabled />
						)}
						{/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */}
						<BreadcrumbsWrapper data-testid="rapidboard-breadcrumbs">
							<BreadcrumbRenderer />
						</BreadcrumbsWrapper>
						<StyleReset>
							<Breadcrumbs />
							<RapidBoardRoot />
							{projectKey != null && projectId != null && (
								<AsyncLaunchToolchainSpotlight projectId={projectId} projectKey={projectKey} />
							)}
						</StyleReset>
					</HideContent>
				</KeyboardShortcuts>
			</Wrapper>
		);
	},
);

type HideContentProps = {
	shouldHide?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const HideContent = styled.div<{ shouldHide?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: (props: HideContentProps) => (props.shouldHide ? 'none' : 'block'),
});
