import React, { memo } from 'react';
import Breadcrumb from '@atlassian/jira-breadcrumbs/src/breadcrumb/component.tsx';
import ProjectBreadcrumb from '@atlassian/jira-breadcrumbs/src/common-breadcrumbs/project/index.tsx';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	isSimplifiedProject: boolean;
	baseUrl: string;
	boardId: number;
	boardName: string;
	projectKey: string;
	projectName: string;
	projectId: string;
	chart: string;
};

export const ProjectBreadcrumbs = memo<Props>(
	({
		baseUrl,
		boardId,
		boardName,
		projectKey,
		projectName,
		projectId,
		isSimplifiedProject,
		chart,
	}: Props) => {
		const { formatMessage } = useIntl();
		return (
			<ProjectBreadcrumb
				baseUrl={baseUrl}
				projectKey={projectKey}
				projectName={projectName}
				projectId={projectId}
				isSimplifiedProject={isSimplifiedProject}
			>
				<Breadcrumb
					breadcrumb={{
						text: boardName,
						href: `/jira/software/c/projects/${projectKey}/boards/${boardId}`,
						type: 'boardName',
						analyticsAttributes: {
							nextGenProject: isSimplifiedProject,
							projectConfig: isSimplifiedProject ? 'next-gen' : 'classic',
						},
						analyticsFields: {
							name: 'navigateToSubProject',
							containerId: projectId,
							containerType: 'project',
						},
					}}
				>
					{chart && (
						<Breadcrumb
							breadcrumb={{
								text: formatMessage(messages.projectReportBreadcrumb),
								href: `/projects/${projectKey}?selectedItem=com.atlassian.jira.jira-projects-plugin:report-page`,
							}}
						/>
					)}
				</Breadcrumb>
			</ProjectBreadcrumb>
		);
	},
);
