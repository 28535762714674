import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy, LazyWait } from '@atlassian/react-loosely-lazy';
import IssueView from './issue-view/async.tsx';

type Props = {
	shouldLoad: boolean;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyRapidApps = lazy(
	() => import(/* webpackChunkName: "async-rapid-board-apps-defer" */ './index'),
	{ ssr: false },
);

export const RapidBoardApps = ({ shouldLoad }: Props) => (
	<ErrorBoundary id="async.rapidboard-button.bridge.defer" packageName="jiraClassicBoardAndBacklog">
		<LazyWait until={shouldLoad}>
			<Placeholder name="rapid-apps" fallback={null}>
				<LazyRapidApps />
			</Placeholder>
		</LazyWait>
	</ErrorBoundary>
);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { IssueView };
